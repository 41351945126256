<template>
  <TheAccountMenuPopupService
    v-if="isAccessible"
    service="review_addon"
    :items="items"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceReviewAddon',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('smartstore', ['smartstoreExpiresAt']),
    ...mapState('session', ['adminSettings', 'reviewSyncStatus']),
    items() {
      return [...this.smartstoreItems, ...this.reviewSyncItems];
    },
    smartstoreItems() {
      return this.smartstoreExpiresAt
        ? [
            {
              title: this.$t('smartstore')
            },
            {
              title: this.$t('smartstore_expires_on'),
              content: this.formatDate(this.smartstoreExpiresAt)
            }
          ]
        : [];
    },
    reviewSyncItems() {
      return this.reviewSyncStatus?.review_sync_expires_at &&
        this.adminSettings?.enable_renewed_review_sync
        ? [
            {
              title: this.$t('review_sync')
            },
            {
              title: this.$t('review_sync_expires_on'),
              content: this.formatDate(
                this.reviewSyncStatus?.review_sync_expires_at
              )
            }
          ]
        : [];
    },
    isAccessible() {
      return !!(
        this.smartstoreExpiresAt ||
        (this.reviewSyncStatus?.review_sync_expires_at &&
          this.adminSettings?.enable_renewed_review_sync)
      );
    }
  },
  mounted() {
    this.fetchAddonInfos();
  },
  methods: {
    ...mapActions('smartstore', ['fetchSmartstoreServiceInfo']),
    fetchAddonInfos() {
      this.fetchSmartstoreServiceInfo();
    }
  }
};
</script>

<i18n locale="ko">
{
  "smartstore": "스마트스토어",
  "smartstore_expires_on": "매일리뷰연동 만료일",
  "review_sync": "쇼핑몰간",
  "review_sync_expires_on": "리뷰연동 만료일"
}
</i18n>
