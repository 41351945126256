<template>
  <AppModalFormLivePreview
    class="ChatbotMessageTemplateShowDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{ ...formProps, disabled: true }"
    width="560px"
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview :template="template" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'content'">
        <div
          class="ChatbotMessageTemplateShowDialog__content AppForm__group-field"
        >
          {{ template.content }}
        </div>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplatePreview from '../../biz_messages/dialogs/BizMessageTemplatePreview.vue';

export default {
  name: 'ChatbotMessageTemplateShowDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView],
  props: {
    template: { type: Object, default: null }
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              type: 'static',
              label: this.$t('template.name'),
              value: this.template.name
            },
            {
              id: 'content',
              type: 'custom',
              label: this.$t('template.content'),
              value: this.template.content
            }
          ]
        }
      ];
    }
  }
};
</script>

<style scoped>
.ChatbotMessageTemplateShowDialog__content {
  white-space: pre-line;
}
</style>

<i18n locale="ko">
{
  "title": "템플릿 상세보기",
  "sub_title": "챗봇",
  "template": {
    "name": "템플릿 명",
    "content": "템플릿 내용"
  }
}
</i18n>
