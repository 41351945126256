<template>
  <AppIntroContent
    document-url="https://cremafactory.notion.site/e23816bb0da5443493dc4c7824ee57ec"
    :document-label="$t('document_label')"
    :image-urls="[
      'https://assets.cre.ma/m/admin/crema-insight-intro-v1-1.png',
      'https://assets.cre.ma/m/admin/crema-insight-intro-v1-2.gif',
      'https://assets.cre.ma/m/admin/crema-insight-intro-v1-3.png',
      'https://assets.cre.ma/m/admin/crema-insight-intro-v1-4.gif',
      'https://assets.cre.ma/m/admin/crema-insight-intro-v1-5.png'
    ]"
    :image-width="600"
  />
</template>

<script>
export default {
  name: 'InsightIntro'
};
</script>

<i18n locale="ko">
{
  "document_label": "서비스 매뉴얼 보기"
}
</i18n>
