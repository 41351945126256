import api from '@/lib/api';
import i18n from '@/lib/i18n';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';

const state = {
  templateType: null,
  templates: []
};

const mutations = {
  SET_TEMPLATE_TYPE(state, templateType) {
    state.templateType = templateType;
  },
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  APPEND_TEMPLATE(state, template) {
    state.templates = [...state.templates, template];
  },
  UPDATE_TEMPLATE(state, template) {
    state.templates = state.templates.map(e =>
      e.id === template.id ? template : e
    );
  },
  DELETE_TEMPLATE(state, id) {
    state.templates = state.templates.filter(template => template.id !== id);
  }
};

const getters = {
  isGroupTemplate: ({ templateType }) => {
    return templateType === BizMessageTemplateType.GROUP;
  }
};

function bizMessageTemplateUrl() {
  const { templateType } = state;
  if (templateType === null) return null;

  return templateType === BizMessageTemplateType.NORMAL
    ? '/biz_message/templates'
    : '/biz_message/group_templates';
}

const actions = {
  setTemplateType: ({ commit }, templateType) => {
    commit('SET_TEMPLATE_TYPE', templateType);
  },
  fetchTemplates: ({ commit }, serviceType) => {
    return api
      .get(bizMessageTemplateUrl(), { params: { service_type: serviceType } })
      .then(({ data }) => {
        commit('bizMessage/SET_BIZ_MESSAGE_PROFILE', data.profile, {
          root: true
        });
        commit('SET_TEMPLATES', data.templates);
      });
  },
  fetchDefaultGroupTemplates: ({ commit }, serviceType) => {
    return api
      .get(`${bizMessageTemplateUrl()}/default`, {
        params: { service_type: serviceType }
      })
      .then(({ data }) => commit('SET_TEMPLATES', data.templates));
  },
  fetchTemplate: (_, id) => api.get(`${bizMessageTemplateUrl()}/${id}`),
  fetchTemplateByTemplateType: (_, { id, templateType }) =>
    api.get(
      `${
        templateType === BizMessageTemplateType.NORMAL
          ? '/biz_message/templates'
          : '/biz_message/group_templates'
      }/${id}`
    ),
  newTemplate: () => api.get(`${bizMessageTemplateUrl()}/new`),
  editTemplate: (_, id) => api.get(`${bizMessageTemplateUrl()}/${id}/edit`),
  createTemplate: ({ commit }, { formData, successMessage }) => {
    return new Promise(resolve => {
      api
        .post(bizMessageTemplateUrl(), formData, { successMessage })
        .then(({ data }) => {
          commit('APPEND_TEMPLATE', data.template);
          resolve(data.template);
        });
    });
  },
  updateTemplate: ({ commit }, { id, formData, successMessage }) => {
    return new Promise(resolve => {
      api
        .patch(`${bizMessageTemplateUrl()}/${id}`, formData, { successMessage })
        .then(({ data }) => {
          commit('UPDATE_TEMPLATE', data.template);
          resolve(data.template);
        });
    });
  },
  deleteTemplate: ({ commit }, id) => {
    api
      .delete(`${bizMessageTemplateUrl()}/${id}`, {
        successMessage: i18n.t('app.deleted')
      })
      .then(() => commit('DELETE_TEMPLATE', id));
  },
  requestTemplateInspection: (
    { state, commit },
    { id, formData, successMessage }
  ) => {
    return api
      .post(`${bizMessageTemplateUrl()}/${id}/template_inspection`, formData, {
        successMessage
      })
      .then(() => {
        const template = state.templates.find(e => e.id === id);
        commit('UPDATE_TEMPLATE', {
          ...template,
          inspection_status: BizMessageTemplateInspectionStatus.REQUESTED
        });
      });
  },
  cancelTemplateInspection: ({ state, commit }, { id, successMessage }) => {
    return api
      .delete(`${bizMessageTemplateUrl()}/${id}/template_inspection`, {
        successMessage
      })
      .then(() => {
        const template = state.templates.find(e => e.id === id);
        commit('UPDATE_TEMPLATE', {
          ...template,
          inspection_status: BizMessageTemplateInspectionStatus.REGISTERED
        });
      });
  },
  testApprovalTemplate: (
    { state, commit },
    { id, formData, successMessage }
  ) => {
    return api
      .post(
        `${bizMessageTemplateUrl()}/${id}/template_test_approval`,
        formData,
        {
          successMessage
        }
      )
      .then(() => {
        const template = state.templates.find(e => e.id === id);
        commit('UPDATE_TEMPLATE', {
          ...template,
          status: BizMessageTemplateStatus.READY,
          inspection_status: BizMessageTemplateInspectionStatus.APPROVED
        });
      });
  },
  testRejectTemplate: ({ state, commit }, { id, comment, successMessage }) => {
    return api
      .delete(
        `${bizMessageTemplateUrl()}/${id}/template_test_approval`,
        { params: { comment } },
        { successMessage }
      )
      .then(() => {
        const template = state.templates.find(e => e.id === id);
        commit('UPDATE_TEMPLATE', {
          ...template,
          status: BizMessageTemplateStatus.STOPPED,
          inspection_status: BizMessageTemplateInspectionStatus.REJECTED
        });
      });
  }
};

export default { namespaced: true, state, mutations, getters, actions };
