<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    :form-props="{
      ...formProps,
      objectId: 'template_inspection',
      submitLabel: $t('submit')
    }"
    v-bind="{ isLoading }"
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview :template="template" />
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplatePreview from './BizMessageTemplatePreview';
import CremaServiceType from '@/enums/CremaServiceType';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BizMessageTemplateInspectionRequestDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView],
  props: { templateId: { type: Number, required: true } },
  data() {
    return { isLoading: true, template: {}, detectFormDataChange: false };
  },
  computed: {
    ...mapGetters('bizMessage', ['yellowId']),
    formSections() {
      return [
        {
          groups: [
            {
              type: 'infobox',
              label: null,
              value: this.$t('inspection_info_html')
            },
            { id: 'service_type', type: 'static' },
            { id: 'name', type: 'static' },
            { id: 'profile_type', type: 'static' },
            {
              id: 'comment',
              type: 'textarea',
              rows: 5,
              placeholder: this.$t('comment_placeholder')
            }
          ].map(e => ({ ...e, label: this.$t(e.id) }))
        }
      ];
    }
  },
  mounted() {
    this.fetchTemplate(this.templateId)
      .then(({ data }) => {
        this.template = data.template;
        this.orgFormObject = {
          service_type: CremaServiceType.t(this.template.service_type),
          name: this.template.name,
          profile_type: this.$t('profile_type_value'), // We only support SenderKeyType.SINGLE for now - JM
          comment: ''
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('bizMessageTemplate', [
      'fetchTemplate',
      'requestTemplateInspection'
    ]),
    submit(formData) {
      this.isSubmitting = true;
      this.requestTemplateInspection({
        id: this.templateId,
        formData,
        successMessage: this.$t('requested')
      })
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "템플릿 검수요청",
  "subtitle": "알림톡 템플릿 관리",
  "service_type": "제품",
  "name": "템플릿 명",
  "profile_type": "발신 프로필 타입",
  "profile_type_value": "기본 (default)",
  "comment": "의견 또는 문의사항",
  "comment_placeholder": "템플릿 검수 요청 시 카카오에 문의 또는 의견을 남길 수 있습니다.",
  "submit": "검수요청",
  "requested": "템플릿 검수요청을 완료했습니다.",
  "inspection_info_html": "검수과정은 영업일 기준 약 2~3일이 소요되나,<br />카카오의 내부 상황에 따라 더 지연될 수 있습니다."
}
</i18n>
