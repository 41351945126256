var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppContainer',{staticClass:"ReviewBulkReviewCsvs"},[_c('AppForm',_vm._g(_vm._b({attrs:{"object-id":"bulk_review_csv","is-submitting":_vm.isSubmitting,"submit-button":{
        submitLabel: _vm.$t('app.upload'),
        submittingLabel: _vm.$t('app.uploading')
      }},on:{"change-group":_vm.formGroupChanged}},'AppForm',_vm.formProps,false),_vm.formEvents))],1),_c('AppResourceTable',{attrs:{"table-id":"bulk-review-csvs-table","table-name":_vm.$t('csv_table.title'),"resources":_vm.bulk_review_csvs,"columns":_vm.COLUMNS,"rows":_vm.rows},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"additional-table-searchbar",fn:function(){return [_c('AppSelect',{attrs:{"options":_vm.CSV_STATUS_SEARCH_OPTIONS},on:{"change":_vm.searchResource},model:{value:(_vm.resourceParams.brand_csv_status),callback:function ($$v) {_vm.$set(_vm.resourceParams, "brand_csv_status", $$v)},expression:"resourceParams.brand_csv_status"}})]},proxy:true},{key:"cell",fn:function(ref){
      var column = ref.column;
      var value = ref.value;
      var row = ref.row;
return [(column === 'brandCsvStatus')?[_c('AppBadge',{attrs:{"badge-style":_vm.dataStatusBadgeInfo(value.status).style,"label":_vm.dataStatusBadgeInfo(value.status).label,"tooltip":value.tooltipMessage}})]:_vm._e(),(column === 'action')?[_c('AppButton',[_c('a',{attrs:{"href":value.csvDownloadUrl}},[_vm._v(" "+_vm._s(_vm.$t('download_csv_file'))+" ")])]),(value.showErrorCsvDownloadButton)?_c('AppButton',{attrs:{"disabled":!value.errorCsvDownloadUrl,"tooltip":value.errorCsvDownloadTooltip}},[_c('a',{attrs:{"href":value.errorCsvDownloadUrl}},[_vm._v(" "+_vm._s(_vm.$t('download_error_csv_file'))+" ")])]):_vm._e()]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }