import api from '@/lib/api';

const state = {
  templates: []
};

const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  APPEND_TEMPLATE(state, template) {
    state.templates = [...state.templates, template];
  },
  UPDATE_TEMPLATE(state, template) {
    state.templates = state.templates.map(t => {
      if (!t.id || t.id !== template.id) return t;

      return {
        ...t,
        name: template.name,
        content: template.content
      };
    });
  },
  DELETE_TEMPLATE(state, templateId) {
    state.templates = state.templates.filter(t => !t.id || t.id !== templateId);
  }
};

const actions = {
  fetchTemplates: ({ commit }) => {
    return api.get('/review/chatbot_message_templates').then(({ data }) => {
      commit('SET_TEMPLATES', data.chatbot_message_templates);
    });
  },
  createTemplate: ({ commit }, { formData, successMessage }) => {
    return api
      .post('/review/chatbot_message_templates', formData, { successMessage })
      .then(({ data }) => {
        commit('APPEND_TEMPLATE', data.chatbot_message_template);
      });
  },
  updateTemplate: ({ commit }, { templateId, formData, successMessage }) => {
    return api
      .patch(`/review/chatbot_message_templates/${templateId}`, formData, {
        successMessage
      })
      .then(({ data }) => {
        const template = data.chatbot_message_template;
        commit('UPDATE_TEMPLATE', template);
      });
  },
  deleteTemplate: ({ commit }, { templateId, successMessage }) => {
    api
      .delete(`/review/chatbot_message_templates/${templateId}`, {
        successMessage
      })
      .then(() => {
        commit('DELETE_TEMPLATE', templateId);
      });
  }
};

export default { namespaced: true, state, mutations, actions };
