<template>
  <AppModalFormLivePreview
    class="ChatbotMessageTemplateFormDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      submitLabel: $t('submit_and_inspect'),
      objectId: 'chatbot_message_template'
    }"
    width="560px"
    v-bind="{ isLoading }"
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview :template="formObject" />
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplatePreview from '../../biz_messages/dialogs/BizMessageTemplatePreview.vue';
import api from '@/lib/api';

export default {
  name: 'ChatbotMessageTemplateFormDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView],
  props: {
    templateId: { type: Number, default: null }
  },
  data() {
    return {
      KEYWORDS: [
        { key: 'user', desc: this.$t('keywords.user') },
        { key: 'brand', desc: this.$t('keywords.brand') }
      ],
      isLoading: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: this.$t('template.name'),
              type: 'textarea',
              rows: 1,
              placeholder: this.$t('template.name_placeholder'),
              maxlength: 30,
              validate: ['required']
            },
            {
              id: 'content',
              label: this.$t('template.content'),
              type: 'keyword_textarea',
              keywords: this.KEYWORDS,
              placeholder: this.$t('template.content_placeholder'),
              groupDescription: this.$t('template.content_description'),
              autosize: true,
              maxlength: 1000,
              validate: [
                'required',
                { rule: 'max_length', limit: 1000 },
                { rule: 'keywords', keywords: this.KEYWORDS }
              ]
            }
          ]
        }
      ];
    }
  },
  mounted() {
    if (this.templateId) {
      this.isLoading = true;
      api
        .get(`/review/chatbot_message_templates/${this.templateId}/edit`)
        .then(({ data }) => {
          this.orgFormObject = data.chatbot_message_template;
        })
        .finally(() => (this.isLoading = false));
    }
  },
  methods: {
    ...mapActions('chatbotMessageTemplate', [
      'createTemplate',
      'updateTemplate'
    ]),
    submit(formData) {
      this.templateId
        ? this.requestUpdateTemplate(formData)
        : this.requestCreateTemplate(formData);
    },
    requestCreateTemplate(formData) {
      this.isSubmitting = true;
      this.createTemplate({
        formData,
        successMessage: this.$t('created')
      })
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    },
    requestUpdateTemplate(formData) {
      this.isSubmitting = true;
      this.updateTemplate({
        templateId: this.templateId,
        formData,
        successMessage: this.$t('app.saved')
      })
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "템플릿 메시지 설정",
  "sub_title": "챗봇",
  "template": {
    "name": "템플릿 명",
    "name_placeholder": "예시) 크리마_챗봇리뷰001",
    "content": "템플릿 내용",
    "content_placeholder": "한/영 구분없이 1,000자까지 입력 가능합니다.",
    "content_description": "개인화된 텍스트 영역은 변수로 작성할 수 있습니다."
  },
  "keywords": {
    "user": "고객명",
    "brand": "쇼핑몰명"
  },
  "submit_and_inspect": "저장 및 검수요청",
  "created": "새로운 알림톡 템플릿을 추가했습니다."
}
</i18n>
