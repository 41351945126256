<template>
  <AppAjaxContent :is-loading="isLoading">
    <div class="ReviewDashboardShopInfo">
      <AppForm
        v-bind="{ ...formProps, disabled: isCompleted }"
        v-on="formEvents"
      >
        <template #group="{id, errors}">
          <template v-if="id === 'brand_categories'">
            <div class="AppForm__group-field">
              <AppSelect
                v-model="formObject.market_category_id"
                :options="categoryOptions"
                :placeholder="$t('brand_category.market.placeholder')"
                :disabled="isCompleted"
                @blur="validateField('market_category_id')"
                @change="resetProductCategoryId"
              />
              <AppSelect
                v-model="formObject.product_category_id"
                class="ReviewDashboardShopInfo__product_category"
                :options="productCategoryOptions"
                :placeholder="$t('brand_category.product.placeholder')"
                :disabled="isCompleted"
                @blur="validateField('product_category_id')"
                @change="validateField('product_category_id')"
              />
              <AppFormError
                :error="errors.market_category_id || errors.product_category_id"
              />
            </div>
          </template>
        </template>
      </AppForm>
    </div>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FormView from '@/mixins/FormView';
import api from '@/lib/api';
import BrandCategoryType from '@/enums/BrandCategoryType';
import BrandReviewStatus from '@/enums/BrandReviewStatus';

export default {
  name: 'ReviewDashboardShopInfo',
  mixins: [FormView],
  props: {
    isLoading: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true }
  },
  data() {
    return {
      isValidCafe24Url: true
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('easyJoin', ['onboardingProgress']),
    ...mapState('brandCategory', ['all_brand_categories']),
    ...mapGetters('session', ['isImwebBrand']),
    ...mapGetters('easyJoin', ['marketCategoryId', 'productCategoryId']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_name',
              type: 'text',
              label: this.$t('brand_name.label'),
              labelDisabled: this.isCompleted,
              required: !this.isCompleted,
              placeholder: this.$t('brand_name.placeholder'),
              validate: [
                'required',
                {
                  rule: v => !(v.includes('[') || v.includes(']')),
                  errorMessage: this.$t('brand_name.square_brackets_error')
                }
              ]
            },
            this.currentBrand.shop_builder !== 'makeshop'
              ? {
                  id: 'brand_url',
                  type: 'text',
                  label: this.$t('brand_url.label'),
                  labelDisabled: this.isCompleted,
                  required: !this.isCompleted,
                  placeholder: this.$t('brand_url.placeholder'),
                  validate: [
                    'required',
                    {
                      rule: 'url_format',
                      errorMessage: this.$t('brand_url.url_format_validation')
                    },
                    {
                      rule: () => this.isValidCafe24Url,
                      errorMessage: this.$t('brand_url.cafe24_url_error')
                    },
                    this.urlChanged(
                      this.orgFormObject.brand_url,
                      this.formObject.brand_url
                    )
                      ? { rule: 'check_brand_url_duplication', async: true }
                      : null
                  ].filter(v => v)
                }
              : null,
            {
              id: 'brand_categories',
              label: this.$t('brand_category.label'),
              labelDisabled: this.isCompleted,
              required: !this.isCompleted,
              fields: [
                {
                  id: 'market_category_id',
                  validate: ['required']
                },
                {
                  id: 'product_category_id',
                  validate: ['required']
                }
              ]
            }
          ].filter(v => v)
        }
      ];
    },
    categoryOptions() {
      return this.all_brand_categories
        .filter(
          ({ category_type }) => category_type == BrandCategoryType.MARKET
        )
        .map(category => ({
          label: category.category_value,
          value: category.id
        }));
    },
    productCategoryOptions() {
      const unusedCategories = ['A', 'B', 'C', 'D', 'E'];

      return this.all_brand_categories
        .filter(
          c =>
            c.category_type === BrandCategoryType.PRODUCT &&
            this.formObject.market_category_id === c.market_brand_category_id &&
            !unusedCategories.includes(c.category_value)
        )
        .map(c => ({
          label: c.category_value,
          value: c.id
        }));
    }
  },
  watch: {
    'formObject.brand_url'() {
      this.isValidCafe24Url = true;
    },
    isLoading(newValue) {
      if (newValue && !this.hasError) {
        this.formEventBus.$emit('submit');
      } else this.$emit('loaded');
    },
    hasError(newValue) {
      this.$emit('errored', !!newValue);
      this.$emit('loaded');
    }
  },
  created() {
    this.orgFormObject = {
      brand_name: this.currentBrand.name,
      brand_url: this.currentBrand.url,
      market_category_id: this.marketCategoryId,
      product_category_id: this.productCategoryId
    };
  },
  updated() {
    this.validateField('brand_name');
  },
  methods: {
    ...mapActions('session', ['fetchSession']),
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    async submit() {
      try {
        await this.validateCafe24Url();
        await this.updateOnboardingProgress({
          shop_info_completed: !this.isCompleted,
          ...this.formObject
        });
        this.orgFormObject = { ...this.formObject };
        this.$emit('submitted');
      } catch {
        this.validateField('brand_url');
      } finally {
        this.$emit('loaded');
        if (
          this.isImwebBrand &&
          this.onboardingProgress.review_status ===
            BrandReviewStatus.ONBOARDING_PAID
        ) {
          this.fetchSession();
        }
      }
    },
    validateCafe24Url() {
      if (this.currentBrand.shop_builder !== 'cafe24') return;

      this.isValidCafe24Url = false;
      return api.get('/cafe24/url_validations/validate', {
        params: { url: this.formObject.brand_url },
        silent: true
      });
    },
    urlChanged(orgUrl, newUrl) {
      if (!orgUrl || !newUrl) return orgUrl !== newUrl;
      return (
        orgUrl.replace(/(http(s?)):\/\//, '') !==
        newUrl.replace(/(http(s?)):\/\//, '')
      );
    },
    resetProductCategoryId() {
      this.formObject.product_category_id = null;
      this.validateField('market_category_id', 'product_category_id');
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewDashboardShopInfo__product_category {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
  {
    "brand_name": {
      "label": "쇼핑몰 명",
      "placeholder": "쇼핑몰명을 입력해주세요.",
      "square_brackets_error": "쇼핑몰명에 대괄호([])는 입력할 수 없습니다. 대괄호를 제거해주세요."
    },
    "brand_url": {
      "label": "쇼핑몰 URL",
      "placeholder": "http://",
      "url_format_validation": "잘못된 형식의 url 입니다. (http:// 혹은 https://로 시작하는 주소를 입력해주세요)",
      "cafe24_url_error": "가입한 쇼핑몰의 정보와 다른 url 입니다. 확인 후 다시 입력해주세요.",
      "cafe24_url_validating": "url을 통해 쇼핑몰 정보를 확인중입니다.." 
    },
    "brand_category": {
      "label": "쇼핑몰 카테고리",
      "market": {
        "placeholder": "쇼핑몰이 속한 시장을 선택해주세요."
      },
      "product": {
        "placeholder": "쇼핑몰의 주요 판매 상품을 선택해주세요."
      }
    }
  }
</i18n>
