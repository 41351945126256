<template>
  <AppModalDefault
    :title="title"
    :sub-title="subTitle"
    :width="width"
    :is-max-height="isMaxHeight"
    :can-maximize="canMaximize"
    :is-maximized-by-default="isMaximizedByDefault"
    :default-left-min-width="defaultLeftMinWidth"
    :default-main-min-width="defaultMainMinWidth"
    :show-bubble-top="showBubbleTop"
    :show-bubble-mid="showBubbleMid"
    :hide-head-close-button="hideHeadCloseButton"
    @close="$emit('close')"
  >
    <template #left>
      <template v-if="!isLoading">
        <slot name="left" />
      </template>
    </template>
    <template #body>
      <AppAjaxContent :is-loading="isLoading" :height="height">
        <AppSearchbar v-if="$scopedSlots.searchbar">
          <slot name="searchbar" />
        </AppSearchbar>
        <AppForm v-bind="formProps" v-on="$listeners">
          <slot />
          <template #head>
            <slot name="head" />
          </template>
          <template #group="groupProps">
            <slot name="group" v-bind="groupProps" />
          </template>
          <template
            v-if="$scopedSlots['group-label']"
            #group-label="labelProps"
          >
            <slot name="group-label" v-bind="labelProps" />
          </template>
          <template
            v-if="$scopedSlots['group-sub-item']"
            #group-sub-item="subItemProps"
          >
            <slot name="group-sub-item" v-bind="subItemProps" />
          </template>
          <template #group-foot="groupProps">
            <slot name="group-foot" v-bind="groupProps" />
          </template>
          <template #description="descriptionProps">
            <slot name="description" v-bind="descriptionProps" />
          </template>
        </AppForm>
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppButtonSubmit
        v-if="!formProps.disabled"
        type="button"
        :button-style="
          formProps.submitStyle === 'danger' ? 'red' : formProps.submitStyle
        "
        :submit-label="formProps.submitLabel"
        :submitting-label="formProps.submittingLabel"
        :is-submitting="formProps.isSubmitting"
        :disabled="
          isLoading ||
            submitDisabled ||
            (formProps.detectFormDataChange && !formProps.isFormDataChanged)
        "
        :disabled-tooltip="submitDisabledTooltip"
        :error-summary="formProps.errorSummary"
        @click="formProps.eventBus.$emit('submit')"
      />
      <AppButton
        v-if="!hideCloseButton"
        :label="formProps.closeLabel || $t('app.close')"
        @click="$emit('close')"
      />
      <slot name="foot" />
    </template>
  </AppModalDefault>
</template>

<script>
import AppModalFormProps from './AppModalFormProps';

export default {
  name: 'AppModalForm',
  mixins: [AppModalFormProps],
  props: {
    height: { type: String, default: undefined },
    defaultLeftMinWidth: {
      type: Number,
      default: 600
    },
    defaultMainMinWidth: {
      type: Number,
      default: 418
    },
    showBubbleTop: {
      type: Boolean,
      default: false
    },
    showBubbleMid: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    hideHeadCloseButton: {
      type: Boolean,
      default: false
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    submitDisabledTooltip: {
      type: String,
      default: null
    }
  }
};
</script>
