<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm v-if="data" v-bind="reviewFormProps" v-on="formEvents" />
  </AppAjaxContent>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import _ from 'lodash';
import ReviewSettingsView from './ReviewSettingsView';
import BizMessageCampaignType from '@/enums/BizMessageCampaignType';
import BizMessageServiceType from '@/enums/BizMessageServiceType';
import HttpStatus from '@/enums/HttpStatus';

export default {
  name: 'ReviewSettingsSuperAdminBizMessage',
  mixins: [ReviewSettingsView],
  data() {
    return {
      SETTINGS_ID: 'review_settings_super_admin_biz_message',
      SETTINGS_URL: 'review/settings/super_admin_biz_message',
      isSendingTestBizMessage: false,
      testBizMessageType: ['member', 'nonmember', 'offline']
    };
  },
  computed: {
    ...mapState('session', ['brandSettings']),
    groupOptions() {
      return {
        biz_message_unit_price: {
          type: 'number',
          digits: 2,
          default: 11,
          validate: ['positive_integer']
        },
        biz_message_test: {
          type: 'button',
          value: this.$t('biz_message_test'),
          description: this.$t('biz_message_test_description'),
          clickHandler: this.sendTestBizMessage,
          disabled: this.isSendingTestBizMessage
        },
        sweettracker_biz_message_template_code_member: {
          type: 'text',
          placeholder: this.$t('biz_message_template_code_placeholder')
        },
        biz_message_text_member: {
          type: 'textarea',
          rows: 1,
          placeholder: this.$t('biz_message_text_placeholder')
        },
        biz_message_button_text_member: {
          type: 'text',
          placeholder: this.$t('biz_message_button_text_placeholder')
        },
        biz_message_button_url_member: {
          type: 'url',
          placeholder: this.$t('biz_message_button_url_placeholder'),
          validate: ['url_format']
        },
        sweettracker_biz_message_template_code_nonmember: {
          type: 'text',
          placeholder: this.$t('biz_message_template_code_placeholder')
        },
        biz_message_text_nonmember: {
          type: 'textarea',
          rows: 1,
          placeholder: this.$t('biz_message_text_placeholder')
        },
        biz_message_button_text_nonmember: {
          type: 'text',
          placeholder: this.$t('biz_message_button_text_placeholder')
        },
        biz_message_button_url_nonmember: {
          type: 'url',
          placeholder: this.$t('biz_message_button_url_placeholder'),
          validate: ['url_format']
        },
        sweettracker_biz_message_template_code_offline: {
          type: 'text',
          placeholder: this.$t('biz_message_template_code_placeholder')
        },
        biz_message_text_offline: {
          type: 'textarea',
          rows: 1,
          placeholder: this.$t('biz_message_text_placeholder')
        },
        biz_message_button_text_offline: {
          type: 'text',
          placeholder: this.$t('biz_message_button_text_placeholder')
        },
        biz_message_button_url_offline: {
          type: 'url',
          placeholder: this.$t('biz_message_button_url_placeholder'),
          validate: ['url_format']
        }
      };
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    isSendableTestBizMessage(messageType) {
      const bizMeesageSettings = [
        `sweettracker_biz_message_template_code_${messageType}`,
        `biz_message_text_${messageType}`,
        `biz_message_button_text_${messageType}`,
        `biz_message_button_url_${messageType}`
      ];

      const blankBizMessageSetting = _.find(bizMeesageSettings, setting => {
        return this.reviewSettings[setting] === '';
      });
      return !blankBizMessageSetting;
    },
    checkBizMessageSettings() {
      if (
        this.brandSettings.biz_message_service_type ===
        BizMessageServiceType.LUNASOFT
      )
        return;

      let message = null;

      if (
        this.reviewSettings.offline_message_enabled &&
        !this.isSendableTestBizMessage('offline')
      ) {
        message = this.$t('errors.offline_template_required');
      }
      if (
        this.reviewSettings.nonmember_message_enabled &&
        !this.isSendableTestBizMessage('nonmember')
      ) {
        if (message) {
          message = this.$t('errors.offline_and_nonmember_template_required');
        } else {
          message = this.$t('errors.nonmember_template_required');
        }
      }

      if (message) alert(message);
      return !!message;
    },
    bizMessageCampaignType(messageType) {
      switch (messageType) {
        case 'member':
          return BizMessageCampaignType.REVIEW_MEMBER;
        case 'nonmember':
          return BizMessageCampaignType.REVIEW_NONMEMBER;
        case 'offline':
          return BizMessageCampaignType.REVIEW_OFFLINE;
      }
    },
    sendTestBizMessage() {
      if (this.checkBizMessageSettings()) return;

      let to = prompt(this.$t('biz_message_test_prompt'));
      if (!to) return;

      to = to.replace(/[-\s]/g, '');

      const PHONE_REGEX = /^01[0-9]{8,9}$/;
      if (!PHONE_REGEX.test(to)) {
        alert(this.$t('biz_message_test_phone_invalid'));
        return;
      }

      this.isSendingTestBizMessage = true;
      this.testBizMessageType.forEach((key, i) => {
        const test_biz_message = {
          biz_message_test_phone_number: to,
          biz_message_test_campaign_type: this.bizMessageCampaignType(key),
          biz_message_test_sweettracker_template_code: this.reviewSettings[
            `sweettracker_biz_message_template_code_${key}`
          ],
          biz_message_test_text: this.reviewSettings[`biz_message_text_${key}`],
          biz_message_test_button_text: this.reviewSettings[
            `biz_message_button_text_${key}`
          ],
          biz_message_test_button_url: this.reviewSettings[
            `biz_message_button_url_${key}`
          ]
        };

        if (
          this.brandSettings.biz_message_service_type ===
            BizMessageServiceType.LUNASOFT ||
          _.values(test_biz_message).every(v => _.trim(v))
        ) {
          api
            .post('/test_biz_messages', { test_biz_message })
            .then(({ status }) => {
              if (status === HttpStatus.OK) {
                this.createToast(this.$t('biz_message_test_finished', { to }));
              }
            })
            .finally(() => {
              this.setTestBizMessageButtonEnable(i);
            });
        } else {
          this.setTestBizMessageButtonEnable(i);
        }
      });
    },
    setTestBizMessageButtonEnable(index) {
      if (index == this.testBizMessageType.length - 1) {
        this.isSendingTestBizMessage = false;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "biz_message_test": "테스트 알림톡 발송",
  "biz_message_test_description": "<ul><li>발송 조건: 메시지 발송 대상에 체크되어있으며 설정된 템플릿이 있을 경우</li><li>발송 순서: 회원 → 비회원 → 매장 회원</li></ul>",
  "biz_message_test_prompt": "테스트 알림톡을 받을 번호를 입력해주세요.",
  "biz_message_test_phone_invalid": "잘못된 형식의 전화번호입니다.",
  "biz_message_test_finished": "테스트 알림톡을 `{to}`로 발송했습니다.",
  "biz_message_template_code_placeholder": "알림톡에 등록한 템플릿 코드를 입력해주세요.",
  "biz_message_text_placeholder": "알림톡에 등록한 발송 메시지를 입력해주세요.",
  "biz_message_button_text_placeholder": "버튼에 표시할 문구를 입력해주세요.",
  "biz_message_button_url_placeholder": "버튼을 누르면 이동할 URL을 입력해주세요.",
  "errors": {
    "offline_template_required": "매장 구매 고객의 알림톡 설정이 입력되어 있지 않아 테스트 발송이 불가능합니다.",
    "nonmember_template_required": "비회원의 알림톡 설정이 입력되어 있지 않아 테스트 발송이 불가능합니다.",
    "offline_and_nonmember_template_required": "매장 구매 고객 및 비회원의 알림톡 설정이 입력되어 있지 않아 테스트 발송이 불가능합니다."
  }
}
</i18n>
