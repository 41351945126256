<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="isPreset ? null : product.name"
    :width="template ? null : 'default'"
    :form-props="{
      ...formProps,
      id: 'fit_product_form',
      objectId: 'fit_product'
    }"
    :is-loading="isLoading"
    is-max-height
    can-maximize
    v-on="formEvents"
    @change-group="formGroupChanged"
  >
    <input
      v-if="isPreset && fitProduct.fit_product_id"
      type="hidden"
      name="fit_product_id"
      :value="fitProduct.fit_product_id"
    />
    <input
      v-if="!isDepth4FitCategoriesOptionsValid"
      type="hidden"
      name="fit_product[depth4_fit_category_id]"
    />
    <template #left>
      <AppFitProductLivePreview
        v-if="template"
        v-bind="{
          fitProduct,
          fitTemplate: template,
          fitDefaultSampleImages,
          widgetCss,
          liquidTemplate: liquidTemplate()
        }"
      />
    </template>
    <template #group="{ id, value, inputId, inputName, error }">
      <template v-if="id === 'sample_image'">
        <div class="AppForm__group-field AppForm__group-field--mt8">
          <AppSelectButton
            v-model="fitProduct.fit_sample_image_source_type"
            name="fit_product[fit_sample_image_source_type]"
            :options="FIT_SAMPLE_IMAGE_SOURCE_TYPES"
          />
        </div>
        <div
          v-if="fitProduct.fit_sample_image_source_type === 'template'"
          class="AppForm__group-field AppForm__group-field--mt12"
        >
          {{ $t('form.sample_image.template_setting') }}:
          {{ templateSampleImage.description }}
          <div>
            <AppImage
              v-if="templateSampleImage.url"
              :src="templateSampleImage.url"
              class="FitSizeProductEditDialog__template-sample-image-preview"
            />
          </div>
        </div>
        <div
          v-else-if="fitProduct.fit_sample_image_source_type === 'default'"
          class="AppForm__group-field AppForm__group-field--mt12"
        >
          <AppSelect
            v-model="fitProduct.fit_default_sample_image_id"
            name="fit_product[fit_default_sample_image_id]"
            :options="fitDefaultSampleImageOptions"
            :placeholder="$t('app.select')"
          />
          <AppImage
            v-if="productSampleImageUrl"
            :src="productSampleImageUrl"
            class="FitSizeProductEditDialog__preview-fit-default-sample-image"
          />
        </div>
        <div
          v-else-if="fitProduct.fit_sample_image_source_type === 'product'"
          class="AppForm__group-field AppForm__group-field--mt12"
        >
          <AppImageInput
            name="fit_product[sample_image]"
            :image-url="productSampleImageUrl"
            not-removable
            @change="$set(fitProduct, 'sample_image_url', $event.imageUrl)"
          />
        </div>
      </template>
      <div
        v-else-if="id === 'product_option_values'"
        class="AppForm__group-field"
      >
        <AppTags
          :id="inputId"
          :tags="unmatchedOptionValues"
          :banned-tags="matchedOptionValues"
          :banned-tags-message="
            $t('form.size.already_existing_product_option_value')
          "
          :placeholder="$t('form.size.create_product_option_value')"
          @add="createProductOptionValue"
          @remove="removeProductOptionValue"
        />
      </div>
      <div
        v-else-if="id.startsWith('product_option_value')"
        class="AppForm__group-field AppForm__group-field--mt12"
      >
        <AppTable
          table-id="fit-size-product-edit-dialog"
          :columns="
            [
              'size_option_key_name',
              'size_value',
              'size_description'
            ].map(id => ({ id, label: $t(`form.size.${id}`) }))
          "
          :rows="value.sizeOptions"
        >
          <template #cell="{ row, column }">
            <template v-if="column.id === 'size_option_key_name'">
              <span class="AppTable__cell-pre">{{ row.display_name }}</span>
              <input
                type="hidden"
                name="fit_product[sizes][][key]"
                :value="row.key"
              />
            </template>
            <template v-else-if="column.id === 'size_value'">
              <AppNumberInput
                :value="row.value"
                name="fit_product[sizes][][value]"
                :placeholder="$t('form.size.size_placeholder')"
                :digits="3"
                :invalid="!!error"
                allow-decimal
                @blur="validateField(id)"
                @change="
                  updateAndValidateSizeOption(
                    {
                      ...row,
                      value: $event
                    },
                    id
                  )
                "
              />
            </template>
            <template v-else-if="column.id === 'size_description'">
              <AppTextarea
                :value="row.description"
                name="fit_product[sizes][][description]"
                :rows="1"
                :placeholder="$t('form.size.size_description_placeholder')"
                :maxlength="50"
                @change="updateSizeOption({ ...row, description: $event })"
              />
            </template>
          </template>
        </AppTable>
      </div>
      <div
        v-else-if="id.startsWith('info_option_key')"
        class="AppForm__group-field"
      >
        <input
          type="hidden"
          name="fit_product[options][][key]"
          :value="value.id"
        />
        <AppTextarea
          :id="inputId"
          :value="value.value"
          name="fit_product[options][][value]"
          :maxlength="255"
          @change="updateInfoOption(value.id, $event)"
        />
      </div>
      <div
        v-else-if="id === 'fit_option_value_ids'"
        class="AppForm__group-field"
      >
        <AppMultipleSelectCheckbox
          :name="`${inputName}[]`"
          :value="fitOptionValueIds(value)"
          :options="fitOptionValueOptions(value)"
          @change="updateFitOptionValueIds(value, $event)"
        />
      </div>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import _ from 'lodash';
import { mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import api from '@/lib/api';

export default {
  name: 'FitSizeProductEditDialog',
  mixins: [DialogFormView],
  props: {
    isPreset: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: null
    },
    fitProductId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      FIT_SAMPLE_IMAGE_SOURCE_TYPES: [
        {
          label: this.$t('form.sample_image.source_type.template'),
          value: 'template'
        },
        {
          label: this.$t('form.sample_image.source_type.default'),
          value: 'default'
        },
        {
          label: this.$t('form.sample_image.source_type.product'),
          value: 'product'
        },
        {
          label: this.$t('app.none'),
          value: 'none'
        }
      ],
      isLoading: true,
      fitTemplates: [],
      fitCategories: [],
      fitDefaultSampleImages: [],
      widgetCss: '',
      combinedFitProductLiquid: null
    };
  },
  computed: {
    fitProduct() {
      return this.formObject;
    },
    formSections() {
      const sections = [{ id: 'basic', groups: this.basicGroups }];
      if (this.template) {
        if (this.template.sample_enabled) {
          sections.push({
            id: 'sample_image',
            groups: [{ id: 'sample_image' }]
          });
        }

        if (this.template.size_enabled) {
          sections.push({ id: 'size', groups: this.sizeGroups });
        }

        if (this.template.info_enabled && this.infoOptions.length) {
          sections.push({
            id: 'info',
            groups: this.infoOptions.map(optionKey => ({
              id: `info_option_key_${optionKey.id}`,
              label: optionKey.display_name,
              value: optionKey
            }))
          });
        }

        if (
          this.template.property_enabled &&
          this.template.property_option_keys.length
        ) {
          sections.push({
            id: 'property',
            groups: this.template.property_option_keys.map(optionKey => ({
              id: 'fit_option_value_ids',
              label: optionKey.display_name,
              value: optionKey
            }))
          });
        }

        if (this.template.etc_enabled) {
          sections.push({
            id: 'etc',
            groups: [{ id: 'etc_description_html', type: 'textarea', rows: 12 }]
          });
        }

        sections.push({
          id: 'note',
          groups: [
            {
              id: 'note',
              type: 'textarea',
              rows: 12,
              placeholder: this.$t('fit_product.note_info')
            }
          ]
        });
      }
      return sections;
    },
    basicGroups() {
      const groups = [];
      if (this.isPreset) {
        groups.push({
          id: 'preset_code',
          type: 'text',
          validate: ['required']
        });
      }

      groups.push({
        id: 'hide_size_widget',
        type: 'checkbox',
        description: this.$t('form.hide_size_widget_text')
      });

      groups.push({
        id: 'fit_template_id',
        type: 'select_filterable',
        options: this.fitTemplateSelectOptions,
        placeholder: this.$t('app.select'),
        validate: ['required']
      });

      if (this.template) {
        if (this.depth3FitCategoriesOptions.length) {
          groups.push({
            id: 'depth3_fit_category_id',
            type: 'select_radio',
            options: this.depth3FitCategoriesOptions,
            validate: ['required']
          });
        }

        if (this.isDepth4FitCategoriesOptionsValid) {
          groups.push({
            id: 'depth4_fit_category_id',
            type: 'select_radio',
            options: this.depth4FitCategoriesOptions
          });
        }
      }

      return groups;
    },
    sizeGroups() {
      const groups = [
        { id: 'size_unit', type: 'static', value: 'cm' },
        {
          id: 'product_option_type_id',
          type: 'select',
          options: this.productOptionTypes,
          placeholder: this.$t('form.size.no_product_option_type_id'),
          hint: this.$t('form.size.product_option_type_hint')
        }
      ];

      if (this.productOptionType) {
        groups.push({
          id: 'product_option_values',
          groupDescription: this.matchedOptionValues.length
            ? `<ul class="app-list">${this.matchedOptionValues
                .map(v => `<li class="app-list__item">${v}</li>`)
                .join('')}</ul>`
            : null,
          label: this.$t('form.size.product_option_values', {
            option_type_name: this.productOptionType.name
          }),
          hint: this.$t('form.size.product_option_values_note')
        });
      } else {
        groups.push({ id: 'free_size_display_name', type: 'text' });
      }

      this.productOptionValues.forEach((productOptionValue, index) =>
        groups.push({
          id: `product_option_value${index}`,
          label: productOptionValue.title,
          value: productOptionValue,
          hint: this.$t('form.no_data_for_product_option_value'),
          validate: [
            {
              rule: () => {
                if (this.fitProduct.id && !productOptionValue.unmatched) {
                  return true;
                }

                return productOptionValue.sizeOptions.some(o => !!o.value);
              },
              errorMessage: this.$t(
                'errors.product_option_value_must_have_at_least_one_size',
                productOptionValue
              )
            }
          ]
        })
      );

      groups.push({ id: 'size_description_html', type: 'textarea', rows: 12 });

      return groups;
    },
    template() {
      return this.fitTemplates.find(
        t => t.id == this.fitProduct.fit_template_id
      );
    },
    fitTemplateSelectOptions() {
      return this.fitTemplates.map(template => ({
        label: template.name,
        value: template.id
      }));
    },
    depth3FitCategoriesOptions() {
      return this.fitCategories
        .filter(c => c.parent_fit_category_id === this.template.fit_category_id)
        .map(c => ({ label: c.name, value: c.id }));
    },
    depth4FitCategoriesOptions() {
      return [
        { label: this.$t('form.fit_category.default'), value: null },
        ...this.fitCategories
          .filter(
            c =>
              c.parent_fit_category_id ===
              this.fitProduct.depth3_fit_category_id
          )
          .map(c => ({ label: c.name, value: c.id }))
      ];
    },
    templateSampleImage() {
      const result = {};
      if (this.template.fit_sample_image_source_type === 'default') {
        const image = this.fitDefaultSampleImages.find(
          i => i.id === this.template.fit_default_sample_image_id
        );
        result.description = `${this.$t(
          'form.sample_image.source_type.default'
        )} - ${image.name}`;
        result.url = image.image_url;
      } else if (this.template.fit_sample_image_source_type === 'template') {
        result.description = this.$t('form.sample_image.upload');
        result.url = this.template.sample_image_url;
      } else {
        result.description = this.$t('form.sample_image.source_type.none');
      }
      return result;
    },
    fitDefaultSampleImageOptions() {
      return this.fitDefaultSampleImages
        .filter(
          fitDefaultSampleImage =>
            fitDefaultSampleImage.fit_category_id ===
            this.template.fit_category_id
        )
        .map(fitDefaultSampleImage => ({
          label: fitDefaultSampleImage.name,
          value: fitDefaultSampleImage.id
        }));
    },
    productSampleImageUrl() {
      const source_type = this.fitProduct.fit_sample_image_source_type;
      if (source_type === 'default') {
        const image = this.fitDefaultSampleImages.find(
          image => image.id == this.fitProduct.fit_default_sample_image_id
        );
        return image ? image.image_url : null;
      } else if (source_type === 'product') {
        return this.fitProduct.sample_image_url;
      }
      return null;
    },
    productOptionTypes() {
      return this.fitProduct.product_option_types.map(type => {
        return {
          value: type.id,
          label: `${type.name}${
            this.isUnmatched(type) ? this.$t('form.size.unmatched') : ''
          }`
        };
      });
    },
    fitProductSizeMap() {
      return _.keyBy(this.fitProduct.fit_product_sizes, size =>
        size.product_option_value_id
          ? `${size.fit_option_key_id}/${size.product_option_value_id}`
          : _.toString(size.fit_option_key_id)
      );
    },
    productOptionValues() {
      const productOptionType = this.fitProduct.product_option_type_id
        ? this.fitProduct.product_option_types.find(
            t => t.id == this.fitProduct.product_option_type_id
          )
        : null;
      const sizeOptionKeys = this.template
        ? this.template.size_option_keys
        : [];
      if (productOptionType) {
        return productOptionType.product_option_values.map(pov => ({
          ...pov,
          name: pov.value,
          title: this.$t('form.size.size_options', {
            name: `${productOptionType.name} - '${pov.value}'`,
            unmatched: this.isUnmatched(pov)
              ? this.$t('form.size.unmatched')
              : ''
          }),
          sizeOptions: sizeOptionKeys.map(fitOptionKey => {
            const key = `${fitOptionKey.id}/${pov.id}`;
            const size = this.fitProductSizeMap[key] || {};
            return {
              key,
              fit_option_key_id: fitOptionKey.id,
              product_option_value_id: pov.id,
              display_name: fitOptionKey.display_name,
              value: size.value,
              description: size.description
            };
          })
        }));
      } else {
        const name = this.$t('form.size.no_product_option_type_id');
        return [
          {
            title: this.$t('form.size.size_options', { name }),
            name,
            sizeOptions: sizeOptionKeys.map(fitOptionKey => {
              const key = _.toString(fitOptionKey.id);
              const size = this.fitProductSizeMap[key] || {};
              return {
                key,
                fit_option_key_id: fitOptionKey.id,
                product_option_value_id: null,
                display_name: fitOptionKey.display_name,
                value: size.value,
                description: size.description
              };
            })
          }
        ];
      }
    },
    productOptionType() {
      return this.fitProduct.product_option_types.find(
        t => t.id == this.fitProduct.product_option_type_id
      );
    },
    unmatchedOptionValues() {
      return this.productOptionValues
        .filter(v => v.unmatched)
        .map(v => v.value);
    },
    matchedOptionValues() {
      return this.productOptionValues
        .filter(v => !v.unmatched)
        .map(v => v.value);
    },
    infoOptions() {
      return this.template
        ? this.template.info_option_keys.map(key => {
            const option = this.fitProduct.fit_product_options.find(
              o => o.fit_option_key_id === key.id
            );
            return {
              ...key,
              inputId: `fit_product_option_${key.id}`,
              value: option ? option.value : ''
            };
          })
        : [];
    },
    isDepth4FitCategoriesOptionsValid() {
      return this.depth4FitCategoriesOptions.some(o => o.value);
    }
  },
  mounted() {
    let url;
    const params = {};
    if (this.isPreset) {
      url = '/fit/preset_products/edit';
      params.fit_product_id = this.fitProductId;
    } else {
      url = `/fit/products/${this.product.id}/edit`;
    }

    api
      .get(url, { params })
      .then(({ data }) => {
        this.orgFormObject = data.product;
        this.fitTemplates = data.fit_templates;
        this.fitCategories = data.fit_categories;
        this.fitDefaultSampleImages = data.fit_default_sample_images;
        this.widgetCss = data.widget_css;
        this.combinedFitProductLiquid = data.combined_fit_product_liquid;
        this.$watch('productOptionValues', (newVal, oldVal) => {
          oldVal.forEach((val, index) => {
            this.unsetFieldError(`product_option_value${index}`);
          });
        });
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('fitProduct', ['UPDATE_FIT_SIZE_PRODUCT']),
    ...mapMutations('fitPresetProduct', ['UPDATE_FIT_PRESET_PRODUCT']),
    submit(formData) {
      this.isSubmitting = true;
      if (this.isPreset) {
        api
          .patch('/fit/preset_products', formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.UPDATE_FIT_PRESET_PRODUCT(data.fit_product);
            this.close(true);
          })
          .finally(() => (this.isSubmitting = false));
      } else {
        api
          .patch(`/fit/products/${this.fitProduct.id}`, formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.UPDATE_FIT_SIZE_PRODUCT(data);
            this.close(true);
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
    createProductOptionValue(newValue) {
      api
        .post('/fit/products/create_product_option_value', {
          value: newValue,
          product_option_type_id: this.fitProduct.product_option_type_id,
          product_id: this.fitProduct.id
        })
        .then(({ data }) => {
          this.productOptionType.product_option_values.push(data);
        });
    },
    removeProductOptionValue(targetValue) {
      const { product_option_values } = this.productOptionType;
      const index = product_option_values.findIndex(
        v => v.value === targetValue
      );
      const productOptionValueId = product_option_values[index].id;
      this.fitProduct.fit_product_sizes = this.fitProduct.fit_product_sizes.filter(
        s => s.product_option_value_id !== productOptionValueId
      );
      product_option_values.splice(index, 1);
    },
    formGroupChanged(group) {
      if (group.id === 'fit_template_id') {
        if (this.template) {
          this.fitProduct.note = this.template.note;
          this.fitProduct.etc_description_html = this.template.etc_description_html;
          this.fitProduct.size_description_html = this.template.size_description_html;
        } else {
          this.fitProduct.note = null;
          this.fitProduct.etc_description_html = null;
          this.fitProduct.size_description_html = null;
        }
        this.fitProduct.fit_sample_image_source_type = 'template';
        this.fitProduct.fit_default_sample_image_id = null;
        this.fitProduct.depth3_fit_category_id = null;
        this.fitProduct.depth4_fit_category_id = null;
      } else if (group.id === 'depth3_fit_category_id') {
        this.fitProduct.depth4_fit_category_id = null;
      } else if (group.id === 'product_option_type_id') {
        this.fitProduct.fit_product_sizes = this.orgFormObject.fit_product_sizes.filter(
          size =>
            this.productOptionType
              ? this.productOptionType.product_option_values.some(
                  v => v.id === size.product_option_value_id
                )
              : !size.product_option_value_id
        );
      }
    },
    updateSizeOption(sizeOption) {
      const fitProductSize = this.fitProductSizeMap[sizeOption.key];
      if (_.isNumber(sizeOption.value) || sizeOption.description) {
        const description = sizeOption.description || null;
        if (fitProductSize) {
          this.$set(fitProductSize, 'value', sizeOption.value);
          this.$set(fitProductSize, 'description', description);
        } else {
          this.fitProduct.fit_product_sizes.push({
            fit_option_key_id: sizeOption.fit_option_key_id,
            product_option_value_id: sizeOption.product_option_value_id,
            value: sizeOption.value,
            description
          });
        }
      } else if (fitProductSize) {
        const index = this.fitProduct.fit_product_sizes.indexOf(fitProductSize);
        this.$delete(this.fitProduct.fit_product_sizes, index);
      }
    },
    updateAndValidateSizeOption(sizeOption, groupId) {
      this.updateSizeOption(sizeOption);
      this.validateField(groupId);
    },
    updateInfoOption(fitOptionKeyId, value) {
      const index = this.fitProduct.fit_product_options.findIndex(
        o => o.fit_option_key_id === fitOptionKeyId
      );
      if (value) {
        if (index === -1) {
          this.fitProduct.fit_product_options.push({
            fit_option_key_id: fitOptionKeyId,
            value: value
          });
        } else {
          this.$set(this.fitProduct.fit_product_options[index], 'value', value);
        }
      } else {
        this.$delete(this.fitProduct.fit_product_options, index);
      }
    },
    fitOptionValueOptions(optionKey) {
      return optionKey.option_values.map(value => ({
        label: value.display_name,
        value: value.id
      }));
    },
    fitOptionValueIds(optionKey) {
      return _.intersection(
        this.fitProduct.fit_option_value_ids,
        optionKey.option_values.map(v => v.id)
      );
    },
    updateFitOptionValueIds(optionKey, valueIds) {
      const otherValueIds = _.difference(
        this.fitProduct.fit_option_value_ids,
        optionKey.option_values.map(v => v.id)
      );
      this.fitProduct.fit_option_value_ids = [
        ...valueIds,
        ...otherValueIds
      ].sort((a, b) => a - b);
    },
    isUnmatched(option) {
      if (this.isPreset && !this.fitProduct.id) return false;
      return option.unmatched;
    },
    liquidTemplate() {
      return (
        this.combinedFitProductLiquid || {
          fit_product_liquid: this.template.fit_product_liquid
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.FitSizeProductEditDialog__template-sample-image-preview {
  margin-top: 4px;
  max-width: 100%;
  border: solid 1px $color-grey-35;
}

.FitSizeProductEditDialog__preview-fit-default-sample-image {
  margin-top: 8px;
  max-width: 100%;
  border: solid 1px $color-grey-35;
}

::v-deep {
  .fit-size-product-edit-dialog__size-option-key-name {
    min-width: 148px;

    @include media-breakpoint-each(mobile) {
      min-width: 100px;
    }
  }

  .fit-size-product-edit-dialog__size-value {
    min-width: 84px;
  }

  .fit-size-product-edit-dialog__size-description {
    min-width: 128px;
  }

  .AppModal__left {
    width: 600px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "실측치수 입력",
  "form": {
    "hide_size_widget_text": "실측 사이즈 모듈을 숨김 처리한 상품은 쇼핑몰 상품 상세 페이지에 사이즈표를 노출하지 않습니다.",
    "fit_category": {
      "default": "기본"
    },
    "sample_image": {
      "source_type": {
        "template": "템플릿 설정 사용",
        "default": "기본 이미지",
        "product": "직접 업로드",
        "none": "없음"
      },
      "template_setting": "템플릿 설정",
      "upload": "직접 업로드"
    },
    "size": {
      "product_option_type_hint": "상품의 사이즈 옵션이 없는 경우 없음(free)을 선택하세요.",
      "no_product_option_type_id": "없음(Free)",
      "size_options": "{name} 의 치수 입력{unmatched}",
      "size_placeholder": "숫자만 입력하세요",
      "display_below_items_with_next_line": "아래 항목부터 다음 줄에 표시",
      "size_description_placeholder": "입력 예) 밴딩",
      "size_option_key_name": "치수 이름",
      "size_value": "치수값",
      "size_description": "텍스트 표시 정보",
      "unmatched": "(매칭안됨)",
      "product_option_values": "상품 옵션 - '{option_type_name}'에 해당하는 옵션값",
      "product_option_values_note": "실제 등록한 상품과 동일한 옵션값을 입력해주세요.",
      "already_existing_product_option_value": "이미 존재하는 옵션값입니다.",
      "create_product_option_value": "클릭해서 옵션값을 추가해주세요."
    },
    "no_data_for_product_option_value": "실측 치수를 하나도 입력하지 않은 사이즈 옵션값은 실측 사이즈표에 표시하지 않습니다."
  },
  "errors": {
    "product_option_value_must_have_at_least_one_size": "'{name}' 옵션값에 해당하는 치수값을 하나 이상 입력해야 합니다."
  }
}
</i18n>
