<template>
  <div class="TheJoin">
    <div class="TheJoin__inner">
      <AppSvgIcon name="icon-crema-logo" :height="30" class="TheJoin__logo" />
      <AppForm
        id="join"
        object-id="registration_user"
        v-bind="formProps"
        :submit-button="{
          submitLabel: $t('submit'),
          submittingLabel: $t('submitting'),
          buttonClasses: [
            'TheJoin__submit',
            formValid && requiredFieldsValid
              ? 'TheJoin__submit--valid'
              : 'TheJoin__submit--invalid',
            shouldDisplaySubmitButton ? '' : 'TheJoin__submit--display-none'
          ]
        }"
        v-on="formEvents"
      >
        <input
          type="hidden"
          name="registration_user[registered_from]"
          :value="registeredFrom"
        />
        <template #group="{ id, error }">
          <template v-if="id === 'shop_builder'">
            <AppSelectButton
              v-model="formObject.shop_builder"
              class="AppForm__group-field"
              name="registration_user[shop_builder]"
              :options="ShopBuilder.options()"
            />
          </template>
          <template v-else-if="id === 'app_store'">
            <AppStoreSummaryBox
              :app-store-link="appStoreLink"
              :shop-builder="formObject.shop_builder"
            />
          </template>
          <template v-else-if="id === 'easy_join'">
            <AppSummaryBox>
              <template #title>{{
                $t('easy_join_summary_box.title')
              }}</template>
              <pre>{{ $t('easy_join_summary_box.content') }}</pre>
              <template #buttons>
                <AppExternalLink :to="easyJoinMakeshopLink" target="_blank">
                  <AppButton
                    button-style="blue-outline"
                    type="external_link"
                    :label="$t('easy_join_summary_box.button_label')"
                  />
                </AppExternalLink>
              </template>
            </AppSummaryBox>
          </template>
          <template v-else-if="id === 'external_service'">
            <AppSummaryBox>
              <template #title class="TheJoin__external-title">
                {{ $t('external_service_summary_box.title') }}
              </template>
              <pre>{{ $t('external_service_summary_box.content') }}</pre>
              <template #buttons>
                <AppExternalLink :to="godoExternalPageLink" target="_blank">
                  <AppButton button-style="blue-outline" type="external_link">
                    {{ $t('external_service_summary_box.button_label') }}
                    <AppExternalLinkIcon />
                  </AppButton>
                </AppExternalLink>
              </template>
            </AppSummaryBox>
          </template>
          <template v-else-if="id === 'email'">
            <AppEmailVerificationInput
              id="email"
              v-model="formObject.email"
              name="registration_user[email]"
              :placeholder="$t('email.placeholder')"
              :invalid="error && !error.after"
              @blur="validateField('email')"
              @change="validateField('email')"
              @status="setEmailstatus"
            />
          </template>
          <template v-else-if="id === 'terms'">
            <AppTermsAgreementCheckbox
              id="terms"
              v-model="formObject.terms"
              name="registration_user[terms][]"
              :invalid="!!error"
              @change="validateField('terms')"
            />
          </template>
        </template>
      </AppForm>
    </div>
    <TheDialogs />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import FormView from '@/mixins/FormView';
import PasswordField from '@/mixins/PasswordField';
import AppStoreSummaryBox from '@/layouts/components/AppStoreSummaryBox';
import CremaServiceType from '@/enums/CremaServiceType';
import ShopBuilder from '@/enums/ShopBuilder';
import RegistrationSource from '@/enums/RegistrationSource';
import TheDialogs from './TheDialogs';

export default {
  name: 'TheJoin',
  components: { AppStoreSummaryBox, TheDialogs },
  mixins: [FormView, PasswordField],
  data() {
    const { mall_id, cafe24_registration_service, shop_no } = this.$route.query;
    const services = cafe24_registration_service
      ? [cafe24_registration_service]
      : [];
    const registeredFrom = cafe24_registration_service
      ? RegistrationSource.CAFE24
      : RegistrationSource.DEFAULT;

    return {
      orgFormObject: {
        email: '',
        brand_url: this.$t('brand_url.placeholder'),
        mall_id,
        shop_no: shop_no || 1,
        services
      },
      terms: [],
      emailStatus: '',
      formValid: false,
      registeredFrom
    };
  },
  computed: {
    ...mapState(['env']),
    ...mapState('session', ['adminSettings']),
    ShopBuilder: () => ShopBuilder,
    formSections() {
      if (this.shouldDisplaySubmitButton) {
        return [
          ...this.settingsSection,
          ...this.userSections,
          ...this.brandSections,
          ...this.termSections
        ];
      } else {
        return [...this.settingsSection];
      }
    },
    settingsSection() {
      return [
        {
          id: 'settings_section',
          label: this.$t('settings_section'),
          groups: [
            {
              id: 'services',
              type: 'multiple_select_checkbox',
              label: this.$t('services.label'),
              options: CremaServiceType.options([
                CremaServiceType.REVIEW,
                CremaServiceType.TARGET,
                CremaServiceType.FIT,
                CremaServiceType.DISPLAY,
                CremaServiceType.CHANNEL,
                CremaServiceType.INSIGHT
              ]),
              validate: ['required']
            },
            {
              id: 'shop_builder',
              label: this.$t('shop_builder.label'),
              validate: ['required']
            },
            this.shouldDisplayAppStoreSummaryBox
              ? {
                  id: 'app_store',
                  label: ''
                }
              : null,
            this.shouldDisplayEasyJoinSummaryBox
              ? {
                  id: 'easy_join',
                  label: ''
                }
              : null,
            this.shouldDisplayExternalServicesSummaryBox
              ? {
                  id: 'external_service',
                  label: ''
                }
              : null
          ].filter(g => g)
        }
      ];
    },
    userSections() {
      return [
        {
          id: 'user_section',
          label: this.$t('user_section'),
          groups: [
            {
              id: 'email',
              label: this.$t('email.label'),
              type: 'email_verification',
              validate: [
                'required',
                'email_format',
                {
                  rule: () => this.emailStatus === 'verified',
                  errorMessage: this.$t('email.verification_required'),
                  after: true
                },
                { rule: 'check_email_duplication', async: true }
              ]
            },
            {
              id: 'name',
              label: this.$t('name.label'),
              placeholder: this.$t('name.placeholder'),
              type: 'text',
              validate: ['required']
            },
            {
              id: 'phone',
              label: this.$t('phone.label'),
              placeholder: this.$t('phone.placeholder'),
              type: 'text',
              validate: ['required', 'korean_phone_format']
            },
            this.newPasswordGroup(true, this.$t('password_field.password')),
            this.newPasswordRequirementsGroup
          ]
        }
      ];
    },
    brandSections() {
      return [
        {
          id: 'brand_section',
          label: this.$t('brand_section'),
          groups: [
            {
              id: 'brand_name',
              label: this.$t('brand_name.label'),
              placeholder: this.$t('brand_name.placeholder'),
              type: 'text',
              validate: ['required']
            },
            {
              id: 'brand_url',
              label: this.$t('brand_url.label'),
              placeholder: this.$t('brand_url.placeholder'),
              type: 'text',
              validate: [
                'required',
                {
                  rule: 'url_format',
                  errorMessage: this.$t('brand_url.url_format_validation')
                },
                { rule: 'check_brand_url_duplication', async: true }
              ]
            },
            ...(this.formObject.shop_builder === ShopBuilder.CAFE24
              ? [
                  {
                    id: 'mall_id',
                    label: this.$t('shop_builder.mall_id.label'),
                    placeholder: this.$t('shop_builder.mall_id.placeholder'),
                    hint: this.$t('shop_builder.mall_id.hint'),
                    type: 'text',
                    validate: ['required']
                  },
                  {
                    id: 'shop_no',
                    label: this.$t('shop_builder.shop_no.label'),
                    hint: this.$t('shop_builder.shop_no.hint'),
                    type: 'number',
                    default: 1,
                    digits: 5,
                    validate: ['required']
                  }
                ]
              : []),
            {
              id: 'agency_name',
              label: this.$t('agency.label'),
              placeholder: this.$t('agency.placeholder'),
              type: 'text'
            }
          ].filter(g => g)
        }
      ];
    },
    termSections() {
      return [
        {
          id: 'terms_section',
          label: this.$t('terms_section'),
          groups: [
            {
              id: 'terms',
              label: '',
              validate: [
                {
                  rule: v =>
                    _.difference(['age', 'use', 'privacy', 'outsource'], v)
                      .length === 0,
                  errorMessage: this.$t('validations.required')
                }
              ]
            }
          ]
        }
      ];
    },
    shouldDisplayAppStoreSummaryBox() {
      const { services, shop_builder } = this.formObject;

      const isReviewServiceSelected =
        services && services.includes(CremaServiceType.REVIEW);
      const isCafe24OrShopbySelected =
        shop_builder === ShopBuilder.CAFE24 ||
        shop_builder === ShopBuilder.SHOPBY;
      const isReviewOnlyShopBuilder =
        shop_builder === ShopBuilder.SHOPIFY ||
        shop_builder === ShopBuilder.IMWEB;

      return !!(
        (isReviewServiceSelected && isCafe24OrShopbySelected) ||
        isReviewOnlyShopBuilder
      );
    },
    shouldDisplayEasyJoinSummaryBox() {
      const { services, shop_builder } = this.formObject;

      const isReviewServiceSelected =
        services && services.includes(CremaServiceType.REVIEW);
      const isMakeshopSelected = shop_builder === ShopBuilder.MAKESHOP;

      return !!(
        this.adminSettings.enable_makeshop_one_day_installation &&
        isReviewServiceSelected &&
        isMakeshopSelected
      );
    },
    shouldDisplayExternalServicesSummaryBox() {
      const { services, shop_builder } = this.formObject;

      const isReviewServiceSelected =
        services && services.includes(CremaServiceType.REVIEW);
      const isGodoSelected = shop_builder === ShopBuilder.GODO;

      return !!(
        this.adminSettings.enable_godo_one_day_installation &&
        isReviewServiceSelected &&
        isGodoSelected
      );
    },
    requiredFieldsValid() {
      return _(this.formSections)
        .map('groups')
        .flatten()
        .every(group => {
          if (group.validate?.includes('required')) {
            if (Array.isArray(this.formObject[group.id]))
              return this.formObject[group.id].length;
            return this.formObject[group.id];
          }
          return true;
        });
    },
    shouldDisplaySubmitButton() {
      return (
        this.formObject.services &&
        this.formObject.services.length !== 0 &&
        this.formObject.shop_builder !== undefined &&
        !this.shouldDisplayAppStoreSummaryBox &&
        !this.shouldDisplayEasyJoinSummaryBox &&
        !this.shouldDisplayExternalServicesSummaryBox
      );
    },
    appStoreLink() {
      switch (this.formObject.shop_builder) {
        case ShopBuilder.CAFE24:
          return 'https://store.cafe24.com/kr/apps/1406';
        case ShopBuilder.SHOPBY:
          return 'https://apps.nhn-commerce.com/apps/755';
        case ShopBuilder.SHOPIFY:
          return 'https://apps.shopify.com/crema-review?locale=ko';
        case ShopBuilder.IMWEB:
          return 'https://imweb.me/appstore?app=crema';
        default:
          return '';
      }
    },
    easyJoinMakeshopLink() {
      const env = this.env === 'production' ? '' : location.host[0];
      return `${window.location.protocol}//${env}admin.cre.ma/v2/easy-join?shop_key=makeshop/unknown`;
    },
    godoExternalPageLink() {
      return 'https://www.nhn-commerce.com/echost/power/add/convenience/crema-intro.gd';
    }
  },
  watch: {
    hasError(value) {
      this.formValid = !value;
    }
  },
  methods: {
    ...mapActions('session', ['join', 'fetchSession', 'redirectToNextPage']),
    submit(formData) {
      this.isSubmitting = true;
      this.join(formData)
        .then(() => {
          this.fetchSession()
            .then(() => this.redirectToNextPage('/'))
            .finally(() => (this.isSubmitting = false));
        })
        .catch(error => {
          this.isSubmitting = false;
          error.errorHandler();
        });
    },
    setEmailstatus(status) {
      this.emailStatus = status;
      this.validateField('email');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheJoin {
  margin: 0 auto;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 25px;
}

.TheJoin__inner {
  width: 500px;
  margin: 0 auto;
  position: relative;
}

.TheJoin__logo {
  display: block;
  margin: 53px auto 40px;
  color: black;
}

::v-deep {
  .AppSelectButton > div {
    width: 500px;
    row-gap: 8px;
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .AppSelectButton .AppButton__button {
    padding: 6px 16px;
    border-radius: 3px !important;
  }

  .TheJoin__submit--display-none {
    display: none !important;
  }

  .TheJoin__link-icon {
    vertical-align: sub;
    fill: $color-blue-dark;
  }
}
</style>

<i18n locale="ko">
{
  "settings_section": "신청 서비스 및 운영 호스팅사를 선택해 주세요.",
  "user_section": "기본 가입정보",
  "brand_section": "쇼핑몰 정보",
  "terms_section": "이용 약관 동의",
  "email": {
    "label": "담당자 이메일 (ID)",
    "placeholder": "해당 메일은 크리마 관리시스템 로그인 아이디로도 사용됩니다.",
    "verify_button": "인증메일 발송",
    "verification_required": "이메일 인증이 필요합니다."
  },
  "name": {
    "label": "담당자 이름",
    "placeholder": "담당자 이름을 입력해주세요."
  },
  "phone": {
    "label": "담당자 전화번호",
    "placeholder": "직접 연락 가능한 전화번호를 입력해주세요."
  },
  "services": {
    "label": "신청 서비스"
  },
  "brand_name": {
    "label": "쇼핑몰명",
    "placeholder": "쇼핑몰명을 입력해주세요."
  },
  "brand_url": {
    "label": "쇼핑몰 URL",
    "placeholder": "http://",
    "url_format_validation": "잘못된 형식의 url 입니다. (http://혹은 https://로 시작하는 주소를 입력해주세요)"
  },
  "agency": {
    "label": "운영대행사",
    "placeholder": "운영대행사가 있다면 입력해주세요. 직접 신청하는 경우에는 입력하지 않아도 됩니다."
  },
  "shop_builder": {
    "label": "운영 호스팅사",
    "mall_id": {
      "label": "상점 아이디(mall ID)",
      "placeholder": "카페 24에서 사용하는 상점 아이디를 입력해주세요.",
      "hint": "API 신청을 위해 필요한 정보입니다."
    },
    "shop_no": {
      "label": "샵넘버",
      "hint": "국문몰일 경우 샵넘버 기본값은 1입니다. 멀티몰일 경우 카페24 쇼핑몰 관리자 [쇼핑몰 설정 > 채널 설정 > 멀티쇼핑몰 > 멀티쇼핑몰 설정]에서 '기본 접속 URL'에 shop2, shop3 정보 확인 후 숫자만 입력해주세요."
    }
  },
  "easy_join_summary_box": {
    "title": "간편 회원가입 페이지를 진행해 주세요.",
    "content": "화면 이동 후 간편 회원가입이 진행됩니다.",
    "button_label": "간편 회원가입 페이지로 이동"
  },
  "external_service_summary_box": {
    "title": "고도몰 부가서비스 페이지에서\n크리마 리뷰를 신청해 주세요.",
    "content": "크리마 리뷰 앱 신청 후 간편 회원가입이 진행됩니다.",
    "button_label": "고도몰 부가서비스 페이지 바로가기"
  },
  "submit": "서비스 신청하기",
  "submitting": "서비스 신청 중..."
}
</i18n>
