<template>
  <div class="ReviewSmartInstallationEtc">
    <ReplaceReviewsCount />
    <NewReviewLink />
    <ProductReviewsLink />
    <MyPendingReviewsCount />
    <MyWrittenReviewsCount />
  </div>
</template>

<script>
import ProductReviewsLink from './components/ProductReviewsLink';
import NewReviewLink from './components/NewReviewLink';
import ReplaceReviewsCount from './components/ReplaceReviewsCount';
import MyPendingReviewsCount from './components/MyPendingReviewsCount';
import MyWrittenReviewsCount from './components/MyWrittenReviewsCount';

export default {
  name: 'ReviewSmartInstallationEtc',
  components: {
    ReplaceReviewsCount,
    NewReviewLink,
    ProductReviewsLink,
    MyPendingReviewsCount,
    MyWrittenReviewsCount
  }
};
</script>
