<template>
  <div class="BizMessageTemplatesTable">
    <AppHeading :label="title" />
    <div v-if="!isApproved" class="BizMessageTemplatesTable__table-description">
      {{ $t('unapproved_template_description') }}
    </div>
    <AppTable v-bind="{ columns, rows }">
      <template #cell="{ row, column, rowIndex }">
        <template v-if="column.id === 'actions'">
          <AppButton
            :label="$t('app.show_detail')"
            @click="clickShowTemplate(row.id)"
          />
          <template v-if="isApproved">
            <AppButton
              v-if="isGroupTemplate"
              :label="$t('app.edit')"
              @click="clickEditTemplate(row.id)"
            />
            <AppButton
              v-else
              button-style="red-outline"
              :label="$t('app.delete')"
              :disabled="row.is_selected"
              :tooltip="row.is_selected ? $t('selected_not_deletable') : null"
              @click="clickDeleteTemplate(row.id)"
            />
          </template>
          <AppDropdownMenuButton
            v-else
            :label="$t('app.manage')"
            :menu-items="[
              [
                ...(isInspectionRequestable(row.inspection_status.value)
                  ? [
                      {
                        label: $t('request_inspection'),
                        disabled: isInspectionRejected(
                          row.inspection_status.value
                        ),
                        tooltip: isInspectionRejected(
                          row.inspection_status.value
                        )
                          ? $t('rejected_tooltip')
                          : null,
                        clickHandler: ({ close }) => {
                          close();
                          clickRequestInspection(row.id);
                        }
                      }
                    ]
                  : isInspectionRequested(row.inspection_status.value)
                  ? [
                      {
                        label: $t('cancel_inspection'),
                        clickHandler: ({ close }) => {
                          close();
                          clickCancelInspection(row.id);
                        }
                      }
                    ]
                  : []),
                {
                  label: $t('app.edit'),
                  disabled: !isEditable(rowIndex),
                  tooltip: isEditable(rowIndex)
                    ? null
                    : $t('edit_tooltip_disabled'),
                  clickHandler: ({ close }) => {
                    close();
                    clickEditTemplate(row.id);
                  }
                }
              ],
              [
                {
                  label: $t('app.delete'),
                  style: 'danger',
                  disabled: row.is_selected,
                  tooltip: row.is_selected
                    ? $t('selected_not_deletable')
                    : null,
                  clickHandler: ({ close }) => {
                    close();
                    clickDeleteTemplate(row.id);
                  }
                }
              ]
            ]"
          />
        </template>
        <template v-else-if="column.id === 'admin'">
          <div v-if="isSuperAdminAccessible" class="super-admin__item">
            <AppButton
              v-if="isInspectionRequested(row.inspection_status.value)"
              label="승인"
              @click="clickApprove(row.id)"
            />
            <AppButton
              v-if="isInspectionRequested(row.inspection_status.value)"
              label="반려"
              @click="clickReject(row.id)"
            />
          </div>
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  name: 'BizMessageTemplatesTable',
  props: {
    serviceType: { type: Number, default: null },
    title: { type: String, required: true },
    templates: { type: Array, required: true },
    isApproved: { type: Boolean, required: true }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', [
      'isReviewUsingBizMessage',
      'isTargetOperatingOrExpired'
    ]),
    ...mapGetters('bizMessageTemplate', ['isGroupTemplate']),
    shouldDisplayServiceType() {
      return (
        this.serviceType === null &&
        this.isReviewUsingBizMessage &&
        this.isTargetOperatingOrExpired
      );
    },
    columnIds() {
      if (this.isApproved) {
        return [
          this.shouldDisplayServiceType ? 'service_type' : null,
          'name',
          'status',
          'created_at',
          'actions'
        ].filter(v => v);
      } else {
        return [
          this.shouldDisplayServiceType ? 'service_type' : null,
          'name',
          'inspection_status',
          'created_at',
          'actions',
          this.isSuperAdminAccessible && this.env !== 'production'
            ? 'admin'
            : null
        ].filter(v => v);
      }
    },
    columnOptions() {
      return {
        service_type: { type: 'text' },
        name: { type: 'text' },
        status: { type: 'badge', width: '20%' },
        inspection_status: { type: 'badge', width: '20%' },
        created_at: { type: 'datetime', width: '20%' },
        actions: {
          type: 'custom',
          label: this.$t('app.actions'),
          width: '20%'
        },
        admin: { type: 'custom', label: this.$t('app.super_admin') }
      };
    },
    columns() {
      return this.columnIds.map(id => {
        return {
          id,
          ...this.columnOptions[id],
          label: this.columnOptions[id].label || this.$t(id)
        };
      });
    },
    rows() {
      return this.templates.map(template => {
        return {
          id: template.id,
          name: template.name,
          service_type: CremaServiceType.t(template.service_type),
          status: {
            label: BizMessageTemplateStatus.t(template.status),
            badgeStyle: BizMessageTemplateStatus.badgeStyle(template.status),
            badgeTooltip:
              template.status === BizMessageTemplateStatus.STOPPED
                ? { message: this.$t('stopped_tooltip'), textAlign: 'left' }
                : null
          },
          inspection_status: {
            value: template.inspection_status,
            label: BizMessageTemplateInspectionStatus.t(
              template.inspection_status
            ),
            badgeStyle: BizMessageTemplateInspectionStatus.badgeStyle(
              template.inspection_status
            )
          },
          created_at: template.created_at,
          is_selected: template.is_selected
        };
      });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    ...mapActions('bizMessageTemplate', [
      'deleteTemplate',
      'cancelTemplateInspection',
      'testApprovalTemplate',
      'testRejectTemplate'
    ]),
    clickShowTemplate(templateId) {
      this.openDialog(['BizMessageTemplateShowDialog', { templateId }]);
    },
    clickEditTemplate(templateId) {
      const { serviceType, isApproved } = this;
      this.openDialog([
        'BizMessageTemplateFormDialog',
        { serviceType, templateId, isApproved }
      ]);
    },
    clickDeleteTemplate(templateId) {
      this.confirm({
        type: 'delete_confirm',
        title: this.$t('delete_confirm_title'),
        message: this.$t('delete_confirm_message')
      }).then(result => {
        if (result) this.deleteTemplate(templateId);
      });
    },
    clickCancelInspection(id) {
      return this.confirm({
        type: 'delete_confirm',
        title: this.$t('cancel_inspection_confirm_title'),
        message: this.$t('cancel_inspection_confirm_message'),
        closeButtonLabel: this.$t('cancel_inspection')
      }).then(result => {
        if (!result) return;

        this.isLoading = true;
        this.cancelTemplateInspection({
          id,
          successMessage: this.$t('cancel_inspection_success')
        }).finally(() => (this.isLoading = false));
      });
    },
    clickRequestInspection(id) {
      this.openDialog([
        'BizMessageTemplateInspectionRequestDialog',
        { templateId: id }
      ]);
    },
    clickApprove(id) {
      const comment = window.prompt(
        '승인 메시지를 입력해주세요. 테스트 서버에서만 적용됩니다.'
      );
      if (comment === null) return;

      this.testApprovalTemplate({
        id,
        formData: { template_test_approval: { comment: comment } },
        successMessage: this.$t('test_approve_success')
      }).finally(() => (this.isLoading = false));
    },
    clickReject(id) {
      const comment = window.prompt(
        '반려 메시지를 입력해주세요. 테스트 서버에서만 적용됩니다.'
      );
      if (comment === null) return;

      this.testRejectTemplate({
        id,
        comment,
        successMessage: this.$t('test_reject_success')
      }).finally(() => (this.isLoading = false));
    },
    isInspectionRejected(inspection_status) {
      return inspection_status === BizMessageTemplateInspectionStatus.REJECTED;
    },
    isInspectionRequested(inspection_status) {
      return inspection_status === BizMessageTemplateInspectionStatus.REQUESTED;
    },
    isInspectionRequestable(inspection_status) {
      return [
        BizMessageTemplateInspectionStatus.NONE,
        BizMessageTemplateInspectionStatus.REGISTERED,
        BizMessageTemplateInspectionStatus.REJECTED
      ].includes(inspection_status);
    },
    isEditable(index) {
      return (
        this.templates[index].inspection_status !==
        BizMessageTemplateInspectionStatus.REQUESTED
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.BizMessageTemplatesTable__table-description {
  @include text-content-light;
  padding-bottom: 12px;
}
</style>

<i18n locale="ko">
{
  "service_type": "제품",
  "name": "템플릿 명",
  "status": "템플릿 상태",
  "inspection_status": "템플릿 검수 상태",
  "created_at": "생성일자",
  "stopped_tooltip": "이 템플릿은 하단 작성된 문제로 인해 발송이 차단되었습니다.\n* 중지: 사용하지 않는 템플릿\n* 휴면: 1년 간 발송 이력 없음\n* 차단: 카카오 관리자에 의해 차단 된 템플릿\n* 휴면삭제: 휴면 상태에서 1년 이상 발송 이력 없음",
  "cancel_inspection": "검수취소",
  "request_inspection": "검수요청",
  "delete_confirm_title": "이 템플릿을 삭제하시겠습니까?",
  "delete_confirm_message": "삭제된 템플릿은 복구가 어렵습니다.",
  "cancel_inspection_confirm_title": "검수요청을 취소하겠습니까?",
  "cancel_inspection_confirm_message": "템플릿 검수 상태가 [검수대기]로 변경되며 템플릿 수정이 가능해집니다.",
  "cancel_inspection_success": "템플릿 검수요청을 취소했습니다.",
  "edit_tooltip_disabled": "템플릿 상태가 검수중일 때엔 수정 및 편집이 불가합니다.\n검수 취소 후 템플릿을 수정해주세요.",
  "test_approve_success": "템플릿 검수를 승인했습니다.",
  "test_reject_success": "템플릿 검수를 반려했습니다.",
  "unapproved_template_description": "템플릿 작성 후 카카오 검수과정을 거쳐야 템플릿 사용이 가능합니다.",
  "rejected_tooltip": "카카오 피드백에 따라 템플릿 수정 후 저장해주세요.",
  "selected_not_deletable": "해당 템플릿으로 발송설정이 되어있기 때문에 삭제가 불가합니다."
}
</i18n>
