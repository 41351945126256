export default [
  {
    id: 'target',
    path: getters => {
      if (!getters.isTargetAccessible) return '/target/intro';
      return '/target/widget-campaigns/product-recommendation';
    },
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'review',
    path: getters => {
      if (!getters.isReviewAccessible) return '/review/intro';
      if (getters.isShopifyBrand) return '/review/shopify_onboard_guide';
      if (getters.isReviewOnboarding) return '/review/dashboard';
      return '/review/reviews';
    }
  },
  {
    id: 'fit',
    path: getters =>
      getters.isFitAccessible ? '/fit/size/products' : '/fit/intro',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'display',
    path: getters =>
      getters.isDisplayAccessible
        ? '/display/main-page/corners'
        : '/display/intro',
    isAccessible: getters => getters.isDisplayMenuAccessibleByShopBuilder,
    isLaunched: getters => getters.isDisplayMenuLaunched
  },
  {
    id: 'channel',
    path: '/channel/feeds',
    isLaunched: getters => getters.isChannelMenuLaunched
  },
  {
    id: 'product',
    path: '/product/products',
    isAccessible: getters => getters.isProductAccessible
  },
  {
    id: 'analytics',
    path: getters =>
      getters.isInsightMenuAccessible || getters.isSuperAdminAccessible
        ? '/analytics/insight_dashboard'
        : '/analytics/users',
    isAccessible: getters => getters.isAnalyticsAccessible
  },
  {
    id: 'connect',
    path: '/connect/center',
    isAccessible: getters => !getters.isShopifyBrand
  },
  { id: 'ui', path: '/ui/grid', superAdmin: true, label: 'UI' },
  { id: 'qa', path: '/qa/jpeg-simulations', superAdmin: true, label: 'QA' }
].map(e => ({ ...e, type: 'main_menu' }));
