<template>
  <AppMobilePhoneBizMessagePreview
    :brand-name="brandName"
    :dispatch-time="dispatchTime"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="TargetDmCampaignsBizMessagePreview__message"
      v-html="messageHtml"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div v-if="buttonText" class="TargetDmCampaignsBizMessagePreview__button">
      {{ buttonText }}
    </div>
  </AppMobilePhoneBizMessagePreview>
</template>

<script>
import TargetMessagePreview from '@/mixins/TargetMessagePreview';

export default {
  name: 'TargetDmCampaignsBizMessagePreview',
  mixins: [TargetMessagePreview],
  props: {
    brandName: { type: String, required: true },
    buttonText: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetDmCampaignsBizMessagePreview__message {
  padding: 22px 0;

  ::v-deep a {
    color: #528fce;
  }
}

.TargetDmCampaignsBizMessagePreview__button {
  @include text-button;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  text-align: center;
  background-color: $color-grey-15;
}
</style>

<i18n locale="ko">
{
  "morning": "오전 {0}:00",
  "afternoon": "오후 {0}:00"
}
</i18n>
