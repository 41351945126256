import api from '@/lib/api';
import { nullResources } from '@/lib/resources';

const FETCH_BULK_REVIEW_CSVS_REQUEST_ID = 'FETCH_BULK_REVIEW_CSVS';

const state = {
  bulk_review_csvs: nullResources
};

const mutations = {
  SET_BULK_REVIEW_CSVS(state, bulk_review_csvs) {
    state.bulk_review_csvs = bulk_review_csvs;
  },
  PREPEND_BULK_REVIEW_CSV(state, bulk_review_csv) {
    state.bulk_review_csvs = {
      ...state.bulk_review_csvs,
      items: [bulk_review_csv, ...state.bulk_review_csvs.items],
      total_count: state.bulk_review_csvs.total_count + 1
    };
  }
};

const actions = {
  fetchBulkReviewCsvs({ commit }, params) {
    api.cancel(FETCH_BULK_REVIEW_CSVS_REQUEST_ID);
    api
      .get('/review/bulk_review_csvs', {
        requestId: FETCH_BULK_REVIEW_CSVS_REQUEST_ID,
        params
      })
      .then(({ data }) => {
        commit('SET_BULK_REVIEW_CSVS', data.bulk_review_csvs);
      });
  },
  createBulkReviewCsv({ commit }, { formData }) {
    return api.post('/review/bulk_review_csvs', formData).then(({ data }) => {
      commit('PREPEND_BULK_REVIEW_CSV', data.bulk_review_csv);
    });
  }
};

const getters = {
  isFetchingBulkReviewCsvs(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_BULK_REVIEW_CSVS_REQUEST_ID
    );
  },
  csvDownloadUrl: (
    _state,
    _getters,
    _rootState,
    rootGetters
  ) => brand_csv_id => {
    return rootGetters['pathWithBrandParams'](
      `/api/review/bulk_review_csvs/${brand_csv_id}/download`
    );
  },
  errorCsvDownloadUrl: (
    _state,
    _getters,
    _rootState,
    rootGetters
  ) => brand_csv_id => {
    return rootGetters['pathWithBrandParams'](
      `/api/review/bulk_review_csvs/${brand_csv_id}/error_file_download`
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
