<template>
  <div>
    <div v-for="(section, i) in SECTIONS" :key="i" class="ui-section">
      <div class="ui-section__title">
        <label>{{ section.name }}</label>
      </div>
      <div class="ui-section__item">
        <div v-for="({ name, color }, j) in section.colors" :key="j">
          <div :class="`UiColor__chip UiColor__chip--${name}`" />
          <div class="UiColor__name">{{ name }}({{ color }})</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiColor',
  data() {
    const GREY_COLORS = [
      { name: 'color-grey-80', color: '#424244' },
      { name: 'color-grey-75', color: '#525355' },
      { name: 'color-grey-70', color: '#616163' },
      { name: 'color-grey-60', color: '#757577' },
      { name: 'color-grey-50', color: '#9e9ea0' },
      { name: 'color-grey-40', color: '#bdbdbf' },
      { name: 'color-grey-35', color: '#ceced0' },
      { name: 'color-grey-32', color: '#dadadc' },
      { name: 'color-grey-25', color: '#e8e8ea' },
      { name: 'color-grey-15', color: '#f3f3f5' },
      { name: 'color-grey-05', color: '#fafafc' }
    ];

    const BLACK_COLORS = [
      { name: 'color-black-opaque', color: 'rgba(0, 0, 0, 0.3)' },
      { name: 'color-black-opaque-dark', color: 'rgba(0, 0, 0, 0.4)' },
      { name: 'color-black-opaque-darker', color: 'rgba(0, 0, 0, 0.5)' },
      { name: 'color-black-opaque-light', color: 'rgba(0, 0, 0, 0.15)' }
    ];

    const NAMED_COLORS = [
      { name: 'color-blue', color: '#298ce7' },
      { name: 'color-blue-dark', color: '#2274bf' },
      { name: 'color-blue-darker', color: '#1e68ad' },
      { name: 'color-blue-light', color: '#b5d2ee' },
      { name: 'color-flamingo', color: '#f27452' },
      { name: 'color-red', color: '#d12727' },
      { name: 'color-red-dark', color: '#b52222' },
      { name: 'color-red-darker', color: '#a11e1e' },
      { name: 'color-red-light', color: '#f0bbbb' },
      { name: 'color-red-lighter', color: '#ffe6e6' },
      { name: 'color-green', color: '#64af48' },
      { name: 'color-green-dark', color: '#508c3a' },
      { name: 'color-green-darker', color: '#3e6e2d' },
      { name: 'color-green-light', color: '#cbe6c1' },
      { name: 'color-mint-green', color: '#48c192' },
      { name: 'color-mint-green-dark', color: '#3b9e78' },
      { name: 'color-mint-green-darker', color: '#308061' },
      { name: 'color-mint-green-light', color: '#c3e6d8' },
      { name: 'color-mustard', color: '#ffc404' },
      { name: 'color-mustard-light', color: '#ffe294' },
      { name: 'color-mango', color: '#fea426' },
      { name: 'color-mango-text', color: '#e3890b' },
      { name: 'color-mango-dark', color: '#d57f07' },
      { name: 'color-mango-light', color: '#f7d8ab' }
    ];

    const FUNCTIONAL_COLORS = [
      { name: 'color-brand-main', color: '#528fce' },
      { name: 'color-brand-light', color: '#d4e3f3' },
      { name: 'color-brand-lighter', color: '#edf3fc' },
      { name: 'color-background', color: '#fff' },
      { name: 'color-highlight', color: '#ffeebc' },
      { name: 'color-dropdown', color: 'rgba(18, 18, 20, 0.4)' },
      { name: 'color-button-text', color: '$color-grey-60' },
      { name: 'color-button-text-dark', color: '$color-grey-80' },
      { name: 'color-content-text', color: '$color-grey-70' },
      { name: 'color-content-text-light', color: '$color-grey-50' },
      { name: 'color-content-text-dark', color: '$color-grey-80' },
      { name: 'color-disable-text', color: '$color-grey-40' },
      { name: 'color-form-border', color: '$color-grey-35' },
      { name: 'color-form-border-hover', color: '$color-grey-40' },
      { name: 'color-form-border-selected', color: '$color-grey-40' },
      { name: 'color-form-focus-highlight', color: 'rgba(66, 66, 100, 0.07)' },
      { name: 'color-layout-section', color: '$color-grey-25' },
      { name: 'color-layout-section-dark', color: '$color-grey-32' },
      { name: 'color-nav-selected', color: '$color-grey-15' },
      { name: 'color-nav-selected-light', color: '$color-grey-05' },
      { name: 'color-primary', color: '$color-brand-main' },
      { name: 'color-primary-light', color: '$color-brand-light' },
      { name: 'color-primary-lighter', color: '$color-brand-lighter' },
      { name: 'color-title-text', color: '$color-grey-80' },
      { name: 'color-title-text-light', color: '$color-grey-50' }
    ];

    const SECTIONS = [
      { name: 'Grey Colors', colors: GREY_COLORS },
      { name: 'Black Colors', colors: BLACK_COLORS },
      { name: 'Named Colors', colors: NAMED_COLORS },
      { name: 'Functional Colors', colors: FUNCTIONAL_COLORS }
    ];
    return { SECTIONS };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.UiColor__chip {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
}

.UiColor__name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}

.UiColor__chip--color-grey-80 {
  background: $color-grey-80;
}
.UiColor__chip--color-grey-75 {
  background: $color-grey-75;
}
.UiColor__chip--color-grey-70 {
  background: $color-grey-70;
}
.UiColor__chip--color-grey-60 {
  background: $color-grey-60;
}
.UiColor__chip--color-grey-50 {
  background: $color-grey-50;
}
.UiColor__chip--color-grey-40 {
  background: $color-grey-40;
}
.UiColor__chip--color-grey-35 {
  background: $color-grey-35;
}
.UiColor__chip--color-grey-32 {
  background: $color-grey-32;
}
.UiColor__chip--color-grey-25 {
  background: $color-grey-25;
}
.UiColor__chip--color-grey-15 {
  background: $color-grey-15;
}
.UiColor__chip--color-grey-05 {
  background: $color-grey-05;
}

.UiColor__chip--color-black-opaque {
  background: $color-black-opaque;
}
.UiColor__chip--color-black-opaque-dark {
  background: $color-black-opaque-dark;
}
.UiColor__chip--color-black-opaque-darker {
  background: $color-black-opaque-darker;
}
.UiColor__chip--color-black-opaque-light {
  background: $color-black-opaque-light;
}

.UiColor__chip--color-blue {
  background: $color-blue;
}
.UiColor__chip--color-blue-dark {
  background: $color-blue-dark;
}
.UiColor__chip--color-blue-darker {
  background: $color-blue-darker;
}
.UiColor__chip--color-blue-light {
  background: $color-blue-light;
}
.UiColor__chip--color-flamingo {
  background: $color-flamingo;
}
.UiColor__chip--color-red {
  background: $color-red;
}
.UiColor__chip--color-red-dark {
  background: $color-red-dark;
}
.UiColor__chip--color-red-darker {
  background: $color-red-darker;
}
.UiColor__chip--color-red-light {
  background: $color-red-light;
}
.UiColor__chip--color-red-lighter {
  background: $color-red-lighter;
}
.UiColor__chip--color-green {
  background: $color-green;
}
.UiColor__chip--color-green-dark {
  background: $color-green-dark;
}
.UiColor__chip--color-green-darker {
  background: $color-green-darker;
}
.UiColor__chip--color-green-light {
  background: $color-green-light;
}
.UiColor__chip--color-mint-green {
  background: $color-mint-green;
}
.UiColor__chip--color-mint-green-dark {
  background: $color-mint-green-dark;
}
.UiColor__chip--color-mint-green-darker {
  background: $color-mint-green-darker;
}
.UiColor__chip--color-mint-green-light {
  background: $color-mint-green-light;
}
.UiColor__chip--color-mustard {
  background: $color-mustard;
}
.UiColor__chip--color-mustard-light {
  background: $color-mustard-light;
}
.UiColor__chip--color-mango {
  background: $color-mango;
}
.UiColor__chip--color-mango-text {
  background: $color-mango-text;
}
.UiColor__chip--color-mango-dark {
  background: $color-mango-dark;
}
.UiColor__chip--color-mango-light {
  background: $color-mango-light;
}

.UiColor__chip--color-brand-main {
  background: $color-brand-main;
}
.UiColor__chip--color-brand-light {
  background: $color-brand-light;
}
.UiColor__chip--color-brand-lighter {
  background: $color-brand-lighter;
}
.UiColor__chip--color-background {
  background: $color-background;
}
.UiColor__chip--color-highlight {
  background: $color-highlight;
}
.UiColor__chip--color-dropdown {
  background: $color-dropdown;
}
.UiColor__chip--color-button-text {
  background: $color-button-text;
}
.UiColor__chip--color-button-text-dark {
  background: $color-button-text-dark;
}
.UiColor__chip--color-content-text {
  background: $color-content-text;
}
.UiColor__chip--color-content-text-light {
  background: $color-content-text-light;
}
.UiColor__chip--color-content-text-dark {
  background: $color-content-text-dark;
}
.UiColor__chip--color-disable-text {
  background: $color-disable-text;
}
.UiColor__chip--color-form-border {
  background: $color-form-border;
}
.UiColor__chip--color-form-border-hover {
  background: $color-form-border-hover;
}
.UiColor__chip--color-form-border-selected {
  background: $color-form-border-selected;
}
.UiColor__chip--color-form-focus-highlight {
  background: $color-form-focus-highlight;
}
.UiColor__chip--color-layout-section {
  background: $color-layout-section;
}
.UiColor__chip--color-layout-section-dark {
  background: $color-layout-section-dark;
}
.UiColor__chip--color-nav-selected {
  background: $color-nav-selected;
}
.UiColor__chip--color-nav-selected-light {
  background: $color-nav-selected-light;
}
.UiColor__chip--color-primary {
  background: $color-primary;
}
.UiColor__chip--color-primary-light {
  background: $color-primary-light;
}
.UiColor__chip--color-primary-lighter {
  background: $color-primary-lighter;
}
.UiColor__chip--color-title-text {
  background: $color-title-text;
}
.UiColor__chip--color-title-text-light {
  background: $color-title-text-light;
}
</style>
