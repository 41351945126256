<template>
  <MessageTemplateSelectTable
    :templates="templates"
    :selected-value="selectedValue"
    @invalid="$emit('invalid', $event)"
    @change="$emit('change', $event)"
    @show-template="onShowTemplate"
    @edit-template="onEditTemplate"
    @delete-template="onDeleteTemplate"
  />
</template>

<script>
import { mapActions } from 'vuex';
import MessageTemplateSelectTable from '../../components/MessageTemplateSelectTable.vue';

export default {
  name: 'ChatbotMessageTemplateSelectTable',
  components: { MessageTemplateSelectTable },
  props: {
    templates: { required: true, type: Array },
    selectedValue: { type: Number, default: null }
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('chatbotMessageTemplate', ['deleteTemplate']),
    onShowTemplate(template) {
      this.openDialog([
        'ChatbotMessageTemplateShowDialog',
        { template: template }
      ]);
    },
    onEditTemplate(template) {
      this.openDialog([
        'ChatbotMessageTemplateFormDialog',
        { templateId: template.id }
      ]);
    },
    onDeleteTemplate(template) {
      this.confirm({
        type: 'delete_confirm',
        title: this.$t('delete_confirm_title'),
        message: this.$t('delete_confirm_message')
      }).then(result => {
        if (result)
          this.deleteTemplate({
            templateId: template.id,
            successMessage: this.$t('app.deleted')
          });
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "delete_confirm_title": "이 템플릿을 삭제하시겠습니까?",
  "delete_confirm_message": "삭제된 템플릿은 복구가 어렵습니다."
}
</i18n>
