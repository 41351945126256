<template>
  <AppModalDefault
    class="ChatbotMessageProfileDialog"
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    @close="close"
  >
    <template #body>
      <AppInfoBox>
        <AppMarkdown
          :text="
            $t('chatbot_info_description', {
              link: 'https://forms.gle/S1qwmfwbaDm3DYBt9'
            })
          "
        />
      </AppInfoBox>
      <AppContainer class="ChatbotMessageProfileDialog__contents">
        <AppDataList :data="items">
          <template #value="{ id, value }">
            <template v-if="id === 'channel_profile_image'">
              <AppImage
                :src="value"
                type="rounded"
                :square="true"
                :border-radius="6"
              />
            </template>
          </template>
        </AppDataList>
      </AppContainer>
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import BizMessageStatus from '@/enums/BizMessageStatus';

export default {
  name: 'ChatbotMessageProfileDialog',
  mixins: [DialogView],
  props: {
    profileTypeBadge: {
      type: Object,
      required: true
    },
    channelId: {
      type: String,
      required: true
    },
    chatbotMessageStatus: {
      type: String,
      required: true,
      validator: v => BizMessageStatus.values.includes(v)
    },
    usePremiumChatbot: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    items() {
      return [
        {
          id: 'profile_type',
          type: 'badge',
          value: this.profileTypeBadge
        },
        {
          id: 'channel_id',
          value: this.channelId
        },
        ...(this.usePremiumChatbot
          ? []
          : [
              {
                id: 'channel_profile_image',
                value: 'https://assets.cre.ma/m/admin/v2/crema-logo.png'
              }
            ]),
        {
          id: 'chatbot_message_status',
          value: this.chatbotMessageStatusValue
        }
      ]
        .filter(v => v)
        .map(e => ({ ...e, label: this.$t(e.id) }));
    },
    chatbotMessageStatusValue() {
      let translatedStatus = BizMessageStatus.t(this.chatbotMessageStatus);

      if (this.chatbotMessageStatus === BizMessageStatus.BLOCKED) {
        translatedStatus += this.$t('chatbot_message_status_blocked');
      }

      return translatedStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
.ChatbotMessageProfileDialog__contents {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "title": "프로필 상세정보",
  "subtitle": "챗봇",
  "profile_type": "챗봇 타입",
  "channel_id": "카카오톡 채널 아이디",
  "channel_profile_image": "카카오톡 채널 이미지",
  "chatbot_message_status": "발신 프로필 상태",
  "chatbot_message_status_blocked": " (카카오 정책에 의해 채널이 차단되었습니다.)",
  "chatbot_info_description": "챗봇 프리미엄 계정을 사용하면 채널명과 이미지를 커스텀 할 수 있습니다. &nbsp;&nbsp;&nbsp;[프리미엄 챗봇 신청하러 가기 >]({link})"
}
</i18n>
