<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      v-bind="{
        ...formProps,
        id: SETTINGS_ID,
        submitButton: true,
        objectId: 'widget_settings',
        formStyle: 'hor'
      }"
      v-on="formEvents"
    >
      <template #group="{id, inputId, inputName, value, errors}">
        <template v-if="id === 'show_thumbnail_as_square_on_popup'">
          <div class="AppForm__group-description">
            {{ $t('show_thumbnail_as_square_on_popup.widget_hint') }}
            <AppInlineButton
              :label="$t('app.detail')"
              button-style="caption"
              @click="openShowThumbnailAsSquareOnPopupMessageDialog"
            />
          </div>
          <div class="AppForm__group-description">
            {{ $t('show_thumbnail_as_square_on_popup.hint') }}
            <AppInlineButton
              :label="$t('app.detail')"
              button-style="caption"
              @click="openShowThumbnailAsSquareOnPopupSettingMessageDialog"
            />
          </div>
          <div class="AppForm__group-field">
            <AppSelectRadio
              v-model="formObject.show_thumbnail_as_square_on_popup"
              name="widget_settings[show_thumbnail_as_square_on_popup]"
              :options="SHOW_THUMBNAIL_AS_SQUARE_ON_POPUP_OPTIONS"
            />
          </div>
        </template>
        <template v-else-if="id === 'show_options' && isSuperAdminAccessible">
          <div class="AppForm__sub-group">
            <AppCheckbox
              v-model="formObject.allow_searching_product_options"
              :disabled="!formObject.show_options"
              name="widget_settings[allow_searching_product_options]"
              class="
                AppForm__group-field
                super-admin__item
                ReviewSettingsWidget__allow-searching-product-options
              "
              :label="$t('allow_searching_product_options.label')"
            >
              <template #sub-item>
                <div class="AppForm__sub-group-hint">
                  {{ $t('allow_searching_product_options.hint_for_widget') }}
                </div>
                <div class="AppForm__sub-group-hint">
                  {{ $t('allow_searching_product_options.hint_for_use') }}
                </div>
              </template>
            </AppCheckbox>
          </div>
        </template>
        <template v-else-if="id === 'name_display_type'">
          <div class="AppForm__group-field">
            <input type="hidden" :name="inputName" :value="value" />
            <AppSelectButton
              :id="inputId"
              :value="nameDisplay.type"
              :options="NAME_DISPLAY_TYPE_OPTIONS"
              @change="setNameDisplay({ ...nameDisplay, type: $event })"
            />
          </div>
          <div
            v-if="nameDisplay.type !== 'name_username'"
            class="AppForm__group-field"
          >
            <AppCheckbox
              :checked="nameDisplay.mask"
              :label="$t('name_display_type.is_partial')"
              @change="setNameDisplay({ ...nameDisplay, mask: $event })"
            >
              <template #sub-item="{ checked }">
                <div class="AppForm__sub-group">
                  <i18n path="name_display_type.partial_settings">
                    <AppSelect
                      slot="hide_front_or_back"
                      v-model="hideFrontOrBack"
                      :disabled="!checked"
                      :name="
                        `widget_settings[${
                          nameDisplay.type === 'name'
                            ? 'hide_name_front_or_back'
                            : 'hide_username_front_or_back'
                        }]`
                      "
                      :options="HIDE_FRONT_OR_BACK_OPTIONS"
                    />
                    <AppNumberInput
                      slot="hide_no_chars"
                      v-model="hideNoChars"
                      :disabled="!checked"
                      :digits="2"
                      :invalid="
                        !!(nameDisplay.type === 'name'
                          ? errors.hide_name_no_chars
                          : errors.hide_username_no_chars)
                      "
                      :name="
                        `widget_settings[${
                          nameDisplay.type === 'name'
                            ? 'hide_name_no_chars'
                            : 'hide_username_no_chars'
                        }]`
                      "
                      @blur="validateHideNoChars()"
                      @change="validateHideNoChars()"
                    />
                    <AppSelect
                      slot="hide_positive"
                      v-model="hidePositive"
                      :disabled="!checked"
                      :name="
                        `widget_settings[${
                          nameDisplay.type === 'name'
                            ? 'hide_name_positive'
                            : 'hide_username_positive'
                        }]`
                      "
                      :options="HIDE_POSITIVE_OPTIONS"
                    />
                  </i18n>
                  <AppFormError
                    :error="
                      nameDisplay.type === 'name'
                        ? errors.hide_name_no_chars
                        : errors.hide_username_no_chars
                    "
                  />
                </div>
                <div class="AppForm__sub-group">
                  <AppCheckbox
                    v-model="hideLength"
                    :label="$t('name_display_type.hide_length')"
                    :disabled="!checked"
                    :name="
                      `widget_settings[${
                        nameDisplay.type === 'name'
                          ? 'hide_name_length'
                          : 'hide_username_length'
                      }]`
                    "
                  />
                </div>
              </template>
            </AppCheckbox>
            <AppFormHint
              v-if="isSuperAdminAccessible"
              class="super-admin__item"
              :message="$t('name_display_type.hint_for_third_party_review')"
            />
          </div>
        </template>
        <template v-else-if="id === 'allow_custom_font'">
          <div class="AppForm__group-description">
            {{ $t('allow_custom_css.group_description') }}
          </div>
          <div
            :class="[
              'AppForm__group-field',
              formObject.allow_custom_font
                ? null
                : 'AppForm__group-field--disabled'
            ]"
          >
            <div class="AppForm__sub-group">
              <div>
                {{ $t('widget_korean_font') }}
                <span class="ReviewSettingsWidget__widget-korean-font">
                  *
                </span>
              </div>
              <AppSelect
                v-model="formObject.widget_korean_font"
                :disabled="!formObject.allow_custom_font"
                name="widget_settings[widget_korean_font]"
                :options="ReviewWidgetFont.options(ReviewWidgetFont.koreanFont)"
              />
            </div>
            <div class="AppForm__sub-group">
              <div>
                {{ $t('widget_english_number_font') }}
              </div>
              <AppSelect
                v-model="formObject.widget_english_number_font"
                :disabled="!formObject.allow_custom_font"
                name="widget_settings[widget_english_number_font]"
                :options="
                  ReviewWidgetFont.options(ReviewWidgetFont.englishNumberFont)
                "
                :placeholder="$t('app.select')"
              />
            </div>
          </div>
        </template>
        <template v-else-if="id === 'use_likes_count'">
          <div class="AppForm__sub-group-hint">
            {{ $t('use_likes_count.hint') }}
          </div>
          <AppCheckbox
            v-model="formObject.use_unlikes_count"
            :disabled="!formObject.use_likes_count"
            name="widget_settings[use_unlikes_count]"
            class="AppForm__group-field ReviewSettingsWidget__use-unlikes-count"
            :label="$t('use_unlikes_count')"
          />
        </template>
        <template v-else-if="id === 'use_google_translation'">
          <div
            :class="[
              'AppForm__group-field',
              {
                'AppForm__group-field--disabled': !formObject.use_google_translation
              }
            ]"
          >
            <div
              class="AppForm__sub-group ReviewSettingsWidget__use_google_translation"
            >
              <AppCheckbox
                v-model="formObject.use_translate_button"
                :disabled="!formObject.use_google_translation"
                name="widget_settings[use_translate_button]"
                :label="$t('use_translate_button.label')"
              />
              <div class="AppForm__sub-group-hint">
                {{ $t('use_translate_button.group_description') }}
              </div>
            </div>
            <div
              class="AppForm__sub-group ReviewSettingsWidget__use_google_translation"
            >
              <label class="AppForm__sub-group-title">
                {{ $t('default_translate_button_status.label') }}
              </label>
              <AppSelectRadio
                v-model="formObject.default_translate_button_status"
                :disabled="
                  !formObject.use_google_translation ||
                    !formObject.use_translate_button
                "
                name="widget_settings[default_translate_button_status]"
                :options="DEFAULT_TRANSLATE_BUTTON_STATUS_OPTIONS"
              />
            </div>
          </div>
        </template>
        <template v-else-if="id === 'use_renewed_review_popup'">
          <div class="AppForm__group-field">
            <AppCheckbox
              v-model="formObject.use_renewed_review_popup_for_pc"
              name="widget_settings[use_renewed_review_popup_for_pc]"
              :label="$t('use_renewed_review_popup.pc')"
            />
          </div>
          <div class="AppForm__group-field">
            <AppCheckbox
              v-model="formObject.use_renewed_review_popup"
              name="widget_settings[use_renewed_review_popup]"
              :label="$t('use_renewed_review_popup.mobile')"
            />
          </div>
        </template>
        <template v-else-if="id === 'use_comments'">
          <div class="AppForm__sub-group">
            <AppCheckbox
              v-model="formObject.always_show_comments"
              :disabled="!formObject.use_comments"
              name="widget_settings[always_show_comments]"
              class="
                AppForm__group-field
                ReviewSettingsWidget__sub-group
              "
              :label="$t('always_show_comments.label')"
            >
              <template #sub-item>
                <div class="AppForm__sub-group-hint">
                  {{ $t('always_show_comments.hint') }}
                </div>
              </template>
            </AppCheckbox>
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapState, mapGetters, mapActions } from 'vuex';
import { REVIEW_PERFECT_SCORE } from '@/constants/review';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import ReviewAuthorDisplayType from '@/enums/ReviewAuthorDisplayType';
import ReviewWidgetFont from '@/enums/ReviewWidgetFont';
import MobileScoreIconType from '@/enums/MobileScoreIconType';
import FilterableCategoryDepthType from '@/enums/FilterableCategoryDepthType';
import punycode from 'punycode';

export default {
  name: 'ReviewSettingsWidget',
  mixins: [FormView, SettingsView],
  data() {
    return {
      isLoading: false,
      SETTINGS_ID: 'review_settings_widget',
      NAME_DISPLAY_TYPE_OPTIONS: [
        { label: this.$t('name_display_type.name'), value: 'name' },
        { label: this.$t('name_display_type.username'), value: 'username' },
        {
          label: this.$t('name_display_type.name_username'),
          value: 'name_username'
        }
      ],
      SHOW_THUMBNAIL_AS_SQUARE_ON_POPUP_OPTIONS: [
        {
          label: this.$t('show_thumbnail_as_square_on_popup.square_ratio'),
          value: true
        },
        {
          label: this.$t('show_thumbnail_as_square_on_popup.original_ratio'),
          value: false
        }
      ],
      HIDE_FRONT_OR_BACK_OPTIONS: [
        { label: this.$t('hide_front_or_back.front'), value: true },
        { label: this.$t('hide_front_or_back.back'), value: false }
      ],
      HIDE_POSITIVE_OPTIONS: [
        { label: this.$t('hide_positive.show'), value: true },
        { label: this.$t('hide_positive.hide'), value: false }
      ],
      DEFAULT_TRANSLATE_BUTTON_STATUS_OPTIONS: [
        {
          label: this.$t('default_translate_button_status.on'),
          value: 'on'
        },
        {
          label: this.$t('default_translate_button_status.off'),
          value: 'off'
        }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapGetters('session', ['isShopifyBrand', 'adminLocale']),
    ...mapGetters(['isSuperAdminAccessible']),
    ReviewWidgetFont() {
      return ReviewWidgetFont;
    },
    brandPunycodeHost() {
      const brandUrl = this.currentBrand.url;
      const brandUrlHost = new URL(brandUrl).hostname.replace(/^www./, '');

      return punycode.toASCII(brandUrlHost);
    },
    groupOptions() {
      return {
        advanced_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('review_settings_advanced_tip_box.text')
        },
        name_display_type: {
          fields: ['name', 'username'].includes(this.nameDisplay.type)
            ? [
                {
                  id:
                    this.nameDisplay.type === 'name'
                      ? 'hide_name_no_chars'
                      : 'hide_username_no_chars',
                  validate: ['required', 'positive_integer']
                }
              ]
            : [],
          groupDescription: this.$t('name_display_type.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('name_display_type'),
          hint: this.showNameDisplayTypeHint
            ? this.$t('name_display_type.hint_for_global_mall')
            : ''
        },
        reviews_index_url: {
          type: 'text',
          placeholder: this.$t('reviews_index_url.placeholder'),
          hint: this.$t('reviews_index_url.hint'),
          validate: this.shopUrlValidation
        },
        mall_login_url: {
          type: 'url',
          placeholder: this.$t('mall_login_url.placeholder'),
          hint: this.$t('mall_login_url.hint'),
          validate: this.shopUrlValidation
        },
        mall_login_url_mobile: {
          type: 'url',
          placeholder: this.$t('mall_login_url_mobile.placeholder'),
          hint: this.$t('mall_login_url_mobile.hint'),
          validate: this.shopUrlValidation
        },
        author_display_type: {
          type: 'select_radio',
          options: this.isShopifyBrand
            ? ReviewAuthorDisplayType.options().filter(
                option => option.value != ReviewAuthorDisplayType.USER_GRADE
              )
            : ReviewAuthorDisplayType.options(),
          groupDescription: this.$t('author_display_type.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('author_display_type')
        },
        show_created_at: {
          type: 'checkbox',
          groupDescription: this.$t('show_created_at.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('show_created_at')
        },
        show_score: {
          type: 'checkbox',
          groupDescription: this.$t('show_score.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('show_score')
        },
        show_score_summary: {
          type: 'checkbox',
          groupDescription: this.$t('show_score_summary.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('show_score_summary')
        },
        enable_review_search: {
          type: 'checkbox',
          groupDescription: this.$t('enable_review_search.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('enable_review_search')
        },
        use_comments: {
          type: 'checkbox',
          groupDescription: this.$t('use_comments.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('use_comments')
        },
        show_spam_report_button: {
          type: 'checkbox',
          groupDescription: this.$t(
            'show_spam_report_button.group_description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('show_spam_report_button')
        },
        show_options: {
          type: 'checkbox',
          groupDescription: this.$t('show_options.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('show_options')
        },
        mobile_score_icon_type: {
          type: 'select_radio',
          options: MobileScoreIconType.options(),
          groupDescription: this.$t('mobile_score_icon_type.group_description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('mobile_score_icon_type')
        },
        filterable_category_depth: {
          type: 'select_radio',
          options: FilterableCategoryDepthType.options(),
          groupDescription: this.$t(
            'filterable_category_depth.group_description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('filterable_category_depth')
        },
        use_after_use_review_badge: {
          type: 'checkbox',
          groupDescription: this.$t(
            'use_after_use_review_badge.group_description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('use_after_use_review_badge')
        },
        use_purchase_purpose_badge: {
          type: 'checkbox',
          hint: this.$t('use_purchase_purpose_badge.hint')
        },
        allow_custom_font: { type: 'checkbox' },
        perfect_score: {
          type: 'number',
          digits: 2,
          i18n: 'review_settings_perfect_score.score',
          hint: this.$t('perfect_score.hint', [REVIEW_PERFECT_SCORE]),
          validate: ['required', { rule: 'min_max_integer', min: 1, max: 100 }]
        },
        thumbnail_video_autoplay: {
          type: 'select_radio',
          groupDescription: this.$t(
            'thumbnail_video_autoplay.group_description'
          ),
          options: [
            {
              label: this.$t('thumbnail_video_autoplay.false'),
              value: false,
              tooltip: {
                message: this.$t('thumbnail_video_autoplay.false_tooltip')
              }
            },
            {
              label: this.$t('thumbnail_video_autoplay.true'),
              value: true,
              disabled: this.reviewSettings.disable_thumbnail_video_autoplay,
              tooltip: this.reviewSettings.disable_thumbnail_video_autoplay
                ? {
                    message: this.$t('thumbnail_video_autoplay.true_tooltip')
                  }
                : null
            }
          ]
        },
        show_store_name_prefix: { type: 'checkbox' },
        use_likes_count: { type: 'checkbox' },
        use_google_translation: { type: 'checkbox' },
        use_renewed_list_style: {
          type: 'checkbox',
          disabled:
            !this.formObject.use_renewed_review_popup ||
            !this.formObject.use_renewed_review_popup_for_pc,
          hint: this.$t('use_renewed_list_style.hint')
        },
        custom_code_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('custom_code_tip_box.text')
        },
        custom_css_pc: {
          type: 'code_editor',
          lang: 'css'
        },
        custom_css_mobile: {
          type: 'code_editor',
          lang: 'css'
        },
        custom_js_pc: {
          type: 'code_editor',
          lang: 'javascript'
        },
        custom_js_mobile: {
          type: 'code_editor',
          lang: 'javascript'
        },
        utilized_url_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('utilized_url_tip_box.text')
        }
      };
    },
    shopUrlValidation() {
      return [
        'url_format',
        { rule: 'check_url', async: true },
        {
          rule: v => this.checkUrlIncludeBrandUrlHost(v),
          errorMessage: this.$t('not_including_brand_url_host_error', {
            brand_url_host: this.brandPunycodeHost
          })
        }
      ];
    },
    nameDisplay() {
      const { name_display_type } = this.formObject;
      if (name_display_type === undefined) return { type: 'name', mask: false };

      if (name_display_type === 'partial_username') {
        return { type: 'username', mask: true };
      } else if (name_display_type === 'partial_name') {
        return { type: 'name', mask: true };
      } else {
        return { type: name_display_type, mask: false };
      }
    },
    showNameDisplayTypeHint() {
      return (
        this.currentBrand.locale !== 'ko-KR' && this.formObject.source_brand_id
      );
    },
    isAdminLocaleKo() {
      return this.adminLocale === 'ko';
    },
    hideFrontOrBack: {
      get() {
        return this.nameDisplay.type === 'name'
          ? this.formObject.hide_name_front_or_back
          : this.formObject.hide_username_front_or_back;
      },
      set(value) {
        if (this.nameDisplay.type === 'name') {
          this.formObject.hide_name_front_or_back = value;
        } else {
          this.formObject.hide_username_front_or_back = value;
        }
      }
    },
    hideNoChars: {
      get() {
        return this.nameDisplay.type === 'name'
          ? this.formObject.hide_name_no_chars
          : this.formObject.hide_username_no_chars;
      },
      set(value) {
        if (this.nameDisplay.type === 'name') {
          this.formObject.hide_name_no_chars = value;
        } else {
          this.formObject.hide_username_no_chars = value;
        }
      }
    },
    hidePositive: {
      get() {
        return this.nameDisplay.type === 'name'
          ? this.formObject.hide_name_positive
          : this.formObject.hide_username_positive;
      },
      set(value) {
        if (this.nameDisplay.type === 'name') {
          this.formObject.hide_name_positive = value;
        } else {
          this.formObject.hide_username_positive = value;
        }
      }
    },
    hideLength: {
      get() {
        return this.nameDisplay.type === 'name'
          ? this.formObject.hide_name_length
          : this.formObject.hide_username_length;
      },
      set(value) {
        if (this.nameDisplay.type === 'name') {
          this.formObject.hide_name_length = value;
        } else {
          this.formObject.hide_username_length = value;
        }
      }
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/review/widget_settings')
      .then(({ data }) => {
        this.orgFormObject = _.cloneDeep(data.widget_settings);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateReviewSettings']),
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/review/widget_settings', formData)
        .then(() => {
          this.orgFormObject = _.cloneDeep(this.formObject);
          this.updateReviewSettings(this.formObject);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkUrlIncludeBrandUrlHost(value) {
      return !value || value.includes(this.brandPunycodeHost);
    },
    setNameDisplay(nameDisplay) {
      const { type, mask } = nameDisplay;
      let name_display_type;
      if (type === 'name' && mask) {
        name_display_type = 'partial_name';
      } else if (type === 'username' && mask) {
        name_display_type = 'partial_username';
      } else {
        name_display_type = type;
      }

      this.$set(this.formObject, 'name_display_type', name_display_type);
    },
    validateHideNoChars() {
      if (this.nameDisplay.type === 'name') {
        this.validateField('hide_name_no_chars');
      } else {
        this.validateField('hide_username_no_chars');
      }
    },
    openShowThumbnailAsSquareOnPopupMessageDialog() {
      this.confirm({
        type: 'alert',
        title: this.$t('show_thumbnail_as_square_on_popup.popup_title'),
        message: this.$t('show_thumbnail_as_square_on_popup.popup_message')
      });
    },
    openShowThumbnailAsSquareOnPopupSettingMessageDialog() {
      this.confirm({
        type: 'alert',
        title: this.$t('show_thumbnail_as_square_on_popup.setting_title'),
        message: this.$t('show_thumbnail_as_square_on_popup.setting_message')
      });
    },
    openDetailDialog(id) {
      this.confirm({
        type: 'alert',
        title: this.$t(`settings.${this.SETTINGS_ID}.groups.${id}`),
        message: this.$t(`${id}.popup_message`, {
          image_asset_link: this.imageAssetLink(id)
        })
      });
    },
    imageAssetLink(id) {
      const imageName = this.isAdminLocaleKo ? id : `en_${id}`;

      return `\n\n<br />![](https://assets.cre.ma/m/admin/v2/${imageName}_dialog-v2@2x.png)`;
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/mixins/_texts.scss';

.ReviewSettingsWidget__sub-group,
.ReviewSettingsWidget__allow-searching-product-options,
.ReviewSettingsWidget__use-unlikes-count,
.ReviewSettingsWidget__use_google_translation {
  margin-left: 12px;
}

.ReviewSettingsWidget__thumbnail-video-autoplay {
  margin-bottom: 4px;
}

.ReviewSettingsWidget__widget-korean-font {
  color: $color-red;
}

.ReviewSettingsWidget__hint {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "name_display_type": {
    "name": "이름",
    "username": "아이디",
    "name_username": "이름 (아이디)",
    "is_partial": "일부만 표시",
    "hide_length": "글자수 숨기기",
    "partial_settings": "{hide_front_or_back} 의 {hide_no_chars} 글자 {hide_positive}",
    "hint_for_global_mall": "해외몰의 경우 기본이 되는 국문몰의 설정과 동일하게 유지시켜주시길 권장합니다.",
    "hint_for_third_party_review": "외부 연동된 리뷰의 경우에는 해당 설정이 적용되지 않습니다",
    "group_description": "리뷰 위젯에 표시되는 작성자 표시 방법을 설정할 수 있습니다.",
    "popup_message": "리뷰 위젯에 노출되는 작성자 표시 방법을 설정할 수 있습니다.<br />고급 설정에 있는 ‘작성자명 일부만 표시’ 기능을 활용하면 개인정보가<br />노출되지 않도록 텍스트를 선별적으로 표시할 수 있습니다.{image_asset_link}"
  },
  "hide_front_or_back": {
    "front": "앞",
    "back": "뒤"
  },
  "hide_positive": {
    "show": "노출하기",
    "hide": "숨기기"
  },
  "reviews_index_url": {
    "placeholder": "전체 리뷰 게시판 URL을 입력해주세요.",
    "hint": "상품 리뷰 위젯에서 '전체 상품 리뷰 보기' 버튼 클릭시 입력한 URL로 이동합니다."
  },
  "mall_login_url": {
    "placeholder": "PC 로그인 페이지 URL을 입력해주세요.",
    "hint": "리뷰 작성 대상이 회원일 경우, '상품 리뷰 작성하기' 버튼 클릭 시 입력한 PC URL로 이동합니다."
  },
  "mall_login_url_mobile": {
    "placeholder": "모바일 로그인 페이지 URL을 입력해주세요.",
    "hint": "리뷰 작성 대상이 회원일 경우, '상품 리뷰 작성하기' 버튼 클릭 시 입력한 모바일 URL로 이동합니다."
  },
  "author_display_type": {
    "group_description": "리뷰 위젯에 작성자의 등급 표시 방법을 설정할 수 있습니다.",
    "popup_message": "리뷰 위젯에 작성자의 등급 표시 방법을 설정할 수 있습니다.{image_asset_link}"
  },
  "show_created_at": {
    "group_description": "리뷰 위젯에 리뷰가 작성된 일자를 표시합니다.",
    "popup_message": "리뷰 위젯에 리뷰 작성일의 표시 여부를 설정할 수 있습니다.{image_asset_link}"
  },
  "show_score": {
    "group_description": "리뷰 위젯에 작성자가 리뷰 작성 시 평가한 리뷰 별점을 표시합니다.",
    "popup_message": "리뷰 위젯에 작성자가 리뷰 작성 시 평가한 리뷰 별점의 표시 여부를 설정할 수 있습니다.{image_asset_link}"
  },
  "show_score_summary": {
    "group_description": "리뷰 위젯 중 상품 평점이 표시되는 영역에 상품 평점 표시합니다.",
    "popup_message": "리뷰 위젯 중 상품 평점이 표시되는 영역에 상품 평점 표시 여부를 설정할 수 있습니다.<br />단, 상품 상세 페이지에 설치되는 상품 리뷰 위젯 평점 통계 부분의 노출 여부는 각 위젯 설정의 평점 영역 사용 여부를 통해 설정할 수 있습니다.{image_asset_link}"
  },
  "enable_review_search": {
    "group_description": "리뷰 위젯 내 리뷰 검색창 기능을 사용합니다.",
    "popup_message": "리뷰 위젯 내 리뷰 검색창 기능 사용 여부를 설정할 수 있습니다.<br />사용 설정하지 않을 경우 리뷰 검색과 관련된 기능이 위젯에 노출되지 않습니다.{image_asset_link}"
  },
  "use_comments": {
    "group_description": "리뷰 위젯에 댓글 기능 사용 여부를 설정할 수 있습니다.",
    "popup_message": "리뷰 위젯에 댓글 작성 및 댓글 기능 사용 여부를 설정할 수 있습니다.<br />사용 설정하지 않을 경우 댓글 작성 및 댓글 보기 등 댓글과 관련된 기능이 위젯에 노출되지 않습니다.{image_asset_link}"
  },
  "show_spam_report_button": {
    "group_description": "모바일 리스트 위젯 및 포토 팝업에서 ‘신고 및 차단’ 버튼을 노출합니다.",
    "popup_message": "모바일 리스트 위젯 및 포토 팝업에서 ‘신고 및 차단’ 버튼을 노출할 수 있습니다. 버튼 클릭 시 게시물을 신고하거나, 작성자를 차단할 수 있는 ‘신고 및 차단 팝업' 이 노출됩니다.{image_asset_link}"
  },
  "show_options": {
    "group_description": "리뷰 위젯에 작성자가 상품 구매시 선택한 옵션값을 표시합니다.",
    "popup_message": "리뷰 위젯에 작성자가 상품 구매 시 선택한 옵션값 표시 여부를 설정할 수 있습니다.{image_asset_link}"
  },
  "allow_searching_product_options": {
    "label": "구매옵션 필터 사용",
    "hint_for_widget": "해당 설정을 사용할 경우에만, 구매옵션별 요약 위젯이 표시됩니다.",
    "hint_for_use": "해당 업체의 상품 리소스에 상품 옵션이 한건도 들어오지 않은 경우(ex. 일부 독립몰), 사용에 제한이 있습니다."
  },
  "mobile_score_icon_type": {
    "group_description": "모바일 전체/상품리뷰 게시판 위젯, 상품리뷰 분리형 게시판 위젯에 표시되는 별점 아이콘 모양을 설정할 수 있습니다.",
    "popup_message": "모바일 전체/상품리뷰 게시판 위젯, 상품리뷰 분리형 게시판 위젯에 표시되는 별점 아이콘 모양을 설정할 수 있습니다.{image_asset_link}"
  },
  "filterable_category_depth": {
    "group_description": "리뷰 위젯 내 카테고리 검색 기능의 검색 기준을 설정할 수 있습니다.",
    "popup_message": "리뷰 위젯 내 카테고리 검색 기능의 검색 기준을 설정할 수 있습니다.{image_asset_link}"
  },
  "show_thumbnail_as_square_on_popup": {
    "square_ratio": "정사각형으로 표시",
    "original_ratio": "원본 비율대로 표시",
    "widget_hint": "해당 설정은 '리스트 위젯 V2'와 '포토 팝업 V2'를 사용하는 경우에만 '포토/동영상 모아보기 팝업(PC)'과 '포토 팝업 V2 상세'에 적용됩니다.",
    "hint": "해당 설정에서 리뷰 포토/동영상의 썸네일 비율을 선택할 수 있습니다.",
    "popup_title": "리뷰 포토/동영상 썸네일 비율 설정이 적용되는 팝업",
    "popup_message": "<li>해당 설정은 ‘포토/동영상 모아보기 팝업(PC)'과 ‘포토 팝업 상세’에 적용됩니다.</li>\n\n<br />![](https://assets.cre.ma/m/admin/v2/show_thumbnail_as_square_popup_dialog@2x.png)",
    "setting_title": "리뷰 포토/동영상 썸네일 비율 설정",
    "setting_message": "<li>포토/동영상을 정사각형으로 표시하거나 원본 비율대로 표시할 수 있습니다.</li><li>원본 비율대로 표시할 경우 상황에 따라 상하 혹은 좌우로 여백이 생길 수 있습니다.</li>\n\n<br />![](https://assets.cre.ma/m/admin/v2/show_thumbnail_as_square_dialog@2x.png)"
  },
  "use_after_use_review_badge": {
    "group_description": "배송 완료 후 30일이 지난 상품에 리뷰를 작성한 경우 '한달 사용 리뷰' 배지를 노출합니다.",
    "popup_message": "배송 완료 후 30일이 지난 상품에 리뷰를 작성한 경우 '한달 사용 리뷰' 배지를 노출합니다. 한달 사용 리뷰 배지는 리스트 위젯과 포토 팝업에만 적용됩니다.{image_asset_link}"
  },
  "use_purchase_purpose_badge": {
    "hint": "쇼핑몰에 해당 데이터가 있고 배지가 노출될 수 있도록 연동이 되어 있을 경우, 위젯에 배지를 노출합니다.<br>구매 목적: 선물, 체험단"
  },
  "allow_custom_css": {
    "group_description": "기존에 CSS로 폰트를 설정한 경우에는 해당 설정이 동작하지 않습니다.\n변경을 원하시면 운영팀으로 문의해주세요."
  },
  "perfect_score": {
    "hint": "위젯에만 적용하는 설정으로 관리자 페이지에서는 {0}점을 만점 기준으로 표시합니다."
  },
  "thumbnail_video_autoplay": {
    "group_description": "동영상 리뷰 사용 시 썸네일에서",
    "false": "동영상 멈춤 상태에서 재생 마크 노출",
    "true": "동영상 자동 재생",
    "false_tooltip": "모바일 게시판 위젯에서는 별도의 재생 마크 없이 썸네일 이미지만 노출됩니다.",
    "true_tooltip": "동영상 자동 재생을 사용하기 원하시면 크리마 운영팀에 문의해주세요."
  },
  "use_likes_count": {
    "hint": "해당 설정 사용 여부는 추천순 정렬에 영향을 줍니다."
  },
  "use_translate_button": {
    "label": "번역 버튼 표시",
    "group_description": "버튼을 표시하지 않을 경우, 번역된 상태를 기본으로 표시합니다."
  },
  "default_translate_button_status": {
    "label": "번역 버튼의 기본 값",
    "on": "ON : 로컬 언어로 번역된 상태를 기본으로 표시",
    "off": "OFF : 번역되지 않은, 리뷰 원본의 언어를 기본으로 표시"
  },
  "use_renewed_review_popup": {
    "pc": "PC",
    "mobile": "모바일"
  },
  "use_renewed_list_style": {
    "hint": "포토팝업 V2를 모두 사용해야 리스트위젯 V2를 사용할 수 있습니다. 설치된 모든 리스트 위젯이 V2로 적용됩니다."
  },
  "custom_code_tip_box": {
    "text": "공통 CSS/JavaScript 를 설정하면 모든 위젯에 설정 내용이 적용됩니다.<br />설정 이후 위젯 업데이트 시 설정한 내용과 충돌이 생겨  디자인이 의도와 다르게 변경되거나 문제가 발생할 수 있습니다.<br />크리마에서는 CSS/JavaScript 적용으로 인해 발생한 문제에 대해서는 대응이 어려우니,<br /><b>쇼핑몰 내부에 개발팀이 있거나 웹 개발에 대한 이해가 높은 담당자가 있는 경우</b>에만 설정하는 것을 추천합니다.<br />의도치 않게 설정을 하였거나, 문제가 발생했으나 해결이 어려운 경우 <b>설정값을 모두 삭제 후 저장 하면 정상동작</b> 할 것입니다."
  },
  "utilized_url_tip_box": {
    "text": "위젯 설치 및 위젯에 활용되는 URL입니다. 설정된 값과 쇼핑몰 실제 URL이 다른 경우 꼭 변경해주세요."
  },
  "always_show_comments": {
    "label": "댓글 영역 항상 펼쳐두기",
    "hint": "리스트 위젯과 포토 팝업에만 적용됩니다."
  },
  "use_unlikes_count": "위젯의 \"도움 안 돼요\" 사용",
  "widget_english_number_font": "영문, 숫자 폰트",
  "widget_korean_font": "한글 폰트",
  "not_including_brand_url_host_error": "입력한 URL과 쇼핑몰 URL의 domain이 다릅니다. 도메인에 해당하는 ‘{brand_url_host}’를 포함하는 URL을 입력해야 합니다.",
  "show_more_link": "자세히"
}
</i18n>

<i18n locale="en">
{
  "name_display_type": {
    "partial_settings": "{hide_positive} {hide_no_chars} characters from {hide_front_or_back}"
  }
}
</i18n>
