<template>
  <AppResourceTable :columns="columns" :rows="rows" :enable-total-count="false">
    <template #cell="{ column, row }">
      <template v-if="column === 'select'">
        <div v-tooltip="row.isSelectable ? null : $t('not_selectable')">
          <AppRadio
            input-style="standalone"
            :value="row.id"
            :selected-value="selectedValue"
            :disabled="!row.isSelectable"
            @change="changeSelectedTemplate(row.bizMessageTemplateType, $event)"
          />
        </div>
      </template>
      <template v-else-if="column === 'name'">
        <template
          v-if="row.bizMessageTemplateType === BizMessageTemplateType.GROUP"
        >
          <AppSelect
            v-model="selectedGroupTemplateId"
            :options="groupTemplateOptions"
            :disabled="!isGroupTemplateSelected"
            @change="$emit('change', $event)"
          />
        </template>
      </template>
      <template v-else-if="column === 'status'">
        <AppBadge v-bind="row.status" />
      </template>
      <template v-else-if="column === 'manage'">
        <ReviewMessageBizMessageTemplateManageCell :template="row" />
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';

export default {
  name: 'ReviewMessagesBizMessageTemplateSelectTable',
  model: { prop: 'selectedValue', event: 'change' },
  props: {
    templates: { required: true, type: Array },
    groupTemplates: { required: true, type: Array },
    selectedValue: { type: Number, default: null }
  },
  data() {
    return {
      selectedGroupTemplateId: this.groupTemplates[0].id,
      isGroupTemplateSelected: null
    };
  },
  computed: {
    ...mapState('bizMessageTemplate', ['templateType']),
    BizMessageTemplateType: () => BizMessageTemplateType,
    columns() {
      return [
        { id: 'select', label: this.$t('app.select'), width: '40px' },
        { id: 'name', label: this.$t('columns.name'), align: 'left' },
        { id: 'status', label: this.$t('app.status'), width: '100px' },
        { id: 'manage', label: this.$t('app.manage'), width: '82px' }
      ];
    },
    rows() {
      return this.groupTemplateRow.concat(this.templateRows);
    },
    groupTemplateRow() {
      return [
        {
          id: this.selectedGroupTemplateId,
          status: {
            badgeStyle: 'grey-outline',
            label: this.$t('group_template.status')
          },
          isSelectable: true,
          bizMessageTemplateType: BizMessageTemplateType.GROUP
        }
      ];
    },
    templateRows() {
      return this.templates
        .filter(
          template =>
            ![
              BizMessageTemplateInspectionStatus.NONE,
              BizMessageTemplateInspectionStatus.REGISTERED
            ].includes(template.inspection_status)
        )
        .map(template => ({
          ...template,
          status:
            template.inspection_status ===
            BizMessageTemplateInspectionStatus.APPROVED
              ? {
                  badgeStyle: BizMessageTemplateStatus.badgeStyle(
                    template.status
                  ),
                  label: BizMessageTemplateStatus.t(template.status)
                }
              : {
                  badgeStyle: BizMessageTemplateInspectionStatus.badgeStyle(
                    template.inspection_status
                  ),
                  label: BizMessageTemplateInspectionStatus.t(
                    template.inspection_status
                  ),
                  tooltip: template.rejected_message
                },
          isSelectable:
            template.status !== BizMessageTemplateStatus.STOPPED &&
            template.inspection_status ===
              BizMessageTemplateInspectionStatus.APPROVED,
          bizMessageTemplateType: BizMessageTemplateType.NORMAL
        }));
    },
    groupTemplateOptions() {
      return this.groupTemplates.map(t => ({
        label: t.name,
        value: t.id
      }));
    }
  },
  created() {
    if (this.templateType === BizMessageTemplateType.GROUP) {
      this.selectedGroupTemplateId = this.selectedValue;
      this.isGroupTemplateSelected = true;
    }
  },
  methods: {
    ...mapActions('bizMessageTemplate', ['setTemplateType']),
    changeSelectedTemplate(templateType, e) {
      this.setTemplateType(templateType);
      this.isGroupTemplateSelected =
        templateType === BizMessageTemplateType.GROUP;
      const seletedTemplateId = this.isGroupTemplateSelected
        ? this.selectedGroupTemplateId
        : e;
      this.$emit('change', seletedTemplateId);
    }
  }
};
</script>

<i18n locale="ko">
{
  "columns": {
    "name": "메시지 템플릿 이름"
  },
  "group_template": {
    "status": "기본 템플릿"
  },
  "not_selectable": "상태가 '사용가능'인 템플릿만 선택이 가능합니다."
}
</i18n>
