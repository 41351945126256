<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm
      v-if="data"
      v-bind="reviewFormProps"
      v-on="{
        ...formEvents,
        submit: submitForm
      }"
      @change-group="formGroupChanged"
    >
      <template #group="{ id, inputId, errors }">
        <div v-if="id === 'review_launched_at'" class="AppForm__group-field">
          <input
            type="hidden"
            :value="reviewSettings.review_launched_at"
            name="review_settings[review_launched_at]"
          />
          <AppDatePicker :id="inputId" v-model="reviewLaunchedAtDate" />
        </div>
        <div
          v-else-if="id === 'update_godo_product_reviews_count'"
          class="AppForm__group-field"
        >
          <AppButton
            :label="$t('update_godo_product_reviews_count.update')"
            :disabled="
              data.update_godo_product_reviews_count_job_in_progress ||
                isUpdatingGodoProductReviewsCount
            "
            :tooltip="updateGodoProductReviewsCountTooltip"
            @click="updateGodoProductReviewsCount"
          />
        </div>
        <template v-else-if="id === 'push_review_to_shop_builder'">
          <div class="AppForm__group-field">
            <AppCheckbox
              v-model="reviewSettings.enable_push_review"
              class="AppForm__group-field--mt8"
              name="review_settings[enable_push_review]"
              :label="$t('enable_push_review')"
            />
            <AppCheckbox
              v-model="reviewSettings.enable_push_nonmember_review"
              class="ReviewSettingsSuperAdminManage__enable-push-review-option"
              name="review_settings[enable_push_nonmember_review]"
              :disabled="!reviewSettings.enable_push_review"
              :label="$t('enable_push_nonmember_review')"
            />
            <AppCheckbox
              v-model="reviewSettings.enable_push_manager_review"
              class="ReviewSettingsSuperAdminManage__enable-push-review-option"
              name="review_settings[enable_push_manager_review]"
              :disabled="!reviewSettings.enable_push_review"
              :label="$t('enable_push_manager_review')"
            />
          </div>
        </template>
        <template v-else-if="id === 'push_reviews_count_to_shop_builder'">
          <div class="AppForm__group-field">
            <AppCheckbox
              v-model="reviewSettings.enable_push_reviews_count"
              class="AppForm__group-field--mt8"
              name="review_settings[enable_push_reviews_count]"
              :label="$t('enable_push_reviews_count')"
            />
            <AppCheckbox
              v-model="reviewSettings.enable_push_nonmember_reviews_count"
              class="ReviewSettingsSuperAdminManage__enable_push_reviews_count-option"
              name="review_settings[enable_push_nonmember_reviews_count]"
              :disabled="!reviewSettings.enable_push_reviews_count"
              :label="$t('enable_push_nonmember_reviews_count')"
            />
            <AppCheckbox
              v-model="reviewSettings.enable_push_manager_reviews_count"
              class="ReviewSettingsSuperAdminManage__enable_push_reviews_count-option"
              name="review_settings[enable_push_manager_reviews_count]"
              :disabled="!reviewSettings.enable_push_reviews_count"
              :label="$t('enable_push_manager_reviews_count')"
            />
          </div>
        </template>
        <template v-else-if="id === 'nlp_category'">
          <div
            class="ReviewSettingsSuperAdminManage__category-auto-classification"
          >
            <AppButton
              class="ReviewSettingsSuperAdminManage__category-auto-classification-button"
              :label="categoryAutoClassificationLabel"
              :disabled="
                nlpCategoryAutoClassificationJobEnqueued ||
                  data.nlp_category_auto_classification_job_in_progress
              "
              @click="categoryAutoClassification"
            />
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="AppForm__sub-group-hint"
              v-html="$t('description.category_auto_classification')"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <template v-for="nlp_category_type in data.nlp_category_types">
            <div :key="nlp_category_type.id" class="AppForm__sub-group">
              <label
                class="AppForm__sub-group-title"
                :for="
                  `review_settings[nlp_category_ids_map][${nlp_category_type.id}][]`
                "
                >{{
                  $t('categories_for_nlp_api', [nlp_category_type.name])
                }}</label
              >
              <AppSelectProductCategoryCheckable
                v-model="
                  reviewSettings.nlp_category_ids_map[nlp_category_type.id]
                "
                :name="
                  `review_settings[nlp_category_ids_map][${nlp_category_type.id}][]`
                "
                :invalid="!!errors['review_settings[nlp_category_ids_map]']"
                :checked-category-ids="reviewSettings.nlp_category_ids_fixed"
                @blur="validateField('review_settings[nlp_category_ids_map]')"
                @change="validateField('review_settings[nlp_category_ids_map]')"
                @check="nlpCategoryIdsMapOnCheck"
                @remove="removeOptionFromNlpCategoryIdsChecked"
              />
            </div>
          </template>
          <div class="AppForm__sub-group-hint">
            {{ $t('description.category_manual_fix') }}
          </div>
          <AppHiddenInputArray
            :name="`review_settings[nlp_category_ids_fixed][]`"
            :values="reviewSettings.nlp_category_ids_fixed"
          />
          <AppFormError
            :error="errors['review_settings[nlp_category_ids_map]']"
          />
        </template>
        <template v-else-if="id === 'use_nlp_by_openai'">
          <div
            class="AppForm__group-field--mt8 ReviewSettingsSuperAdminManage__use-nlp"
          >
            <AppCheckbox
              v-model="reviewSettings.use_nlp_sentiment"
              :disabled="!reviewSettings.use_nlp_by_openai"
              name="review_settings[use_nlp_sentiment]"
              :label="$t('use_nlp_sentiment')"
            />
            <div class="AppForm__sub-group-hint">
              {{ $t('description.use_nlp_sentiment') }}
            </div>
            <AppCheckbox
              v-model="reviewSettings.use_admin_analysis_badge"
              :disabled="!reviewSettings.use_nlp_by_openai"
              name="review_settings[use_admin_analysis_badge]"
              class="AppForm__group-field--mt8"
              :label="$t('use_admin_analysis_badge')"
            />
          </div>
        </template>
        <template v-else-if="id === 'analyze_reviews_now'">
          <div class="AppForm__group-field">
            <AppSelect
              v-model="analysisReviewsLimit"
              :options="NLP_ANALYSIS_REVIEWS_LIMIT"
            />
            <AppButton
              class="ReviewSettingsSuperAdminManage__analyze-reviews-now-button"
              :label="$t('anlaysis_reviews_limit_button')"
              @click="analyzeReviews"
            />
          </div>
        </template>
        <template v-else-if="id === 'resend_date_range'">
          <div class="AppForm__group-field">
            <AppDateRangePicker
              v-model="dateRange"
              required
              :min-date="minDate"
              @update="changeDateRange"
            />
            <div class="AppForm__description">
              {{ $t('description.resend_date_range') }}
            </div>
          </div>
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <AppButton
              :label="$t('resend_messages.title')"
              :disabled="!dateRange.start_date || !dateRange.end_date"
              @click="resendMessages"
            />
            <div class="AppForm__description">
              {{ $t('resend_messages.description') }}
            </div>
          </div>
        </template>
        <template v-else-if="id === 'source_brand_id'">
          <div class="AppForm__group-field">
            <AppSelectFilterable
              v-model="reviewSettings.source_brand_id"
              name="review_settings[source_brand_id]"
              :options="brandOptions"
              :placeholder="$t('source_brand_id_placeholder')"
            />
          </div>
        </template>
        <template v-else-if="id === 'review_sync_transaction_id'">
          <div class="AppForm__group-field">
            <AppSelect
              v-model="reviewSettings.review_sync_transaction_id"
              name="review_settings[review_sync_transaction_id]"
              :options="reviewSyncPackageOptions"
              :placeholder="$t('select_review_sync_transaction')"
            />
          </div>
        </template>
        <template v-else-if="id === 'review_sync_start_date'">
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <input
              type="hidden"
              :value="reviewSyncStartDate"
              name="review_settings[review_sync_start_date]"
            />
            <AppDatePicker
              v-model="reviewSyncStartDate"
              v-tooltip="
                reviewSyncStartDateInputOptions.disabled
                  ? $t('review_sync_start_date_tooltip')
                  : ''
              "
              :disabled="reviewSyncStartDateInputOptions.disabled"
            />
          </div>
        </template>
        <template v-else-if="id === 'import_reviews'">
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <AppButton
              :label="$t('import_reviews')"
              :disabled="
                !reviewSettings.source_brand_id ||
                  !reviewSettings.review_sync_start_date
              "
              :tooltip="
                reviewSettings.source_brand_id
                  ? undefined
                  : $t('import_reviews_tooltip_none_selected')
              "
              @click="importReviews"
            />
          </div>
        </template>
        <template v-else-if="id === 'option_translation_map'">
          <div class="AppForm__group-field">
            <AppButton
              :label="$t('option_translation_map.add_translation')"
              @click="addOptionTranslation"
            />
            <AppButton
              button-style="grey"
              :label="$t('option_translation_map.load_source')"
              :disabled="!reviewSettings.source_brand_id"
              @click="loadTranslationSource"
            />
          </div>
          <div class="AppForm__group-field AppForm__group-field--mt12">
            <AppSmallDeviceScrollable>
              <AppTable
                valign-baseline
                :columns="[
                  {
                    id: 'original_text',
                    label: $t('option_translation_map.original_text')
                  },
                  {
                    id: 'translated_text',
                    label: $t('option_translation_map.translated_text')
                  },
                  { id: 'actions', label: $t('app.actions') }
                ]"
                :rows="optionTranslations"
                :no-data="$t('option_translation_map.no_data')"
              >
                <template #cell="{ row, column, rowIndex }">
                  <template v-if="column.id === 'original_text'">
                    <AppTextInput
                      :ref="`textInput${rowIndex}`"
                      v-model="optionTranslations[rowIndex].original_text"
                      name="review_settings[option_translation_map][][original_text]"
                      :placeholder="
                        $t('option_translation_map_original_text_placeholder')
                      "
                      :invalid="!!errors[`${row.id}[original_text]`]"
                      @blur="validateField(`${row.id}[original_text]`)"
                      @change="
                        optionTranslations.forEach(({ id }) =>
                          validateField(`${id}[original_text]`)
                        )
                      "
                    />
                    <AppFormError :error="errors[`${row.id}[original_text]`]" />
                  </template>
                  <template v-else-if="column.id === 'translated_text'">
                    <AppTextInput
                      v-model="optionTranslations[rowIndex].translated_text"
                      name="review_settings[option_translation_map][][translated_text]"
                      :placeholder="
                        $t('option_translation_map_translated_text_placeholder')
                      "
                      :invalid="!!errors[`${row.id}[translated_text]`]"
                      @blur="validateField(`${row.id}[translated_text]`)"
                      @change="validateField(`${row.id}[translated_text]`)"
                    />
                    <AppFormError
                      :error="errors[`${row.id}[translated_text]`]"
                    />
                  </template>
                  <template v-else-if="column.id === 'actions'">
                    <AppButton
                      button-style="red-outline"
                      :label="$t('app.delete')"
                      @click="optionTranslations.splice(rowIndex, 1)"
                    />
                  </template>
                </template>
              </AppTable>
            </AppSmallDeviceScrollable>
          </div>
        </template>
      </template>
      <template #group-label="{ group, value, checked, errors }">
        <template v-if="group.id === 'nlp_analyzable_reviews_limit'">
          <div v-if="value === 'nlp_limit_including_allowed_days_review'">
            <i18n path="nlp_limit_including_allowed_days_review">
              <AppNumberInput
                v-model="reviewSettings.nlp_limit_within_allowed_days"
                :digits="3"
                :disabled="!checked"
                :invalid="
                  !!errors['review_settings[nlp_limit_within_allowed_days]']
                "
                name="review_settings[nlp_limit_within_allowed_days]"
                @blur="
                  validateField(
                    'review_settings[nlp_limit_within_allowed_days]'
                  )
                "
                @change="
                  validateField(
                    'review_settings[nlp_limit_within_allowed_days]'
                  )
                "
              />
            </i18n>
            <AppFormError
              :error="errors['review_settings[nlp_limit_within_allowed_days]']"
            />
          </div>
          <div v-else-if="value === 'nlp_limit_including_allowed_review_count'">
            <i18n path="nlp_limit_including_allowed_review_count">
              <AppNumberInput
                v-model="reviewSettings.nlp_limit_max_review_count_per_day"
                :digits="5"
                :disabled="!checked"
                :invalid="
                  !!errors[
                    'review_settings[nlp_limit_max_review_count_per_day]'
                  ]
                "
                name="review_settings[nlp_limit_max_review_count_per_day]"
                @blur="
                  validateField(
                    'review_settings[nlp_limit_within_allowed_days]'
                  )
                "
                @change="
                  validateField(
                    'review_settings[nlp_limit_within_allowed_days]'
                  )
                "
              />
            </i18n>
            <AppFormError
              :error="
                errors['review_settings[nlp_limit_max_review_count_per_day]']
              "
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import HttpStatus from '@/enums/HttpStatus';
import ReviewSettingsView from './ReviewSettingsView';
import ReviewMileageMessageValidationHelper from '@/mixins/ReviewMileageMessageValidationHelper';

export default {
  name: 'ReviewSettingsSuperAdminManage',
  mixins: [ReviewSettingsView, ReviewMileageMessageValidationHelper],
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null }
  },
  data() {
    const min_date = new Date();
    min_date.setFullYear(min_date.getFullYear() - 1);
    return {
      SETTINGS_ID: 'review_settings_super_admin_manage',
      SETTINGS_URL: 'review/settings/super_admin_manage',
      AUTO_MILEAGE_BATCH_INTERVAL_TYPE_OPTIONS: [2, 6, 12, 24].map(value => ({
        value,
        label: this.$t('unit.hour', [value])
      })),
      NLP_ANALYZABLE_REVIEWS_LIMIT_OPTIONS: [
        {
          id: 'nlp_excluding_invisible_product_review',
          name: 'review_settings[nlp_excluding_invisible_product_review]',
          label: this.$t('nlp_excluding_invisible_product_review')
        },
        {
          id: 'nlp_limit_including_allowed_days_review',
          name: 'review_settings[nlp_limit_including_allowed_days_review]',
          label: this.$t('nlp_limit_including_allowed_days_review')
        },
        {
          id: 'nlp_limit_including_allowed_review_count',
          name: 'review_settings[nlp_limit_including_allowed_review_count]',
          label: this.$t('nlp_limit_including_allowed_review_count')
        }
      ],
      NLP_ANALYSIS_REVIEWS_LIMIT: [10, 50, 100].map(limit => ({
        label: this.$t('anlaysis_reviews_limit', [limit]),
        value: limit
      })),
      analysisReviewsLimit: 10,
      dateRange: { start_date: this.startDate, end_date: this.endDate },
      minDate: min_date.toDateString(),
      isCalculating: false,
      isUpdatingGodoProductReviewsCount: false,
      nlpCategoryAutoClassificationJobEnqueued: false,
      space_size_in_megabyte: null,
      optionTranslations: [],
      reviewSyncStartDates: {}
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'adminSettings']),
    reviewLaunchedAt: {
      get() {
        return moment(this.reviewSettings.review_launched_at);
      },
      set(newValue) {
        this.reviewSettings.review_launched_at = newValue.toISOString();
      }
    },
    reviewLaunchedAtDate: {
      get() {
        return this.reviewLaunchedAt.toVal();
      },
      set(newValue) {
        const newDate = moment(newValue);
        this.reviewLaunchedAt = this.reviewLaunchedAt
          .clone()
          .year(newDate.year())
          .month(newDate.month())
          .date(newDate.date())
          .hour(0)
          .minute(0)
          .second(0);
      }
    },
    reviewSyncStartDate: {
      get() {
        let reviewSyncStartDate;
        if (this.adminSettings.enable_renewed_review_sync) {
          reviewSyncStartDate = this.reviewSyncStartDates[
            this.reviewSettings.review_sync_transaction_id
          ];
        } else {
          reviewSyncStartDate = this.reviewSettings.review_sync_start_date;
        }
        return reviewSyncStartDate ? moment(reviewSyncStartDate).toVal() : null;
      },
      set(newValue) {
        const reviewSyncStartDate = moment(newValue).toVal();
        if (this.adminSettings.enable_renewed_review_sync) {
          this.reviewSyncStartDates = {
            ...this.reviewSyncStartDates,
            [this.reviewSettings
              .review_sync_transaction_id]: reviewSyncStartDate
          };
        } else {
          this.reviewSettings.review_sync_start_date = reviewSyncStartDate;
        }
      }
    },
    categoryAutoClassificationLabel() {
      return this.$t(
        this.nlpCategoryAutoClassificationJobEnqueued ||
          this.data.nlp_category_auto_classification_job_in_progress
          ? 'category_auto_classification_in_progress'
          : 'category_auto_classification'
      );
    },
    updateGodoProductReviewsCountTooltip() {
      return this.data.update_godo_product_reviews_count_job_in_progress ||
        this.isUpdatingGodoProductReviewsCount
        ? this.$t('update_godo_product_reviews_count.updating')
        : this.data.review_settings.product_reviews_count_for_godo_updated_at
        ? this.$t('update_godo_product_reviews_count.last_updated_at', [
            this.formatDateTime(
              this.data.review_settings
                .product_reviews_count_for_godo_updated_at
            )
          ])
        : null;
    },
    checkDuplicateNlpCategoryIds() {
      const flattenIds = _.chain(this.formObject.nlp_category_ids_map)
        .values()
        .flatten()
        .value();
      return flattenIds.length === _.uniq(flattenIds).length;
    },
    brandOptions() {
      if (!this.data) return [];

      return [
        { label: this.$t('select_brand'), value: null },
        ...this.data.brands.map(b => ({ label: b.name, value: b.id }))
      ];
    },
    reviewSyncPackageOptions() {
      if (!this.data) return [];

      return [
        ...this.data.review_sync_packages
          .map(p => ({
            label: `${moment(p.transaction_created_at).format(
              'YYYY.MM.DD.'
            )} ${this.$t(
              'CremaServiceType.REVIEW_SYNC'
            )} ${this.$t('unit.months', [p.package.months])}`,
            value: p.transaction_id
          }))
          .sort((a, b) => b.value - a.value)
      ];
    },
    groupOptions() {
      return {
        review_launched_at: {
          description: this.$t('description.review_launched_at'),
          hint: this.$t('review_launched_at_hint')
        },
        use_strong_security_policy: { type: 'checkbox' },
        admin_usernames: {
          type: 'tags',
          groupDescription: this.$t('description.admin_usernames'),
          placeholder: this.$t('admin_usernames_placeholder')
        },
        push_review_to_shop_builder: {
          groupDescriptionBox: this.$t(
            'description.push_review_to_shop_builder'
          )
        },
        push_reviews_count_to_shop_builder: {
          groupDescriptionBox: this.$t(
            'description.push_reviews_count_to_shop_builder'
          )
        },
        skip_product_image_download: { type: 'checkbox' },
        search_engine_noindex: { type: 'checkbox' },
        allow_only_basic_chars_for_new_review: { type: 'checkbox' },
        allow_category_ids_without_review_contents: { type: 'checkbox' },
        file_attach_not_supported_powerapps_android_versions: {
          type: 'tags',
          placeholder: this.$t(
            'file_attach_not_supported_powerapps_android_versions_placeholder'
          ),
          hint: this.$t(
            'description.file_attach_not_supported_powerapps_android_versions'
          )
        },
        detail_popup_only_ios_versions: {
          type: 'tags',
          placeholder: this.$t('detail_popup_only_ios_versions_placeholder'),
          hint: this.$t('description.detail_popup_only_ios_versions')
        },
        disable_replace_state_in_mobile_app: {
          type: 'checkbox',
          description: this.$t(
            'description.disable_replace_state_in_mobile_app'
          )
        },
        enable_review_allowed_author_type_delivery_finished: {
          type: 'checkbox',
          description: this.$t(
            'description.enable_review_allowed_author_type_delivery_finished'
          )
        },
        require_photo: { type: 'checkbox' },
        use_inducing_popup_for_pc: { type: 'checkbox' },
        use_product_category_product_type: { type: 'checkbox' },
        hide_review_messages_menu: {
          type: 'checkbox',
          description: this.$t('description.hide_review_messages_menu')
        },
        hide_review_csv_button: {
          type: 'checkbox',
          description: this.$t('description.hide_review_csv_button')
        },
        pushable_reviews_space_size: {
          type: 'button',
          value: this.$t(
            this.isCalculating
              ? 'pushable_reviews_space_size.OFF'
              : 'pushable_reviews_space_size.ON'
          ),
          description:
            this.space_size_in_megabyte != null &&
            this.$t('description.pushable_reviews_space_size', {
              size: this.space_size_in_megabyte
            }),
          disabled: this.isCalculating,
          clickHandler: this.calculateSpaceSizeForReviews
        },
        use_toggle_byapps_bottom_menu: {
          type: 'checkbox',
          description: this.$t('description.use_toggle_byapps_bottom_menu')
        },
        matching_review_with_order_code_in_my_orders: {
          type: 'checkbox',
          description: this.$t(
            'description.matching_review_with_order_code_in_my_orders'
          )
        },
        use_video_reviews: {
          type: 'checkbox',
          groupDescription: this.$t('description.use_video_reviews')
        },
        use_smartstore_sync_menu: {
          type: 'checkbox'
        },
        use_nlp_by_openai: {
          type: 'checkbox',
          fields: [
            {
              id: 'review_settings[nlp_category_ids_map]',
              validate: [
                {
                  rule: () => this.checkDuplicateNlpCategoryIds,
                  errorMessage: this.$t('duplicated_nlp_category_ids')
                }
              ]
            }
          ]
        },
        nlp_analyzable_reviews_limit: {
          type: 'hash_select_checkbox',
          options: this.NLP_ANALYZABLE_REVIEWS_LIMIT_OPTIONS,
          fields: [
            {
              id: 'review_settings[nlp_limit_within_allowed_days]',
              value: () => this.reviewSettings.nlp_limit_within_allowed_days,
              validate: ['required', 'positive_integer']
            },
            ...(this.reviewSettings.nlp_limit_including_allowed_review_count
              ? [
                  {
                    id: 'review_settings[nlp_limit_max_review_count_per_day]',
                    value: () =>
                      this.reviewSettings.nlp_limit_max_review_count_per_day,
                    validate: ['required', 'positive_integer']
                  }
                ]
              : [])
          ]
        },
        analyze_reviews_now: {
          groupDescription: this.$t('description.analyze_reviews_now')
        },
        mileage_message_encode_euc_kr: {
          type: 'checkbox',
          description: this.$t('description.mileage_message_encode_euc_kr')
        },
        auto_mileage_batch: { type: 'checkbox' },
        auto_mileage_batch_starts_after_days: {
          type: 'number',
          digits: 2,
          i18n:
            'review_settings_super_admin_manage.auto_mileage_batch_starts_after_days',
          validate: ['required']
        },
        auto_mileage_batch_interval_type: {
          type: 'select_radio',
          groupDescription: this.$t(
            'description.auto_mileage_batch_interval_type'
          ),
          options: this.AUTO_MILEAGE_BATCH_INTERVAL_TYPE_OPTIONS
        },
        auto_mileage_message: {
          type: 'text',
          hint: this.$t('description.auto_mileage_message', {
            product_name: '%{product_name}',
            product_options: '%{product_options}'
          }),
          validate: ['required', this.mileageMessageBytesValidation].filter(
            v => v
          )
        },
        enable_mileage_confirmation: { type: 'checkbox' },
        include_order_code_on_mileage_action: { type: 'checkbox' },
        skip_mileage: {
          type: 'checkbox',
          description: this.$t('description.skip_mileage')
        },
        review_sync_start_date: this.reviewSyncStartDateInputOptions,
        import_reviews: {
          groupDescription: this.$t('description.import_reviews')
        },
        sync_comments_with_reviews: {
          type: 'checkbox'
        },
        translate_matched_products: {
          type: 'checkbox',
          hint: this.$t('description.translate_matched_products')
        },
        source_brand_review_sync_with_hide: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('source_brand_review_sync_with_hide.true'),
              value: true
            },
            {
              label: this.$t('source_brand_review_sync_with_hide.false'),
              value: false
            }
          ]
        },
        max_translations_per_day: {
          type: 'number',
          digits: 5,
          hint: this.$t('description.max_translations_per_day')
        },
        option_translation_map: {
          description: this.$t('description.option_translation_map'),
          fields: _.flatten(
            this.optionTranslations.map(({ id }, i) => [
              {
                id: `${id}[original_text]`,
                value: () => this.optionTranslations[i].original_text,
                validate: [
                  'required',
                  {
                    rule: v =>
                      this.optionTranslations.filter(i => i.original_text === v)
                        .length === 1,
                    errorMessage: this.$t(
                      'option_translation_map_original_text_must_be_unique'
                    )
                  }
                ]
              },
              {
                id: `${id}[translated_text]`,
                value: () => this.optionTranslations[i].translated_text,
                validate: ['required']
              }
            ])
          )
        },
        show_recommendation_products: {
          type: 'checkbox',
          description: this.$t('description.show_recommendation_products')
        }
      };
    },
    reviewSyncEndDate() {
      if (
        !this.reviewSyncStartDate ||
        !this.reviewSettings.review_sync_transaction_id
      )
        return null;

      const selectedReviewSyncPackage = this.data.review_sync_packages.find(
        p => p.transaction_id == this.reviewSettings.review_sync_transaction_id
      ).package;
      return moment(this.reviewSyncStartDate)
        .add(selectedReviewSyncPackage.months, 'months')
        .subtract(1, 'day')
        .format('YYYY.MM.DD');
    },
    // TODO: remove again after renewed review sync has been fully implemented
    reviewSyncStartDateInputOptions() {
      if (this.adminSettings.enable_renewed_review_sync) {
        return {
          label: this.$t('renewed_review_sync_start_date'),
          groupDescription: this.$t(
            'description.renewed_review_sync_start_date'
          ),
          ...(this.reviewSyncEndDate && {
            description: this.$t('description.review_sync_end_date', [
              this.reviewSyncEndDate
            ])
          }),
          disabled: !this.reviewSettings.review_sync_transaction_id,
          required: !!this.reviewSettings.review_sync_transaction_id,
          validate: [
            ...(this.reviewSettings.review_sync_transaction_id
              ? [
                  {
                    rule: () => !!this.reviewSyncStartDate,
                    errorMessage: this.$t('validations.required')
                  }
                ]
              : []),
            {
              rule: () =>
                this.isValidDateRange(
                  this.reviewSyncStartDate,
                  this.reviewSyncEndDate,
                  this.unusableReviewSyncDateRanges
                ),
              errorMessage: this.$t('validations.invalid_date')
            }
          ]
        };
      } else {
        return {
          groupDescription: this.$t('description.review_sync_start_date')
        };
      }
    },
    unusableReviewSyncDateRanges() {
      if (!this.data) return [];

      const today = moment().startOf('day');
      return [
        ...this.data.review_syncs
          .filter(
            p => p.source_brand_id === this.reviewSettings.source_brand_id
          )
          .filter(p => moment(p.expires_at).isSameOrAfter(today))
          .map(p => ({
            startDate: moment(p.started_at),
            endDate: moment(p.expires_at)
          }))
      ];
    }
  },
  watch: {
    reviewSettings() {
      this.optionTranslations = _.map(
        this.reviewSettings.option_translation_map,
        (translated_text, original_text) => ({
          id: _.uniqueId('option_translation'),
          original_text,
          translated_text
        })
      );
    },
    optionTranslations: {
      handler() {
        this.reviewSettings.option_translation_map = _.fromPairs(
          this.optionTranslations
            .filter(t => t.original_text && t.translated_text)
            .map(t => [t.original_text, t.translated_text])
        );
      },
      deep: true
    },
    'reviewSettings.skip_mileage'(newValue, oldValue) {
      if (oldValue != true) return;
      if (newValue) return;

      this.confirmSkipMileage();
    },
    'reviewSettings.enable_push_review'(newValue, oldValue) {
      if (oldValue != false) return;
      if (!newValue) return;

      this.confirmEnablePushReview();
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('session', ['redirectToNextPage']),
    ...mapActions('toast', ['createToast']),
    analyzeReviews() {
      api.post(
        '/review/settings/analyze_reviews',
        { limit: this.analysisReviewsLimit },
        { successMessage: this.$t('analyze_reviews_job_enqueued') }
      );
    },
    importReviews() {
      const { source_brand_id, review_sync_start_date } = this.reviewSettings;
      const brand_name = this.data.brands.find(b => b.id === source_brand_id)
        .name;

      if (!confirm(this.$t('import_reviews_confirm', { brand_name }))) return;
      api
        .post(
          'review/settings/translate_from_source_brand',
          { source_brand_id, review_sync_start_date },
          { successMessage: this.$t('import_reviews_job_enqueued') }
        )
        .then(() => (this.orgFormObject.source_brand_id = source_brand_id));
    },
    addOptionTranslation() {
      this.optionTranslations.push({ id: _.uniqueId('option_translation') });
      this.$nextTick(() =>
        this.$refs[`textInput${this.optionTranslations.length - 1}`].$el.focus()
      );
    },
    loadTranslationSource() {
      this.openDialog([
        'ReviewSettingsTranslationSourceDialog',
        {
          sourceBrandId: this.reviewSettings.source_brand_id,
          values: this.optionTranslations.map(i => i.original_text)
        }
      ]).then(eventBus => {
        eventBus.$on('submit', ({ addedValues, removedValues }) => {
          if (removedValues.length) {
            this.optionTranslations = this.optionTranslations.filter(
              ({ original_text }) => !removedValues.includes(original_text)
            );
          }
          addedValues.forEach(original_text => {
            this.optionTranslations.push({
              id: _.uniqueId('option_translation'),
              original_text,
              translated_text: null
            });
          });
        });
      });
    },
    submitForm(formData) {
      if (
        !this.adminSettings.enable_renewed_review_sync &&
        this.reviewSettings.source_brand_id !== null &&
        this.reviewSettings.max_translations_per_day === null &&
        !confirm(this.$t('max_translations_per_day_alert'))
      )
        return;

      this.submit(formData);
    },
    calculateSpaceSizeForReviews() {
      this.isCalculating = true;
      api
        .get('/review/settings/pushable_reviews_space_size')
        .then(({ data }) => {
          this.space_size_in_megabyte = data.space_size_in_megabyte;
          this.isCalculating = false;
        });
    },
    updateGodoProductReviewsCount() {
      api.get('/review/settings/update_godo_product_reviews_count', {
        successMessage: this.$t(
          'update_godo_product_reviews_count.job_enqueued'
        )
      });
      this.isUpdatingGodoProductReviewsCount = true;
    },
    categoryAutoClassification() {
      api
        .post(
          '/review/settings/category_auto_classification',
          {},
          {
            successMessage: this.$t(
              'nlp_category_auto_classification_job_enqueued'
            )
          }
        )
        .then(() => {
          this.nlpCategoryAutoClassificationJobEnqueued = true;
        });
    },
    confirmSkipMileage() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'uncheck_confirm',
          title: this.$t('confirm_to_set_skip_mileage_dialog.title'),
          markdownText: this.$t(
            'confirm_to_set_skip_mileage_dialog.markdownText'
          ),
          closeButtonLabel: this.$t('confirm_to_set_skip_mileage_dialog.close'),
          cancelButtonLabel: this.$t(
            'confirm_to_set_skip_mileage_dialog.cancel'
          ),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('cancel', () => (this.reviewSettings.skip_mileage = true));
      });
    },
    confirmEnablePushReview() {
      if (
        !confirm(
          this.$t('confirm_to_enable_push_review', {
            brand_mall_id: this.currentBrand.mall_id,
            brand_name: this.currentBrand.name
          })
        )
      )
        this.$nextTick(() => (this.reviewSettings.enable_push_review = false));
    },
    changeDateRange(dateRange) {
      this.$emit('change-date-range', dateRange);
    },
    nlpCategoryIdsMapOnCheck(option) {
      const categoryId = option.value;
      const categoryIds = this.reviewSettings.nlp_category_ids_fixed;

      // toggle presence
      if (categoryIds.includes(categoryId)) {
        this.removeOptionFromNlpCategoryIdsChecked(option);
      } else {
        categoryIds.push(categoryId);
      }
    },
    removeOptionFromNlpCategoryIdsChecked(option) {
      const categoryId = option.value;
      const categoryIds = this.reviewSettings.nlp_category_ids_fixed;

      if (!categoryIds.includes(categoryId)) return;
      categoryIds.splice(categoryIds.indexOf(categoryId), 1);
    },
    resendMessages() {
      api
        .post('/review/message_batches/manual_send', {
          start_date: this.dateRange.start_date,
          end_date: this.dateRange.end_date
        })
        .then(response => {
          if (response.status === HttpStatus.NO_CONTENT) {
            alert(this.$t('resend_messages.no_messages'));
          } else {
            this.createToast(
              this.$t('resend_messages.complete', {
                messages_count: response.data.messages_count
              })
            );
            this.$nextTick(() =>
              this.redirectToNextPage('/review/messages/sms')
            );
          }
        });
    },
    formGroupChanged(group) {
      if (group.id === 'use_nlp_by_openai') this.changeNlpSettings();
    },
    changeNlpSettings() {
      this.$set(
        this.formObject,
        'use_nlp_sentiment',
        this.reviewSettings.use_nlp_by_openai
      );
      this.$set(
        this.formObject,
        'use_admin_analysis_badge',
        this.reviewSettings.use_nlp_by_openai
      );
    },
    isValidDateRange(startDate1, endDate1, invalidDateRanges) {
      return !invalidDateRanges.some(
        ({ startDate: startDate2, endDate: endDate2 }) =>
          moment(startDate1).isBetween(startDate2, endDate2, 'day', '[]') ||
          moment(endDate1).isBetween(startDate2, endDate2, 'day', '[]')
      );
    }
  }
};
</script>

<style scoped>
.ReviewSettingsSuperAdminManage__analyze-reviews-now-button {
  display: inline-block;
  margin-left: 8px;
}

.ReviewSettingsSuperAdminManage__use-nlp {
  margin-left: 12px;
}

.ReviewSettingsSuperAdminManage__category-auto-classification {
  margin: 12px 0;
}

.ReviewSettingsSuperAdminManage__enable-push-review-option {
  display: block;
  margin-left: 12px;
  margin-top: 4px;
}

.ReviewSettingsSuperAdminManage__enable_push_reviews_count-option {
  display: block;
  margin-left: 12px;
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "anlaysis_reviews_limit": "최대 {0}개",
  "anlaysis_reviews_limit_button": "리뷰 분석",
  "analyze_reviews_job_enqueued": "리뷰 분석 job이 등록되었습니다",
  "use_nlp_sentiment": "본문 긍정/부정 분석 사용",
  "use_admin_analysis_badge": "관리자 페이지 내 분석 배지 노출",
  "categories_for_nlp_api": "{0} API 적용 카테고리",
  "category_auto_classification": "카테고리 자동분류",
  "category_auto_classification_in_progress": "카테고리 자동분류 중",
  "nlp_category_auto_classification_job_enqueued": "카테고리 자동분류 job이 등록되었습니다",
  "nlp_excluding_invisible_product_review" : "리뷰 숨김 상품의 리뷰 분석 제외",
  "nlp_limit_including_allowed_days_review" : "최근 {0} 일 이내 작성한 리뷰만 분석",
  "nlp_limit_including_allowed_review_count" : "매일 최신 작성일 순으로 {0} 개의 리뷰만 분석",
  "source_brand_review_sync_with_hide": {
    "true": "숨김 상태로 가져오기",
    "false": "바로 노출하기"
  },
  "resend_messages": {
    "title": "발송하기",
    "no_messages": "발송 대상에 해당하는 주문건이 없습니다.",
    "complete": "{messages_count}건의 SMS/알림톡이 발송 되었습니다.",
    "description": "현재 메시지 재발송 기능은 리뷰작성대상설정을 고려하지 않고 있으므로 재발송한 리뷰작성 메시지를 통한 리뷰 작성이 불가할 수 있습니다. 발송 전, 작성 가능한 시점의 기간을 고려하여 날짜를 지정해 발송해주세요."
  },
  "select_brand": "쇼핑몰 선택",
  "select_review_sync_transaction": "결제건 선택",
  "renewed_review_sync_start_date": "연동할 리뷰 기간",
  "review_sync_start_date_tooltip": "리뷰를 가져올 결제건을 먼저 선택해주세요.",
  "import_reviews": "지금 가져오기",
  "import_reviews_tooltip_none_selected": "선택된 쇼핑몰이 없습니다.",
  "import_reviews_confirm": "{brand_name}의 리뷰를 가지고 옵니다. 계속하시겠습니까?",
  "import_reviews_job_enqueued": "리뷰 가져오기 job이 등록되었습니다.",
  "import_reviews": "지금 가져오기",
  "import_reviews_tooltip_none_selected": "선택된 쇼핑몰이 없습니다.",
  "import_reviews_confirm": "{brand_name}의 리뷰를 가지고 옵니다. 계속하시겠습니까?",
  "import_reviews_job_enqueued": "리뷰 가져오기 job이 등록되었습니다.",
  "option_translation_map": {
    "add_translation": "+ 수집정보 추가",
    "load_source": "국문몰에서 선택값 불러오기",
    "original_text": "국문몰 선택값 문구",
    "translated_text": "번역 문구",
    "no_data": "국문몰 선택값 문구가 없습니다"
  },
  "review_launched_at_hint": "영향 범위: 리뷰 서비스 사용 전/후 효율, 적립금 지급 대상 리뷰 선정",
  "description": {
    "review_launched_at": "이 설정을 통해 시작일을 수정하여도 실제 서비스의 이용기간에 영향을 미치치 않습니다.",
    "admin_usernames": "리뷰의 CSV를 임포팅 할 때 사용합니다.",
    "use_nlp_sentiment": "해당 설정을 끄면, 관리자 페이지 내 분석 배지가 노출되지 않습니다. (분석할 본문이 없으므로)",
    "category_auto_classification": "쇼핑몰의 카테고리를 분석하여 뷰티, 패션 중 적합한 카테고리로 분류합니다.<br />분석 중에는 버튼이 비활성화됩니다.<br />누락된 카테고리는 수동으로 추가해주세요.<br />정기적으로 동작하는 자동분류는 월 1회 매달 1일에 동작하므로 그 외에 자동분류가 필요할 경우에는 버튼을 눌러 수동 실행해주세요.",
    "category_manual_fix": "카테고리 항목을 클릭하면 주황색으로 하이라이트되며, 자동분류에 따라 변경되거나 삭제되지 않습니다.",
    "push_review_to_shop_builder": "하루 한 번, 크리마 리뷰를 호스팅사에 동기화하며 어뷰징 이슈로 인해 아래 리뷰는 동기화가 불가능합니다.<li>스탭 소셜 리뷰, 상품간 연동/복사한 리뷰, 외부채널에서 연동된 리뷰(리글, 스마트스토어 리뷰 등) 리뷰</li>",
    "push_reviews_count_to_shop_builder": "크리마 리뷰수를 호스팅사에 동기화하여 리뷰수를 연동하며 어뷰징 이슈로 인해 아래 리뷰는 리뷰수에 포함되지 않습니다.<li>스탭 소셜 리뷰, 상품간 연동/복사한 리뷰, 외부채널에서 연동된 리뷰(리글, 스마트스토어 리뷰 등) 리뷰</li>",
    "file_attach_not_supported_powerapps_android_versions": "파워앱에서 파일첨부가 지원되지 않는 안드로이드 버전을 입력해주세요.",
    "detail_popup_only_ios_versions": "IOS 웹뷰에서 동영상 재생이 지원되지 않는 IOS 버전을 입력해주세요.",
    "disable_replace_state_in_mobile_app": "replaceState를 사용하여 문제가 생기는 경우, 체크해주세요. (예: 시크폭스)",
    "enable_review_allowed_author_type_delivery_finished": "이 설정을 켤 경우 고객사에서 '구매 시점'뿐 아닌 '배송 완료 시점'도 선택할 수 있게 됩니다.",
    "hide_review_messages_menu": "메시지 관리 메뉴 전체를 숨깁니다.",
    "pushable_reviews_space_size": "{size}MB의 데이터가 있습니다.",
    "hide_review_csv_button": "리뷰 목록 상단의 csv 파일 다운로드 버튼을 숨깁니다.",
    "use_toggle_byapps_bottom_menu": "바이앱스 앱 내에서 포토리뷰 팝업이 열리고 닫힐 때, 하단 메뉴가 토글 됩니다.",
    "matching_review_with_order_code_in_my_orders": "리뷰 작성버튼을 통해 리뷰 작성 팝업을 띄울 때 주문건을 제대로 파악하기 위한 추가 활용방안입니다.",
    "analyze_reviews_now": "테스트 서버에서는 AI 리뷰 분석이 자동 실행되지 않으므로, AI 리뷰 분석 및 관련 설정 정상동작 확인을 위한 수동 실행 기능입니다.<br />AI 리뷰 분석 실행은 전날 생성된 리뷰까지만 집계됩니다.",
    "mileage_message_encode_euc_kr": "적립금 메시지에 한글이 깨질 경우, 체크해주세요.",
    "auto_mileage_batch_interval_type": "새벽 2시를 기준으로, 선택한 시간마다 미지급 적립금에 대해 자동 지급합니다.",
    "auto_mileage_message": "{product_name}은 상품명, {product_options}는 옵션명으로 대체됩니다.",
    "resend_date_range": "1년 전부터 오늘까지의 기간 내에서만 설정할 수 있습니다.",
    "source_brand_id": "선택한 쇼핑몰의 리뷰를 가져옵니다.",
    "review_sync_start_date": "선택한 날짜부터 리뷰를 가지고 옵니다.",
    "renewed_review_sync_start_date": "선택한 쇼핑몰에서 선택된 기간 사이에 작성된 모든 리뷰를 가지고 옵니다. (연동리뷰 제외)<br/>새로운 매칭표를 등록한 경우, 이미 가져온 리뷰는 중복 로직으로 걸러내고 연동하지 않은 리뷰만 가지고 옵니다.",
    "review_sync_end_date": "만료일 {0}",
    "import_reviews": "선택한 쇼핑몰에서 시작일 기준으로 모든 리뷰를 가지고 옵니다. (연동리뷰 제외)<br />새로운 매칭표를 등록한 경우, 이미 가져온 리뷰는 중복 로직으로 걸러내고 연동하지 않은 리뷰만 가지고 옵니다.",
    "skip_mileage": "해당 설정이 꺼진 상태에서 예상 적립금 지급이 될 경우, 실제로 적립금 지급이 될 수 있습니다.",
    "translate_matched_products": "리뷰를 가져올 쇼핑몰과 상품 번호가 다른 경우 설정해 주세요. 원본 쇼핑몰의 상품 번호를 등록한 상품의 리뷰만 가져옵니다.",
    "max_translations_per_day": "입력하지 않으면, 모든 리뷰를 가져옵니다.",
    "option_translation_map": "해외몰의 경우, 국문몰의 신규 리뷰 데이터가 매일 추가되며 한글로 작성한 추가 수집정보의 선택값을 해외몰의 언어로 자동 변환하기 위해 번역 문구를 미리 설정해야 합니다.",
    "use_video_reviews": "작성 허용 시 작성팝업, 관리자 페이지를 통해 직접 동영상을 추가할 수 있습니다. 작성 허용과 상관없이 리뷰 연동 등을 통한 외부에서 추가되는 동영상은 표시될 수 있습니다.",
    "show_recommendation_products": "작성유도메시지(SMS,알림톡)을 통해 접근한 리뷰 작성 페이지 내 추천상품 메뉴와, 모든 리뷰 작성 후 노출하는 추천 상품을 표시합니다.<br/><br/>&lt;추천 상품 로직&gt;<br/>추천 상품페이지<br/><li>추천제외 상품/카테고리를 제외한 최신상품</li>리뷰 작성 완료 후 상품 추천페이지<li>작성한 리뷰 상품과 함께 구매되는 상품이 있다면 노출, 없다면 추천제외 상품/카테고리를 제외한 최신 상품</li>"
  },
  "validations": {
    "invalid_date": "이미 입력된 날짜입니다."
  },
  "max_translations_per_day_alert": "모든 리뷰를 가져옵니다. 진행하시겠습니까?",
  "admin_usernames_placeholder": "이름을 입력해주세요.",
  "confirm_to_enable_push_review": "관리자가 작성했던 리뷰 댓글은 '{brand_mall_id}({brand_name})' 로 옮겨집니다. 계속 하시겠습니까?",
  "file_attach_not_supported_powerapps_android_versions_placeholder": "버전을 입력해주세요.",
  "detail_popup_only_ios_versions_placeholder": "버전을 입력해주세요.",
  "duplicated_nlp_category_ids": "카테고리당 하나의 API만 적용할 수 있습니다.",
  "source_brand_id_placeholder": "쇼핑몰을 선택해주세요.",
  "option_translation_map_original_text_placeholder": "번역 대상 선택값을 입력해주세요.",
  "option_translation_map_original_text_must_be_unique": "같은 번역 대상 선택값이 있습니다.",
  "option_translation_map_translated_text_placeholder": "번역 결과 문구를 입력해주세요.",
  "enable_push_review": "크리마를 통해 작성한 리뷰",
  "enable_push_nonmember_review": "비회원 작성리뷰",
  "enable_push_manager_review": "스탭 일반 리뷰",
  "enable_push_reviews_count": "크리마를 통해 작성한 리뷰",
  "enable_push_nonmember_reviews_count": "비회원 작성리뷰",
  "enable_push_manager_reviews_count": "스탭 일반 리뷰",

  "pushable_reviews_space_size": {
    "ON": "용량 확인하기",
    "OFF": "용량 확인 중"
  },
  "update_godo_product_reviews_count": {
    "update": "호출하기",
    "job_enqueued": "전체 리뷰수 호출을 시작합니다.",
    "updating": "전체 리뷰수 호출이 진행중입니다.",
    "last_updated_at": "{0} 전체 리뷰수를 마지막으로 호출하였습니다."
  },
  "confirm_to_set_skip_mileage_dialog": {
    "title": "설정을 끄시겠습니까?",
    "markdownText": "해당 설정이 꺼진 상태에서 예상 적립금 지급이 될 경우<br />실제로 적립금이 지급될 수 있습니다.",
    "close": "끄기",
    "cancel": "취소"
  }
}
</i18n>
