<template>
  <AppIntroContent
    document-url="https://assets.cre.ma/m/fit/crema_fit_introduction.pdf"
    :image-urls="['https://assets.cre.ma/m/admin/crema-fit-intro-v8-01.png']"
    :image-width="600"
  />
</template>

<script>
export default {
  name: 'FitIntro'
};
</script>
