<template>
  <div
    :class="[
      'TheMain super-admin',
      {
        'TheMain--sub-menu-visible-desktop':
          isSubMenuVisibleDesktop && selectedSubMenuItemId,
        'TheMain--sub-menu-visible-tablet':
          isSubMenuVisibleTablet && selectedSubMenuItemId,
        'super-admin--hide': !enableSuperAdminMode,
        'super-admin--highlight': enableSuperAdminHighlight,
        'TheMain--with-main-alert': !!mainAlert
      }
    ]"
  >
    <TheMainAlert />
    <div class="TheMain__main-menu">
      <TheMainMenu />
    </div>
    <div
      v-if="selectedSubMenuItemId"
      v-skip-transition-on-window-resize
      class="TheMain__sub-menu"
    >
      <div class="TheMain__sub-menu-head">
        <button
          type="button"
          class="TheMain__close-sub-menu"
          @click="toggleSubMenu"
        >
          <AppSvgIcon name="icon-close" />
        </button>
      </div>
      <TheSubMenu @click="clickSubMenu" />
    </div>
    <div v-skip-transition-on-window-resize class="TheMain__main">
      <div class="TheMain__main-head">
        <TheMainTopbar @toggle-sub-menu="toggleSubMenu" />
        <TheContentAlerts />
      </div>
      <div id="main-body" ref="body" class="TheMain__main-body">
        <div class="TheMain__main-body-inner">
          <TheContent />
        </div>
        <TheFooter class="TheMain__main-footer" />
      </div>
      <TheHelp v-if="isTheHelpVisible" />
    </div>
    <div class="TheMain__jobs">
      <TheAsyncJobs />
      <TheMultiAsyncJobs />
    </div>
    <TheDialogs />
    <TheDrawers />
    <AppScriptLoader :type="selectedMainMenuItemId" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import qs from 'qs';

import Focusable from '@/mixins/Focusable';

import BrandInsightStatus from '@/enums/BrandInsightStatus';

import TheMainAlert from './TheMainAlert';
import TheMainMenu from './TheMainMenu';
import TheSubMenu from './TheSubMenu';
import TheMainTopbar from './TheMainTopbar';
import TheContentAlerts from './TheContentAlerts';
import TheContent from './TheContent';
import TheFooter from './TheFooter';
import TheHelp from './TheHelp';
import TheAsyncJobs from './TheAsyncJobs';
import TheMultiAsyncJobs from './TheMultiAsyncJobs';
import TheDialogs from './TheDialogs';
import TheDrawers from './TheDrawers';

import skipTransitionOnWindowResize from '@/directives/skipTransitionOnWindowResize';

export default {
  name: 'TheMain',
  components: {
    TheMainAlert,
    TheMainMenu,
    TheSubMenu,
    TheMainTopbar,
    TheContentAlerts,
    TheContent,
    TheFooter,
    TheHelp,
    TheAsyncJobs,
    TheMultiAsyncJobs,
    TheDialogs,
    TheDrawers
  },
  directives: { skipTransitionOnWindowResize },
  mixins: [Focusable],
  data() {
    return { isSubMenuVisibleTablet: false };
  },
  computed: {
    ...mapState(['isContentReady']),
    ...mapState('session', ['currentBrand']),
    ...mapState('editor', [
      'enableSuperAdminMode',
      'enableSuperAdminHighlight',
      'isSubMenuVisibleDesktop',
      'savedScrollPosition'
    ]),
    ...mapGetters('menu', [
      'selectedMainMenuItemId',
      'selectedSubMenuItemId',
      'subMenuItems'
    ]),
    ...mapGetters('alert', ['mainAlert']),
    isTheHelpVisible() {
      return (
        ['review', 'fit', 'target', 'display'].includes(
          this.selectedMainMenuItemId
        ) ||
        ('analytics' === this.selectedMainMenuItemId &&
          BrandInsightStatus.isMenuAccessible(this.currentBrand.insight_status))
      );
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) this.$refs.body.scrollTo(0, 0);
      this.$store.state.isContentReady = false;
    },
    isContentReady(value) {
      if (!value) return;

      if (this.isScrollPositionSaved()) {
        this.scrollToSavedPosition();
      } else if (this.$route.hash && this.$route.hash.includes('elementId')) {
        const elementId = qs.parse(this.$route.hash).elementId;
        if (!elementId) return;

        const element = document.getElementById(elementId);
        if (element) this.focusOnElement(element);
      } else if (this.$route.hash && !this.$route.hash.includes('-dialog')) {
        this.setAdvancedSettingVisible(this.$route.hash.replace('#', ''));
        this.$nextTick(() => {
          const element = document.querySelector(this.$route.hash);
          if (element) this.focusOnElement(element);
        });
      }
    }
  },
  created() {
    window.addEventListener('beforeunload', this.saveScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.saveScrollPosition);
  },
  mounted() {
    this.openDialogFromHash();
  },
  methods: {
    ...mapActions('editor', ['setSubMenuVisibleDesktop']),
    ...mapActions('dialog', ['openDialogFromHash']),
    ...mapActions('form', ['setAdvancedSettingVisible']),
    isDesktop() {
      return window.innerWidth >= 1024;
    },
    toggleSubMenu() {
      if (this.isDesktop()) {
        this.setSubMenuVisibleDesktop(!this.isSubMenuVisibleDesktop);
      } else {
        this.isSubMenuVisibleTablet = !this.isSubMenuVisibleTablet;
      }
    },
    clickSubMenu(menuItem) {
      if (!this.isDesktop()) {
        const isLeafNode = this.subMenuItems.every(
          e => e.parentId !== menuItem.id
        );
        if (isLeafNode) this.isSubMenuVisibleTablet = false;
      }
    },
    saveScrollPosition() {
      const { scrollTop, scrollLeft } = this.$refs.body;
      this.$store.dispatch('editor/saveScrollPosition', {
        scrollTop,
        scrollLeft
      });
    },
    scrollToSavedPosition() {
      const { scrollTop, scrollLeft } = this.savedScrollPosition;
      this.scrollToPosition(this.$refs.body, { scrollTop, scrollLeft });
      this.$store.dispatch('editor/resetScrollPosition');
    },
    isScrollPositionSaved() {
      return (
        this.savedScrollPosition?.savedTime + 10000 > Date.now() &&
        this.savedScrollPosition.url === location.href
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_layouts.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_buttons.scss';
@import '@/scss/mixins/_breakpoints.scss';

$main-menu-width: 64px;
$sub-menu-width: 202px;
$border-width: 1px;

$footer-margin-top: 40px;
$footer-height: 98px;

.TheMain__main-menu {
  position: fixed;
  z-index: $z-index-fixed;
  width: $main-menu-width;
  height: 100vh;
  background-color: $color-background;

  .TheMain--with-main-alert & {
    height: calc(100vh - #{$main-alert-height});
  }

  @include media-breakpoint-each(tablet, mobile) {
    bottom: 0;
    width: 100%;
    border-top: $border-width solid $color-grey-25;

    &,
    .TheMain--with-main-alert & {
      height: $main-menu-height-tablet;
    }
  }
}

.TheMain__sub-menu {
  position: absolute;
  left: $main-menu-width;
  width: $sub-menu-width;
  height: 100vh;
  border-left: $border-width solid $color-grey-25;
  background-color: #fff;

  @include media-breakpoint-each(desktop) {
    @include transition-slow(left);
  }

  .TheMain--with-main-alert & {
    height: calc(100vh - #{$main-alert-height});
  }

  @include media-breakpoint-each(tablet, mobile) {
    @include transition-slow(opacity);

    top: 0;
    left: 0;
    bottom: $main-menu-height-tablet;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: $z-index-modal;
    border-left: none;
    opacity: 0;
    pointer-events: none;

    &,
    .TheMain--with-main-alert & {
      height: auto;
    }

    .TheMain--sub-menu-visible-tablet & {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.TheMain__sub-menu-head {
  position: sticky;
  top: 0;
  height: $topbar-height;
  background-color: $color-background;
  z-index: $z-index-sticky;
  @include media-breakpoint-each(desktop) {
    display: none;
  }
}

.TheMain__close-sub-menu {
  margin: 2px 12px;
  @include svg-button-container;
}

.TheMain__main {
  position: absolute;
  left: $main-menu-width;
  right: 0;
  height: 100vh;
  display: flex;
  flex-flow: column;
  border-left: $border-width solid $color-grey-25;
  background-color: #fff;
  @include transition-slow(left);

  .TheMain--with-main-alert & {
    height: calc(100vh - #{$main-alert-height});
  }

  @include media-breakpoint-each(desktop) {
    .TheMain--sub-menu-visible-desktop & {
      left: $main-menu-width + $sub-menu-width + $border-width;
    }
  }

  @include media-breakpoint-each(tablet, mobile) {
    left: 0;
    border-left: 0;
    height: calc(100vh - #{$main-menu-height-tablet});

    .TheMain--with-main-alert & {
      height: calc(100vh - #{$main-alert-height + $main-menu-height-tablet});
    }

    .TheMain--sub-menu-visible-tablet & {
      left: 0;
    }
  }
}

.TheMain__main-head {
  flex: 0 0 auto;
  @include main-padding-horizontal;
}

.TheMain__breadcrumb {
  position: relative;
  left: 32px;

  @include media-breakpoint-each(tablet, mobile) {
    display: none;
  }
}
.TheMain__main-body {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.TheMain__main-body-inner {
  padding: 20px 0;
  @include main-padding-horizontal;
  min-height: calc(100% - #{$footer-height + $footer-margin-top});
}

.TheMain__main-footer {
  margin-top: $footer-margin-top;
  height: $footer-height;
  padding: 40px 0;
  @include main-padding-horizontal;
}

.TheMain__jobs {
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: $z-index-async-jobs;
  pointer-events: none;
}
</style>
