<template>
  <AppSearchbar @submit="$emit('submit')">
    <template #right>
      <li>
        <AppSearchWithType
          v-model="searchWithType"
          :search-types="searchTypes"
        />
      </li>
      <li><AppDateRangePicker v-model="dateRange" :min-date="minDate" /></li>
    </template>
  </AppSearchbar>
</template>

<script>
import moment from 'moment';
export default {
  name: 'ReviewMessagesSearchbar',
  props: {
    searchTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    const minDate = moment()
      .subtract(364, 'days')
      .toVal();

    return {
      dateRange: {
        start_date: minDate,
        end_date: moment().toVal()
      },
      searchWithType: {
        search_type: this.searchTypes[0].value,
        search_query: ''
      },
      minDate: minDate
    };
  },
  watch: {
    dateRange: {
      handler(dateRange) {
        this.$emit('change-date', dateRange);
      },
      deep: true
    },
    searchWithType: {
      handler(searchWithType) {
        this.$emit('change-search-data', searchWithType);
      },
      deep: true
    }
  }
};
</script>
