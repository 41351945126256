<template>
  <AppModalFormLivePreview
    class="ChatbotMessageTemplatesFormDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="formProps"
    width="530px"
    v-bind="{ isLoading }"
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview :template="{ buttons: [] }" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'template_id'">
        <AppContainer
          class="AppForm__group-field ChatbotMessageTemplatesFormDialog__template-description"
        >
          {{ $t('biz_messages.template_description') }}
          <AppButton
            to="https://help-review.cre.ma/hc/ko/articles/9872095799961--%EB%A6%AC%EB%B7%B0-%EB%A6%AC%EB%B7%B0-%EC%9E%91%EC%84%B1-%EC%9C%A0%EB%8F%84-%EB%A9%94%EC%8B%9C%EC%A7%80-%EB%AC%B8%EC%9E%90-%EB%A5%BC-%EC%95%8C%EB%A6%BC%ED%86%A1%EC%9C%BC%EB%A1%9C-%EB%B0%9C%EC%86%A1%ED%95%A0-%EC%88%98-%EC%9E%88%EB%82%98%EC%9A%94"
            type="external_link"
            button-style="underline"
            >{{ $t('biz_messages.template_description_link') }}</AppButton
          >
        </AppContainer>
        <AppButton
          class="AppForm__group-field"
          :label="$t('biz_messages.add_template')"
          @click="clickNewTemplate"
        />
        <ChatbotMessageTemplateSelectTable
          class="AppForm__group-field"
          :templates="templates"
        />
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplatePreview from '../../biz_messages/dialogs/BizMessageTemplatePreview.vue';

export default {
  name: 'ChatbotMessageTemplatesFormDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView],
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapState('chatbotMessageTemplate', ['templates']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('biz_messages.template_tip_message')
            },
            {
              id: 'template_id',
              label: this.$t('biz_messages.template_id'),
              type: 'custom',
              validate: ['required']
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.isLoading = true;
    this.fetchTemplates().finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('chatbotMessageTemplate', ['fetchTemplates']),
    clickNewTemplate() {
      this.openDialog('ChatbotMessageTemplateFormDialog');
    }
  }
};
</script>

<style scoped>
.ChatbotMessageTemplatesFormDialog__template-description {
  white-space: pre-line;
}
</style>

<i18n locale="ko">
{
  "title": "첫 메시지 설정",
  "sub_title": "챗봇"
}
</i18n>
