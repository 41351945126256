<template>
  <AppModalDefault
    :title="title"
    :sub-title="subTitle"
    is-max-height
    @close="close"
  >
    <template #body>
      <AppSearchbar v-if="searchTypes.length" @submit="searchResource">
        <template #[searchTypesAlign]>
          <li>
            <AppSearchWithType
              v-model="resourceParams"
              autofocus
              :search-types="searchTypes"
            />
          </li>
          <li>
            <AppButtonToggle
              v-if="advancedSearchSections.length"
              v-model="isAdvancedSearchVisible"
              :label="$t('app.advanced_search')"
            />
            <AppButton v-else :label="$t('app.search')" type="submit" />
          </li>
        </template>
        <template v-if="advancedSearchSections.length" #advanced-search>
          <AppAdvancedSearch
            v-model="resourceParams"
            :visible="isAdvancedSearchVisible"
            :sections="advancedSearchSections"
            @submit="searchResource"
          />
        </template>
      </AppSearchbar>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          :resources="resources"
          :columns="[
            ...columns,
            multiple
              ? { id: 'row_select', type: 'row_select' }
              : { id: 'select', label: $t('app.select') }
          ]"
          :rows="rows"
          :enable-total-count="false"
          :enable-per="false"
          :selected-row-ids="currentItems.map(i => i.id)"
          @paginate="paginate"
          @select-rows="selectItems"
        >
          <template #cell="{ row, column }">
            <AppRadio
              v-if="column === 'select'"
              :selected-value="currentItems.length ? currentItems[0].id : null"
              :value="row.id"
              input-style="standalone"
              @change="currentItems = [row]"
            />
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppButton
        :label="selectButtonLabel || title"
        button-style="blue"
        @click="select"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

const REQUEST_ID = 'PRODUCT_SELECT_DIALOG_FETCH_PRODUCTS';

export default {
  name: 'AppSelectSearchableDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, default: null },
    searchTypes: { type: Array, required: true },
    searchTypesAlign: {
      type: String,
      default: 'default',
      validator(val) {
        return ['default', 'right'].includes(val);
      }
    },
    advancedSearchSections: { type: Array, default: () => [] },
    multiple: { type: Boolean, required: true },
    items: { type: Array, required: true },
    columns: { type: Array, required: true },
    selectButtonLabel: { type: String, default: null },
    sort: { type: Boolean, default: true },
    socialMediaOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: true,
      isAdvancedSearchVisible: false,
      currentItems: _.cloneDeep(this.items),
      resources: nullResources
    };
  },
  computed: {
    ...mapGetters('request', ['isRequestPending']),
    defaultResourceParams() {
      return {
        page: '1',
        per: '20',
        ...(this.searchTypes.length
          ? { search_type: this.searchTypes[0].value }
          : {}),
        social_media: this.socialMediaOnly ? '1' : null
      };
    },
    rows() {
      return this.resources.items;
    }
  },
  beforeDestroy() {
    api.cancel(REQUEST_ID);
  },
  methods: {
    select() {
      this.eventBus.$emit('select', this.currentItems);
      this.close();
    },
    selectItems(selectedRows) {
      const unselectedItems = _.chain(this.rows)
        .differenceBy(selectedRows, 'id')
        .value();
      const newlySelectedItems = _.chain(selectedRows)
        .differenceBy(this.currentItems, 'id')
        .value();
      const items = _.chain(this.currentItems)
        .differenceBy(unselectedItems, 'id')
        .concat(newlySelectedItems);
      this.currentItems = (this.sort ? items.sortBy('id') : items).value();
    },
    fetchResource(params) {
      this.isLoading = true;
      this.eventBus.$emit('search', {
        params,
        onSuccess: resources => {
          this.resources = resources;
          this.isLoading = false;
        },
        onFailure: () => (this.isLoading = false)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppReviewMediaCell {
    margin-top: 0;
    text-align: left;
  }

  .AppReviewMediaCell__image {
    line-height: 24px;
    width: 24px;
    height: 24px;
  }
}
</style>
