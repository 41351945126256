<template>
  <div>
    <SummaryBoxEasyJoinWithBrand
      v-if="shouldDisplaySummaryBoxEasyJoinWithBrand"
      class="ReviewIntro__summary-box"
    />
    <AppIntroContent
      :document-url="reviewIntroLink"
      :image-urls="reviewIntroUrls"
      :image-width="600"
    />
  </div>
</template>

<style lang="scss">
.ReviewIntro__summary-box {
  margin-bottom: 28px;
}
</style>

<script>
import SummaryBoxEasyJoinWithBrand from './components/SummaryBoxEasyJoinWithBrand';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ReviewIntro',
  components: { SummaryBoxEasyJoinWithBrand },
  computed: {
    ...mapState('session', ['currentBrand', 'adminSettings']),
    ...mapGetters('session', ['isShopifyBrand']),
    reviewIntroLink() {
      return this.isShopifyBrand
        ? 'https://cremafactory.notion.site/SERVICE-INTRODUCTION-132504922fd8456bb4507d9fb2d8ce2e'
        : 'https://sclu.io/share/bulk/file/redirect?hashId=ff0694dbd73c19a0&historyId=fhsoJRjtE';
    },
    reviewIntroUrls() {
      return this.isShopifyBrand
        ? ['https://assets.cre.ma/m/admin/crema-review-intro-v4-shopify-01.png']
        : [
            'https://assets.cre.ma/m/admin/crema-review-intro-v4-01.png',
            'https://assets.cre.ma/m/admin/crema-review-intro-v4-02.png',
            'https://assets.cre.ma/m/admin/crema-review-intro-v4-03.png',
            'https://assets.cre.ma/m/admin/crema-review-intro-v4-04.png',
            'https://assets.cre.ma/m/admin/crema-review-intro-v4-05.png'
          ];
    },
    shouldDisplaySummaryBoxEasyJoinWithBrand() {
      const reviewStatus = this.currentBrand.review_status;

      const reviewStatusCondition =
        reviewStatus === BrandReviewStatus.NONE ||
        reviewStatus === BrandReviewStatus.REQUESTED ||
        reviewStatus === BrandReviewStatus.TERMINATED;

      return reviewStatusCondition && this.enableEasyJoinIdleBrand;
    },
    enableEasyJoinIdleBrand() {
      const {
        enable_easy_join_for_idle_cafe24,
        enable_easy_join_for_idle_shopby
      } = this.adminSettings;

      switch (this.currentBrand.shop_builder) {
        case 'cafe24':
          return enable_easy_join_for_idle_cafe24;
        case 'shopby':
          return enable_easy_join_for_idle_shopby;
        default:
          return false;
      }
    }
  }
};
</script>
