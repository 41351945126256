<template>
  <AppDropdownMenuButton v-bind="{ label, buttonStyle, menuItems }" />
</template>

<script>
import api from '@/lib/api';
import ReviewCampaignStatus from '@/enums/ReviewCampaignStatus';

export default {
  name: 'ReviewMessagesCampaignsManageButton',
  props: {
    campaign: { type: Object, required: true },
    showStatusLabel: { type: Boolean, default: false }
  },
  computed: {
    label() {
      if (this.showStatusLabel)
        return ReviewCampaignStatus.t(this.campaign.status);
      return this.$t('app.manage');
    },
    buttonStyle() {
      if (!this.showStatusLabel) return;
      return this.campaign.status == ReviewCampaignStatus.ENABLED
        ? 'mint-green'
        : 'red-outline';
    },
    menuItems() {
      return [ReviewCampaignStatus.ENABLED, ReviewCampaignStatus.DISABLED].map(
        status => {
          const selected = this.campaign.status === status;
          return {
            label: ReviewCampaignStatus.t(status),
            selected,
            clickHandler: ({ close }) => {
              this.updateCampaignStatus(status);
              close();
            }
          };
        }
      );
    }
  },
  methods: {
    updateCampaignStatus(status) {
      if (status === this.campaign.status) return;

      api
        .patch(`/review/campaigns/${this.campaign.id}/status`, {
          campaign: { status }
        })
        .then(() => this.$emit('change-status', status));
    }
  }
};
</script>
