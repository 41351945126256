<template>
  <AppAjaxContent :is-loading="isLoading">
    <CustomContentHeader
      :resource="campaign"
      resource-type="review_campaign"
      :is-name-editable="false"
    >
      <template #actions>
        <ReviewMessagesCampaignsManageButton
          :campaign="campaign"
          show-status-label
          @change-status="changeStatus"
        />
      </template>
    </CustomContentHeader>
    <AppInfoBox class="ReviewMessagesCampaignShow__info-box">
      {{ $t('info_box') }}
    </AppInfoBox>
    <ReviewMessagesCampaignReport />
    <AppButton
      :label="$t('button.add_campaign_message')"
      button-style="red"
      @click="openCreateCampaignMessageDialog"
    />
    <ReviewMessagesCampaignMessagesTable
      :campaign-id="campaignId"
      :campaign-type="campaign.campaign_type"
      :event-bus="tableEventBus"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import Vue from 'vue';
import { mapActions } from 'vuex';
import ReviewCampaignType from '@/enums/ReviewCampaignType';

export default {
  name: 'ReviewMessagesCampaignShow',
  data: () => ({
    isLoading: true,
    campaign: {},
    tableEventBus: new Vue()
  }),
  computed: {
    campaignId() {
      return Number(this.$route.params.id);
    }
  },
  mounted() {
    api
      .get(`/review/campaigns/${this.campaignId}`)
      .then(({ data }) => {
        this.campaign = {
          ...data.campaign,
          name: ReviewCampaignType.t(data.campaign.campaign_type)
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    changeStatus(status) {
      this.campaign = { ...this.campaign, status };
    },
    openCreateCampaignMessageDialog() {
      this.openDialog([
        'ReviewMessagesMessageFormDialog',
        { campaignType: this.campaign.campaign_type }
      ]).then(eventBus => {
        eventBus.$on('submit', () => this.tableEventBus.$emit('refresh'));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesCampaignShow__info-box {
  @include text-label;

  margin: 20px 0;
  padding: 20px 0;
}
</style>

<i18n locale="ko">
{
  "info_box": "💡 리뷰를 작성하지 않은 쇼핑몰 회원에게 반복적으로 메시지를 보내 리뷰 작성을 유도할 수 있습니다.",
  "button": {
    "add_campaign_message": "발송 메시지 추가"
  }
}
</i18n>
