<template>
  <div
    :class="[
      'AppKeywordTextarea',
      { 'AppKeywordTextarea--multi-line': !singleLine }
    ]"
  >
    <span
      v-if="!disabled"
      class="AppKeywordTextarea__show-keywords-dialog"
      @click="isShowingKeywordsDialog = !isShowingKeywordsDialog"
      >{{ $t('show_available_keywords') }}</span
    >
    <AppModeless
      v-if="isShowingKeywordsDialog"
      align="right"
      @close="closeKeywordsDialog"
    >
      <AppDropdownMenu :menu-items="menuItems" @close="closeKeywordsDialog" />
    </AppModeless>
    <div
      :class="[
        'AppKeywordTextarea__input',
        { 'AppKeywordTextarea__input--disabled': disabled },
        { 'AppKeywordTextarea__input--invalid': invalid }
      ]"
    >
      <AppCodeMirror
        ref="editor"
        v-bind="{ id, name, value, disabled, options }"
        @ready="codeMirror = $event"
        @change="change"
        @before-change="beforeChange"
      />
      <div v-if="placeholder && !value" class="AppKeywordTextarea__placeholder">
        {{ placeholder }}
      </div>
    </div>
    <div v-if="maxBytes" class="AppKeywordTextarea__bytes">
      {{ `${byteSize}/${maxBytes} bytes` }}
    </div>
    <div v-else-if="maxlength" class="AppKeywordTextarea__length">
      {{ `${length}/${maxlength}` }}
    </div>
    <AppTooltip v-show="isShowingTooltip" ref="tooltip" v-bind="tooltipProps" />
  </div>
</template>

<script>
import _ from 'lodash';
import 'codemirror/addon/hint/show-hint.js';
import getByteSize from '@/lib/vendor/getByteSize';
import interpolate from '@/lib/interpolate';
import TextInput from '@/mixins/TextInput';
import CodeMirror from 'codemirror';

CodeMirror.defineMode('keyword', ({ keywords }) => ({
  token: stream => {
    const hit = stream.match(/%{(.*?)}/);
    if (hit)
      return keywords().some(({ key }) => key === hit[1])
        ? 'keyword'
        : 'invalid';
    else {
      if (stream.pos < stream.string.length) {
        const code = stream.string.charCodeAt(stream.pos);
        const isEmoji = 0xd800 <= code && code <= 0xdbff;
        stream.pos += isEmoji ? 2 : 1;
      }
      return null;
    }
  }
}));

const getMouseOverKeywordElement = (editor, e) => {
  if (!editor.contains(e.target)) return null;

  const keywordElements = editor.querySelectorAll('.cm-keyword, .cm-invalid');
  const { clientX, clientY } = e;

  return _.find(keywordElements, el => {
    const rect = el.getBoundingClientRect();
    return (
      rect.left <= clientX &&
      clientX <= rect.right &&
      rect.top <= clientY &&
      clientY <= rect.bottom
    );
  });
};

export default {
  name: 'AppKeywordTextarea',
  mixins: [TextInput],
  props: {
    keywords: { type: Array, required: true },
    maxBytes: { type: Number, default: 0 },
    singleLine: { type: Boolean, default: false }
  },
  data() {
    return {
      isShowingTooltip: false,
      isShowingKeywordsDialog: false,
      tooltipProps: { message: '', left: '0', top: '0' },
      codeMirror: null
    };
  },
  computed: {
    interpolation() {
      return this.keywords.reduce((m, k) => ({ ...m, [k.key]: k.value }), {});
    },
    keywordsMap() {
      return _.fromPairs(this.keywords.map(k => [k.key, k.desc]));
    },
    options() {
      return {
        mode: 'keyword',
        theme: 'keyword',
        flattenSpans: false,
        keywords: () => this.keywords,
        ...(this.singleLine
          ? { lineWrapping: false, scrollbarStyle: null }
          : { lineWrapping: true }),
        extraKeys: {
          "'{'": function(cm) {
            const startCursor = cm.getCursor();
            const startIdx = startCursor.ch;
            const prevChar = cm.getLine(startCursor.line)[startIdx - 1];
            if (prevChar === '%')
              setTimeout(() =>
                cm.showHint({
                  completeSingle: false,
                  hint: cm => {
                    const cur = cm.getCursor();
                    const word = cm
                      .getLine(cur.line)
                      .slice(startIdx + 1, cur.ch);
                    return {
                      list: cm.options.keywords
                        .filter(k => k.key.startsWith(word))
                        .map(k => ({
                          text: `%{${k.key}}`,
                          displayText: `%{${k.key}}: ${k.desc}`
                        })),
                      from: CodeMirror.Pos(cur.line, startIdx - 1),
                      to: cur
                    };
                  }
                })
              );
            return CodeMirror.Pass;
          }
        }
      };
    },
    menuItems() {
      return this.keywords.map(item => ({
        label: `%{${item.key}}: ${item.desc}`,
        tooltip: item.tooltip ? item.tooltip : this.$t('click_to_insert'),
        disabled: item.disabled ? item.disabled : false,
        clickHandler: ({ close }) => {
          this.codeMirror.replaceSelection(`%{${item.key}}`);
          close();
        }
      }));
    },
    byteSize() {
      const interpolatedValue = interpolate(this.value, this.interpolation);
      return getByteSize(interpolatedValue).toLocaleString();
    },
    length() {
      return this.value?.length.toLocaleString() || 0;
    }
  },
  mounted() {
    this.$el.addEventListener('mousemove', this.toggleTooltip);
    this.$el.addEventListener('mouseleave', this.toggleTooltip);
  },
  beforeDestroy() {
    this.$el.removeEventListener('mousemove', this.toggleTooltip);
    this.$el.removeEventListener('mouseleave', this.toggleTooltip);
  },
  methods: {
    toggleTooltip(e) {
      if (this.disabled) return;

      const editor = this.$refs.editor.$el;
      const keywordElement = getMouseOverKeywordElement(editor, e);
      if (!keywordElement) {
        this.isShowingTooltip = false;
        return;
      }

      this.isShowingTooltip = true;

      this.tooltipProps.message =
        this.keywordsMap[keywordElement.innerText.slice(2, -1)] ||
        this.$t('not_supported');

      const parentRect = this.$el.getBoundingClientRect();
      const TOOLTIP_OFFSET_Y = 12;
      const left = e.clientX - parentRect.x;
      const top = e.clientY - parentRect.y - TOOLTIP_OFFSET_Y;

      this.tooltipProps.left = `${left}px`;
      this.tooltipProps.top = `${top}px`;

      this.$nextTick(() => {
        const tooltip = this.$refs.tooltip.$el;
        const TOOLTIP_MARGIN_X = 12;
        const minX = tooltip.offsetWidth * 0.5 + TOOLTIP_MARGIN_X;
        const maxX =
          parentRect.width - tooltip.offsetWidth * 0.5 - TOOLTIP_MARGIN_X;
        this.tooltipProps.left = `${Math.max(Math.min(left, maxX), minX)}px`;
      });
    },
    beforeChange(change) {
      if (!this.singleLine) return;

      if (change.text?.length > 1)
        change.update(change.from, change.to, [
          change.text.filter(t => t).join(' ')
        ]);
    },
    closeKeywordsDialog() {
      this.isShowingKeywordsDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';
@import '@/scss/mixins/_body.scss';

.AppKeywordTextarea {
  position: relative;

  &--multi-line ::v-deep .CodeMirror-scroll {
    min-height: 40px;
  }
}

.AppKeywordTextarea__show-keywords-dialog {
  position: absolute;
  top: -23px;
  right: 6px;
  @include text-caption;
  &:hover,
  &:active {
    cursor: pointer;
    user-select: none;
    @include text-caption-dark;
  }
}

.AppKeywordTextarea__input {
  @include input-base;
  padding: 5px 11px;

  &--disabled {
    pointer-events: all;

    ::v-deep .CodeMirror {
      opacity: 0.45;
    }
  }
}

.AppKeywordTextarea__placeholder {
  position: absolute;
  top: 6px;
  left: 12px;
  @include text-placeholder;
}

.AppKeywordTextarea__bytes,
.AppKeywordTextarea__length {
  text-align: right;
  margin-top: 2px;
}

::v-deep {
  .CodeMirror.cm-s-keyword {
    @include body;
    color: $color-content-text;

    pre.CodeMirror-line,
    pre.CodeMirror-line-like {
      padding-left: 0;
      padding-right: 0;
    }

    .CodeMirror-lines {
      padding-top: 0;
      padding-bottom: 0;
    }

    .cm-keyword {
      color: $color-blue-dark;
    }

    .cm-invalid {
      position: relative;
      color: $color-red;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        border-bottom: 2px dotted #f07878;
      }
    }
  }
}
</style>

<style lang="scss">
@import 'codemirror/addon/hint/show-hint';
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_body.scss';

.CodeMirror-hints {
  @include body;
  z-index: $z-index-tooltip-modal;
  padding: 9px 7px;
}

.CodeMirror-hint {
  color: inherit;
  padding: 6px 8px;
}

li.CodeMirror-hint-active {
  background-color: $color-nav-selected;
  color: inherit;
}
</style>

<i18n locale="ko">
{
  "show_available_keywords": "사용 가능한 예약어",
  "click_to_insert": "클릭해서 입력하기",
  "not_supported": "지원하지 않는 예약어"
}
</i18n>
