<template>
  <div>
    <div v-if="documentUrl" class="AppIntroContent__head">
      <AppButton
        :label="documentLabel || $t('button_label')"
        type="external_link"
        :to="documentUrl"
      />
    </div>
    <div
      class="AppIntroContent__image-wrapper"
      :style="{ maxWidth: `${imageWidth}px` }"
    >
      <AppImage v-for="(imageUrl, i) in imageUrls" :key="i" :src="imageUrl" />
    </div>
    <div v-if="documentUrl" class="AppIntroContent__foot">
      <AppButton
        :label="documentLabel || $t('button_label')"
        type="external_link"
        :to="documentUrl"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppIntroContent',
  props: {
    documentUrl: {
      type: String,
      default: null
    },
    documentLabel: {
      type: String,
      default: null
    },
    imageUrls: {
      type: Array,
      required: true
    },
    imageWidth: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.AppIntroContent__head {
  margin-bottom: 24px;
}

.AppIntroContent__image-wrapper {
  line-height: 0;
}

.AppIntroContent__foot {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "button_label": "서비스 제안서 보기"
}
</i18n>
