import Vue from 'vue';
import _ from 'lodash';

import api from '@/lib/api';
import i18n from '@/lib/i18n';
import { pathWithParams } from '@/lib/params';
import ShopBuilder from '@/enums/ShopBuilder';
import BizMessageServiceType from '@/enums/BizMessageServiceType';
import BrandFitStatus from '@/enums/BrandFitStatus';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import BrandTargetStatus from '@/enums/BrandTargetStatus';
import BrandDisplayStatus from '@/enums/BrandDisplayStatus';
import BrandChannelStatus from '@/enums/BrandChannelStatus';
import BrandInsightStatus from '@/enums/BrandInsightStatus';
import TargetBillingStatus from '@/enums/TargetBillingStatus';
import ReviewPointType from '@/enums/ReviewPointType';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import ReviewMessageDispatchMethod from '@/enums/ReviewMessageDispatchMethod';
import router from '@/router';

const getDefaultState = () => ({
  currentUser: null,
  currentBrand: null,
  directUploadsUrl: null,
  easyJoin: {},
  reviewStatus: null,
  reviewSyncStatus: null,
  targetStatus: null,
  targetPayment: null,
  fitStatus: null,
  displayStatus: null,
  channelStatus: null,
  insightStatus: null,
  insightNotices: [],
  reviewTagTypes: null,
  adminSettings: {},
  brandSettings: {},
  reviewSettings: {},
  targetSettings: {},
  fitSettings: {},
  displaySettings: {},
  channelSettings: {},
  productSettings: {},
  laboratory: {},
  vueWidget: {},
  imwebApiKeyValidity: true,
  isBizMessageCampaignStopped: false
});

const state = getDefaultState();

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DIRECT_UPLOADS_URL(state, url) {
    state.directUploadsUrl = url;
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_CURRENT_BRAND(state, brand) {
    state.currentBrand = brand;
  },
  SET_EASY_JOIN(state, easyJoin) {
    state.easyJoin = easyJoin;
  },
  SET_IMWEB_API_KEY_VALIDITY(state, imwebApiKeyValidity) {
    state.imwebApiKeyValidity = imwebApiKeyValidity;
  },
  SET_IS_BIZ_MESSAGE_CAMPAIGN_STOPPED(state, isBizMessageCampaignStopped) {
    state.isBizMessageCampaignStopped = isBizMessageCampaignStopped;
  },
  SET_REVIEW_STATUS(state, reviewStatus) {
    state.reviewStatus = reviewStatus;
  },
  SET_REVIEW_SYNC_STATUS(state, reviewSyncStatus) {
    state.reviewSyncStatus = reviewSyncStatus;
  },
  SET_TARGET_STATUS(state, targetStatus) {
    state.targetStatus = targetStatus;
  },
  SET_TARGET_PAYMENT(state, targetPayment) {
    state.targetPayment = targetPayment;
  },
  SET_FIT_STATUS(state, fitStatus) {
    state.fitStatus = fitStatus;
  },
  SET_DISPLAY_STATUS(state, displayStatus) {
    state.displayStatus = displayStatus;
  },
  SET_INSIGHT_STATUS(state, insightStatus) {
    state.insightStatus = insightStatus;
  },
  SET_INSIGHT_NOTICES(state, insightNotices) {
    state.insightNotices = insightNotices;
  },
  SET_CHANNEL_STATUS(state, channelStatus) {
    state.channelStatus = channelStatus;
  },
  SET_REVIEW_TAG_TYPES(state, reviewTagTypes) {
    state.reviewTagTypes = reviewTagTypes;
  },
  SET_ADMIN_SETTINGS(state, adminSettings) {
    state.adminSettings = adminSettings;
  },
  SET_BRAND_SETTINGS(state, brandSettings) {
    state.brandSettings = brandSettings;
  },
  SET_REVIEW_SETTINGS(state, reviewSettings) {
    state.reviewSettings = reviewSettings;
  },
  SET_TARGET_SETTINGS(state, targetSettings) {
    state.targetSettings = targetSettings;
  },
  SET_DISPLAY_SETTINGS(state, displaySettings) {
    state.displaySettings = displaySettings;
  },
  SET_CHANNEL_SETTINGS(state, channelSettings) {
    state.channelSettings = channelSettings;
  },
  UPDATE_DISPLAY_SETTINGS(state, displaySettings) {
    state.displaySettings = { ...state.displaySettings, ...displaySettings };
  },
  SET_FIT_SETTINGS(state, fitSettings) {
    state.fitSettings = fitSettings;
  },
  SET_PRODUCT_SETTINGS(state, productSettings) {
    state.productSettings = productSettings;
  },
  SET_LABORATORY(state, laboratory) {
    state.laboratory = laboratory;
  },
  SET_VUE_WIDGET(state, vueWidget) {
    state.vueWidget = vueWidget;
  },
  RESET_UNLISTED_NOTIFICATIONS_COUNT({ currentUser }) {
    Vue.set(currentUser, 'unlisted_notifications_count', 0);
  },
  DEDUCT_UNCHECKED_NOTIFICATIONS_COUNT({ currentUser }) {
    Vue.set(
      currentUser,
      'unchecked_notifications_count',
      currentUser.unchecked_notifications_count - 1
    );
  },
  UNSET_RESET_PASSWORD_REQUIRED({ currentUser }) {
    Vue.set(currentUser, 'reset_password_required', false);
  },
  SET_TARGET_SETTINGS_ENABLE_DISPATCH({ targetSettings }, enableDispatch) {
    Vue.set(targetSettings, 'enable_dispatch', enableDispatch);
  },
  SET_EXECUTED_WIDGET_INITIAL_INSTALLATION({ brandSettings }) {
    Vue.set(brandSettings, 'executed_widget_initial_installation', true);
  }
};

function handleSession({ rootState, commit, dispatch, rootGetters }, data) {
  if (data.redirect_sso_url) {
    window.location.href = data.redirect_sso_url;
    return;
  }
  commit('RESET_STATE');

  api.defaults.headers.common['X-CSRF-Token'] = data.csrf_token;

  commit('translation/SET_TRANSLATIONS', data.translations, { root: true });

  commit('translation/SET_ORIGINAL_KEY_MAP', data.original_key_map, {
    root: true
  });

  commit('SET_ADMIN_SETTINGS', data.admin_settings);
  if (!data.user) return;
  commit('SET_CURRENT_USER', data.user);
  i18n.locale = data.user.admin_locale;

  if (!data.brand) return;
  commit('SET_CURRENT_BRAND', data.brand);

  if (
    !_.isEmpty(rootState.brandParams) &&
    !['agency', 'admin', 'admin_bpo'].includes(data.user.role)
  ) {
    commit('SET_BRAND_PARAMS', {}, { root: true });
    router.replace({
      ...router.currentRoute,
      query: _.omit(
        router.currentRoute.query,
        'brand_id',
        'brand_code',
        'brand_url',
        'user_id'
      )
    });
  }

  if (data.review_status) commit('SET_REVIEW_STATUS', data.review_status);
  if (data.review_sync_status)
    commit('SET_REVIEW_SYNC_STATUS', data.review_sync_status);
  if (data.target_status) commit('SET_TARGET_STATUS', data.target_status);
  if (data.target_payment) commit('SET_TARGET_PAYMENT', data.target_payment);
  if (data.fit_status) commit('SET_FIT_STATUS', data.fit_status);
  if (data.display_status) commit('SET_DISPLAY_STATUS', data.display_status);
  if (data.insight_status) commit('SET_INSIGHT_STATUS', data.insight_status);
  if (data.insight_notices) commit('SET_INSIGHT_NOTICES', data.insight_notices);
  if (data.channel_status) commit('SET_CHANNEL_STATUS', data.channel_status);
  if (data.mini_notices)
    commit('editor/SET_MINI_NOTICES', data.mini_notices, { root: true });

  // sharded db data
  if (data.brand_settings) {
    commit('SET_BRAND_SETTINGS', data.brand_settings);
    commit('SET_REVIEW_SETTINGS', data.review_settings);
    commit('SET_TARGET_SETTINGS', data.target_settings);
    commit('SET_FIT_SETTINGS', data.fit_settings);
    commit('SET_DISPLAY_SETTINGS', data.display_settings);
    commit('SET_CHANNEL_SETTINGS', data.channel_settings);
    commit('SET_PRODUCT_SETTINGS', data.product_settings);
    commit('brandCategory/SET_MY_BRAND_CATEGORIES', data.my_brand_categories, {
      root: true
    });
    commit(
      'brandCategory/SET_ALL_BRAND_CATEGORIES',
      data.all_brand_categories,
      { root: true }
    );
    commit('productCategory/SET_PRODUCT_CATEGORIES', data.product_categories, {
      root: true
    });
    commit('productIcons/SET_PRODUCT_ICONS', data.product_icons, {
      root: true
    });
    commit('thirdParties/SET_THIRD_PARTIES', data.third_parties, {
      root: true
    });
    if (!data.brand_settings.enable_user_grades)
      commit('menu/DISABLE_MENU', 'review_user_grades', { root: true });

    if (data.user.popup_notices.length)
      dispatch(
        'dialog/openDialog',
        [
          'TheNoticePopupDialog',
          {
            notices: data.user.popup_notices,
            keepOnRoute: true
          }
        ],
        { root: true }
      );

    if (
      data.popup_free_trial &&
      !rootGetters['alert/isAlertSnoozed']('free_trial_popup')
    ) {
      const { start_date, end_date } = data.popup_free_trial;

      dispatch(
        'dialog/openDialog',
        [
          'TheFreeTrialDialog',
          {
            startDate: start_date,
            endDate: end_date,
            keepOnRoute: true
          }
        ],
        { root: true }
      );
    }

    const { stopped_biz_message_templates } = data;
    commit(
      'SET_IS_BIZ_MESSAGE_CAMPAIGN_STOPPED',
      !!stopped_biz_message_templates.length
    );

    if (
      !rootGetters['alert/isAlertSnoozed']('stopped_biz_message_templates') &&
      stopped_biz_message_templates.length
    )
      dispatch(
        'dialog/openDialog',
        [
          'TheBizMessageTemplateStoppedDialog',
          { templates: stopped_biz_message_templates }
        ],
        { root: true }
      );

    if (data.inspected_biz_message_templates.length)
      dispatch(
        'dialog/openDialog',
        [
          'TheBizMessageTemplateInspectedDialog',
          { templates: data.inspected_biz_message_templates }
        ],
        { root: true }
      );

    commit('SET_LABORATORY', data.laboratory);
    commit('SET_VUE_WIDGET', data.vue_widget);
  }

  commit('SET_EASY_JOIN', data.easy_join);

  commit('SET_IMWEB_API_KEY_VALIDITY', data.imweb_api_key_validity);

  commit('SET_REVIEW_TAG_TYPES', data.review_tag_types);

  if (data.flash.notice)
    dispatch('toast/createToast', data.flash.notice, { root: true });
  if (data.flash.alert) alert(data.flash.alert);
  if (data.direct_uploads_url)
    commit('SET_DIRECT_UPLOADS_URL', data.direct_uploads_url);

  showRequireMaterialPopupDialog(rootGetters, dispatch, data.brand_settings);
}

function showRequireMaterialPopupDialog(rootGetters, dispatch, brand_settings) {
  if (rootGetters['alert/isAlertSnoozed']('material_popup')) return;

  const materials = [];
  if (brand_settings?.corporate_number_required) materials.push('corporate');
  if (brand_settings?.phone_number_not_registered) materials.push('phone');
  if (brand_settings?.biz_message_profile_required)
    materials.push('biz_message_profile');
  if (materials.length === 0) return;

  dispatch(
    'dialog/openDialog',
    ['TheRequiredMaterialPopupDialog', { materials, keepOnRoute: true }],
    { root: true }
  );
}

const actions = {
  fetchSession(context) {
    return api
      .get(
        '/session',
        {
          params: {
            sso_from: router.currentRoute.query.return_to
          }
        },
        { requestId: 'FETCH_SESSION' }
      )
      .then(({ data }) => handleSession(context, data));
  },
  login(context, formData) {
    if (router.currentRoute.query.return_to)
      formData.append('sso_from', router.currentRoute.query.return_to);
    return api.post('/session', formData, { silent: true }).then(response => {
      handleSession(context, response.data);
      return response;
    });
  },
  authenticateOtp(context, formData) {
    return api
      .post('/session/authenticate_otp', formData, { silent: true })
      .then(({ data }) => handleSession(context, data));
  },
  onLogout({ commit, dispatch }, { keepPathAfterLogin, redirectToLogin }) {
    commit('RESET_STATE');
    dispatch('fetchSession');
    if (redirectToLogin) dispatch('redirectToLogin', keepPathAfterLogin);
  },
  logout({ dispatch }) {
    api.delete('/session').then(() =>
      dispatch('onLogout', {
        keepPathAfterLogin: false,
        redirectToLogin: true
      })
    );
  },
  logoutOnly({ dispatch }) {
    api.delete('/session').then(() =>
      dispatch('onLogout', {
        keepPathAfterLogin: false,
        redirectToLogin: false
      })
    );
  },
  redirectToLogin({ rootState }, redirectBack) {
    const loginPath = '/login';
    const callbackParam = _.pick(router.currentRoute.query, [
      'callback',
      'data'
    ]);
    const query = { ...rootState.brandParams, ...callbackParam };
    if (
      redirectBack &&
      router.currentRoute.path !== loginPath &&
      router.currentRoute.path !== '/'
    )
      query.next = pathWithParams(
        router.currentRoute.path,
        _.omit(
          router.currentRoute.query,
          'brand_id',
          'brand_code',
          'user_id',
          'brand_url'
        ),
        router.currentRoute.hash
      );
    router.push({ path: loginPath, query });
  },
  redirectToNextPage({ state, dispatch }, next) {
    if (next?.includes('v=1')) {
      window.location.href = next;
      return true;
    }

    const isAdmin = ['admin', 'admin_bpo', 'agency'].includes(
      state.currentUser.role
    );
    const isApiUser = ['third_party'].includes(state.currentUser.role);
    const brand = state.currentBrand;

    if (isApiUser) {
      window.location.href = next;
      return true;
    }

    if (isAdmin && !brand) {
      if (next !== '/') alert(i18n.t('errors.brand_params_required'));
      window.location.href = '/';
      return true;
    }

    if (brand) {
      if (state.easyJoin?.requires_manager_info) {
        dispatch('routeToEasyJoinManagerInfo');
        return;
      }
      if (next === '/' || router.currentRoute.path === '/')
        dispatch('redirectToHome');
      else if (next) router.push(next);
    } else alert(i18n.t('errors.brand_required'));
    return false;
  },
  redirectToHome({ state, dispatch, rootGetters }) {
    const user = state.currentUser;
    if (!user) return dispatch('redirectToLogin');

    const brand = state.currentBrand;

    let homePath = '';
    if (state.reviewStatus) homePath = '/review/reviews';
    else if (state.targetStatus)
      homePath = '/target/widget-campaigns/product-recommendation';
    else if (state.fitStatus) homePath = '/fit/size/products';
    else if (state.displayStatus) homePath = '/display/main-page/corners';
    else if (state.channelStatus) homePath = '/channel/settings/basic';
    else if (state.insightStatus) homePath = '/analytics/insight_dashboard';
    else if (brand.review_status) homePath = '/review/intro';
    else if (brand.target_status) homePath = '/target/intro';
    else if (brand.fit_status) homePath = '/fit/intro';
    else if (brand.display_status) homePath = '/display/intro';
    else homePath = '/review/intro';

    if (BrandReviewStatus.isOnboarding(brand.review_status))
      homePath = '/review/dashboard';

    router.push(rootGetters.pathWithBrandParams(homePath));
  },
  routeToEasyJoinManagerInfo() {
    if (router.currentRoute.path !== '/easy-join-manager-info')
      router.replace('/easy-join-manager-info');
  },
  skipResetPassword({ commit }) {
    commit('UNSET_RESET_PASSWORD_REQUIRED');
    api.post('/user/skip_reset_password', {}, { silent: true });
  },
  updateLaboratory({ state, commit }, laboratory) {
    commit('SET_LABORATORY', {
      ...state.laboratory,
      ..._.pick(laboratory, _.keys(state.laboratory))
    });
  },
  updatePassword({ commit }, formData) {
    return api
      .patch('/user/update_password', formData, {
        silent: true,
        successMessage: i18n.t('session.password_updated')
      })
      .then(() => commit('UNSET_RESET_PASSWORD_REQUIRED'));
  },
  updateReviewSettings({ state, commit }, reviewSettings) {
    commit('SET_REVIEW_SETTINGS', {
      ...state.reviewSettings,
      ..._.pick(reviewSettings, _.keys(state.reviewSettings))
    });
  },
  updateFitSettings({ state, commit }, fit_settings) {
    const changed = _.pick(fit_settings, _.keys(state.fitSettings));
    commit('SET_FIT_SETTINGS', { ...state.fitSettings, ...changed });
  },
  join(_, formData) {
    return api.post('/user/registration', formData, { silent: true });
  }
};

const getters = {
  adminLocale({ currentUser }) {
    return currentUser.admin_locale;
  },
  reviewTagTypeNameMap({ reviewTagTypes }) {
    return reviewTagTypes
      ? _.chain(reviewTagTypes)
          .keyBy('id')
          .mapValues('name')
          .value()
      : null;
  },
  brandReviewTagTypes({ currentBrand, reviewTagTypes }) {
    return reviewTagTypes.filter(
      ({ nlp_category_type_id }) =>
        nlp_category_type_id === currentBrand.nlp_category_type_id
    );
  },
  isAnalyticsAccessible({ currentUser }) {
    return currentUser.menu.analytics_accessible;
  },
  isAnalyticsInsightLabAccessible(_state, getters) {
    return (
      getters.isBrandSettingsHavingReviewKeywordsBiUrl ||
      getters.isBrandSettingsHavingReviewStatisticsBiUrl
    );
  },
  isBizMessageSettingsMenuAccessible({ brandSettings, reviewSettings }) {
    return (
      brandSettings.biz_message_service_type ==
        BizMessageServiceType.LUNASOFT &&
      reviewSettings.message_sending_enabled &&
      reviewSettings.dispatch_method === ReviewMessageDispatchMethod.BIZ_MESSAGE
    );
  },
  isBrandSettingsAccessible({ currentUser }) {
    return currentUser.menu.brand_settings_accessible;
  },
  isBrandSettingsHavingReviewKeywordsBiUrl({ brandSettings }) {
    return !!brandSettings.review_keywords_bi_url;
  },
  isBrandSettingsHavingReviewStatisticsBiUrl({ brandSettings }) {
    return !!brandSettings.review_statistics_bi_url;
  },
  isChannelMenuLaunched({ channelSettings }) {
    return channelSettings.launch_ad_menu;
  },
  isCurrentUserAdmin({ currentUser }) {
    return ['admin', 'admin_bpo'].includes(currentUser?.role);
  },
  isCurrentUserInstallationAgency({ currentUser }) {
    return currentUser?.is_installation_agency === true;
  },
  isDispatchMethodBizMessageOnlyEnabled({ adminSettings }) {
    return adminSettings.enable_dispatch_method_biz_message_only;
  },
  isDisplayAccessible({ currentUser }) {
    return currentUser.menu.display_accessible;
  },
  isDisplayMenuLaunched({ displaySettings }) {
    return displaySettings.launch_display_menu;
  },
  isDisplayMenuAdvancedSettingsWidgetSettings({ currentBrand }) {
    return (
      currentBrand.shop_builder === 'shopby' ||
      currentBrand.shop_builder === 'cafe24'
    );
  },
  isBatchMileageAPIAvailable({ currentBrand }) {
    return currentBrand.use_batch_mileage_api;
  },
  isFitAccessible({ currentUser }) {
    return currentUser.menu.fit_accessible;
  },
  isFitTesting({ currentBrand }) {
    return currentBrand.fit_status === BrandFitStatus.TESTING;
  },
  isKoreanBrand({ currentBrand }) {
    return currentBrand.locale === 'ko-KR';
  },
  isProductAccessible({ currentUser }) {
    return currentUser.menu.product_accessible;
  },
  isReviewAccessible({ currentUser }) {
    return currentUser.menu.review_accessible;
  },
  isReviewCampaignEnabled({ adminSettings }) {
    return adminSettings.enable_review_campaign;
  },
  isBrandReviewCsvImportEnabled({ adminSettings }) {
    return adminSettings.enable_brand_review_csv_import;
  },
  isReviewOptionV2Enabled({ reviewSettings }) {
    return reviewSettings.use_product_category_product_type;
  },
  isReviewExpiredButOperating({ currentBrand }) {
    return (
      currentBrand.review_status === BrandReviewStatus.EXPIRED_BUT_OPERATING ||
      currentBrand.review_status === BrandReviewStatus.EXPIRED_AND_ADMIN_BLOCKED
    );
  },
  isReviewMessagesAccessible({ reviewSettings }) {
    return !reviewSettings.hide_review_messages_menu;
  },
  isChatbotSettingsPartOneEnabled({ adminSettings }) {
    return adminSettings.enable_chatbot_settings_part_one;
  },
  isChatbotSettingsPartTwoEnabled({ adminSettings }) {
    return adminSettings.enable_chatbot_settings_part_two;
  },
  isReviewOnboarding({ currentBrand }) {
    return BrandReviewStatus.isOnboarding(currentBrand.review_status);
  },
  isReviewOnboardingNotPaid({ currentBrand }) {
    return currentBrand.review_status === BrandReviewStatus.ONBOARDING_NOT_PAID;
  },
  isReviewOperating({ currentBrand }) {
    return BrandReviewStatus.isOperating(currentBrand.review_status);
  },
  isReviewPreparing({ currentBrand }) {
    return BrandReviewStatus.isPreparing(currentBrand.review_status);
  },
  isReviewSettingsAccessible({ currentUser }) {
    return currentUser.menu.review_settings_accessible;
  },
  isReviewSmartstoreMenuAccessible({ reviewSettings }) {
    return reviewSettings.use_smartstore_sync_menu;
  },
  isReviewTerminated({ currentBrand }) {
    return currentBrand.review_status === BrandReviewStatus.TERMINATED;
  },
  isReviewTesting({ currentBrand }) {
    return currentBrand.review_status === BrandReviewStatus.TESTING;
  },
  isReviewUsingBizMessage({ currentBrand }) {
    return BrandReviewStatus.isUsingBizMessage(currentBrand.review_status);
  },
  isTargetAccessible({ currentUser }) {
    return currentUser.menu.target_accessible;
  },
  isTargetNone({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.NONE;
  },
  isTargetRequested({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.REQUESTED;
  },
  isTargetExpiredAndAdminBlocked({ currentBrand }) {
    return (
      currentBrand.target_status === BrandTargetStatus.EXPIRED_AND_ADMIN_BLOCKED
    );
  },
  isTargetTerminated({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.TERMINATED;
  },
  isTargetTesting({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.TESTING;
  },
  isUrlPatternMenuAccessible({ currentBrand }) {
    return (
      currentBrand.shop_builder === 'cafe24' ||
      currentBrand.shop_builder === 'shopby'
    );
  },
  isUsingNlp({ reviewSettings }) {
    return reviewSettings.use_nlp_by_openai;
  },
  targetRestorableDays({ targetSettings }) {
    if (targetSettings.remaining_operating_days !== null) {
      return i18n.t('unit.days', [targetSettings.remaining_operating_days]);
    } else {
      return i18n.t('unit.months', [targetSettings.remaining_operating_months]);
    }
  },
  isTargetCustomCampaignEnabled({ targetSettings }) {
    return targetSettings.enable_custom_campaigns;
  },
  isTargetOperating({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.OPERATING;
  },
  isTargetExpired({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.EXPIRED;
  },
  isTargetOperatingOrExpired({ currentBrand }) {
    return (
      currentBrand.target_status === BrandTargetStatus.OPERATING ||
      currentBrand.target_status === BrandTargetStatus.EXPIRED
    );
  },
  isTargetFreeTrial(
    _state,
    { isTargetFreeTrialOperating, isTargetFreeTrialExpired }
  ) {
    return isTargetFreeTrialOperating || isTargetFreeTrialExpired;
  },
  isTargetFreeTrialOperating({ currentBrand }) {
    return (
      currentBrand.target_status === BrandTargetStatus.FREE_TRIAL_OPERATING
    );
  },
  isTargetFreeTrialExpired({ currentBrand }) {
    return currentBrand.target_status === BrandTargetStatus.FREE_TRIAL_EXPIRED;
  },
  isTargetPaymentDisabled({ currentBrand }) {
    return currentBrand.prepare_payment_disabled;
  },
  targetFreeTrialStartedAt({ targetStatus }) {
    return targetStatus.target_free_trial_started_at;
  },
  isTargetBillingTablePrepared({ targetPayment }) {
    return (
      targetPayment?.target_billing_info.billing_status ===
      TargetBillingStatus.PAYMENT_PREPARED
    );
  },
  isTargetSpecialPackagePublished({ targetPayment }) {
    return (
      targetPayment?.target_billing_info.billing_status ===
      TargetBillingStatus.SPECIAL_PACKAGE_PUBLISHED
    );
  },
  isTargetConfiguationRequired({ targetPayment }) {
    return (
      targetPayment.target_billing_info.billing_status ===
      TargetBillingStatus.CONFIGURATION_REQUIRED
    );
  },
  isTargetLastPaidBillingInfoCustom({ targetPayment }) {
    return targetPayment?.is_last_paid_billing_info_custom;
  },
  isSmartInstallationSupported({ currentBrand }) {
    return ['cafe24', 'makeshop', 'godo', 'shopby'].includes(
      currentBrand.shop_builder
    );
  },
  isWidgetInitialInstallationUsable({ currentBrand, brandSettings }) {
    return (
      BrandReviewStatus.isPreparing(currentBrand.review_status) &&
      !brandSettings.executed_widget_initial_installation
    );
  },
  isInstallRequestRenewalUsable({ currentBrand }, getters) {
    return (
      getters.isInstallRequestRenewalEnabled &&
      [BrandReviewStatus.OPERATING].includes(currentBrand.review_status) &&
      ['cafe24'].includes(currentBrand.shop_builder)
    );
  },
  smsPrice({ targetPayment }) {
    return targetPayment?.sms_price;
  },
  activeTargetBillingPlanId({ targetPayment }) {
    return targetPayment?.target_billing_info.target_billing_plan_id;
  },
  isDisplayTesting({ currentBrand }) {
    return currentBrand.display_status === BrandDisplayStatus.TESTING;
  },
  isDisplayFreeTrial({ currentBrand }) {
    return [
      BrandDisplayStatus.FREE_TRIAL_OPERATING,
      BrandDisplayStatus.FREE_TRIAL_EXPIRED
    ].includes(currentBrand.display_status);
  },
  isDisplayMenuAccessible({ currentBrand }) {
    return BrandDisplayStatus.isMenuAccessible(currentBrand.display_status);
  },
  isDisplayMenuAccessibleByShopBuilder({ currentBrand }) {
    return ['makeshop', 'cafe24'].includes(currentBrand.shop_builder);
  },
  isChannelTesting({ currentBrand }) {
    return currentBrand.channel_status === BrandChannelStatus.TESTING;
  },
  isChannelFreeTrial({ currentBrand }) {
    return [
      BrandChannelStatus.FREE_TRIAL_OPERATING,
      BrandChannelStatus.FREE_TRIAL_EXPIRED
    ].includes(currentBrand.channel_status);
  },
  isChannelMenuAccessible({ currentBrand }) {
    return BrandChannelStatus.isMenuAccessible(currentBrand.channel_status);
  },
  isChannelBonusAddable({ currentBrand }) {
    return BrandChannelStatus.isBonusAddable(currentBrand.channel_status);
  },
  isInsightAccessible({ currentUser }) {
    return currentUser.menu.insight_accessible;
  },
  isInsightBonusAddable({ currentBrand }) {
    return BrandInsightStatus.isBonusAddable(currentBrand.insight_status);
  },
  isInsightTesting({ currentBrand }) {
    return currentBrand.insight_status === BrandInsightStatus.TESTING;
  },
  isInsightFreeTrial({ currentBrand }) {
    return [
      BrandInsightStatus.FREE_TRIAL_OPERATING,
      BrandInsightStatus.FREE_TRIAL_EXPIRED
    ].includes(currentBrand.insight_status);
  },
  isInsightMenuAccessible({ currentBrand }) {
    return BrandInsightStatus.isMenuAccessible(currentBrand.insight_status);
  },
  showMileageConfirm({ reviewSettings }, _getters, { env }) {
    return env !== 'production' && !reviewSettings.skip_mileage;
  },
  currencyKey({ brandSettings }) {
    return `unit.currency.${brandSettings.currency}`;
  },
  canSaveMileage({ currentUser, reviewSettings }) {
    return (
      currentUser.ability.save_brand_mileage &&
      reviewSettings.review_point_type !== ReviewPointType.COUPON
    );
  },
  canManageComment({ currentUser }) {
    return currentUser?.ability.save_brand_comment;
  },
  accountConfiguredForMileage({ currentUser }) {
    return currentUser?.account_configured_for_mileage;
  },
  pointTypeText({ reviewSettings }) {
    return ReviewPointType.t(reviewSettings.review_point_type);
  },
  isShopifyBrand({ currentBrand }) {
    return currentBrand && currentBrand.shop_builder === 'shopify';
  },
  isShopifyWidgetInitialInstallationUsable({ currentBrand, brandSettings }) {
    return (
      currentBrand?.shop_builder === 'shopify' &&
      !brandSettings.executed_widget_initial_installation
    );
  },
  isImwebBrand({ currentBrand }) {
    return currentBrand && currentBrand.shop_builder === 'imweb';
  },
  isMakeshopBrand({ currentBrand }) {
    return currentBrand && currentBrand.shop_builder === 'makeshop';
  },
  isGodoBrand({ currentBrand }) {
    return currentBrand && currentBrand.shop_builder === 'godo';
  },
  isWidgetInstallCompleteAccessible({ currentBrand, easyJoin }) {
    return (
      easyJoin.widget_install_status === WidgetInstallStatus.REQUESTED &&
      [BrandReviewStatus.TESTING, BrandReviewStatus.ONBOARDING_PAID].includes(
        currentBrand.review_status
      )
    );
  },
  isReviewSettingsSuperAdmin(_state, getters, _rootState, rootGetters) {
    if (!getters.isReviewOnboarding) return false;

    if (getters.isImwebBrand) {
      return !rootGetters['easyJoin/shouldDisplaySummaryBoxFreeTrial'];
    } else {
      return !rootGetters['easyJoin/shouldDisplaySummaryBoxLive'];
    }
  },
  isWidgetInstallationRequestMenuAccessible({ currentBrand }) {
    return (
      currentBrand.review_status === BrandReviewStatus.TESTING &&
      ['cafe24', 'makeshop', 'makeglob', 'godo', 'wisa', 'bizhost'].includes(
        currentBrand.shop_builder
      )
    );
  },
  hostingName({ currentBrand }) {
    return currentBrand.shop_builder === 'none'
      ? currentBrand.name
      : ShopBuilder.tk(currentBrand.shop_builder);
  },
  paymentHistoryReviewAdditionalServicesAccessible({ currentBrand }) {
    return currentBrand.payment_history_review_additional_services_accessible;
  },
  isListV3InstallationEnabled({ adminSettings }) {
    return adminSettings.enable_list_v3_installation;
  },
  isPageUrlPatternV2Enabled({ adminSettings }) {
    return adminSettings.enable_page_url_pattern_v2;
  },
  isInstallRequestRenewalEnabled({ adminSettings }) {
    return adminSettings.enable_install_request_renewal;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
