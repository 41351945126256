<template>
  <table class="AppColorReportTable">
    <thead>
      <th class="AppColorReportTable__col-summary">{{ title }}</th>
      <th
        v-for="(column, i) in columns"
        :key="`head-${i}`"
        class="AppColorReportTable__col-data"
      >
        {{ column }}
      </th>
    </thead>
    <tbody>
      <tr v-for="(row, i) in rows" :key="`row-${i}`">
        <td class="AppColorReportTable__col-summary">
          <span>{{ row.label }}</span
          ><span class="AppColorReportTable__summary"
            >{{ row.summary
            }}<AppSvgIcon
              v-tooltip="row.summaryTooltip"
              class="AppColorReportTable__summary-icon"
              name="icon-info-tooltip"
              :width="10"
              :height="10"
          /></span>
        </td>
        <td
          v-for="(cell, j) in row.data"
          :key="`column-${j}`"
          v-tooltip="
            cell.tooltipMessage
              ? {
                  message: cell.tooltipMessage,
                  info: cell.tooltipInfo,
                  textAlign: 'left'
                }
              : null
          "
          :class="[
            'AppColorReportTable__col-data',
            cell.mark
              ? 'AppColorReportTable__cell--mark'
              : `AppColorReportTable__cell--level-${Math.min(
                  maxLevel,
                  cell.level
                )}`
          ]"
        >
          <AppNumber
            v-if="cell.type === 'percentage'"
            :value="cell.value"
            :precision="1"
            unit="percentage"
          />
          <AppData v-else :value="cell.value" :type="cell.type" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'AppColorReportTable',
  props: {
    title: { type: String, required: true },
    columns: { type: Array, required: true },
    rows: { type: Array, required: true }
  },
  computed: {
    maxLevel() {
      return 9;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/colors';

.AppColorReportTable {
  border-collapse: separate;
  color: $color-grey-80;
}

.AppColorReportTable__col-summary {
  vertical-align: middle;
  width: 155px;
  padding-right: 23px;
}

.AppColorReportTable__col-data {
  text-align: center;
  vertical-align: middle;
  width: 66px;
  height: 34px;
  border: 1px solid #ffffff;
  border-radius: 3px;
}

.AppColorReportTable__summary {
  float: right;
}

.AppColorReportTable__summary-icon {
  margin-left: 4px;
}

.AppColorReportTable__cell {
  &--mark {
    background: #ffffff;
    outline: 1px solid #b5d2ee;
    outline-offset: -2px;
  }

  &--level-9 {
    background: #2274bf;
    color: $color-grey-05;
  }

  &--level-8 {
    background: #298ce7;
    color: $color-grey-05;
  }

  &--level-7 {
    background: #4d9ee9;
    color: $color-grey-05;
  }

  &--level-6 {
    background: #6caeeb;
    color: $color-grey-05;
  }

  &--level-5 {
    background: #85bbed;
  }

  &--level-4 {
    background: #9cc6ed;
  }

  &--level-3 {
    background: #b5d2ee;
  }

  &--level-2 {
    background: #d4e5f5;
  }

  &--level-1 {
    background: #eaf2fa;
  }

  &--level-0 {
    background: #ffffff;
  }
}
</style>
