var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{class:[
    'AppForm',
    _vm.formStyle ? ("AppForm--" + _vm.formStyle) : null,
    _vm.sectionStyle ? ("AppForm--section-style-" + _vm.sectionStyle) : null
  ],on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._t("default"),_vm._t("head"),(_vm.currentSections.length)?_c('div',{staticClass:"AppForm__sections"},_vm._l((_vm.currentSections),function(section){return _c('fieldset',{key:section.id,class:{
        AppForm__section: !!section.id,
        'super-admin__item': section.superAdmin,
        advanced: section.advanced,
        hidden: section.advanced && !section.visible
      },attrs:{"id":section.id}},[(section.label)?_c('legend',{staticClass:"AppForm__section-title"},[_vm._v(" "+_vm._s(section.label)+" "),_c('AppFormHint',_vm._b({staticClass:"AppForm__section-hint"},'AppFormHint',_vm.hintProps(section),false))],1):_vm._e(),_vm._l((section.groups),function(group,index){return _c('div',{key:((group.id) + "-" + index),class:[
          'AppForm__group',
          {
            'super-admin__item': group.superAdmin,
            advanced: group.advanced,
            hidden: group.advanced && !group.visible
          }
        ],attrs:{"id":group.groupElementId}},[(group.label)?_c('div',{class:[
            'AppForm__group-title',
            group.labelDisabled ? 'AppForm__group-title--disabled' : ''
          ]},[(group.bubble)?_c('AppBubble',{attrs:{"side":group.bubbleSide}},[_vm._v(" "+_vm._s(group.bubbleMessage)+" ")]):_vm._e(),(group.type === 'switch')?_c('div',[_c('label',{staticClass:"AppForm__group-title-switch"},[_c('AppSwitch',{attrs:{"id":group.inputId,"checked":group.value,"name":group.inputName},on:{"change":function($event){return _vm.changeGroup(group, $event)}}}),_vm._v(" "+_vm._s(group.label)+" ")],1)]):(group.type === 'checkbox')?_c('AppCheckbox',{staticClass:"AppForm__group-title-checkbox",attrs:{"id":group.inputId,"name":group.inputName,"checked":group.value,"label":group.label,"disabled":_vm.disabled || group.disabled,"invalid":!!_vm.getFieldError(group.id)},on:{"change":function($event){return _vm.changeGroup(group, $event)}}}):(group.type === 'advanced_group_title')?_c('div',{staticClass:"AppForm__advanced-group-title"},[_vm._v(" "+_vm._s(group.label)+" ")]):(group.type !== 'agreement_checkbox')?_c('label',{attrs:{"for":group.inputId},domProps:{"textContent":_vm._s(group.label)}}):_vm._e(),(_vm.isRequired(group))?_c('span',{staticClass:"AppForm__group-title-required"},[_vm._v(" *")]):_vm._e(),(group.titleBadge)?_c('span',{staticClass:"AppForm__group-title-badge"},[_c('AppBadge',{attrs:{"label":group.titleBadge.label,"badge-style":group.titleBadge.badgeStyle,"badge-size":group.titleBadge.badgeSize}})],1):_vm._e(),(group.groupTooltip)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(group.groupTooltip),expression:"group.groupTooltip"}],staticClass:"AppForm__group-tooltip"},[_c('AppSvgIcon',{attrs:{"name":"icon-info-tooltip"}})],1):_vm._e()],1):_vm._e(),(group.tipMessage)?_c('AppTipBox',{staticClass:"AppForm__group-tip-box",attrs:{"text":group.tipMessage,"margin-bottom":0}}):_vm._e(),(group.groupDescription)?_c('AppFormGroupDescription',{staticClass:"AppForm__group-description",attrs:{"disabled":!!group.disabled,"message":typeof group.groupDescription === 'object'
              ? group.groupDescription.message
              : group.groupDescription,"detail-button-click-handler":group.groupDescriptionDetailClickHandler,"mode":typeof group.groupDescription === 'object'
              ? group.groupDescription.mode
              : 'html'}}):_vm._e(),(group.groupDescriptionBox)?_c('AppFormGroupDescriptionBox',{staticClass:"AppForm__group-description-box",attrs:{"message":group.groupDescriptionBox}}):_vm._e(),_vm._t("group",function(){return [(group.type !== 'checkbox')?_c('div',{class:[
              'AppForm__group-field',
              group.fieldStyle
                ? ("AppForm__group-field--" + (group.fieldStyle))
                : null
            ]},[_c('AppFormField',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.focusGroupId === group.id),expression:"focusGroupId === group.id"}],attrs:{"group":group,"disabled":_vm.disabled,"invalid":!!_vm.getFieldError(group.id),"input-size":_vm.inputSize,"errors":_vm.validationErrors},on:{"blur":function($event){return _vm.blurGroup(group.id)},"change":function($event){return _vm.changeGroup(group, $event)},"validate":_vm.validateOrUnset},scopedSlots:_vm._u([(_vm.$scopedSlots['group-label'])?{key:"label",fn:function(props){return [_vm._t("group-label",null,null,Object.assign({}, props, {disabled: _vm.disabled}))]}}:null,(_vm.$scopedSlots['group-sub-item'])?{key:"sub-item",fn:function(props){return [_vm._t("group-sub-item",null,null,Object.assign({}, props, {disabled: _vm.disabled}))]}}:null],null,true)})],1):_vm._e()]},null,Object.assign({}, group,
            {disabled: _vm.disabled || group.disabled,
            error: _vm.getFieldError(group.id),
            errors: _vm.validationErrors})),_vm._t("description",function(){return [(group.description)?_c('div',{staticClass:"AppForm__description",domProps:{"innerHTML":_vm._s(group.description)}}):_vm._e()]},null,group),_c('AppFormHint',_vm._b({attrs:{"disabled":group.disabled}},'AppFormHint',_vm.hintProps(group),false)),_c('AppFormError',{attrs:{"error":_vm.getFieldError(group.id)}}),_vm._t("group-foot",null,{"id":group.id})],2)}),(section.advancedGroups && section.advancedGroups.length)?_c('AppAdvancedSettingsButton',{staticClass:"AppForm__advanced-settings-button",attrs:{"is-showing-advanced-setting":_vm.advancedGroupVisibleMap[section.id]},on:{"change":function($event){return _vm.toggleAdvancedGroup(section.id, $event)}}}):_vm._e()],2)}),0):_vm._e(),(_vm.advancedSection)?_c('AppAdvancedSettingsButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isAdvancedSectionVisible ? '' : _vm.advancedSection.tooltip),expression:"isAdvancedSectionVisible ? '' : advancedSection.tooltip"}],staticClass:"AppForm__advanced-settings-button",attrs:{"is-showing-advanced-setting":_vm.isAdvancedSectionVisible},on:{"change":_vm.toggleAdvancedSection}}):_vm._e(),(_vm.submitButton)?_c('div',{staticClass:"AppForm__form-controls"},[_c('AppButtonSubmit',_vm._b({},'AppButtonSubmit',_vm.submitButtonProps,false))],1):_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"submit"}}),(!_vm.noScreen)?_c('AppLoadingScreen',{attrs:{"is-loading":_vm.isSubmitting}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }