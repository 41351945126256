<template>
  <AppModalDefault
    :title="$t('dialog_title')"
    is-maximized-by-default
    is-max-height
    @close="close"
  >
    <template #body>
      <div class="RequestInstallCountAndScoreWidgetSelectionDialog__title">
        {{ $t('title') }}
      </div>
      <template>
        <div
          class="RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-container RequestInstallCountAndScoreWidgetSelectionDialog__group"
        >
          <div
            v-for="(option, i) in InstallationPresetAttachTypeOptions"
            :key="i"
            class="RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-item"
          >
            <div :class="imageContainerClasses(option.value)">
              <AppImage
                :src="installMethodImageUrl(option.value)"
                :class="installMethodClasses(option.value)"
              />
            </div>
            <AppRadio
              v-model="attach_type"
              name="attach_type"
              :label="option.label"
              :value="option.value"
            />
            <div
              class="RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-description"
            >
              {{ option.description }}
              <template
                v-if="
                  option.value ===
                    InstallationPresetAttachType.REPLACE_AND_APPEND
                "
              >
                <AppButton
                  :label="$t('preview.replace_and_append.label')"
                  button-style="underline"
                  @click="openReplaceAndAppendPreviewDialog()"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isReplaceAndAppendSelected">
        <div class="RequestInstallCountAndScoreWidgetSelectionDialog__group">
          <div
            class="RequestInstallCountAndScoreWidgetSelectionDialog__group-title"
          >
            {{ $t('display_format.label') }}
          </div>
          <div
            class="RequestInstallCountAndScoreWidgetSelectionDialog__group-field"
          >
            <AppSelect
              v-model="display_format"
              :options="displayFormatOptions"
              :placeholder="$t('display_format.placeholder')"
            />
          </div>
        </div>
      </template>
      <template v-if="showInstallPageType">
        <div class="RequestInstallCountAndScoreWidgetSelectionDialog__group">
          <div
            class="RequestInstallCountAndScoreWidgetSelectionDialog__group-title"
          >
            {{ $t('install_page_type.label') }}
          </div>
          <div
            class="RequestInstallCountAndScoreWidgetSelectionDialog__group-field"
          >
            <AppMultipleSelectCheckbox
              v-model="install_page_type"
              class="RequestInstallCountAndScoreWidgetSelectionDialog__multiple-select-checkbox"
              :options="installPageTypeOptions"
            />
          </div>
        </div>
      </template>
      <template v-if="showInstallPosition">
        <div class="RequestInstallCountAndScoreWidgetSelectionDialog__group">
          <div
            class="RequestInstallCountAndScoreWidgetSelectionDialog__group-title"
          >
            {{ $t('install_position.label') }}
          </div>
          <div
            class="RequestInstallCountAndScoreWidgetSelectionDialog__group-field"
          >
            <AppTable
              class="RequestInstallCountAndScoreWidgetSelectionDialog__table"
              table-id="request-install-count-and-score-widget-table"
              :columns="columns"
              :rows="rows"
            >
              <template #cell="{ column, row }">
                <template v-if="column.id === 'install_position'">
                  <template v-if="row.id === 'position_selectable'">
                    <AppSelect
                      v-model="install_position"
                      :options="row.options"
                    />
                  </template>
                </template>
                <template v-else-if="column.id === 'preview'">
                  <AppButton
                    :label="$t('app.pc')"
                    button-style="input"
                    @click="openPositionPreviewDialog(row.id, 'pc')"
                  />
                  <AppButton
                    :label="$t('app.mobile')"
                    button-style="input"
                    @click="openPositionPreviewDialog(row.id, 'mobile')"
                  />
                </template>
              </template>
            </AppTable>
          </div>
        </div>
      </template>
      <div class="RequestInstallCountAndScoreWidgetSelectionDialog__submit">
        <AppButton
          :label="$t('app.complete')"
          :button-style="'blue'"
          :disabled="submitButtonDisabled"
          @click="submit()"
        />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DialogView from '@/mixins/DialogView';
import DialogSize from '@/enums/DialogSize';
import InstallationPresetAttachType from '@/enums/InstallationPresetAttachType';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';
import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';

export default {
  name: 'RequestInstallCountAndScoreWidgetSelectionDialog',
  mixins: [DialogView],
  data() {
    return {
      attach_type: null,
      display_format: null,
      install_page_type: [],
      install_position: InstallationPresetPlacementType.AFTER_THUMBNAIL_IMAGE
    };
  },
  computed: {
    InstallationPresetAttachType: () => InstallationPresetAttachType,
    WidgetPageType: () => WidgetPageType,
    InstallationPresetPlacementType: () => InstallationPresetPlacementType,
    InstallationPresetAttachTypeOptions() {
      return [
        InstallationPresetAttachType.REPLACE,
        InstallationPresetAttachType.REPLACE_AND_APPEND
      ].map(value => ({
        label: InstallationPresetAttachType.t(value),
        description: this.installMethodDescription(value),
        value
      }));
    },
    isReplaceSelected() {
      return this.attach_type === InstallationPresetAttachType.REPLACE;
    },
    isReplaceAndAppendSelected() {
      return (
        this.attach_type === InstallationPresetAttachType.REPLACE_AND_APPEND
      );
    },
    isDisplayFormatSelected() {
      return !!this.display_format;
    },
    isInstallPageTypeSelected() {
      return this.install_page_type?.length > 0;
    },
    showInstallPageType() {
      return this.isReplaceAndAppendSelected && this.isDisplayFormatSelected;
    },
    showInstallPosition() {
      return this.showInstallPageType && this.isInstallPageTypeSelected;
    },
    displayFormatOptions() {
      return [
        {
          label: this.$t(
            'display_format.options.ko_text_count_star_score_in_order'
          ),
          value: this.$t(
            'display_format.options.ko_text_count_star_score_in_order'
          )
        },
        {
          label: this.$t(
            'display_format.options.star_score_ko_text_count_in_order'
          ),
          value: this.$t(
            'display_format.options.star_score_ko_text_count_in_order'
          )
        },
        {
          label: this.$t('display_format.options.ko_text_count_in_order'),
          value: this.$t('display_format.options.ko_text_count_in_order')
        },
        {
          label: this.$t('display_format.options.en_text_count_in_order'),
          value: this.$t('display_format.options.en_text_count_in_order')
        },
        {
          label: this.$t(
            'display_format.options.capital_en_text_count_in_order'
          ),
          value: this.$t(
            'display_format.options.capital_en_text_count_in_order'
          )
        }
      ];
    },
    installPageTypeOptions() {
      return WidgetPageType.options([
        WidgetPageType.MAIN,
        WidgetPageType.PRODUCT_LIST,
        WidgetPageType.PRODUCT_SEARCH,
        WidgetPageType.PRODUCT_DETAIL
      ]);
    },
    columns() {
      return [
        { id: 'install_page_type', label: this.$t('table.install_page_type') },
        { id: 'install_position', label: this.$t('table.install_position') },
        { id: 'preview', label: this.$t('table.preview') }
      ];
    },
    positionNoneSelectableRow() {
      return this.install_page_type.includes(WidgetPageType.PRODUCT_DETAIL)
        ? [
            {
              id: 'position_non_selectable',
              install_page_type: WidgetPageType.t(
                WidgetPageType.PRODUCT_DETAIL
              ),
              install_position: InstallationPresetPlacementType.t(
                InstallationPresetPlacementType.AFTER_PRODUCT_NAME
              ),
              preview: ''
            }
          ]
        : [];
    },
    positionSelectableRow() {
      const positionSelectablePageTypes = this.install_page_type.filter(
        pageType => pageType !== WidgetPageType.PRODUCT_DETAIL
      );

      return positionSelectablePageTypes.length > 0
        ? [
            {
              id: 'position_selectable',
              install_page_type: positionSelectablePageTypes
                .map(pageType => WidgetPageType.t(pageType))
                .join(', '),
              install_position: '',
              preview: '',
              options: InstallationPresetPlacementType.options([
                InstallationPresetPlacementType.AFTER_THUMBNAIL_IMAGE,
                InstallationPresetPlacementType.APPEND_PRODUCT_INFO
              ])
            }
          ]
        : [];
    },
    rows() {
      return this.positionSelectableRow.concat(this.positionNoneSelectableRow);
    },
    submitButtonDisabled() {
      return this.isReplaceSelected
        ? false
        : this.isReplaceAndAppendSelected
        ? !this.isDisplayFormatSelected || !this.isInstallPageTypeSelected
        : true;
    },
    requestInstallations() {
      const requestInstallations = [
        {
          presetWidgetType: InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET,
          attachType: InstallationPresetAttachType.REPLACE
        }
      ];

      if (this.isReplaceAndAppendSelected) {
        this.install_page_type.forEach(pageType => {
          const position =
            pageType === WidgetPageType.PRODUCT_DETAIL
              ? InstallationPresetPlacementType.AFTER_PRODUCT_NAME
              : this.install_position;

          requestInstallations.push({
            presetWidgetType:
              InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET,
            attachType: InstallationPresetAttachType.APPEND,
            display_format: this.display_format,
            pageType: pageType,
            position
          });
        });
      }

      return requestInstallations;
    }
  },
  methods: {
    ...mapActions('dialog', ['alert', 'openDialog']),
    ...mapMutations('requestInstallations', [
      'SET_COUNT_AND_SCORE_WIDGET_INSTALLATIONS'
    ]),
    submit() {
      this.SET_COUNT_AND_SCORE_WIDGET_INSTALLATIONS({
        requestInstallations: this.requestInstallations
      });
      this.close(true);
    },
    InstallationPresetAttachTypeToKey(value) {
      return InstallationPresetAttachType.toKey(value).toLowerCase();
    },
    WidgetPageTypeToKey(value) {
      return WidgetPageType.toKey(value).toLowerCase();
    },
    imageContainerClasses(installMethod) {
      return {
        'RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-image-container': true,
        'RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-image-container--checked': this.installMethodChecked(
          installMethod
        )
      };
    },
    installMethodClasses(installMethod) {
      return {
        'RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-image': true,
        'RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-image--replace':
          installMethod === InstallationPresetAttachType.REPLACE,
        'RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-image--replace-and-append':
          installMethod === InstallationPresetAttachType.REPLACE_AND_APPEND
      };
    },
    installMethodImageUrl(installMethod) {
      const InstallationPresetAttachTypeKey = this.InstallationPresetAttachTypeToKey(
        installMethod
      );
      return `https://assets.cre.ma/m/admin/v2/count_and_score_widget_install_method_${InstallationPresetAttachTypeKey}@3x.png`;
    },
    installMethodDescription(installMethod) {
      const InstallationPresetAttachTypeKey = this.InstallationPresetAttachTypeToKey(
        installMethod
      );
      return this.$t(`attach_type.${InstallationPresetAttachTypeKey}`);
    },
    installMethodChecked(installMethod) {
      return this.attach_type === installMethod;
    },
    openReplaceAndAppendPreviewDialog() {
      this.alert({
        title: this.$t('preview.replace_and_append.label'),
        width: DialogSize.DEFAULT,
        message: this.$t('preview.replace_and_append.alert_message_html'),
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    openPositionPreviewDialog(id, device) {
      this.openDialog([
        'RequestInstallCountAndScoreWidgetPreviewDialog',
        {
          device,
          placementSelectableType: id
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .AppModalDefault__body {
    min-height: calc(100vh - 64px);
    background-color: $color-grey-05;
  }

  .AppSelect__select {
    background-color: $color-review-white;
  }

  .AppTable__body-row {
    line-height: 33px;
  }

  .request-install-count-and-score-widget-table__install-page-type,
  .request-install-count-and-score-widget-table__install-position,
  .request-install-count-and-score-widget-table__preview {
    width: 200px;
  }
}

.RequestInstallCountAndScoreWidgetSelectionDialog__title {
  @include text-sub-title;
  text-align: center;
  margin-top: 23px;
}

.RequestInstallCountAndScoreWidgetSelectionDialog__group {
  text-align: center;

  & + & {
    margin-top: 32px;
  }

  &-title {
    @include text-label;
  }

  &-field {
    margin-top: 8px;
  }
}

.RequestInstallCountAndScoreWidgetSelectionDialog__multiple-select-checkbox {
  margin: 0 auto;
  width: 112px;
  text-align: left;
}

.RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-item {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-image {
  &--replace {
    width: 210px;
  }

  &--replace-and-append {
    width: 224px;
  }

  &-container {
    width: 320px;
    height: 240px;
    border-radius: 3px;
    background-color: #e7eaed;
    display: flex;
    align-items: center;
    justify-content: center;

    &--checked {
      background-color: #c9ddf1;
    }
  }
}

.RequestInstallCountAndScoreWidgetSelectionDialog__widget-install-method-description {
  @include text-caption;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  white-space: pre-wrap;
}

.RequestInstallCountAndScoreWidgetSelectionDialog__table {
  background-color: transparent;
  width: 600px;
  margin: 0 auto;
}

.RequestInstallCountAndScoreWidgetSelectionDialog__submit {
  margin-top: 48px;
  text-align: center;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 수 + 평점 위젯을 어떻게 설치할까요?",
  "dialog_title": "위젯 설치 요청",
  "attach_type": {
    "replace": "쇼핑몰 내 기존에 존재하는 리뷰 수 영역을 크리마 리뷰 수로 교체합니다. 쇼핑몰 내 기존 리뷰 수 영역이 없다면 교체 작업이 진행되지 않습니다.",
    "replace_and_append": "기존 영역 교체 및 쇼핑몰 내 리뷰수 정보가 없는 영역에 크리마 리뷰 수 + 평점 위젯을 설치할 수 있습니다."
  },
  "display_format": {
    "label": "추가 위젯의 설치 형태를 선택해 주세요.",
    "placeholder": "설치 형태를 선택해 주세요.",
    "options": {
      "ko_text_count_star_score_in_order" : "리뷰: 36 | ⭐ 4.9",
      "star_score_ko_text_count_in_order" : "⭐ 4.9 | 리뷰 36건",
      "ko_text_count_in_order": "리뷰수 : 36개",
      "en_text_count_in_order": "review : 36",
      "capital_en_text_count_in_order": "Review : 36개"
    }
  },
  "install_page_type": {
    "label": "추가 위젯을 설치할 페이지를 선택해 주세요."
  },
  "install_position": {
    "label": "페이지별 설치 위치를 선택해 주세요."
  },
  "preview": {
    "replace_and_append": {
      "label": "추가 위젯 설치 안내",
      "alert_message_html": "추가 설치하는 리뷰 수 + 평점 위젯은 메인 페이지, 카테고리 페이지, 상품 검색 페이지, 상품 상세 페이지에 설치할 수 있습니다.\n\n<br />메인 페이지, 카테고리 페이지, 상품 검색 페이지에 설치할 경우, 상품 썸네일 하단 또는 가격 하단에 리뷰 수 + 평점 정보를 표시할 수 있습니다.\n\n<br />![](https://assets.cre.ma/m/admin/v2/count_and_score_append_main_page@2x.png)\n\n<br /><br />상품 상세 페이지에 설치할 경우, 상품명 하단에 리뷰 수 + 평점 정보를 표시할 수 있습니다.\n\n<br />![](https://assets.cre.ma/m/admin/v2/count_and_score_append_product_detail_page@2x.png)"
    }
  },
  "table": {
    "install_page_type": "설치 페이지",
    "install_position": "설치 위치",
    "preview": "설치 위치 안내"
  }
}
</i18n>
