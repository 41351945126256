import _ from 'lodash';
import {
  isKoreanPhoneFormatValid,
  isMobilePhoneFormatValid
} from '@/lib/phoneFormatValidator';
import { isEmailFormatValid } from '@/lib/emailFormatValidator';
import { isUrlFormatValid } from '@/lib/urlFormatValidator';
import isCssSelectorFormatValid from '@/lib/isCssSelectorFormatValid';
import { isHtmlFormatValid } from '@/lib/htmlFormatValidator';
import { checkImageCache, checkImage } from '@/lib/checkImage';
import { checkUrlCache, checkUrl } from '@/lib/checkUrl';
import { checkSocialUrlCache, checkSocialUrl } from '@/lib/checkSocialUrl';
import {
  checkEmailDuplicationCache,
  checkEmailDuplication
} from '@/lib/checkEmailDuplication';
import {
  checkBrandUrlDuplicationCache,
  checkBrandUrlDuplication
} from '@/lib/checkBrandUrlDuplication';
import getByteSize from '@/lib/vendor/getByteSize';
import { isSkinIdFormatValid } from '@/lib/skinIdFormatValidator';

const Validators = {
  required(value) {
    return (
      value === 0 ||
      value === false ||
      (value instanceof Array ? !!value.length : !!value)
    );
  },
  gt(value, { allowed }) {
    return value > allowed;
  },
  gte(value, { allowed }) {
    return value >= allowed;
  },
  lt(value, { allowed }) {
    return value < allowed;
  },
  lte(value, { allowed }) {
    return value <= allowed;
  },
  positive_integer(value) {
    return Number.isInteger(value) && Validators.gt(value, { allowed: 0 });
  },
  min_max_integer(value, { min, max }) {
    return Number.isInteger(value) && min <= value && value <= max;
  },
  korean_phone_format(value) {
    return !value || isKoreanPhoneFormatValid(value);
  },
  mobile_phone_format(value) {
    return !value || isMobilePhoneFormatValid(value);
  },
  email_format(value) {
    return !value || isEmailFormatValid(value);
  },
  url_format(value) {
    return !value || isUrlFormatValid(value);
  },
  check_path_or_url(value) {
    return value.startsWith('/') || isUrlFormatValid(value);
  },
  max_length(value, { limit }) {
    return value.length <= limit;
  },
  max_bytes: (value, { limit }) => getByteSize(value) <= limit,
  keywords: (value, { keywords }) => {
    const set = new Set(keywords.map(k => k.key));
    return [...value.matchAll(/%{(.*?)}/g)].every(hit => set.has(hit[1]));
  },
  check_skin_id(value) {
    return !value || isSkinIdFormatValid(value);
  },
  css_selector_format(value) {
    return isCssSelectorFormatValid(value);
  },
  html_format(value) {
    return !value || isHtmlFormatValid(value);
  }
};

const AsyncValidators = {
  check_image: {
    sync: value => checkImageCache(value),
    async: value => checkImage(value)
  },
  check_url: {
    sync: value => !value || checkUrlCache(value),
    async: value => !value || checkUrl(value)
  },
  check_social_url: {
    sync: value => checkSocialUrlCache(value),
    async: value => checkSocialUrl(value)
  },
  check_email_duplication: {
    sync: value => checkEmailDuplicationCache(value),
    async: value => checkEmailDuplication(value)
  },
  check_brand_url_duplication: {
    sync: value => checkBrandUrlDuplicationCache(value),
    async: value => checkBrandUrlDuplication(value)
  }
};

for (const validatorName of Object.keys(Validators)) {
  if (validatorName !== 'required') {
    const validator = Validators[validatorName];
    Validators[validatorName] = (value, ...args) =>
      [null, undefined, ''].includes(value) || validator(value, ...args);
  }
}

export default {
  props: {
    eventBus: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      validationErrors: {}
    };
  },
  computed: {
    hasError() {
      return Object.values(this.validationErrors).some(message => !!message);
    },
    errorSummary() {
      const count = Object.values(this.validationErrors).length;
      return count ? this.$t('validations.summary', { count }) : '';
    },
    fieldOptionsMap() {
      const map = {};
      this.sections.forEach(section => {
        const advancedGroups = section.advancedGroups || [];
        const groups = section.groups.concat(advancedGroups);

        groups.forEach(group => {
          map[group.id] = group;
          if (group.fields)
            group.fields.forEach(field => (map[field.id] = field));
        });
      });
      return map;
    }
  },
  watch: {
    validationErrors() {
      this.$emit('error-summary', this.errorSummary);
    },
    fieldOptionsMap() {
      Object.keys(this.validationErrors).forEach(id => this.validateField(id));
    }
  },
  methods: {
    setFieldError(id, error) {
      this.unsetFieldError(id);
      this.$set(this.validationErrors, id, error);
    },
    unsetFieldError(id) {
      const error = this.getFieldError(id);
      if (!error) {
        this.validationErrors = { ...this.validationErrors };
        return;
      }
      if (error.asyncTimer) clearTimeout(error.asyncTimer);
      this.$delete(this.validationErrors, id);
    },
    getFieldError(id) {
      return this.validationErrors[id];
    },
    clearErrors() {
      _.keys(this.validationErrors).forEach(id => this.unsetFieldError(id));
    },
    validateField(id) {
      this.$nextTick(() => this.doValidateField(id));
    },
    doValidateField(id, skipAsync = false) {
      const fieldOptions = this.fieldOptionsMap[id];

      const rules = fieldOptions?.validate || [];
      const value =
        fieldOptions && 'value' in fieldOptions
          ? typeof fieldOptions.value === 'function'
            ? fieldOptions.value()
            : fieldOptions.value
          : this.objectValue(id);

      const [asyncRules, syncRules] = _.partition(rules, r => r.async);
      if (asyncRules.length > 1)
        throw 'There can be up to one async validation rule per field';

      this.unsetFieldError(id);

      const doValidate = (rule, ruleOptions) => {
        const validator = typeof rule === 'function' ? rule : Validators[rule];
        const isValid = validator(
          'value' in ruleOptions ? ruleOptions.value() : value,
          ruleOptions
        );

        if (!isValid) {
          this.setFieldError(id, {
            errorMessage:
              ruleOptions.errorMessage ||
              (typeof rule === 'string'
                ? this.$t(`validations.${rule}`, ruleOptions)
                : ''),
            ...ruleOptions
          });
          return true;
        }
      };

      const isGroupInvalid = syncRules
        .filter(opt => !opt.after)
        .map(opt => (typeof opt === 'string' ? { rule: opt } : opt))
        .some(({ rule, ...ruleOptions }) => {
          return doValidate(rule, ruleOptions);
        });

      const doValidateAfterGroup = () => {
        syncRules
          .filter(opt => opt.after)
          .map(opt => (typeof opt === 'string' ? { rule: opt } : opt))
          .forEach(({ rule, ...ruleOptions }) => {
            return doValidate(rule, ruleOptions);
          });
      };

      if (!isGroupInvalid) {
        if (!skipAsync && asyncRules.length) {
          const { rule, ...ruleOptions } = asyncRules[0];
          const validator = AsyncValidators[rule];
          const syncResult = validator.sync(
            'value' in ruleOptions ? ruleOptions.value() : value,
            ruleOptions
          );

          ruleOptions.validatingMessage =
            ruleOptions.validatingMessage ||
            this.$t(`async_validations.${rule}.validating`);
          ruleOptions.errorMessage =
            ruleOptions.errorMessage ||
            this.$t(`async_validations.${rule}.error`);

          if (syncResult === false) {
            this.setFieldError(id, ruleOptions);
          } else if (syncResult !== true) {
            const asyncTimer = setTimeout(() => {
              const isStale = () => {
                const error = this.getFieldError(id);
                return !error || error.asyncTimer !== asyncTimer;
              };
              validator
                .async('value' in ruleOptions ? ruleOptions.value() : value)
                .then(() => {
                  if (!isStale()) this.unsetFieldError(id);
                  doValidateAfterGroup();
                })
                .catch(() => {
                  if (!isStale()) this.setFieldError(id, ruleOptions);
                });
            }, 1000);
            this.setFieldError(id, {
              errorMessage: ruleOptions.validatingMessage,
              asyncTimer
            });
          } else {
            doValidateAfterGroup();
          }
        } else {
          doValidateAfterGroup();
        }
      }
    }
  }
};
