export default [
  {
    id: 'review_home',
    type: 'sub_menu',
    path: '/review/dashboard',
    parentId: 'review',
    isAccessible: getters => getters.isReviewOnboarding
  },
  {
    id: 'review_dashboard',
    type: 'sub_menu',
    path: '/review/dashboard',
    parentId: 'review_home',
    isAccessible: getters => getters.isReviewOnboarding
  },
  {
    id: 'review_home_admin',
    type: 'sub_menu',
    path: '/review/home/admin/onboarding',
    parentId: 'review_home',
    superAdmin: true,
    isAccessible: getters => getters.isReviewOnboarding
  },
  {
    id: 'review_shopify_onboard_guide',
    type: 'sub_menu',
    path: '/review/shopify_onboard_guide',
    parentId: 'review',
    isAccessible: getters => getters.isShopifyBrand
  },
  {
    id: 'review_widget_installation_request',
    type: 'sub_menu',
    path: '/review/widget_installation_request',
    parentId: 'review',
    superAdmin: true, // TODO: remove when feature is complete
    isAccessible: getters => getters.isWidgetInstallationRequestMenuAccessible
  },
  {
    id: 'review_main',
    type: 'sub_menu',
    path: '/review/reviews',
    parentId: 'review',
    superAdmin: getters => getters.isReviewOnboardingNotPaid
  },
  {
    id: 'review_reviews',
    type: 'sub_menu',
    path: '/review/reviews',
    parentId: 'review_main'
  },
  {
    id: 'review_products',
    type: 'sub_menu',
    path: '/review/products',
    parentId: 'review_main'
  },
  {
    id: 'review_third_party_review_csvs',
    type: 'sub_menu',
    path: '/review/third_party_review_csvs',
    parentId: 'review_main',
    isAccessible: getters =>
      getters.isShopifyBrand && !getters.isBrandReviewCsvImportEnabled
  },
  {
    id: 'review_bulk_review_csvs',
    type: 'sub_menu',
    path: '/review/bulk_review_csvs',
    parentId: 'review_main',
    superAdmin: getters => !getters.isBrandReviewCsvImportEnabled
  },
  {
    id: 'review_reports',
    type: 'sub_menu',
    path: '/review/reports',
    parentId: 'review',
    superAdmin: getters => getters.isReviewOnboarding
  },
  {
    id: 'review_messages',
    type: 'sub_menu',
    path: getters =>
      getters.isKoreanBrand
        ? '/review/messages/biz_messages'
        : '/review/messages/emails',
    parentId: 'review',
    superAdmin: getters => getters.isReviewOnboarding,
    isAccessible: getters => getters.isReviewMessagesAccessible
  },
  {
    id: 'review_messages_campaigns',
    type: 'sub_menu',
    path: '/review/messages/campaigns',
    parentId: 'review_messages',
    isAccessible: getters => getters.isReviewCampaignEnabled
  },
  {
    id: 'review_messages_biz_messages',
    type: 'sub_menu',
    path: '/review/messages/biz_messages',
    parentId: 'review_messages',
    isAccessible: getters =>
      getters.isReviewMessagesAccessible && getters.isKoreanBrand
  },
  {
    id: 'review_messages_chatbot_messages',
    type: 'sub_menu',
    path: '/review/messages/chatbot_messages/settings',
    parentId: 'review_messages',
    superAdmin: getters => !getters.isChatbotSettingsPartOneEnabled,
    isAccessible: getters =>
      getters.isReviewMessagesAccessible && getters.isKoreanBrand
  },
  {
    id: 'review_messages_sms',
    type: 'sub_menu',
    path: '/review/messages/sms',
    parentId: 'review_messages',
    isAccessible: getters =>
      getters.isReviewMessagesAccessible && getters.isKoreanBrand
  },
  {
    id: 'review_messages_emails',
    type: 'sub_menu',
    path: '/review/messages/emails',
    parentId: 'review_messages',
    isAccessible: getters => getters.isReviewMessagesAccessible
  },
  {
    id: 'review_messages_message_settings',
    type: 'sub_menu',
    path: '/review/messages/settings',
    parentId: 'review_messages',
    isAccessible: getters => getters.isReviewMessagesAccessible
  },
  {
    id: 'review_messages_admin',
    type: 'sub_menu',
    path: '/review/messages/admin/biz_message',
    parentId: 'review_messages',
    superAdmin: true
  },
  {
    id: 'review_settings',
    type: 'sub_menu',
    path: '/review/settings/basic',
    parentId: 'review',
    superAdmin: getters => getters.isReviewSettingsSuperAdmin,
    installationAgency: true,
    isAccessible: getters => getters.isReviewSettingsAccessible
  },
  {
    id: 'review_settings_basic',
    type: 'sub_menu',
    path: '/review/settings/basic',
    parentId: 'review_settings',
    installationAgency: true,
    isAccessible: getters => getters.isReviewSettingsAccessible
  },
  {
    id: 'review_settings_widgets',
    type: 'sub_menu',
    path: '/review/settings/widgets',
    parentId: 'review_settings',
    installationAgency: true
  },
  {
    id: 'review_settings_review_option',
    type: 'sub_menu',
    path: '/review/settings/review_option/category_inclusion',
    parentId: 'review_settings',
    isAccessible: getters => getters.isReviewOptionV2Enabled,
    installationAgency: true
  },
  {
    id: 'review_settings_notice',
    type: 'sub_menu',
    path: '/review/settings/notice',
    parentId: 'review_settings',
    installationAgency: true
  },
  {
    id: 'review_settings_super_admin',
    type: 'sub_menu',
    path: '/review/settings/super-admin',
    parentId: 'review_settings',
    superAdmin: true
  },
  {
    id: 'review_settings_etc',
    type: 'sub_menu',
    path: '/review/settings/etc',
    parentId: 'review_settings',
    superAdmin: true,
    installationAgency: true
  },
  {
    id: 'review_smart_installation',
    type: 'sub_menu',
    path: '/review/smart_installation/widget_management',
    parentId: 'review',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management',
    type: 'sub_menu',
    path: '/review/smart_installation/widget_management',
    parentId: 'review_smart_installation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_etc',
    type: 'sub_menu',
    path: '/review/smart_installation/etc',
    parentId: 'review_smart_installation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_page_url_pattern_management',
    type: 'sub_menu',
    path: '/review/smart_installation/page_url_pattern_management',
    parentId: 'review_smart_installation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters =>
      getters.isUrlPatternMenuAccessible &&
      getters.isSmartInstallationSupported &&
      !getters.isPageUrlPatternV2Enabled
  },
  {
    id: 'review_smart_installation_page_url_pattern_v2_management',
    type: 'sub_menu',
    path: '/review/smart_installation/page_url_pattern_v2_management',
    parentId: 'review_smart_installation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters =>
      getters.isUrlPatternMenuAccessible &&
      getters.isSmartInstallationSupported &&
      getters.isPageUrlPatternV2Enabled
  },
  {
    id: 'review_smart_installation_hide_widget_management',
    type: 'sub_menu',
    path: '/review/smart_installation/hide_widget_management',
    parentId: 'review_smart_installation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_settings_installation',
    type: 'sub_menu',
    path: '/review/smart_installation/settings_installation',
    parentId: 'review_smart_installation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_categories_and_user_grades',
    type: 'sub_menu',
    path: '/review/categories',
    parentId: 'review',
    superAdmin: getters => getters.isReviewOnboarding,
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'review_categories',
    type: 'sub_menu',
    path: '/review/categories',
    parentId: 'review_categories_and_user_grades'
  },
  {
    id: 'review_user_grades',
    type: 'sub_menu',
    path: '/review/user_grades',
    parentId: 'review_categories_and_user_grades'
  },
  {
    id: 'review_move_shop_builder',
    type: 'sub_menu',
    path: '/review/move_shop_builder/basic',
    parentId: 'review',
    superAdmin: true
  },
  {
    id: 'review_import',
    type: 'sub_menu',
    path: '/review/import/smartstore',
    parentId: 'review',
    superAdmin: getters => !getters.isReviewSmartstoreMenuAccessible
  },
  {
    id: 'review_import_smartstore',
    type: 'sub_menu',
    path: '/review/import/smartstore',
    parentId: 'review_import'
  },
  {
    id: 'review_import_kakao_style',
    type: 'sub_menu',
    path: '/review/import/kakao-style',
    parentId: 'review_import'
  },
  {
    id: 'review_import_admin',
    type: 'sub_menu',
    path: '/review/import/admin',
    parentId: 'review_import',
    superAdmin: true
  },
  {
    id: 'review_install_simulation',
    type: 'sub_menu',
    path: '/review/install_simulation/attach_widget',
    parentId: 'review',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported,
    group: 1
  },
  {
    id: 'review_install_simulation_attach_widget',
    type: 'sub_menu',
    path: '/review/install_simulation/attach_widget',
    parentId: 'review_install_simulation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported,
    group: 1
  },
  {
    id: 'review_install_simulation_hide_widget',
    type: 'sub_menu',
    path: '/review/install_simulation/hide_widget',
    parentId: 'review_install_simulation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported,
    group: 1
  },
  {
    id: 'review_install_simulation_etc',
    type: 'sub_menu',
    path: '/review/install_simulation/etc',
    parentId: 'review_install_simulation',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported,
    group: 1
  },
  {
    id: 'review_intro',
    type: 'sub_menu',
    path: '/review/intro',
    parentId: 'review',
    group: 2
  }
];
