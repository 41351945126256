<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    class="RegisterBizMessageProfileDialog"
    :form-props="{
      ...formProps,
      objectId: 'profile',
      submitLabel: $t('app.register'),
      submittingLabel: $t('app.registering')
    }"
    :submit-disabled="isSubmitDisabled"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #group="{ id, inputName, error }">
      <template v-if="id === 'yellow_id'">
        <div class="AppForm__group-field">
          <AppImage
            src="https://assets.cre.ma/m/biz_message_profile_example.png"
          />
          <AppTextInput
            v-model="formObject.yellow_id"
            :maxlength="16"
            :name="inputName"
            :invalid="!!error"
            :placeholder="$t('yellow_id.placeholder')"
            :readonly="isTokenRequested"
            @change="validateField('yellow_id')"
            @blur="validateField('yellow_id')"
          />
        </div>
      </template>
      <template v-else-if="id === 'business_channel'">
        <div class="AppForm__group-field">
          <AppImage
            src="https://assets.cre.ma/m/biz_message_channel_information_example.png"
          />
          <AppCheckbox
            v-model="formObject.business_channel"
            :name="inputName"
            :label="$t('business_channel.checkbox.label')"
            :invalid="!!error"
            @change="validateField('business_channel')"
          />
        </div>
      </template>
      <template v-else-if="id === 'profile_status'">
        <div class="AppForm__group-field">
          <AppImage
            src="https://assets.cre.ma/m/biz_message_profile_status_example.png"
          />
          <AppCheckbox
            v-model="formObject.profile_status"
            :name="inputName"
            :label="$t('profile_status.checkbox.label')"
            :invalid="!!error"
            @change="validateField('profile_status')"
          />
        </div>
      </template>
      <template v-else-if="id === 'phone'">
        <div class="AppForm__group-field">
          <div>
            <AppTextInput
              v-model="formObject.phone"
              class="RegisterBizMessageProfileDialog__phone-input"
              type="tel"
              :placeholder="$t('phone.placeholder')"
              :name="inputName"
              :invalid="!!error"
              :maxlength="11"
              :readonly="isTokenRequested"
              @change="validateField('phone')"
              @blur="validateField('phone')"
            />
            <AppButton
              class="RegisterBizMessageProfileDialog__input-button"
              :label="
                isTokenRequested
                  ? $t('request_token_again_button')
                  : $t('request_token_button')
              "
              :disabled="shouldDisableRequestTokenButton"
              @click="clickRequestToken"
            />
          </div>
          <div v-if="tokenDelaySeconds > 0" class="AppForm__group-description">
            {{ $t('request_token_delay', tokenDelayMinSec) }}
          </div>
        </div>
      </template>
      <template v-else-if="id === 'token'">
        <div
          class="AppForm__group-field RegisterBizMessageProfileDialog__token"
        >
          <div
            v-tooltip="isTokenRequested ? '' : $t('token_tooltip_disabled')"
            class="RegisterBizMessageProfileDialog__token-tooltip"
          >
            <AppTextInput
              v-model="formObject.token"
              class="RegisterBizMessageProfileDialog__phone-input"
              :name="inputName"
              :placeholder="$t('token_placeholder')"
              :maxlength="6"
              :invalid="!!error"
              :disabled="!isTokenRequested"
              @change="validateField('token')"
              @blur="validateField('token')"
            />
          </div>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

const isValidPhoneFormat = v => /^010[0-9]{8}$/.test(v);

export default {
  name: 'RegisterBizMessageProfileDialog',
  mixins: [DialogFormView],
  data() {
    return {
      isLoading: true,
      categories: [],
      isTokenRequested: false,
      tokenDelaySeconds: 0,
      tokenDelayInterval: null,
      isYellowIdValid: true,
      isProfileStatusValid: true,
      isBusinessChannelValid: true,
      isTokenValid: true,
      isPhoneNumberValid: true
    };
  },
  computed: {
    ...mapState('bizMessage', ['bizMessageProfile']),
    ...mapGetters('session', ['isDispatchMethodBizMessageOnlyEnabled']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'tip',
              type: 'tip_box',
              label: null,
              value: this.$t('tip_html')
            },
            {
              id: 'yellow_id',
              label: this.$t('yellow_id.label'),
              groupDescription: {
                message: this.$t('yellow_id.group_description'),
                mode: 'markdown'
              },
              validate: [
                'required',
                {
                  rule: () => this.isYellowIdValid,
                  errorMessage: this.$t('yellow_id.error')
                }
              ]
            },
            ...(this.isDispatchMethodBizMessageOnlyEnabled
              ? [
                  {
                    id: 'business_channel',
                    label: this.$t('business_channel.label'),
                    groupDescription: {
                      message: this.$t('business_channel.group_description'),
                      mode: 'markdown'
                    },
                    validate: [
                      'required',
                      {
                        rule: () => this.isBusinessChannelValid,
                        errorMessage: this.$t('business_channel.error')
                      }
                    ]
                  },
                  {
                    id: 'profile_status',
                    label: this.$t('profile_status.label'),
                    groupDescription: {
                      message: this.$t('profile_status.group_description'),
                      mode: 'markdown'
                    },
                    validate: [
                      'required',
                      {
                        rule: () => this.isProfileStatusValid,
                        errorMessage: this.$t('profile_status.error')
                      }
                    ]
                  }
                ]
              : [
                  {
                    id: 'category_code',
                    label: this.$t('category_code.label'),
                    type: 'select_filterable',
                    validate: ['required'],
                    placeholder: this.$t('category_code.placeholder'),
                    groupDescription: this.$t(
                      'category_code.group_description'
                    ),
                    options: this.categoryOptions
                  }
                ]),
            {
              id: 'phone',
              label: this.$t('phone.label'),
              groupDescription: {
                message: this.$t('phone.group_description'),
                mode: 'markdown'
              },
              validate: [
                'required',
                {
                  rule: isValidPhoneFormat,
                  errorMessage: this.$t('phone.invalid_format')
                },
                {
                  rule: () => this.isPhoneNumberValid,
                  errorMessage: this.$t('phone.invalid_number')
                }
              ]
            },
            {
              id: 'token',
              label: null,
              validate: [
                'required',
                {
                  rule: v => /^[0-9]{6}$/.test(v),
                  errorMessage: this.$t('token_invalid_format')
                },
                {
                  rule: () => this.isTokenValid,
                  errorMessage: this.$t('token.incorrect_error')
                }
              ]
            }
          ].filter(v => v)
        }
      ];
    },
    categoryOptions() {
      return this.categories.map(e => ({ label: e.name, value: e.code }));
    },
    tokenDelayMinSec() {
      const min = Math.floor(this.tokenDelaySeconds / 60);
      const sec = this.tokenDelaySeconds - min * 60;
      return [min, sec];
    },
    shouldDisableRequestTokenButton() {
      const { yellow_id, phone } = this.formObject;

      const isRequestInProgress = this.tokenDelaySeconds > 0;
      if (isRequestInProgress) return true;

      const isValidYellowId = !!yellow_id;
      if (!isValidYellowId) return true;

      const isValidPhone = !!phone && isValidPhoneFormat(phone);
      if (!isValidPhone) return true;

      return false;
    },
    isSubmitDisabled() {
      if (!this.isDispatchMethodBizMessageOnlyEnabled) return false;

      return !(
        this.formObject.yellow_id &&
        this.formObject.business_channel &&
        this.formObject.profile_status &&
        this.formObject.phone &&
        this.formObject.token
      );
    }
  },
  watch: {
    'formObject.yellow_id'() {
      const { yellow_id } = this.formObject;
      if (!yellow_id) return;

      this.isYellowIdValid = true;

      this.$nextTick(() => {
        if (yellow_id.split('')[0] != '@')
          this.formObject.yellow_id = `@${yellow_id}`;
      });
    },
    'formObject.profile_status'() {
      this.isProfileStatusValid = true;
    },
    'formObject.business_channel'() {
      this.isBusinessChannelValid = true;
    },
    'formObject.token'() {
      this.isTokenValid = true;
    },
    'formObject.phone'() {
      this.isPhoneNumberValid = true;
    }
  },
  mounted() {
    this.orgFormObject = {
      yellow_id: '',
      category_code: '',
      phone: '',
      token: ''
    };
    this.isLoading = true;
    api
      .get('/biz_message/profile/new')
      .then(({ data }) => {
        this.categories = data.categories;
        this.startTokenDelayTimer(data.token_request_delay);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('bizMessage', ['createProfile']),
    ...mapActions('dialog', ['alert']),
    clickRequestToken() {
      api
        .post(
          '/biz_message/tokens',
          {
            yellow_id: this.formObject.yellow_id,
            phone: this.formObject.phone
          },
          { silent: true }
        )
        .then(() => this.startTokenRequest())
        .catch(error => {
          if (!this.isDispatchMethodBizMessageOnlyEnabled)
            return error.errorHandler();

          switch (error.response.data.errors[0]) {
            case this.$t('biz_message_api_error_message.yellow_id_not_found'):
              this.isYellowIdValid = false;
              this.validateField('yellow_id');
              break;
            case this.$t(
              'biz_message_api_error_message.profile_status_invalid'
            ):
              this.isProfileStatusValid = false;
              this.validateField('profile_status');
              this.formObject.profile_status = false;
              break;
            case this.$t('biz_message_api_error_message.invalid_phone_number'):
              this.isPhoneNumberValid = false;
              this.validateField('phone');
              break;
            default:
              error.errorHandler();
          }
        });
    },
    startTokenRequest() {
      this.isTokenRequested = true;
      this.startTokenDelayTimer(180);
    },
    startTokenDelayTimer(delay) {
      if (delay <= 0) return;

      this.tokenDelaySeconds = delay;
      this.tokenDelayInterval = setInterval(() => {
        this.tokenDelaySeconds -= 1;
        if (this.tokenDelaySeconds === 0) {
          clearInterval(this.tokenDelayInterval);
          this.tokenDelayInterval = null;
        }
      }, 1000);
    },
    submit(formData) {
      this.isSubmitting = true;
      return this.createProfile({
        data: formData,
        successMessage: this.$t('profile_registered')
      })
        .then(() => {
          this.close(true);
          if (!this.bizMessageProfile.grouped)
            this.alert({
              title: this.$t('biz_message_profile_not_grouped.title'),
              message: this.$t('biz_message_profile_not_grouped.markdown_text')
            });
        })
        .catch(error => {
          if (!this.isDispatchMethodBizMessageOnlyEnabled)
            return error.errorHandler();

          const errorMessage = error.response.data.errors[0];
          switch (errorMessage) {
            case this.$t(
              'biz_message_api_error_message.business_channel_required'
            ):
              this.isBusinessChannelValid = false;
              this.validateField('business_channel');
              this.formObject.business_channel = false;
              break;
            case this.$t('biz_message_api_error_message.incorrect_token'):
              this.isTokenValid = false;
              this.validateField('token');
              break;
            default:
              this.alert({
                title: this.$t('create_biz_message_profile_failed.title'),
                message: errorMessage
              });
          }
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_breakpoints.scss';

.RegisterBizMessageProfileDialog__phone-input {
  width: 206px;

  @include media-breakpoint-each(mobile) {
    width: 100%;
  }
}

.RegisterBizMessageProfileDialog__token {
  margin-top: -16px;
}

.RegisterBizMessageProfileDialog__token-tooltip {
  display: inline-block;
}

.RegisterBizMessageProfileDialog__input-button {
  margin-left: 8px;
  vertical-align: baseline;

  @include media-breakpoint-each(mobile) {
    margin-left: 0;
    margin-top: 4px;
    width: 100%;
  }
}
</style>

<i18n locale="ko">
{
  "title": "프로필 등록",
  "subtitle": "알림톡 프로필 관리",
  "tip_html": "프로필을 등록하면 알림톡이 자동으로 발송됩니다.<br />알림톡 메시지는 프로필 등록 이후 ‘리뷰 작성 유도 메시지 설정’을 통해 확인할 수 있습니다.",
  "yellow_id": {
    "label": "카카오톡 채널",
    "group_description": "@가 붙은 검색용 아이디를 입력해주세요. (확인경로: [카카오톡 채널 관리자 센터 > 내 채널](https://center-pf.kakao.com/profiles))",
    "placeholder": "예: @crema",
    "error": "존재하지 않는 카카오톡 채널입니다."
  },
  "category_code": {
    "label": "카테고리 선택",
    "placeholder": "카테고리를 선택해주세요.",
    "group_description": "카카오톡 채널에 맞는 적절한 카테고리를 선택해주세요."
  },
  "business_channel": {
    "label": "비즈니스 채널 여부 확인",
    "group_description": "등록한 채널이 비즈니스 채널인지 확인해주세요. (확인경로: [카카오톡 채널 관리자 센터 > 관리 > 비즈니스 채널 신청](https://center-pf.kakao.com/))<br />비즈니스 채널 전환은 카카오톡 채널 계정이 마스터 권한일 경우에만 신청가능합니다.",
    "error": "비즈니스 채널 전환이 필요합니다.",
    "checkbox": {
      "label": "비즈니스 채널입니다."
    }
  },
  "profile_status": {
    "label": "카카오톡 채널 프로필 설정값 확인",
    "group_description": "프로필 설정의 채널공개 설정값이 ON 으로 되어있는지 확인해주세요. (확인경로: [카카오톡 채널 관리자 센터 > 내 채널](https://business.kakao.com/dashboard/))",
    "checkbox": {
      "label": "프로필 설정의 채널공개 설정값이 ON 입니다."
    },
    "error": "채널 공개 설정값이 ON으로 되어 있어야 합니다."
  },
  "phone": {
    "label": "관리자 휴대폰번호",
    "group_description": "카카오톡 채널에 등록된 연락처를 입력하여 인증을 진행해주세요. (확인경로: [카카오톡 채널 관리자 센터 > 내 정보](https://center-pf.kakao.com/settings/account))",
    "placeholder": "- 없이 입력",
    "invalid_format": "잘못된 형식의 휴대폰 번호입니다.",
    "invalid_number": "카카오톡 채널 관리자와 같은 번호를 입력해야합니다."
  },
  "token": {
    "incorrect_error": "인증번호 확인 후 다시 입력해주세요."
  },
  "request_token_button": "인증번호 전송",
  "request_token_again_button": "인증번호 재전송",
  "request_token_delay": "{0}분 {1}초 뒤에 인증번호를 다시 보낼 수 있습니다.",
  "token_placeholder": "인증번호 (6자리 숫자)",
  "token_tooltip_disabled": "인증번호 전송 후 입력이 가능합니다.",
  "token_invalid_format": "잘못된 형식의 인증번호입니다.",
  "profile_registered": "프로필이 등록되었습니다.",
  "biz_message_profile_not_grouped": {
    "title": "프로필 등록 중 오류가 발생했습니다.",
    "markdown_text": "오류 해결까지 하루 정도 소요됩니다.<br />잠시 후 그룹 프로필 등록을 시도하거나, 오류 해결을 위해 기다려주세요."
  },
  "biz_message_api_error_message": {
    "yellow_id_not_found": "존재하지 않는 카카오톡 채널입니다.",
    "profile_status_invalid": "카카오톡 채널 상태를 확인 해주세요.",
    "business_channel_required": "비즈니스 채널 전환이 필요한 프로필입니다.",
    "incorrect_token": "유효하지 않은 인증번호 입니다.",
    "invalid_phone_number": "요청한 번호가 카카오톡 채널 관리자 알림 설정 되어있는지 확인해주세요."
  },
  "create_biz_message_profile_failed": {
    "title": "프로필을 등록할 수 없습니다."
  }
}
</i18n>
