var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('dialog_title'),"is-maximized-by-default":"","is-max-height":""},on:{"close":_vm.clickCloseButton},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__containter"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-title"},[_vm._v(" "+_vm._s(_vm.$t('title.optional_widgets'))+" ")]),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-content"},[_c('AppInfoBox',{staticClass:"ReviewDashboardRequestInstallationDialog__info-box"},[_vm._v(" "+_vm._s(_vm.$t('info_content'))+" ")]),_vm._l((_vm.requestsOnPageType),function(request,i){return _c('div',{key:i,staticClass:"ReviewDashboardRequestInstallationDialog__image-table"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__image"},[_c('AppImage',{attrs:{"src":_vm.imageUrl[request.page_type]}})],1),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__table"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__table-topbar"},[_c('div',[_c('span',{staticClass:"ReviewDashboardRequestInstallationDialog__table-topbar__table-name"},[_vm._v(" "+_vm._s(_vm.WidgetPageType.t(request.page_type))+" ")]),_c('AppBadge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                      _vm.$t(
                        ("table.tooltip." + (_vm.WidgetPageType.key(
                          request.page_type
                        ).toLowerCase()))
                      )
                    ),expression:"\n                      $t(\n                        `table.tooltip.${WidgetPageType.key(\n                          request.page_type\n                        ).toLowerCase()}`\n                      )\n                    "}],staticClass:"ReviewDashboardRequestInstallationDialog__table-topbar-badge",attrs:{"label":_vm.badgeLabel(request.page_type),"badge-style":_vm.badgeStyle(request.page_type)}})],1),_c('div',[_c('AppButton',{attrs:{"label":_vm.$t('table.buttons.select_widget'),"button-style":'blue'},on:{"click":function($event){return _vm.openSelectWidgetFormDialog(request.page_type)}}})],1)]),(request.rows.length)?_c('AppTable',{attrs:{"table-id":"request-installation-table","columns":_vm.pageTypeColumns,"rows":request.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                    var row = ref.row;
                    var column = ref.column;
return [(column.id === 'preview')?[_c('AppButton',{attrs:{"label":"PC","disabled":_vm.isCustomPlacement(row.presetPlacementType)},on:{"click":function($event){return _vm.openWidgetSetPreviewDialog(
                          false,
                          row.pageType,
                          row.presetPlacementType,
                          row.presetWidgetType
                        )}}}),_c('AppButton',{attrs:{"label":"모바일","disabled":_vm.isCustomPlacement(row.presetPlacementType)},on:{"click":function($event){return _vm.openWidgetSetPreviewDialog(
                          true,
                          row.pageType,
                          row.presetPlacementType,
                          row.presetWidgetType
                        )}}})]:_vm._e()]}}],null,true)}):_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__reminder"},[_vm._v(" "+_vm._s(_vm.$t('table.reminder.select_widget'))+" ")])],1)])})],2)]),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-title"},[_vm._v(" "+_vm._s(_vm.$t('title.count_and_score_widgets'))+" ")]),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-content"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__image-table"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__image"},[_c('AppImage',{attrs:{"src":_vm.imageUrl[
                    _vm.InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                  ]}})],1),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__table"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__table-topbar"},[_c('div',[_c('span',{staticClass:"ReviewDashboardRequestInstallationDialog__table-topbar__table-name"},[_vm._v(" "+_vm._s(_vm.InstallationPresetWidgetType.t( _vm.InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET ))+" ")]),_c('AppBadge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('table.tooltip.count_and_score_widget')),expression:"$t('table.tooltip.count_and_score_widget')"}],staticClass:"ReviewDashboardRequestInstallationDialog__table-topbar-badge",attrs:{"label":_vm.badgeLabel(
                        _vm.InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                      ),"badge-style":_vm.badgeStyle(
                        _vm.InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
                      )}})],1),_c('AppButton',{attrs:{"label":_vm.$t('table.buttons.select_attach_type'),"button-style":'blue'},on:{"click":function($event){return _vm.openSelectCountAndScoreWidgetFormDialog()}}})],1),(_vm.countAndScoreWidgetRows.length)?_c('AppTable',{attrs:{"table-id":"count-and-score-widget-request-installation-table","columns":_vm.countAndScoreWidgetColumns,"rows":_vm.countAndScoreWidgetRows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                      var row = ref.row;
                      var column = ref.column;
return [(column.id === 'preview')?[(
                        row.attach_type ===
                          _vm.InstallationPresetAttachType.REPLACE
                      )?[_vm._v(" - ")]:[_c('AppButton',{attrs:{"label":_vm.$t('app.pc')},on:{"click":function($event){return _vm.openWidgetSetPreviewDialog(
                            false,
                            row.pageType,
                            row.presetPlacementType,
                            row.presetWidgetType,
                            row.displayFormat
                          )}}}),_c('AppButton',{attrs:{"label":_vm.$t('app.mobile')},on:{"click":function($event){return _vm.openWidgetSetPreviewDialog(
                            true,
                            row.pageType,
                            row.presetPlacementType,
                            row.presetWidgetType,
                            row.displayFormat
                          )}}})]]:_vm._e()]}}],null,false,82444720)}):_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__reminder"},[_vm._v(" "+_vm._s(_vm.$t('table.reminder.select_attach_type'))+" ")])],1)])])]),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-title"},[_vm._v(" "+_vm._s(_vm.$t('title.required_widgets'))+" ")]),_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-content"},[_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__section-content--required-widgets"},_vm._l((_vm.requiredWidgets),function(requiredWidget){return _c('div',{key:requiredWidget.label,staticClass:"ReviewDashboardRequestInstallationDialog__required-widget"},[_c('div',[_c('span',{staticClass:"ReviewDashboardRequestInstallationDialog__required-widget-label"},[_vm._v(" "+_vm._s(requiredWidget.label)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('install_position', [ requiredWidget.presetPlacementTypeText ]))+" ")])]),_c('div',[_c('AppInlineButton',{staticClass:"ReviewDashboardRequestInstallationDialog__required-widget-detail-button",attrs:{"label":_vm.$t('preview.pc'),"button-style":"caption"},on:{"click":function($event){return _vm.openWidgetPreviewDialog(
                      false,
                      requiredWidget.presetWidgetType
                    )}}}),_c('AppInlineButton',{staticClass:"ReviewDashboardRequestInstallationDialog__required-widget-detail-button",attrs:{"label":_vm.$t('preview.mobile'),"button-style":"caption"},on:{"click":function($event){return _vm.openWidgetPreviewDialog(
                      true,
                      requiredWidget.presetWidgetType
                    )}}})],1)])}),0)])])])]},proxy:true},{key:"foot",fn:function(){return [_c('div',{staticClass:"ReviewDashboardRequestInstallationDialog__buttons"},[_c('AppButton',{attrs:{"button-style":"blue","label":_vm.$t('submit_label'),"disabled":_vm.submitButtonDisabled},on:{"click":_vm.submit}}),_c('AppButton',{attrs:{"button-style":"default","label":_vm.$t('close.label')},on:{"click":_vm.clickCloseButton}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }