export default {
  formSections() {
    return {
      display_reviews: ['tag_id', 'exclude_tag_id'],
      common: ['posts_per_page', 'no_item_action'],
      feature: [],
      design: ['photo_thumbnail_color']
    };
  },
  widgetStyles() {
    return [];
  },
  displayOrderOptions() {
    return [];
  },
  showHeaderSectionSubSettings() {
    return [];
  },
  useBackgroundColor() {
    return true;
  }
};
