import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import { mapState } from 'vuex';
import parseSkinPath from '@/lib/parseSkinPath';
import WidgetType from '@/enums/WidgetType';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetInsertType from '@/enums/WidgetInsertType';
import SmartInstallationType from '@/enums/SmartInstallationType';

export default {
  mixins: [DialogFormView],
  data() {
    return {
      isLoading: true,
      installation_type: SmartInstallationType.ATTACH_WIDGET,
      default_position: null,
      default_selector: null,
      default_placements: null
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    WidgetInsertType: () => WidgetInsertType,
    formSections() {
      return [
        {
          id: 'default',
          groups: this.groupIds.map(id => {
            return {
              id,
              ...this.groupOptions[id]
            };
          })
        }
      ];
    },
    baseGroupIds() {
      const pageType = this.formObject.page_type;
      const groupIds = ['page_type'];
      switch (pageType) {
        case null:
        case undefined:
          break;
        case WidgetPageType.PRODUCT_DETAIL:
          groupIds.push('custom_installed');
          if (this.formObject.custom_installed)
            groupIds.push('install_position_specific');
          if (this.widget.widget_style === WidgetStyle.COUNT_AND_SCORE)
            groupIds.push('skip_product_no_from_url');
          break;
        case WidgetPageType.SPECIFIC:
          groupIds.push('page_path', 'install_position_specific');
          break;
        default:
          if (this.formObject.custom_installed)
            groupIds.push('custom_installed', 'install_position_specific');
          else groupIds.push('custom_installed');
          break;
      }

      return groupIds.concat(['skin_id']);
    },
    baseGroupOptions() {
      return {
        page_type: {
          label: this.$t('smart_installations.page_type.label'),
          type: 'select',
          placeholder: this.$t('smart_installations.page_type.placeholder'),
          options: this.pageTypeOptions,
          validate: ['required'],
          required: true
        },
        page_path: {
          label: this.$t('smart_installations.page_path.label'),
          type: 'text',
          groupTooltip: this.$t('smart_installations.page_path.tooltip'),
          placeholder: this.$t('smart_installations.page_path.placeholder'),
          validate: [
            'required',
            {
              rule: 'check_path_or_url',
              errorMessage: this.$t('smart_installations.page_path.error')
            }
          ],
          required: true
        },
        custom_installed: {
          label: this.$t('smart_installations.placement.label'),
          type: 'select',
          placeholder: this.$t('smart_installations.placement.placeholder'),
          hint: this.placementHint,
          options: [false, true].map(value => ({
            label: this.$t(`smart_installations.custom_installed.${value}`),
            value
          })),
          required: true,
          validate: [
            'required',
            {
              rule: v => v || !!this.default_selector,
              errorMessage: this.$t(
                this.isLoading
                  ? 'smart_installations.placement.validating'
                  : 'smart_installations.placement.error'
              )
            }
          ]
        },
        skin_id: {
          groupDescription: this.$t('smart_installations.skin.description'),
          label: this.$t('smart_installations.skin.label'),
          type: 'text',
          placeholder: this.$t('smart_installations.skin.placeholder'),
          validate:
            this.currentBrand.shop_builder === 'cafe24'
              ? [
                  {
                    rule: 'check_skin_id',
                    errorMessage: this.$t('smart_installations.skin.error')
                  }
                ]
              : []
        },
        install_position_specific: {
          label: this.$t('smart_installations.placement.specific.label'),
          type: 'text',
          value: this.formObject.element_selector,
          validate: [
            'required',
            {
              rule: 'css_selector_format',
              errorMessage: this.$t('validations.selector_format')
            }
          ]
        },
        skip_product_no_from_url: {
          label: this.$t('smart_installations.skip_product_no_from_url.label'),
          type: 'checkbox',
          value: this.formObject.skip_product_no_from_url
        }
      };
    },
    basePageTypeOptions() {
      let widgetPageTypes = [];

      if (this.widget.widget_style === WidgetStyle.MANAGING_REVIEWS) {
        widgetPageTypes = [
          WidgetPageType.MY_ORDERS,
          WidgetPageType.MY_REVIEWS,
          WidgetPageType.MYSHOP_MAIN,
          WidgetPageType.SPECIFIC
        ];
      } else if (this.productDetailPageOnly) {
        widgetPageTypes = [WidgetPageType.PRODUCT_DETAIL];
      } else if (this.categoryPageOnly) {
        widgetPageTypes = [WidgetPageType.PRODUCT_LIST];
      } else if (this.widget.widget_style === WidgetStyle.COUNT_AND_SCORE) {
        widgetPageTypes = [
          WidgetPageType.PRODUCT_DETAIL,
          WidgetPageType.MAIN,
          WidgetPageType.PRODUCT_LIST,
          WidgetPageType.PRODUCT_SEARCH,
          WidgetPageType.EVENT,
          WidgetPageType.SPECIFIC
        ];
      } else {
        widgetPageTypes = [
          WidgetPageType.MAIN,
          WidgetPageType.PRODUCT_LIST,
          WidgetPageType.REVIEW_BOARD,
          WidgetPageType.PRODUCT_DETAIL,
          WidgetPageType.EVENT,
          WidgetPageType.SPECIFIC
        ];
      }

      return WidgetPageType.options(widgetPageTypes);
    },
    productDetailPageOnly() {
      return [WidgetType.PRODUCT_REVIEWS, WidgetType.PRODUCT_SCORE].includes(
        this.widget.widget_type
      );
    },
    categoryPageOnly() {
      return this.widget.widget_type === WidgetType.CATEGORY_REVIEWS;
    },
    placementHint() {
      if (!this.isDefaultPlacementNeeded) return '';

      const hint = [];
      if (this.default_selector || this.formObject.element_selector)
        hint.push(this.matchingDefaultPlacementHint);
      if (this.default_placements)
        hint.push(this.candidateDefaultPlacementHint);

      return hint.join('\n');
    },
    matchingDefaultPlacementHint() {
      return this.$t('smart_installations.placement.matching_hint', {
        default_selector:
          this.default_selector || this.formObject.element_selector,
        default_position:
          WidgetInsertType.toKey(this.default_position) ||
          WidgetInsertType.toKey(this.formObject.install_position)
      });
    },
    candidateDefaultPlacementHint() {
      return this.$t('smart_installations.placement.candidates_hint', {
        widget_type: WidgetType.t(this.widget.widget_type),
        widget_style_wrap:
          this.widget.widget_type == WidgetType.PRODUCT_SCORE
            ? ''
            : this.$t('smart_installations.placement.widget_style_wrap', {
                widget_style: WidgetStyle.t(this.widget.widget_style)
              }),
        default_placements: this.defaultPlacementsString
      });
    },
    defaultPlacementsString() {
      return this.default_placements
        .map(
          placement =>
            `${placement.css_selector} ${WidgetInsertType.toKey(
              placement.insert_type
            )}`
        )
        .join('<br />');
    },
    isDefaultPlacementNeeded() {
      return (
        this.formObject.custom_installed === false &&
        this.formObject.page_type !== WidgetPageType.SPECIFIC &&
        _.isNil(this.formObject.page_type) === false
      );
    }
  },
  watch: {
    default_selector() {
      this.validateField('custom_installed');
    }
  },
  methods: {
    appendDefaultPlacement(formData) {
      const { custom_installed, page_type } = this.formObject;

      if (custom_installed !== false || page_type === WidgetPageType.SPECIFIC)
        return;

      formData.append(
        'smart_installation[element_selector]',
        this.default_selector
      );
      formData.append(
        'smart_installation[install_position]',
        this.default_position
      );
    },
    formGroupChanged({ id }) {
      if (id === 'select_widget') {
        this.widgetId = this.formObject.select_widget;
      }

      if (this.isCustomPositionNeeded(id)) {
        this.formObject.install_position = WidgetInsertType.AFTER;
      } else if (this.isDefaultPlacementNeeded)
        this.fetchDefaultPostionAndSelector();
    },
    formGroupBlurred(id) {
      if (id === 'page_path') {
        const [newPagePath, newSkinId] = parseSkinPath(
          this.formObject.page_path
        );
        this.formObject.page_path = newPagePath;
        if (newSkinId) this.formObject.skin_id = newSkinId;
      }
    },
    isCustomPositionNeeded(id) {
      return (
        (id === 'custom_installed' && this.formObject.custom_installed) ||
        (id === 'page_type' &&
          this.formObject.page_type === WidgetPageType.SPECIFIC)
      );
    },
    fetchDefaultPostionAndSelector() {
      this.isLoading = true;
      api
        .get('/smart_installation/default_placements', {
          params: {
            widget_id: this.widgetId,
            page_type: this.formObject.page_type
          }
        })
        .then(({ data }) => {
          this.default_placements = data.default_placements;
          this.default_position = data.default_position;
          this.default_selector = data.default_selector;
        })
        .catch(() => {
          this.default_placements = null;
          this.default_position = null;
          this.default_selector = null;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
