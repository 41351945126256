<template>
  <div>
    <AppDropdownMenuButton
      v-if="showDropDownButton"
      :label="$t('app.manage')"
      :menu-items="menuItems"
    />
    <div v-else>-</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import CremaServiceType from '@/enums/CremaServiceType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';

export default {
  name: 'ReviewMessageBizMessageTemplateManageCell',
  props: { template: { type: Object, required: true } },
  computed: {
    showDropDownButton() {
      return (
        this.template.bizMessageTemplateType !== BizMessageTemplateType.GROUP &&
        this.template.inspection_status !==
          BizMessageTemplateInspectionStatus.APPROVED
      );
    },
    menuItems() {
      return [
        {
          label: this.$t('app.edit'),
          clickHandler: ({ close }) => {
            this.clickEditTemplate();
            close();
          }
        },
        {
          label: this.$t('app.delete'),
          style: 'danger',
          clickHandler: ({ close }) => {
            this.clickDeleteTemplate();
            close();
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('bizMessageTemplate', ['deleteTemplate']),
    clickEditTemplate() {
      this.openDialog([
        'BizMessageTemplateFormDialog',
        {
          serviceType: CremaServiceType.REVIEW,
          templateId: this.template.id,
          inspectDirectly: true
        }
      ]);
    },
    clickDeleteTemplate() {
      this.confirm({
        type: 'delete_confirm',
        title: this.$t('delete_confirm_title'),
        message: this.$t('delete_confirm_message')
      }).then(result => {
        if (result) this.deleteTemplate(this.template.id);
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "delete_confirm_title": "이 템플릿을 삭제하시겠습니까?",
  "delete_confirm_message": "삭제된 템플릿은 복구가 어렵습니다."
}
</i18n>
