export default {
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, default: null },
    width: { type: String, default: undefined },
    formProps: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
    isMaxHeight: { type: Boolean, default: false },
    canMaximize: { type: Boolean, default: false },
    isMaximizedByDefault: { type: Boolean, default: false }
  }
};
