<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    :form-props="{
      ...formProps,
      focusGroupId: 'phone',
      submitLabel: $t('submit_label'),
      submittingLabel: $t('submitting_label'),
      closeLabel: $t('close_label')
    }"
    v-on="formEvents"
  />
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ChatbotMessageTestMessageDialog',
  mixins: [DialogFormView],
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'phone',
              type: 'text',
              label: this.$t('phone'),
              placeholder: this.$t('phone_placeholder'),
              groupDescription: this.$t('phone_description'),
              validate: [
                'required',
                {
                  rule: v => /^\d+$/.test(v) && v.length === 11,
                  errorMessage: this.$t('phone_validation_message')
                }
              ]
            }
          ]
        }
      ];
    }
  },
  watch: {
    'formObject.phone'() {
      if (this.formObject.phone?.includes('-')) {
        this.formObject.phone = this.formObject.phone.replaceAll('-', '');
      }
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('review/chatbot_messages/send_sample', formData, {
          successMessage: this.$t('success_message')
        })
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "테스트 메시지 발송",
  "subtitle": "챗봇",
  "submit_label": "전송",
  "submitting_label": "전송 중..",
  "close_label": "취소",
  "phone": "핸드폰 번호",
  "phone_placeholder": "예: 01012345678",
  "phone_description": "테스트 챗봇 메시지를 받을 번호를 '-' 없이 입력해주세요.",
  "phone_validation_message": "올바른 형식이 아닙니다. 입력된 번호가 맞는지 다시 확인해주세요.",
  "success_message": "테스트 챗봇 메시지가 발송됐습니다."
}
</i18n>
