<template>
  <Component :is="group.i18n ? 'i18n' : 'div'" :path="group.i18n">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="group.type === 'static'" v-html="group.value" />
    <template v-else-if="group.type === 'static_image'">
      <div class="AppFormField__static-image">
        <AppImage :src="group.value" />
      </div>
    </template>
    <ul v-else-if="group.type === 'list'" class="app-list">
      <li v-for="(v, i) in group.value" :key="i" class="app-list__item">
        {{ v }}
      </li>
    </ul>
    <AppAgreementCheckbox
      v-else-if="group.type === 'agreement_checkbox'"
      :checked="group.value"
      :label="group.label"
      :terms-link="group.termsLink"
      @change="change"
    />
    <AppButton
      v-else-if="group.type === 'button'"
      :label="group.value"
      :button-style="group.buttonStyle"
      :tooltip="group.buttonTooltip"
      :disabled="group.disabled"
      @click="group.clickHandler"
    />
    <AppButtonDownload
      v-else-if="group.type === 'button_download'"
      :label="group.value"
      :disabled="group.disabled"
      :url="group.url"
      :filename="group.filename"
    />
    <AppCopyableTextarea
      v-else-if="group.type === 'copyable_textarea'"
      :value="group.value"
      :disabled="group.disabled"
    />
    <AppTextInput
      v-else-if="['text', 'url', 'email', 'tel'].includes(group.type)"
      v-bind="inputProps"
      :maxlength="group.maxlength"
      :type="group.type"
      :input-size="inputSize"
      :autocomplete="group.autocomplete"
      @blur="blur"
      @change="change"
    />
    <AppNumberInput
      v-else-if="group.type === 'number'"
      v-bind="inputProps"
      :digits="group.digits"
      :default="group.default"
      :allow-negative="group.allowNegative"
      :allow-decimal="group.allowDecimal"
      :inline="group.inline"
      @blur="blur"
      @change="change"
    />
    <AppPasswordInput
      v-else-if="group.type === 'password'"
      v-bind="inputProps"
      :type="group.type"
      :input-size="inputSize"
      :autocomplete="group.autocomplete"
      @blur="blur"
      @change="change"
    />
    <AppNewPasswordInput
      v-else-if="group.type === 'new_password'"
      v-bind="inputProps"
      :password-error="errors[`${group.id}[password]`]"
      :password-confirmation-error="
        errors[`${group.id}[password_confirmation]`]
      "
      :input-size="inputSize"
      @validate="$emit('validate', `${group.id}[${$event}]`)"
      @change="change"
    />
    <AppNewPasswordRequirements
      v-else-if="group.type === 'new_password_requirements'"
      :password="group.value"
      :disabled="group.disabled"
    />
    <AppCurrencyInput
      v-else-if="group.type === 'currency'"
      v-bind="inputProps"
      :digits="group.digits"
      :default="group.default"
      :allow-negative="group.allowNegative"
      @blur="blur"
      @change="change"
    />
    <AppDatePicker
      v-else-if="group.type === 'date'"
      v-bind="inputProps"
      :min-date="group.minDate"
      :max-date="group.maxDate"
      @change="change"
    />
    <AppFileInput
      v-else-if="group.type === 'file'"
      v-bind="inputProps"
      :accept="group.accept"
      @change="change"
    />
    <AppImageInput
      v-else-if="group.type === 'image'"
      v-bind="inputProps"
      :image-url="group.value"
      :not-removable="group.notRemovable"
      @change="change"
    />
    <AppKeywordTextarea
      v-else-if="group.type === 'keyword_textarea'"
      v-bind="inputProps"
      :keywords="group.keywords"
      :maxlength="group.maxlength"
      :max-bytes="group.maxBytes"
      :single-line="group.singleLine"
      @blur="blur"
      @change="change"
    />
    <AppSelect
      v-else-if="group.type === 'select'"
      v-bind="inputProps"
      :options="group.options"
      @change="change"
    />
    <AppSelectButton
      v-else-if="group.type === 'select_button'"
      v-bind="inputProps"
      :options="group.options"
      @change="change"
    />
    <AppSelectHour
      v-else-if="group.type === 'select_hour'"
      v-bind="inputProps"
      :selectable-hours="group.selectableHours"
      @change="change"
    />
    <AppSelectRadio
      v-else-if="group.type === 'select_radio'"
      v-bind="inputProps"
      :options="group.options"
      :cols="group.cols"
      @change="change"
    >
      <template v-if="$scopedSlots['label']" #label="props">
        <slot name="label" v-bind="props" :group="group" :errors="errors" />
      </template>
      <template v-if="$scopedSlots['sub-item']" #sub-item="props">
        <slot name="sub-item" v-bind="props" :group="group" :errors="errors" />
      </template>
    </AppSelectRadio>
    <AppSelectFilterable
      v-else-if="group.type === 'select_filterable'"
      v-bind="inputProps"
      :options="group.options"
      :width="group.width"
      @change="change"
    />
    <AppSelectProduct
      v-else-if="group.type === 'select_product'"
      v-bind="inputProps"
      :title="group.title"
      :products="group.products"
      :placeholder="group.placeholder"
      :multiple="group.multiple"
      :sort="group.sort"
      :default-filters="group.defaultFilters"
      @change="change"
      v-on="group.eventHandlers"
    />
    <AppSelectProductCategory
      v-else-if="group.type === 'select_product_category'"
      v-bind="inputProps"
      :multiple="group.multiple"
      @change="change"
    />
    <AppHashSelectCheckbox
      v-else-if="group.type === 'hash_select_checkbox'"
      v-bind="inputProps"
      :options="group.options"
      :hash="group.value"
      @change="change"
    >
      <template v-if="$scopedSlots['label']" #label="props">
        <slot name="label" v-bind="props" :group="group" :errors="errors" />
      </template>
      <template v-if="$scopedSlots['sub-item']" #sub-item="props">
        <slot name="sub-item" v-bind="props" :group="group" :errors="errors" />
      </template>
    </AppHashSelectCheckbox>
    <AppHashSelectButton
      v-else-if="group.type === 'hash_select_button'"
      v-bind="inputProps"
      :select-buttons="group.selectButtons"
      :hash="group.value"
      @change="change"
    />
    <AppInfoBox
      v-else-if="group.type === 'infobox'"
      :hide-type="group.hideType"
      :info-box-id="group.infoBoxId"
    >
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="group.value" />
      <!-- eslint-enable vue/no-v-html -->
    </AppInfoBox>
    <AppTipBox v-else-if="group.type === 'tip_box'" :text="group.value" />
    <AppAlertBox
      v-else-if="group.type === 'alert_box'"
      :title="group.title"
      :content="group.value"
    />
    <AppTags
      v-else-if="group.type === 'tags'"
      v-bind="{ ...inputProps, value: undefined }"
      :tags="group.value"
      :search-placeholder="group.searchPlaceholder"
      :search-no-results="group.searchNoResults"
      :tag-already-exists-message="group.tagAlreadyExistsMessage"
      @change="change"
    />
    <AppMultipleSelect
      v-else-if="group.type === 'multiple_select'"
      v-bind="inputProps"
      :options="group.options"
      :packing-method="group.packingMethod"
      @change="change"
    />
    <AppMultipleSelectBox
      v-else-if="group.type === 'multiple_select_box'"
      v-bind="inputProps"
      :options="group.options"
      :packing-method="group.packingMethod"
      :query-placeholder="group.queryPlaceholder"
      :selected-options-placeholder="group.selectedOptionsPlaceholder"
      :search-options-label="group.searchOptionsLabel"
      :selected-options-label="group.selectedOptionsLabel"
      @change="change"
      v-on="group.eventHandlers"
    >
      <template v-if="$scopedSlots['label']" #label="props">
        <slot name="label" v-bind="props" :group="group" :errors="errors" />
      </template>
    </AppMultipleSelectBox>
    <AppMultipleSelectCheckbox
      v-else-if="group.type === 'multiple_select_checkbox'"
      v-bind="inputProps"
      :options="group.options"
      :packing-method="group.packingMethod"
      :enable-select-all="group.enableSelectAll"
      :enable-indent="group.enableIndent"
      @change="change"
    >
      <template v-if="$scopedSlots['label']" #label="props">
        <slot name="label" v-bind="props" :group="group" :errors="errors" />
      </template>
      <template v-if="$scopedSlots['sub-item']" #sub-item="props">
        <slot name="sub-item" v-bind="props" :group="group" :errors="errors" />
      </template>
    </AppMultipleSelectCheckbox>
    <AppMultipleSelectProductCategory
      v-else-if="group.type === 'multiple_select_product_category'"
      v-bind="{ ...inputProps, productCategories: inputProps.value || [] }"
      @change="change"
    />
    <AppCodeEditor
      v-else-if="group.type === 'code_editor'"
      v-bind="inputProps"
      :lang="group.lang"
      @change="change"
    />
    <AppTextarea
      v-else-if="group.type === 'textarea'"
      v-bind="inputProps"
      :rows="group.rows"
      :autosize="group.autosize"
      :maxlength="group.maxlength"
      :show-byte-size="group.showByteSize"
      :max-bytes="group.maxBytes"
      @blur="blur"
      @change="change"
    />
    <AppTextEditor
      v-else-if="group.type === 'text_editor'"
      v-bind="inputProps"
      @blur="blur"
      @change="change"
    />
    <AppColorPicker
      v-else-if="group.type === 'color'"
      v-bind="inputProps"
      :label="group.label"
      @input="change"
    />
    <AppEmailVerificationInput
      v-else-if="group.type === 'email_verification'"
      v-bind="inputProps"
      @blur="blur"
      @change="change"
    />
  </Component>
</template>

<script>
export default {
  name: 'AppFormField',
  props: {
    group: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, required: true },
    inputSize: { type: String, default: null },
    errors: { type: Object, required: true }
  },
  computed: {
    inputProps() {
      const { group, disabled, invalid } = this;
      return {
        id: group.inputId,
        name: group.inputName,
        value: group.value,
        placeholder: group.placeholder,
        disabled: disabled || group.disabled,
        invalid
      };
    }
  },
  methods: {
    blur() {
      this.$emit('blur');
    },
    change(e) {
      this.$emit('change', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.AppFormField__static-image {
  line-height: 0;
}
</style>
