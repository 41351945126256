<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, objectId: 'write_popup_settings' }"
    :is-loading="isLoading"
    @close="close"
    v-on="formEvents"
  >
    <template #left>
      <div class="WritePopupSettingsDialog__left-container">
        <AppInfoBox class="WritePopupSettingsDialog__image-description">
          {{ $t(`${groupId}.popup_title`) }}
        </AppInfoBox>
        <AppImage :src="detailImageSrc" />
      </div>
    </template>
    <template #group="{ id }">
      <template v-if="id === 'message_default'">
        <div class="AppForm__group-field">
          <AppTextarea
            v-model="formObject.message_default"
            name="write_popup_settings[message_default]"
            :placeholder="$t('message_default.placeholder')"
          />
        </div>
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.default_message_type"
            name="write_popup_settings[default_message_type]"
            :options="ReviewDefaultMessageType.options()"
          />
        </div>
        <div class="AppForm__group-field AppForm__group-field--mt8">
          <AppButton
            v-if="!isShopifyBrand"
            :label="$t('message_default.add_product_category_group')"
            @click="
              productCategoryGroups.push({
                product_category_ids: [],
                review_message_default: null
              })
            "
          />
          <div class="AppForm__sub-group">
            <div
              v-for="(categoryGroup, index) in productCategoryGroups"
              :key="index"
            >
              <div class="AppForm__sub-group-item">
                <label class="AppForm__sub-group-title">{{
                  $t('app.category')
                }}</label>
                <AppSelectProductCategory
                  v-model="categoryGroup.product_category_ids"
                  :name="
                    `write_popup_settings[product_category_groups_attributes][${index}][product_category_ids][]`
                  "
                  multiple
                />
              </div>
              <div class="AppForm__sub-group-item">
                <label class="AppForm__sub-group-title">{{
                  $t('message_default.category_message')
                }}</label>
                <AppTextarea
                  v-model="categoryGroup.review_message_default"
                  :name="
                    `write_popup_settings[product_category_groups_attributes][${index}][review_message_default]`
                  "
                  :placeholder="
                    $t('message_default.category_message_placeholder')
                  "
                />
              </div>
              <div class="AppForm__sub-group-item">
                <AppButton
                  button-style="red-outline"
                  :label="$t('app.delete')"
                  @click="productCategoryGroups.splice(index, 1)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="id === 'use_popup_not_today'">
        <div class="AppForm__group-field">
          <div class="AppForm__sub-group">
            <div class="AppForm__sub-group-item">
              <AppTextInput
                v-model="formObject.popup_not_today_title"
                name="write_popup_settings[popup_not_today_title]"
                :placeholder="$t('use_popup_not_today.placeholder')"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="id === 'first_photo_reward'">
        <AppSelectRadio
          v-model="formObject.show_first_photo"
          name="write_popup_settings[show_first_photo]"
          :options="SHOW_FIRST_PHOTO_OPTIONS"
        >
          <template #sub-item="{ value, checked }">
            <AppTextInput
              v-if="value === true"
              v-model="formObject.popup_first_reward_message"
              :disabled="!checked"
              name="write_popup_settings[popup_first_reward_message]"
              :placeholder="
                $t('first_photo_reward.show_first_photo.placeholder')
              "
            />
          </template>
        </AppSelectRadio>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewDefaultMessageType from '@/enums/ReviewDefaultMessageType';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'WritePopupSettingsDialog',
  mixins: [DialogFormView],
  data() {
    return {
      isLoading: true,
      groupId: 'inducing_popup_info',
      SHOW_FIRST_PHOTO_OPTIONS: [
        {
          label: this.$t('first_photo_reward.show_first_photo.false'),
          value: false
        },
        {
          label: this.$t('first_photo_reward.show_first_photo.true'),
          value: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand', 'adminLocale']),
    ReviewDefaultMessageType: () => ReviewDefaultMessageType,
    productCategoryGroups() {
      return this.formObject.product_category_groups;
    },
    detailImageSrc() {
      const imageName = this.isAdminLocaleKo
        ? this.groupId
        : `en_${this.groupId}`;

      return `//assets.cre.ma/m/admin/v2/${imageName}@2x.png`;
    },
    isAdminLocaleKo() {
      return this.adminLocale === 'ko';
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'inducing_popup_info',
              type: 'text',
              label: this.$t('inducing_popup_info.label'),
              placeholder: this.$t('inducing_popup_info.placeholder'),
              groupDescription: this.$t(
                'inducing_popup_info.group_description'
              ),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('inducing_popup_info'),
              hint: this.$t('inducing_popup_info.hint', {
                max_best_mileage: '%{max_best_mileage}'
              })
            },
            {
              id: 'username_title',
              type: 'text',
              label: this.$t('username_title.label'),
              groupDescription: this.$t('username_title.group_description'),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('username_title'),
              placeholder: this.$t('username_title.placeholder')
            },
            {
              id: 'popup_reward_detail',
              type: 'text',
              label: this.$t('popup_reward_detail.label'),
              groupDescription: this.$t(
                'popup_reward_detail.group_description'
              ),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('popup_reward_detail'),
              placeholder: this.$t('popup_reward_detail.placeholder')
            },
            {
              id: 'reward_notice',
              type: 'textarea',
              label: this.$t('reward_notice.label'),
              groupDescription: this.$t('reward_notice.group_description'),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('reward_notice'),
              placeholder: this.$t('reward_notice.placeholder')
            },
            {
              id: 'message_default',
              label: this.$t('message_default.label'),
              groupDescription: this.$t('message_default.group_description'),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('message_default')
            },
            {
              id: 'popup_submit',
              type: 'text',
              placeholder: this.$t('popup_submit.placeholder'),
              label: this.$t('popup_submit.label'),
              groupDescription: this.$t('popup_submit.group_description'),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('popup_submit')
            },
            {
              id: 'use_popup_not_today',
              type: 'checkbox',
              label: this.$t('use_popup_not_today.label'),
              groupDescription: this.$t(
                'use_popup_not_today.group_description'
              ),
              superAdmin: true
            },
            {
              id: 'first_photo_reward',
              label: this.$t('first_photo_reward.label'),
              groupDescription: this.$t('first_photo_reward.group_description'),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('first_photo_reward')
            },
            {
              id: 'popup_more_reviews',
              type: 'textarea',
              label: this.$t('popup_more_reviews.label'),
              placeholder: this.$t('popup_more_reviews.placeholder'),
              groupDescription: this.$t('popup_more_reviews.group_description'),
              groupDescriptionDetailClickHandler: () =>
                this.changeGroupId('popup_more_reviews')
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('/review/write_popup_settings')
      .then(({ data }) => {
        this.orgFormObject = _.cloneDeep(data.write_popup_settings);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateReviewSettings']),
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/review/write_popup_settings', formData)
        .then(() => {
          this.orgFormObject = _.cloneDeep(this.formObject);
          this.updateReviewSettings(this.orgFormObject);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeGroupId(id) {
      this.groupId = id;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

.WritePopupSettingsDialog__left-container {
  width: 440px;
  padding: 32px 24px;
}

.WritePopupSettingsDialog__image-description {
  margin-bottom: 32px;
}

::v-deep {
  .AppFormGroupDescription__detail-button {
    @include media-breakpoint-each(tablet, mobile) {
      display: none;
    }
  }
}
</style>

<i18n locale="ko">
{
  "title": "작성 팝업 문구 설정",
  "inducing_popup_info": {
    "label": "유도팝업 내 안내문구",
    "group_description": "유도팝업에 노출할 안내 문구를 설정할 수 있습니다.",
    "placeholder": "작성 팝업 제목에 들어갈 문구를 작성해주세요.",
    "hint": "{max_best_mileage}를 입력하면 최대로 받을 수 있는 적립금을 보여줍니다. (조건: 주문총액, 최초포토리뷰, 우수리뷰 선정)",
    "popup_title": "작성 유도 팝업에 작성된 안내문구 내용이 표시됩니다."
  },
  "username_title": {
    "label": "회원 호칭",
    "group_description": "유도팝업에 표시할 회원호칭을 설정할 수 있습니다.",
    "placeholder": "회원을 부를 호칭을 별도로 설정하려면 입력해주세요.",
    "popup_title": "작성 유도 팝업에 설정한 회원호칭이 표시됩니다."
  },
  "popup_reward_detail": {
    "label": "적립금 공지 버튼 문구",
    "group_description": "작성 팝업에 노출할 적립금 공지 버튼 문구를 설정할 수 있습니다.",
    "placeholder": "적립금 공지 제목을 작성해주세요.",
    "popup_title": "작성 팝업에 설정한 적립금 공지 버튼 문구가 표시됩니다."
  },
  "reward_notice": {
    "label": "적립금 공지",
    "group_description": "작성 팝업에 노출할 적립금 공지 내용을 설정할 수 있습니다.",
    "placeholder": "적립금에 대한 공지를 작성해주세요.",
    "popup_title": "작성 팝업에 설정한 적립금 공지 내용이 표시됩니다."
  },
  "message_default": {
    "label": "작성란 내 안내 문구",
    "group_description": "리뷰 작성란에 표시할 안내 문구를 설정할 수 있습니다.",
    "placeholder": "텍스트를 줄바꿈할 경우, 안드로이드나 PC(IE 9이상, 크롬)에서와는 달리 아이폰에서는 기기 특성상 적용되지 않습니다.",
    "add_product_category_group": "카테고리별 안내 문구 추가",
    "category_message": "카테고리별 안내 문구",
    "category_message_placeholder": "안내 문구를 입력해주세요.",
    "popup_title": "작성 팝업 리뷰 작성란에 설정한 안내 문구가 표시됩니다."
  },
  "popup_submit": {
    "label": "작성 완료 버튼 문구",
    "placeholder": "작성 완료버튼의 문구를 설정할 수 있습니다.",
    "group_description": "유도팝업 및 작성 팝업 버튼의 문구를 설정할 수 있습니다.",
    "popup_title": "작성 유도 팝업 및 작성 팝업 버튼에 설정한 문구가 표시됩니다."
  },
  "use_popup_not_today": {
    "label": "오늘 하루 안보기 사용",
    "group_description": "문구 설정",
    "placeholder": "입력하지 않으면, 오늘 하루 안보기로 표시됩니다."
  },
  "first_photo_reward": {
    "label": "최초 포토리뷰 안내",
    "group_description": "작성 팝업에 최초 포토리뷰 작성을 유도하는 안내 메시지 표시 여부를 설정할 수 있습니다.",
    "show_first_photo": {
      "false": "최초 포토리뷰임을 표시하지 않음",
      "true": "최초 포토리뷰임을 표시",
      "placeholder": "포토리뷰 작성을 유도하는 문구를 입력해주세요."
    },
    "popup_title": "작성 팝업에 최초 포토리뷰 작성을 유도하는 안내 메시지 표시 여부를 설정할 수 있습니다."
  },
  "popup_more_reviews": {
    "label": "작성 대기상품 적립금 조건 충족 시 메시지",
    "group_description": "대기 상품에 리뷰 작성을 유도하는 안내 메시지를 설정할 수 있습니다.",
    "placeholder": "아직 리뷰를 작성하지 않은 상품에 대해, 작성유도 메시지를 입력해주세요.",
    "popup_title": "작성 팝업에 적림금 조건을 충족한 작성 대기 상품이 있을 경우 설정한 문구가 표시됩니다."
  }
}
</i18n>
