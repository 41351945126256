<template>
  <AppModalDefault
    :title="$t('manager_review')"
    :sub-title="product.name"
    width="middle"
    is-max-height
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          table-id="review-products-manage-reviews-table"
          :resources="reviews"
          :columns="COLUMNS"
          :rows="rows"
          :enable-total-count="false"
          :no-data="$t('no_data')"
          @paginate="paginate"
        >
          <template #cell="{ row, column }">
            <span v-if="column === 'review_type'">
              {{
                isSocialReview(row) ? $t('social_review') : $t('manager_review')
              }}
            </span>
            <ReviewReviewsCustomerCell
              v-else-if="column === 'author_info'"
              :review="row"
            />
            <ReviewReviewsReviewCell
              v-else-if="column === 'review_details'"
              :review="row"
            />
            <ReviewReviewManageButton
              v-else-if="column === 'manage'"
              :review="row"
              is-manager-review
              :resource-params="orgResourceParams"
            />
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import ReviewSource from '@/enums/ReviewSource';
import SocialMediaType from '@/enums/SocialMediaType';

import ReviewReviewsCustomerCell from '@/views/review/reviews/ReviewReviewsCustomerCell';
import ReviewReviewsReviewCell from '@/views/review/reviews/ReviewReviewsReviewCell';
import ReviewReviewManageButton from '@/views/review/reviews/ReviewReviewManageButton';

export default {
  name: 'ReviewProductsManageReviewsDialog',
  components: {
    ReviewReviewsCustomerCell,
    ReviewReviewsReviewCell,
    ReviewReviewManageButton
  },
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'created_at', label: this.$t('created_at'), type: 'date' },
        { id: 'review_type', label: this.$t('review_type') },
        { id: 'author_info', label: this.$t('author_info') },
        { id: 'review_details', label: this.$t('review_details') },
        { id: 'manage', label: this.$t('manage') }
      ]
    };
  },
  computed: {
    ...mapState('review', ['reviews']),
    ...mapGetters('review', ['isFetchingReviews']),
    defaultResourceParams() {
      return {
        sources: ReviewSource.MANAGER,
        search_type: 'product_id',
        search_query: this.product.id,
        per: '20',
        page: '1'
      };
    },
    isLoading() {
      return this.reviews.isNull || this.isFetchingReviews;
    },
    rows() {
      return this.reviews.items;
    }
  },
  beforeDestroy() {
    this.resetReviews();
  },
  methods: {
    ...mapMutations('review', ['SET_REVIEWS']),
    ...mapActions('review', ['fetchReviews', 'resetReviews']),
    fetchResource(params) {
      this.fetchReviews(params);
    },
    isSocialReview(review) {
      return review.social_media_type !== SocialMediaType.NONE;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  // HACK - ReviewReviewManageButton의 dropdown을 위해 최소 공간을 확보하자.
  .AppResourceTable__body {
    min-height: 320px;
  }

  .review-products-manage-reviews-table__created-at {
    flex: 0 0 100px;
  }

  .review-products-manage-reviews-table__review-type {
    flex: 0 0 115px;
  }

  .review-products-manage-reviews-table__author-info {
    flex: 0 0 130px;
  }

  .review-products-manage-reviews-table__review-details {
    flex: 1 0 200px;
  }

  .review-products-manage-reviews-table__manage {
    flex: 0 0 116px;
  }
}
</style>

<i18n locale="ko">
{
  "manager_review": "스탭 리뷰",
  "social_review": "소셜 리뷰",
  "created_at": "작성일",
  "review_type": "리뷰 유형",
  "author_info": "작성자 이름/ID",
  "review_details": "리뷰 상세 내용",
  "manage": "리뷰 관리",
  "no_data": "스탭 리뷰가 없습니다."
}
</i18n>
<i18n locale="en">
{
  "manager_review": "Staff review",
  "social_review": "Social media review",
  "created_at": "Created at",
  "review_type": "Type of review",
  "author_info": "Author name/ID",
  "review_details": "Review details",
  "manage": "Manager",
  "no_data": "No staff reviews."
}
</i18n>
