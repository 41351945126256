<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSummaryBox class="ReviewSettingsEtc__summary-box">
      {{ $t('info') }}
    </AppSummaryBox>
    <AppForm
      id="review_settings_etc"
      v-bind="formProps"
      submit-button
      v-on="formEvents"
    >
      <template #group="{ id }">
        <template
          v-if="
            adminSettings.enable_v2_init_js && id === 'force_mobile_init_js'
          "
        >
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <div class="AppForm__sub-group-item">
                <AppTags
                  v-model="formObject.force_mobile_skins"
                  name="force_mobile_skins[]"
                  :disabled="!formObject.force_mobile_init_js"
                  :placeholder="$t('force_mobile_skins.placeholder')"
                />
                <AppFormHint
                  :message="$t('force_mobile_skins.hint')"
                  :disabled="!formObject.force_mobile_init_js"
                />
              </div>
            </div>
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import _ from 'lodash';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ReviewSettingsEtc',
  mixins: [FormView, SettingsView],
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapState('session', ['adminSettings']),
    formSections() {
      return [
        {
          id: 'review_settings_etc',
          groups: [
            {
              id: 'force_mobile_init_js',
              type: 'checkbox',
              groupDescription: this.$t(
                'force_mobile_init_js.group_description'
              )
            },
            {
              id: 'deprecate_product_score_widget',
              type: 'checkbox',
              label: this.$t('deprecate_product_score_widget.label'),
              groupDescription: this.$t(
                'deprecate_product_score_widget.group_description'
              )
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('/review/etc_settings')
      .then(({ data }) => {
        this.orgFormObject = data.etc_settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/review/etc_settings', formData)
        .then(() => {
          this.orgFormObject = _.cloneDeep(this.formObject);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsEtc__summary-box {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "info": "해당 페이지는 대행사 권한도 접근 가능한 최고관리자 설정입니다.",
  "force_mobile_init_js": {
    "group_description": "체크 시, 모든 기기(PC/모바일)에서 모바일 위젯만 표시됩니다."
  },
  "force_mobile_skins": {
    "placeholder": "특정 스킨에만 적용할 경우 스킨 정보를 입력해주세요.",
    "hint": "스킨 정보를 입력하지 않을 경우 모든 스킨에 모바일 위젯만 표시됩니다."
  },
  "deprecate_product_score_widget": {
    "label": "평점 위젯 미노출",
    "group_description": "설정 시 쇼핑몰에 설치된 모든 평점 위젯이 미노출됩니다."
  }
}
</i18n>
