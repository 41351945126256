<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppModalFormLivePreview
      :title="title"
      :sub-title="$t('subtitle')"
      :form-props="{
        ...formProps,
        objectId: 'review_campaign_message'
      }"
      width="440px"
      v-on="formEvents"
    >
      <input
        type="hidden"
        name="message[campaign_type]"
        :value="campaignType"
      />
      <input
        type="hidden"
        name="message[biz_message_template_type]"
        :value="templateType"
      />
      <input
        type="hidden"
        name="message[biz_message_template_id]"
        :value="formObject.biz_message_template_id"
      />
      <template #left>
        <AppAjaxContent :is-loading="isLoadingPreview">
          <BizMessageTemplatePreview :template="template" />
        </AppAjaxContent>
      </template>
      <template #group="{ id, errors }">
        <template v-if="id === 'dispatch_recipent'">
          <div class="AppForm__group-field">
            <i18n :path="dispatchDescription">
              <AppSelect
                v-model="formObject.dispatch_days_type"
                name="message[dispatch_days_type]"
                :options="ReviewCampaignDispatchDaysType.options()"
                inline
              />
              <AppNumberInput
                v-model="formObject.dispatch_days"
                name="message[dispatch_days]"
                inline
                :digits="3"
                :invalid="!!errors.dispatch_days"
                @blur="validateField('dispatch_days')"
                @change="validateField('dispatch_days')"
              />
            </i18n>
          </div>
          <AppFormError :error="errors.dispatch_days" />
          <AppFormHint
            :message="
              $t('dispatch_recipent.hint', [giveMileageExpiresAfterDays])
            "
          />
        </template>
        <template v-else-if="id === 'dispatch_hour'">
          <div class="AppForm__group-field">
            <i18n path="dispatch_hour.description">
              <AppSelectHour
                v-model="formObject.dispatch_hour"
                name="message[dispatch_hour]"
                :selectable-hours="SELECTABLE_DISPATCH_HOURS"
              />
            </i18n>
          </div>
          <AppFormHint :message="$t('dispatch_hour.hint')" />
        </template>
        <template v-else-if="id === 'biz_message_template_id'">
          <AppButton
            class="AppForm__group-field"
            :label="$t('add_template')"
            @click="clickNewTemplate"
          />
          <div class="AppForm__group-field">
            <ReviewMessagesBizMessageTemplateSelectTable
              v-model="formObject.biz_message_template_id"
              :templates="templates"
              :group-templates="groupTemplates"
            />
          </div>
        </template>
      </template>
    </AppModalFormLivePreview>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapActions } from 'vuex';
import BizMessageTemplatePreview from '@/views/biz_messages/dialogs/BizMessageTemplatePreview';
import BizMessageTemplateShowView from '@/mixins/BizMessageTemplateShowView';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import ReviewCampaignDispatchDaysType from '@/enums/ReviewCampaignDispatchDaysType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  name: 'ReviewMessagesMessageFormDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView, BizMessageTemplateShowView],
  props: {
    campaignType: { type: Number, required: true },
    campaignMessageId: { type: Number, default: null }
  },
  data: () => ({
    isLoadingMessage: true,
    isLoadingTemplates: true,
    isLoadingPreview: false,
    giveMileageExpiresAfterDays: 0,
    groupTemplates: [],
    SELECTABLE_DISPATCH_HOURS: [...Array(12).keys()].map(h => h + 8)
  }),
  computed: {
    ...mapState('bizMessageTemplate', ['templates', 'templateType']),
    isLoading() {
      return this.isLoadingMessage || this.isLoadingTemplates;
    },
    title() {
      return ReviewCampaignType.t(this.campaignType);
    },
    dispatchDescription() {
      return `dispatch_recipent.description.${ReviewCampaignDispatchDaysType.toKey(
        this.formObject.dispatch_days_type
      ).toLowerCase()}`;
    },
    ReviewCampaignDispatchDaysType: () => ReviewCampaignDispatchDaysType,
    formSections() {
      return [
        {
          groups: [
            {
              id: 'dispatch_recipent',
              label: this.$t('dispatch_recipent.label'),
              fields: [
                {
                  id: 'dispatch_days',
                  validate: ['required', { rule: 'lte', allowed: 365 }]
                }
              ]
            },
            {
              id: 'dispatch_hour',
              label: this.$t('dispatch_hour.label')
            },
            {
              id: 'biz_message_template_id',
              label: this.$t('biz_message_template_id.label'),
              validate: ['required']
            }
          ]
        }
      ];
    },
    campaignMessageIdExist() {
      return Boolean(this.campaignMessageId);
    }
  },
  watch: {
    'formObject.biz_message_template_id'() {
      this.setTemplateData();
      this.validateField('biz_message_template_id');
    }
  },
  mounted() {
    this.setTemplateType(BizMessageTemplateType.NORMAL);
    this.fetchTemplates().finally(() => (this.isLoadingTemplates = false));

    api
      .get(
        this.campaignMessageIdExist
          ? `/review/campaign/messages/${this.campaignMessageId}/edit`
          : '/review/campaign/messages/new'
      )
      .then(({ data }) => {
        this.orgFormObject = { ...data.message };
        this.giveMileageExpiresAfterDays = data.give_mileage_expires_after_days;
        this.groupTemplates = data.group_templates;

        if (
          data.message.biz_message_template_type ===
          BizMessageTemplateType.GROUP
        )
          this.setTemplateType(BizMessageTemplateType.GROUP);
      })
      .finally(() => (this.isLoadingMessage = false));
  },
  beforeDestroy() {
    this.setTemplateType(null);
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('bizMessageTemplate', [
      'fetchTemplates',
      'setTemplateType',
      'fetchTemplate'
    ]),
    submit(formData) {
      this.isSubmitting = true;
      const request = this.campaignMessageIdExist
        ? api.patch(
            `/review/campaign/messages/${this.campaignMessageId}`,
            formData
          )
        : api.post('/review/campaign/messages', formData);

      request
        .then(() => {
          this.emit('submit');
          this.close(true);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    setTemplateData() {
      if (this.formObject.biz_message_template_id) {
        this.isLoadingPreview = true;
        this.fetchTemplate(this.formObject.biz_message_template_id)
          .then(
            ({ data }) => (this.template = this.formatTemplate(data.template))
          )
          .finally(() => (this.isLoadingPreview = false));
      } else this.template = { buttons: [] };
    },
    clickNewTemplate() {
      this.openDialog([
        'BizMessageTemplateFormDialog',
        { serviceType: CremaServiceType.REVIEW, inspectDirectly: true }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "subtitle": "발송 메시지 설정",
  "dispatch_recipent": {
    "label": "발송 대상",
    "description": {
      "order_completed": "{0} {1}일 지난 고객 중 리뷰 미작성 고객",
      "give_mileage_expired": "{0} {1}일 남은 고객 중 리뷰 미작성 고객"
    },
    "hint": "Tip. 현재 설정된 적립금 지급 기간은 배송 완료 후 {0}일 입니다."
  },
  "dispatch_hour": {
    "label": "메시지 발송 시간",
    "description": "{0} 시에 발송합니다.",
    "hint": "Tip. 쇼핑몰 고객이 여유를 가지고 리뷰를 작성할 수 있는 시간을 설정해보세요."
  },
  "biz_message_template_id": {
    "label": "발송할 메시지"
  },
  "errors": {
    "dispatch_days": "최대 365까지 입력 가능합니다."
  },
  "add_template": "+ 메시지 템플릿 생성"
}
</i18n>
