<template>
  <AppModeless
    class="TheAccountMenuPopup"
    align="center"
    @close="$emit('close')"
  >
    <div class="TheAccountMenuPopup__user">
      <div class="TheAccountMenuPopup__user-thumbnail-wrapper">
        <AppImage
          class="TheAccountMenuPopup__user-thumbnail"
          :src="currentUser.thumbnail_url"
        />
      </div>
      <div class="TheAccountMenuPopup__user-detail">
        <div class="TheAccountMenuPopup__user-name">
          {{ currentUser.name }}
        </div>
        <div class="TheAccountMenuPopup__brand-name">
          {{ currentBrand.name }}
        </div>
        <div
          v-if="isSuperAdminAccessible"
          class="super-admin__item TheAccountMenuPopup__shop-builder"
        >
          {{ currentBrand.shop_builder }}
          {{ brandSettings.shop_builder_admin_version }}
        </div>
      </div>
    </div>
    <hr class="AppDropdownMenu__divider" />
    <div class="TheAccountMenuPopup__summary">
      <TheAccountMenuPopupServiceReview class="TheAccountMenuPopup__service" />
      <TheAccountMenuPopupServiceReviewAddon
        class="TheAccountMenuPopup__service"
      />
      <TheAccountMenuPopupServiceTarget class="TheAccountMenuPopup__service" />
      <TheAccountMenuPopupServiceFit class="TheAccountMenuPopup__service" />
      <TheAccountMenuPopupServiceDisplay class="TheAccountMenuPopup__service" />
      <TheAccountMenuPopupServiceAd class="TheAccountMenuPopup__service" />
      <TheAccountMenuPopupServiceInsight class="TheAccountMenuPopup__service" />
      <AppButton
        v-if="currentUser.menu.payment_accessible"
        class="TheAccountMenuPopup__purchase-service-button"
        :label="$t('purchase_service')"
        @click="clickPaymentButton"
      />
    </div>
    <hr class="AppDropdownMenu__divider" />
    <AppDropdownMenu :menu-items="menuItems" @close="$emit('close')" />
  </AppModeless>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TheAccountMenuPopupServiceReview from './TheAccountMenuPopupServiceReview';
import TheAccountMenuPopupServiceTarget from './TheAccountMenuPopupServiceTarget';
import TheAccountMenuPopupServiceFit from './TheAccountMenuPopupServiceFit';
import TheAccountMenuPopupServiceDisplay from './TheAccountMenuPopupServiceDisplay';
import TheAccountMenuPopupServiceAd from './TheAccountMenuPopupServiceAd';
import TheAccountMenuPopupServiceInsight from './TheAccountMenuPopupServiceInsight';
import TheAccountMenuPopupServiceReviewAddon from './TheAccountMenuPopupServiceReviewAddon.vue';

export default {
  name: 'TheAccountMenuPopup',
  components: {
    TheAccountMenuPopupServiceReviewAddon,
    TheAccountMenuPopupServiceReview,
    TheAccountMenuPopupServiceTarget,
    TheAccountMenuPopupServiceFit,
    TheAccountMenuPopupServiceDisplay,
    TheAccountMenuPopupServiceAd,
    TheAccountMenuPopupServiceInsight
  },
  computed: {
    ...mapState('session', [
      'currentUser',
      'currentBrand',
      'adminSettings',
      'brandSettings'
    ]),
    ...mapGetters(['isSuperAdminAccessible', 'advancedSettingsUrl']),
    ...mapGetters(['pathWithBrandParams']),
    menuItems() {
      return [
        [
          this.currentUser.menu.payment_history_accessible
            ? {
                label: this.$t('payment_history'),
                clickHandler: ({ close }) => {
                  this.openDialog('ThePaymentHistoryDialog');
                  close();
                }
              }
            : null,
          {
            label: this.$t('menu.settings_dialog'),
            clickHandler: this.openSettingsDialog
          },
          {
            label: this.$t('menu.admin_locale_dialog'),
            clickHandler: this.openUserLocaleDialog
          },
          {
            label: this.$t('advanced_settings'),
            clickHandler: this.openAdvancedSettingsDialog,
            superAdmin: true,
            tooltip: {
              message: this.$t('advanced_settings_tooltip'),
              textAlign: 'right',
              horizontal: true
            }
          },
          {
            label: this.$t('legacy_advanced_settings'),
            type: 'external_link',
            to: this.advancedSettingsUrl,
            superAdmin: true,
            tooltip: {
              message: this.$t('legacy_advanced_settings_tooltip'),
              textAlign: 'right',
              horizontal: true
            }
          },
          {
            label: this.$t('logout'),
            clickHandler: () => this.logout()
          }
        ].filter(v => v)
      ];
    }
  },
  methods: {
    ...mapActions('session', ['logout']),
    ...mapActions('dialog', ['openDialog']),
    openSettingsDialog({ close }) {
      const initialTab = this.currentUser.menu.brand_settings_accessible
        ? 'settings_dialog_brand'
        : 'settings_dialog_user';
      this.openDialog(['TheSettingsDialog', { initialTab }]);
      close();
    },
    openUserLocaleDialog({ close }) {
      this.openDialog('TheAdminLocaleDialog');
      close();
    },
    clickPaymentButton() {
      this.$emit('close');
      this.openDialog('ThePaymentDialog');
    },
    openAdvancedSettingsDialog({ close }) {
      this.openDialog('TheAdvancedSettingsDialog');
      close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_texts.scss';

.TheAccountMenuPopup {
  width: 220px;
  margin-top: 8px;
}

.TheAccountMenuPopup__user {
  @include clearfix;
  padding: 15px 15px;
}

.TheAccountMenuPopup__user-thumbnail-wrapper {
  float: left;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  line-height: 0;
}

.TheAccountMenuPopup__user-thumbnail {
  width: 50px;
  height: 50px;
}

.TheAccountMenuPopup__user-detail {
  float: left;
  margin-left: 16px;
}

.TheAccountMenuPopup__user-name {
  @include text-label;
}

.TheAccountMenuPopup__brand-name {
  @include text-caption-dark;
}

.TheAccountMenuPopup__shop-builder {
  @include text-caption;
  font-size: 10px;
  line-height: 12px;
}

.TheAccountMenuPopup__summary {
  padding: 15px 15px;
}

.TheAccountMenuPopup__service {
  & + & {
    margin-top: 12px;
  }
}

.TheAccountMenuPopup__purchase-service-button {
  display: block;
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "payment_history": "결제내역 및 이용내역",
  "brand_settings": "쇼핑몰 설정",
  "api_key": "API 키 발급",
  "account_settings": "계정 설정",
  "advanced_settings": "고급설정",
  "advanced_settings_tooltip": "2023. 11. 14. 이후 추가되는 고급설정 기능은 이 고급설정 메뉴에 추가됩니다.\n장기적으로 레거시 고급설정 메뉴의 기능이 이 고급설정 메뉴로 이동될 예정입니다.",
  "legacy_advanced_settings": "레거시 고급설정",
  "legacy_advanced_settings_tooltip": "레거시 고급설정 메뉴의 기능은 장기적으로 새로운 ‘고급설정’ 메뉴로 이동되고\n레거시 고급설정 메뉴는 추후 제거될 예정입니다.",
  "logout": "로그아웃",
  "purchase_service": "서비스 결제"
}
</i18n>
<i18n locale="en">
{
  "payment_history": "Payment History",
  "brand_settings": "Brand Setting",
  "account_settings": "Account Setting",
  "advanced_settings": "Advanced Settings",
  "advanced_settings_tooltip": "Advanced settings features added after 2023.11.14 will be added to this menu.\nIn the long term, features from the Legacy Advanced Settings menu will be moved to this menu.",
  "legacy_advanced_settings": "Legacy Advanced Settings",
  "legacy_advanced_settings_tooltip": "The features in the Legacy Advanced Settings menu will be moved to the new ‘Advanced Settings’ menu in the long term,\nand the Legacy Advanced Settings menu will be removed at a later date.",
  "logout": "Logout",
  "purchase_service": "Purchase Service"
}
</i18n>
