export default [
  {
    id: 'ui_grid',
    path: '/ui/grid',
    parentId: 'ui',
    label: 'Grid'
  },
  {
    id: 'ui_form',
    path: '/ui/form',
    parentId: 'ui',
    label: 'Form'
  },
  {
    id: 'ui_components',
    path: '/ui/components',
    parentId: 'ui',
    label: 'Components'
  },
  {
    id: 'ui_general',
    path: '/ui/button',
    parentId: 'ui',
    label: 'General'
  },
  {
    id: 'ui_accordion',
    path: '/ui/accordion',
    parentId: 'ui_general',
    label: 'Accordion'
  },
  {
    id: 'ui_bubble',
    path: '/ui/bubble',
    parentId: 'ui_general',
    label: 'Bubble'
  },
  {
    id: 'ui_button',
    path: '/ui/button',
    parentId: 'ui_general',
    label: 'Button'
  },
  {
    id: 'ui_button_download',
    path: '/ui/button-download',
    parentId: 'ui_general',
    label: 'ButtonDownload'
  },
  {
    id: 'ui_button_draggable',
    path: '/ui/button-draggable',
    parentId: 'ui_general',
    label: 'ButtonDraggable'
  },
  {
    id: 'ui_button_submit',
    path: '/ui/button-submit',
    parentId: 'ui_general',
    label: 'ButtonSubmit'
  },
  {
    id: 'ui_color',
    path: '/ui/color',
    parentId: 'ui_general',
    label: 'Color'
  },
  {
    id: 'ui_drawer',
    path: '/ui/drawer',
    parentId: 'ui_general',
    label: 'Drawer'
  },
  {
    id: 'ui_dropdown_dialog_button',
    path: '/ui/dropdown-dialog-button',
    parentId: 'ui_general',
    label: 'DropdownDialogButton'
  },
  {
    id: 'ui_dropdown_menu_button',
    path: '/ui/dropdown-menu-button',
    parentId: 'ui_general',
    label: 'DropdownMenuButton'
  },
  {
    id: 'ui_image',
    path: '/ui/image',
    parentId: 'ui_general',
    label: 'Image'
  },
  {
    id: 'ui_link',
    path: '/ui/link',
    parentId: 'ui_general',
    label: 'Link'
  },
  {
    id: 'ui_modal',
    path: '/ui/modal',
    parentId: 'ui_general',
    label: 'Modal'
  },
  {
    id: 'ui_svg_icon',
    path: '/ui/svg-icon',
    parentId: 'ui_general',
    label: 'SVG Icon'
  },
  {
    id: 'ui_tabs_scrollable',
    path: '/ui/tabs-scrollable',
    parentId: 'ui_general',
    label: 'TabsScrollable'
  },
  {
    id: 'ui_text',
    path: '/ui/text',
    parentId: 'ui_general',
    label: 'Text'
  },
  {
    id: 'ui_data_entry',
    path: '/ui/checkbox',
    parentId: 'ui',
    label: 'Data Entry'
  },
  {
    id: 'ui_checkbox',
    path: '/ui/checkbox',
    parentId: 'ui_data_entry',
    label: 'Checkbox'
  },
  {
    id: 'ui_code_editor',
    path: '/ui/code-editor',
    parentId: 'ui_data_entry',
    label: 'CodeEditor'
  },
  {
    id: 'ui_color_picker',
    path: '/ui/color-picker',
    parentId: 'ui_data_entry',
    label: 'ColorPicker'
  },
  {
    id: 'ui_currency_input',
    path: '/ui/currency-input',
    parentId: 'ui_data_entry',
    label: 'CurrencyInput'
  },
  {
    id: 'ui_date_input',
    path: '/ui/date-input',
    parentId: 'ui_data_entry',
    label: 'DateInput'
  },
  {
    id: 'ui_date_picker',
    path: '/ui/date-picker',
    parentId: 'ui_data_entry',
    label: 'DatePicker'
  },
  {
    id: 'ui_date_range_picker',
    path: '/ui/date-range-picker',
    parentId: 'ui_data_entry',
    label: 'DateRangePicker'
  },
  {
    id: 'ui_hash_select_button',
    path: '/ui/hash-select-button',
    parentId: 'ui_data_entry',
    label: 'HashSelectButton'
  },
  {
    id: 'ui_hash_select_checkbox',
    path: '/ui/hash-select-checkbox',
    parentId: 'ui_data_entry',
    label: 'HashSelectCheckbox'
  },
  {
    id: 'ui_image_input',
    path: '/ui/image-input',
    parentId: 'ui_data_entry',
    label: 'ImageInput'
  },
  {
    id: 'ui_keyword_textarea',
    path: '/ui/keyword-textarea',
    parentId: 'ui_data_entry',
    label: 'KeywordTextarea'
  },
  {
    id: 'ui_multiple_select',
    path: '/ui/multiple-select',
    parentId: 'ui_data_entry',
    label: 'MultipleSelect'
  },
  {
    id: 'ui_multiple_select_box',
    path: '/ui/multiple-select-box',
    parentId: 'ui_data_entry',
    label: 'MultipleSelectBox'
  },
  {
    id: 'ui_multiple_select_button',
    path: '/ui/multiple-select-button',
    parentId: 'ui_data_entry',
    label: 'MultipleSelectButton'
  },
  {
    id: 'ui_multiple_select_checkbox',
    path: '/ui/multiple-select-checkbox',
    parentId: 'ui_data_entry',
    label: 'MultipleSelectCheckbox'
  },
  {
    id: 'ui_multiple_select_product',
    path: '/ui/multiple-select-product',
    parentId: 'ui_data_entry',
    label: 'MultipleSelectProduct'
  },
  {
    id: 'ui_multiple_select_product_category',
    path: '/ui/multiple-select-product-category',
    parentId: 'ui_data_entry',
    label: 'MultipleSelectProductCategory'
  },
  {
    id: 'ui_multiple_select_review',
    path: '/ui/multiple-select-review',
    parentId: 'ui_data_entry',
    label: 'MultipleSelectReview'
  },
  {
    id: 'ui_number_input',
    path: '/ui/number-input',
    parentId: 'ui_data_entry',
    label: 'NumberInput'
  },
  {
    id: 'ui_password_input',
    path: '/ui/password-input',
    parentId: 'ui_data_entry',
    label: 'PasswordInput'
  },
  {
    id: 'ui_product_icon',
    path: '/ui/product-icon',
    parentId: 'ui_data_entry',
    label: 'ProductIcon'
  },
  {
    id: 'ui_product_select_button',
    path: '/ui/product-select-button',
    parentId: 'ui_data_entry',
    label: 'ProductSelectButton'
  },
  {
    id: 'ui_search_input',
    path: '/ui/search-input',
    parentId: 'ui_data_entry',
    label: 'SearchInput'
  },
  {
    id: 'ui_select',
    path: '/ui/select',
    parentId: 'ui_data_entry',
    label: 'Select'
  },
  {
    id: 'ui_select_button',
    path: '/ui/select-button',
    parentId: 'ui_data_entry',
    label: 'SelectButton'
  },
  {
    id: 'ui_select_filterable',
    path: '/ui/select-filterable',
    parentId: 'ui_data_entry',
    label: 'SelectFilterable'
  },
  {
    id: 'ui_select_hour',
    path: '/ui/select-hour',
    parentId: 'ui_data_entry',
    label: 'SelectHour'
  },
  {
    id: 'ui_select_radio',
    path: '/ui/select-radio',
    parentId: 'ui_data_entry',
    label: 'SelectRadio'
  },
  {
    id: 'ui_select_product',
    path: '/ui/select-product',
    parentId: 'ui_data_entry',
    label: 'SelectProduct'
  },
  {
    id: 'ui_select_product_category',
    path: '/ui/select-product-category',
    parentId: 'ui_data_entry',
    label: 'SelectProductCategory'
  },
  {
    id: 'ui_select_product_category_code',
    path: '/ui/select-product-category-code',
    parentId: 'ui_data_entry',
    label: 'SelectProductCategoryCode'
  },
  {
    id: 'ui_select_product_option_value',
    path: '/ui/select-product-option-value',
    parentId: 'ui_data_entry',
    label: 'SelectProductOptionValue'
  },
  {
    id: 'ui_select_resource_table',
    path: '/ui/select-resource-table',
    parentId: 'ui_data_entry',
    label: 'SelectResourceTable'
  },
  {
    id: 'ui_select_searchable',
    path: '/ui/select-searchable',
    parentId: 'ui_data_entry',
    label: 'SelectSearchable'
  },
  {
    id: 'ui_switch',
    path: '/ui/switch',
    parentId: 'ui_data_entry',
    label: 'Switch'
  },
  {
    id: 'ui_tags',
    path: '/ui/tags',
    parentId: 'ui_data_entry',
    label: 'Tags'
  },
  {
    id: 'ui_textarea',
    path: '/ui/textarea',
    parentId: 'ui_data_entry',
    label: 'Textarea'
  },
  {
    id: 'ui_text_editor',
    path: '/ui/text-editor',
    parentId: 'ui_data_entry',
    label: 'TextEditor'
  },
  {
    id: 'ui_text_input',
    path: '/ui/text-input',
    parentId: 'ui_data_entry',
    label: 'TextInput'
  },
  {
    id: 'ui_video_input',
    path: '/ui/video-input',
    parentId: 'ui_data_entry',
    label: 'VideoInput'
  },
  {
    id: 'ui_email_verification_input',
    path: '/ui/email-verification-input',
    parentId: 'ui_data_entry',
    label: 'EmailVerificationInput'
  },
  {
    id: 'ui_weekday_select_button',
    path: '/ui/weekday-select-button',
    parentId: 'ui_data_entry',
    label: 'WeekdaySelectButton'
  },
  {
    id: 'ui_data_display',
    path: '/ui/badge',
    parentId: 'ui',
    label: 'Data Display'
  },
  {
    id: 'ui_alert_box',
    path: '/ui/alert-box',
    parentId: 'ui_data_display',
    label: 'AlertBox'
  },
  {
    id: 'ui_badge',
    path: '/ui/badge',
    parentId: 'ui_data_display',
    label: 'Badge'
  },
  {
    id: 'ui_carousel',
    path: '/ui/carousel',
    parentId: 'ui_data_display',
    label: 'Carousel'
  },
  {
    id: 'ui_chart',
    path: '/ui/chart',
    parentId: 'ui_data_display',
    label: 'Chart'
  },
  {
    id: 'ui_color_chip',
    path: '/ui/color-chip',
    parentId: 'ui_data_display',
    label: 'ColorChip'
  },
  {
    id: 'ui_color_report_table',
    path: '/ui/color-report-table',
    parentId: 'ui_data_display',
    label: 'ColorReportTable'
  },
  {
    id: 'ui_copyable_textarea',
    path: '/ui/copyable-textarea',
    parentId: 'ui_data_display',
    label: 'CopyableTextarea'
  },
  {
    id: 'ui_data_box',
    path: '/ui/data-box',
    parentId: 'ui_data_display',
    label: 'DataBox'
  },
  {
    id: 'ui_data_list',
    path: '/ui/data-list',
    parentId: 'ui_data_display',
    label: 'DataList'
  },
  {
    id: 'ui_embed_pdf',
    path: '/ui/embed-pdf',
    parentId: 'ui_data_display',
    label: 'EmbedPdf'
  },
  {
    id: 'ui_info_box',
    path: '/ui/info-box',
    parentId: 'ui_data_display',
    label: 'InfoBox'
  },
  {
    id: 'ui_stat_card',
    path: '/ui/stat-card',
    parentId: 'ui_data_display',
    label: 'StatCard'
  },
  {
    id: 'ui_markdown',
    path: '/ui/markdown',
    parentId: 'ui_data_display',
    label: 'Markdown'
  },
  {
    id: 'ui_number',
    path: '/ui/number',
    parentId: 'ui_data_display',
    label: 'Number'
  },
  {
    id: 'ui_preview',
    path: '/ui/preview',
    parentId: 'ui_data_display',
    label: 'Preview'
  },
  {
    id: 'ui_progress',
    path: '/ui/progress',
    parentId: 'ui_data_display',
    label: 'Progress'
  },
  {
    id: 'ui_progress_steps',
    path: '/ui/progress_steps',
    parentId: 'ui_data_display',
    label: 'ProgressSteps'
  },
  {
    id: 'ui_rate',
    path: '/ui/rate',
    parentId: 'ui_data_display',
    label: 'Rate'
  },
  {
    id: 'ui_resource_table',
    path: '/ui/resource-table',
    parentId: 'ui_data_display',
    label: 'ResourceTable'
  },
  {
    id: 'ui_summary_box',
    path: '/ui/summary-box',
    parentId: 'ui_data_display',
    label: 'SummaryBox'
  },
  {
    id: 'ui_table',
    path: '/ui/table',
    parentId: 'ui_data_display',
    label: 'Table'
  },
  {
    id: 'ui_tip_box',
    path: '/ui/tip-box',
    parentId: 'ui_data_display',
    label: 'TipBox'
  },
  {
    id: 'ui_tooltip',
    path: '/ui/tooltip',
    parentId: 'ui_data_display',
    label: 'Tooltip'
  },
  {
    id: 'ui_data_section',
    path: '/ui/data-section',
    parentId: 'ui_data_display',
    label: 'DataSection'
  },
  {
    id: 'ui_transition',
    path: '/ui/transition-main',
    parentId: 'ui',
    label: 'Transition'
  },
  {
    id: 'ui_transition_main',
    path: '/ui/transition-main',
    parentId: 'ui_transition',
    label: 'Transition Main'
  },
  {
    id: 'ui_feedback',
    path: '/ui/async-job',
    parentId: 'ui',
    label: 'Feedback'
  },
  {
    id: 'ui_async_job',
    path: '/ui/async-job',
    parentId: 'ui_feedback',
    label: 'Async Job'
  },
  {
    id: 'ui_multi_async_job',
    path: '/ui/multi-async-job',
    parentId: 'ui_feedback',
    label: 'Multi Async Job'
  },
  {
    id: 'ui_message_dialog',
    path: '/ui/message-dialog',
    parentId: 'ui_feedback',
    label: 'Message Dialog'
  },
  {
    id: 'ui_toast',
    path: '/ui/toast',
    parentId: 'ui_feedback',
    label: 'Toast'
  },
  {
    id: 'ui_target_components',
    path: '/ui/target-settings-section',
    parentId: 'ui',
    label: 'Target Components'
  },
  {
    id: 'ui_target_settings_section',
    path: '/ui/target-settings-section',
    parentId: 'ui_target_components',
    label: 'TargetSettingsSection'
  }
].map(e => ({ ...e, type: 'sub_menu', superAdmin: true }));
