<template>
  <ul class="badges">
    <li v-for="(status, i) in reviewStatuses" :key="i">
      <AppBadge
        :tooltip="{
          message: status.tooltip,
          info: status.tooltipInfo,
          textAlign: 'left'
        }"
        :badge-style="status.badge"
        :label="status.label"
      />
    </li>
  </ul>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import OrderStatus from '@/enums/OrderStatus';
import ReviewAnalysisStatus from '@/enums/ReviewAnalysisStatus';
import ReviewSentimentType from '@/enums/ReviewSentimentType';
import ReviewSource from '@/enums/ReviewSource';
import SocialMediaType from '@/enums/SocialMediaType';
import ProductStatusReason from '@/enums/ProductStatusReason';

import ReviewSentimentTypeHelper from '@/mixins/ReviewSentimentTypeHelper';

export default {
  name: 'ReviewReviewsStatusCell',
  mixins: [ReviewSentimentTypeHelper],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('session', ['isUsingNlp', 'reviewTagTypeNameMap']),
    ...mapGetters('thirdParties', ['thirdPartyNameMap']),
    reviewStatuses() {
      const statuses = [];
      const review = this.review;
      const { sub_order, social_media_type, code } = review;

      statuses.push(this.reviewSourceBadge);

      if (sub_order) {
        statuses.push({
          badge: 'mint-green',
          label: OrderStatus.t(sub_order.order_status)
        });

        if (sub_order.delivery_service === 'pick_up') {
          statuses.push({
            badge: 'mint-green',
            label: this.$t('review_status.pick_up')
          });
        }
      }

      if (review.nthReview) {
        statuses.push({
          badge: 'blue',
          label: this.$t('review_status.first_review'),
          tooltip: this.$t('review_status.first_review_tooltip'),
          tooltipInfo: this.$t('review_status.first_review_tooltip_info')
        });
      }

      if (social_media_type !== SocialMediaType.NONE) {
        statuses.push({
          badge: 'blue',
          label: this.$t('review_status.social_media_review'),
          tooltip: SocialMediaType.t(social_media_type)
        });
      }

      if (review.nthPhotoReview) {
        const n = review.nthPhotoReview;
        statuses.push({
          badge: 'blue',
          label: this.getPhotoReviewRankLabel('nth_photo_review', n),
          tooltip:
            n === 1
              ? this.$t('review_status.nth_photo_review_tooltip_first')
              : this.getPhotoReviewRankLabel('nth_photo_review_tooltip_nth', n),
          tooltipInfo: this.$t('review_status.nth_photo_review_tooltip_info')
        });
      }

      if (review.duplicate_status === 'duplicate') {
        statuses.push({
          badge: 'red',
          label: this.$t('review_status.duplicate')
        });
      }

      if (
        !review.force_display &&
        (review.display === false || review.product_visible === false)
      ) {
        const reason = this.reviewHideReason;
        statuses.push({
          badge: 'default',
          label: this.$t('review_status.hidden'),
          tooltip: reason
            ? this.$t('review_status.hidden_tooltip', { reason })
            : null,
          tooltipInfo: this.$t('review_status.hidden_tooltip_info')
        });
      }

      const checked = {
        mileaged:
          review.mileage.admin_mileage_amount !== null &&
          review.mileage.admin_mileage_amount >= 0,
        commented: !!review.admin_commented_at,
        gave_mileage:
          review.mileage.gave_mileage_amount !== null &&
          review.mileage.gave_mileage_amount >= 0,
        hid: review.admin_hide
      };
      if (_.some(checked, v => v)) {
        statuses.push({
          badge: 'grey',
          label: this.$t('review_status.checked'),
          tooltip: this.$t(
            'admin_check_tooltip.message',
            _.mapValues(checked, (v, k) =>
              v ? this.$t(`admin_check_tooltip.${k}`) : '-'
            )
          )
        });
      }

      if (this.showReviewAnalysis) {
        const analyzing_messages = [];

        if (
          review.analysis_status === ReviewAnalysisStatus.ANALYZED ||
          review.sentiment_analyzed
        ) {
          const reviewAnalyzedStatuses = this.analyzedStatuses(review);
          if (reviewAnalyzedStatuses) statuses.push(reviewAnalyzedStatuses);
        }

        if (
          this.reviewSettings.use_nlp_sentiment &&
          review.sentiment_analyzed === false
        ) {
          analyzing_messages.push(this.$t('analysis_tooltip.nlp_sentiment'));
        }
        if (
          this.isUsingNlp &&
          review.analysis_status === ReviewAnalysisStatus.ANALYZING
        ) {
          analyzing_messages.push(this.$t('analysis_tooltip.nlp_review'));
        }
        if (0 < analyzing_messages.length) {
          statuses.push({
            badge: 'grey-outline',
            label: this.$t('review_status.analyzable'),
            tooltip: [
              this.$t('analysis_tooltip.analyzable_message'),
              ...analyzing_messages
            ].join('\n- '),
            tooltipInfo: this.$t('app.crema_ai_review')
          });
        }
      }

      if (review.is_copied) {
        let src_review_id = this.$t('default_src_review_id');
        if (code) {
          const result = code.match(/review_(\d+)-product/);
          if (result) src_review_id = result[1];
        }
        statuses.push({
          badge: 'blue-outline',
          label: this.$t('review_status.is_copied'),
          tooltip: this.$t('review_status.copied_review_tooltip', {
            src_review_id
          })
        });
      }

      return statuses;
    },
    reviewSource() {
      switch (this.review.review_source) {
        case ReviewSource.BRAND_MOBILE:
        case ReviewSource.BRAND_MOBILE_POPUP:
        case ReviewSource.BRAND_MOBILE_PRODUCT_DETAIL:
        case ReviewSource.BRAND_MOBILE_PRODUCT_REVIEWS:
        case ReviewSource.BRAND_MOBILE_MY_ORDERS:
        case ReviewSource.BRAND_MOBILE_PENDING_REVIEW:
        case ReviewSource.BRAND_MOBILE_REVIEW_DETAIL:
        case ReviewSource.BRAND_MOBILE_MY_REVIEW:
          return ReviewSource.BRAND_MOBILE;
        case ReviewSource.BRAND_PC:
        case ReviewSource.BRAND_PC_POPUP:
        case ReviewSource.BRAND_PC_PRODUCT:
        case ReviewSource.BRAND_PC_MY_ORDERS:
        case ReviewSource.BRAND_PC_MY_REVIEW:
          return ReviewSource.BRAND_PC;
        case ReviewSource.IMPORTED:
          return this.review.synchronized
            ? ReviewSource.SYNCHRONIZED
            : ReviewSource.IMPORTED;
        default:
          return this.review.review_source;
      }
    },
    reviewHideReason() {
      const review = this.review;
      if (review.display && review.product_visible) return null;

      let reason = null;
      const reason_args = {};
      if (review.product_visible) {
        if (
          review.review_source === ReviewSource.IMPORTED &&
          review.profane_word
        ) {
          reason = 'review_profane_imported';
          reason_args.profane_word = review.profane_word;
        } else if (this.reviewSettings.require_confirmation) {
          reason = 'review_unconfirmed';
        } else if (review.by_inactive_user) {
          reason = 'review_by_inactive_user';
        } else {
          reason = 'review_hided_by_admin';
        }
      } else {
        if (!review.product) {
          reason = 'product_none';
        } else if (!review.product.image_exists) {
          reason = 'product_no_image';
        } else if (
          review.product.product_status_reason === ProductStatusReason.BLACKLIST
        ) {
          reason = 'product_blacklisted';
        } else {
          reason = 'product_hidden';
          reason_args.reason = ProductStatusReason.t(
            review.product.product_status_reason
          );
        }
      }
      return this.$t(`review_hide_reason.${reason}`, reason_args);
    },
    showReviewAnalysis() {
      return (
        this.reviewSettings.use_admin_analysis_badge &&
        ((this.isUsingNlp &&
          this.review.analysis_status !== ReviewAnalysisStatus.NOT_ANALYZE) ||
          (this.reviewSettings.use_nlp_sentiment &&
            this.review.sentiment_analyzed !== undefined))
      );
    },
    reviewSourceBadge() {
      return {
        badge: 'grey-outline',
        label: this.reviewSourceBadgeLabel,
        tooltip: this.reviewSourceBadgeTooltip
      };
    },
    reviewSourceBadgeLabel() {
      let label = '';
      if (this.reviewSource === ReviewSource.ETC)
        label = this.thirdPartyNameMap[this.review.third_party_id];
      return label || ReviewSource.t(this.reviewSource);
    },
    reviewSourceBadgeTooltip() {
      if (!this.review.is_copied && this.reviewSource === ReviewSource.IMPORTED)
        return this.$t('review_status.imported_review_tooltip', {
          code: this.review.code
        });
      else return '';
    }
  },
  methods: {
    analyzedStatuses({ review_tags, sentiment_type }) {
      const reviewKeywordsMap = _.groupBy(review_tags, 'sentiment_type');
      const reviewMessageSentimentType = parseInt(sentiment_type);
      if (!reviewMessageSentimentType) return;

      const reviewMessageSentimentLabel = ReviewSentimentType.t(
        reviewMessageSentimentType
      );

      const tooltipMessages = [];
      tooltipMessages.push(
        this.$t('analysis_tooltip.analyzed_review_message', {
          type: reviewMessageSentimentLabel
        })
      );
      [ReviewSentimentType.NEGATIVE, ReviewSentimentType.POSITIVE].forEach(
        type => {
          const keywords = reviewKeywordsMap[type];
          if (!keywords) return;

          const keywordLabel = ReviewSentimentType.t(type);
          const keywordMessages = [];

          keywordMessages.push(
            this.$t('analysis_tooltip.analyzed_keyword_message', {
              type: keywordLabel
            })
          );
          keywordMessages.push(
            keywords
              .map(t => ` - ${this.reviewTagTypeNameMap[t.review_tag_type_id]}`)
              .join('\n')
          );
          tooltipMessages.push(keywordMessages.join('\n'));
        }
      );

      const hasTooltipMessages = tooltipMessages.length > 0;
      const tooltipMessage = hasTooltipMessages
        ? tooltipMessages.join('\n\n')
        : null;
      const tooltipInfo = hasTooltipMessages
        ? `\n${this.$t('app.crema_ai_review')}`
        : null;
      return {
        badge: this.formatBadgeStyle(reviewMessageSentimentType),
        label: this.$t('review_status.analyzed', {
          type: reviewMessageSentimentLabel
        }),
        tooltip: tooltipMessage,
        tooltipInfo: tooltipInfo
      };
    },
    getPhotoReviewRankLabel(usage, n) {
      const postfixes = ['th', 'st', 'nd', 'rd'];
      const index = n % 100;
      const ordinal = `${n}${postfixes[(index - 20) % 10] ||
        postfixes[index] ||
        postfixes[0]}`;
      return this.$t(`review_status.${usage}`, { ordinal, n });
    }
  }
};
</script>

<i18n locale="ko">
{
  "review_status": {
    "pick_up": "매장구매",
    "imported_review_tooltip": "원본 리뷰 번호: {code}",
    "copied_review_tooltip": "원본 리뷰 id: {src_review_id}",
    "is_copied": "복사된 리뷰",
    "first_review": "최초 작성",
    "first_review_tooltip": "상품 최초로 작성한 리뷰",
    "first_review_tooltip_info": "오른쪽 끝 [관리] 버튼 클릭해서 지정 취소",
    "nth_photo_review": "{n}번째 포토",
    "nth_photo_review_tooltip_first": "상품 최초로 작성한 포토 리뷰",
    "nth_photo_review_tooltip_nth": "상품 리뷰 중 {n}번째로 작성한 포토 리뷰",
    "nth_photo_review_tooltip_info": "오른쪽 끝 [관리] 버튼 클릭해서 지정 취소",
    "social_media_review": "외부포스팅",
    "duplicate": "중복리뷰",
    "hidden": "숨김",
    "hidden_tooltip": "고객에게 숨겨진 리뷰 입니다.\n이유: {reason}",
    "hidden_tooltip_info": "오른쪽 끝 [관리] 버튼을 클릭해 숨김 해제",
    "checked": "확인",
    "analyzable": "리뷰 분석중",
    "analyzed": "{type} 리뷰"
  },
  "review_hide_reason": {
    "review_profane_imported": "금지어({profane_word})가 포함된 기존 리뷰",
    "review_unconfirmed": "관리자 숨김 혹은 새 리뷰 미진열 상태 숨김",
    "review_by_inactive_user": "휴면회원의 리뷰 자동 숨김",
    "review_hided_by_admin": "관리자 숨김",
    "product_none": "상품 없음",
    "product_no_image": "상품 이미지 없음",
    "product_blacklisted": "해당 상품명이 숨김 처리 되어있음.\n리뷰 설정> 일반 설정> 관리 기능 탭에서 수정가능",
    "product_hidden": "판매하지 않는 상품 ({reason})"
  },
  "admin_check_tooltip": {
    "message": "다음 중 하나 이상의 행동을 한 경우 확인리뷰로 표시합니다.\n1. 예상 적립금: {mileaged}\n2. 관리자 댓글: {commented}\n3. 적립금 지급: {gave_mileage}\n4. 리뷰 숨김: {hid}",
    "mileaged": "수정함",
    "commented": "작성함",
    "gave_mileage": "지급함",
    "hid": "숨김"
  },
  "analysis_tooltip": {
    "analyzable_message": "다음 항목에 대해 리뷰를 분석중에 있습니다.",
    "analyzed_review_message": "리뷰 본문 전체 분석 결과\n{type} 리뷰로 감지했습니다.",
    "analyzed_keyword_message": "주요 키워드별 분석 결과\n{type} 표현감지",
    "nlp_review": "주요 키워드별 분석",
    "nlp_sentiment": "리뷰 본문 긍정/부정 분석",
    "sentiment": "리뷰 본문 전반"
  },
  "default_src_review_id": "알 수 없음"
}
</i18n>
<i18n locale="en">
{
  "review_status": {
    "pick_up": "Store Purchase",
    "imported_review_tooltip": "Original review number: {code}",
    "first_review": "{ordinal} review",
    "first_review_tooltip": "First review in product",
    "first_review_tooltip_info": "Cancel the nth review by clicking the [Manage] button on the far right",
    "nth_photo_review": "{ordinal} photo",
    "nth_photo_review_tooltip_first": "First photo review in product",
    "nth_photo_review_tooltip_nth": "{ordinal} photo review in product",
    "nth_photo_review_tooltip_info": "Cancel the nth review by clicking the [Manage] button on the far right",
    "social_media_review": "Social Media Review",
    "duplicate": "Duplicate",
    "hidden": "Hidden",
    "hidden_tooltip": "Hidden by customer.\nReason: {reason}",
    "hidden_tooltip_info": "Click the [Manage] button on the far right to unhide",
    "checked": "Checked",
    "analyzable": "Analyzable",
    "analyzed": "{type} Review"
  },
  "review_hide_reason": {
    "review_profane_imported": "Imported review with profane word({profane_word})",
    "review_unconfirmed": "Unconfirmed review",
    "review_by_inactive_user": "Hidden by inactive user",
    "review_hided_by_admin": "Hidden by admin",
    "product_none": "No product",
    "product_no_image": "No product image",
    "product_hidden": "Hidden product ({reason})"
  },
  "admin_check_tooltip": {
    "message": "A confirmed review is marked if one or more of the following actions are taken.\n1. Estimated mileage edited: {mileaged}\n2. Admin commented: {commented}\n3. Mileage given: {gave_mileage}\n4. Hide reviews: {hid}",
    "mileaged": "Estimated mileage edited",
    "commented": "Admin commented",
    "gave_mileage": "Mileage given",
    "hid": "Hidden"
  },
  "analysis_tooltip": {
    "analyzable_message": "Analysing review for the following items.",
    "analyzed_review_message": "Result for analysis of review\nDetected as {type} review.",
    "analyzed_keyword_message": "Result for analysis by keywords\n{type} expression detected",
    "nlp_review": "Analysis by keywords",
    "nlp_sentiment": "Analysis by positive or negative",
    "sentiment": "Sentiment"
  }
}
</i18n>
