<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #default>
        <li>
          <AppDropdownMenuButton
            v-if="!reviewSettings.hide_review_csv_button"
            :label="$t('download_csv')"
            button-style="grey"
            :tooltip="isCsvDownloadable ? null : $t('download_csv_tooltip')"
            :menu-items="downloadCsvMenuItems"
            :disabled="!isCsvDownloadable"
          />
        </li>
      </template>

      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangeWithType
            v-model="dateRangeWithType"
            :date-types="DATE_TYPES"
            @apply="searchResource"
          />
        </li>
        <li>
          <AppButtonToggle
            v-model="advancedSearchVisible"
            :label="$t('app.advanced_search')"
          />
        </li>
      </template>

      <template #advanced-search>
        <ReviewReviewsAdvancedSearch
          v-model="resourceParams"
          :visible="advancedSearchVisible"
          @submit="advancedSearchResource"
          @review-tags-length="reviewTagsLength = $event"
        />
      </template>
    </AppSearchbar>

    <Component
      :is="
        VIRTUAL_LIST_PERS.includes(reviews.per)
          ? 'AppVirtualListResourceTable'
          : 'AppResourceTable'
      "
      table-id="reviews-table"
      :event-bus="tableEventBus"
      :class="{
        'reviews-table--coupon':
          reviewSettings.review_point_type === ReviewPointType.COUPON
      }"
      enable-refresh
      enable-column-settings
      :custom-buttons="[
        sortButton,
        {
          label: $t('bookmark.go_to_bookmark'),
          icon: 'icon-bookmark',
          tooltip: reviewSettings.bookmarked_review_id
            ? null
            : $t('bookmark.disabled_tooltip'),
          clickHandler: goToBookmark,
          disabled: !reviewSettings.bookmarked_review_id
        }
      ]"
      :resources="reviews"
      :min-row-height="140"
      :advanced-search-height="1316"
      :advanced-search-visible="advancedSearchVisible"
      :columns="columns"
      :rows="rows"
      :batch-buttons="[
        ...(canSaveMileage
          ? [
              {
                label: $t('batch.give_mileage'),
                tooltip: $t('batch.give_mileage_tooltip'),
                clickHandler: batchGiveMileage
              },
              {
                label: $t('batch.deduct_mileage'),
                tooltip: $t('batch.deduct_mileage_tooltip'),
                clickHandler: batchCancelMileage
              }
            ]
          : []),
        ...(canManageComment
          ? [
              {
                label: $t('batch.comment'),
                tooltip: $t('batch.comment_tooltip'),
                clickHandler: batchComment
              }
            ]
          : []),
        {
          label: $t('batch.show'),
          tooltip: $t('batch.show_tooltip'),
          clickHandler: batchShow
        },
        {
          label: $t('batch.hide'),
          tooltip: $t('batch.hide_tooltip'),
          clickHandler: batchHide
        },
        {
          label: $t('batch.tag'),
          tooltip: $t('batch.tag_tooltip'),
          clickHandler: batchTag
        },
        {
          label: $t('app.delete'),
          tooltip: $t('batch.delete_tooltip'),
          clickHandler: batchDelete
        }
      ]"
      :pers="[20, 50, 100, 200, 500]"
      @refresh="refreshResource"
      @paginate="reviewsPaginate"
      @select-rows="selectedReviews = $event"
    >
      <template
        v-if="laboratory.ai_recommendation_sort && searchbarBubbleVisibility"
        #searchbar-bubble
      >
        <AppBubble
          class="ReviewReviews__searchbar-bubble"
          @click="hideSearchbarBubble"
        >
          <div class="ReviewReviews__searchbar-bubble-title">
            {{ $t('searchbar_bubble.title') }}
          </div>
          <div class="ReviewReviews__searchbar-bubble-message">
            {{ $t('searchbar_bubble.message') }}
            <AppInlineButton
              :label="$t('app.detail')"
              class="ReviewReviews__searchbar-bubble-button"
              button-style="caption"
              @click="openAiRecommendationSortHelpDialog"
            />
          </div>
        </AppBubble>
      </template>
      <template #cell="{row, column, value}">
        <ReviewReviewsDateCell v-if="column === 'date'" :review="row" />
        <ReviewReviewsCustomerCell
          v-else-if="column === 'customer'"
          :review="row"
        />
        <ReviewReviewsProductCell
          v-else-if="column === 'product'"
          :review="row"
        />
        <ReviewReviewsReviewCell
          v-else-if="column === 'review'"
          :review="row"
        />
        <ReviewReviewsStatusCell
          v-else-if="column === 'status'"
          :review="row"
        />
        <template v-else-if="column === 'tags'">
          <div
            v-for="tag in value"
            :key="tag.id"
            class="table-line table-line--mt4"
          >
            <AppBadge badge-style="grey-outline" :label="tag.name" />
          </div>
          <div class="table-line table-line--mt8">
            <a class="ReviewReviews__new-tag-link" @click="editTags(row)">{{
              $t('new_tag')
            }}</a>
          </div>
        </template>
        <ReviewReviewsAiRecommendationScoreCell
          v-else-if="column === 'ai_recommendation_score'"
          :review="row"
        />
        <ReviewReviewsActionsCell
          v-else-if="column === 'actions'"
          :review="row"
          :resource-params="orgResourceParams"
        />
      </template>
    </Component>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import qs from 'qs';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import HttpStatus from '@/enums/HttpStatus';
import ReviewPointType from '@/enums/ReviewPointType';

import ResourceView from '@/mixins/ResourceView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';

import ReviewReviewsAdvancedSearch from './ReviewReviewsAdvancedSearch';
import ReviewReviewsDateCell from './ReviewReviewsDateCell';
import ReviewReviewsCustomerCell from './ReviewReviewsCustomerCell';
import ReviewReviewsProductCell from './ReviewReviewsProductCell';
import ReviewReviewsReviewCell from './ReviewReviewsReviewCell';
import ReviewReviewsStatusCell from './ReviewReviewsStatusCell';
import ReviewReviewsAiRecommendationScoreCell from './ReviewReviewsAiRecommendationScoreCell';
import ReviewReviewsActionsCell from './ReviewReviewsActionsCell';

export default {
  name: 'ReviewReviews',
  components: {
    ReviewReviewsAdvancedSearch,
    ReviewReviewsDateCell,
    ReviewReviewsCustomerCell,
    ReviewReviewsProductCell,
    ReviewReviewsReviewCell,
    ReviewReviewsStatusCell,
    ReviewReviewsAiRecommendationScoreCell,
    ReviewReviewsActionsCell
  },
  mixins: [ResourceView, ReviewPointTypeHelper],
  data() {
    return {
      SEARCH_TYPES: [
        'user_id',
        'user_name',
        'phone_number',
        'product_name',
        'review_id',
        'order_code',
        'review_message',
        'product_code',
        'product_id'
      ].map(value => ({
        value,
        label: this.$t(`review_search_type.${value}`)
      })),
      DATE_TYPES: [
        'review_created_at',
        'sub_order_delivered_at',
        'gave_mileage_at',
        'admin_commented_at',
        'order_purchased_at',
        'order_created_at'
      ].map(value => ({
        value,
        label: this.$t(`review_date_type.${value}`)
      })),
      advancedSearchVisible: [
        'analysis_status',
        'admin_mileage_set',
        'auto_mileage_zero',
        'category_id',
        'combo_product_id',
        'comment',
        'duplicate',
        'first_photo',
        'first',
        'keyword_group_id',
        'member',
        'mileage',
        'order_status',
        'photo',
        'picked_up',
        'pinned_location',
        'refunded',
        'review_analysis_result',
        'score',
        'social_media',
        'sources',
        'sub_brand_ids',
        'tag_ids',
        'unchecked',
        'user_grade_id',
        'visible'
      ].some(key => key in this.$route.query),
      tableEventBus: new Vue(),
      VIRTUAL_LIST_PERS: [200, 500],
      reviewTagsLength: 0,
      selectedReviews: [],
      bubbleStorageKey: 'searchbar_bubble',
      searchbarBubbleVisibility: false
    };
  },
  computed: {
    ...mapState(['brandParams']),
    ...mapState('session', ['laboratory']),
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapState('review', ['reviews']),
    ...mapGetters('session', [
      'canSaveMileage',
      'canManageComment',
      'accountConfiguredForMileage'
    ]),
    ...mapGetters('review', ['isFetchingReviews', 'isBookmarkedReview']),
    columns() {
      return [
        { id: 'row_select', type: 'row_select', required: true },
        { id: 'id', label: this.$t('table_header.id'), hideByDefault: true },
        {
          id: 'position',
          label: this.$t('table_header.position'),
          hideByDefault: true
        },
        {
          id: 'date',
          label: this.$t('table_header.date'),
          required: true
        },
        {
          id: 'customer',
          label: this.$t('table_header.customer'),
          required: true
        },
        {
          id: 'product',
          label: this.$t('app.product'),
          required: true
        },
        {
          id: 'product_code',
          label: this.$t('products.code'),
          hideByDefault: true
        },
        this.$store.state.session.brandSettings.show_sub_brand
          ? {
              id: 'sub_brand_name',
              label: this.$t('table_header.sub_brand_name'),
              hideByDefault: true
            }
          : null,
        { id: 'review', label: this.$t('table_header.review'), required: true },
        { id: 'status', label: this.$t('app.status') },
        { id: 'tags', label: this.$t('table_header.tags') },
        this.$store.state.session.laboratory.ai_recommendation_sort
          ? {
              id: 'ai_recommendation_score',
              label: this.$t('table_header.ai_recommendation_score'),
              tooltip: this.$t('table_header.ai_recommendation_score_tooltip'),
              hideByDefault: this.resourceParams.sort !== 'ai_recommendation'
            }
          : null,
        {
          id: 'actions',
          label: this.$t('table_header.actions'),
          required: true
        }
      ].filter(c => c);
    },
    defaultResourceParams: () => ({
      per: localStorage.reviewReviewsPer || '20',
      page: '1',
      search_type: 'user_id',
      date_type: 'review_created_at',
      sort: 'created_at_desc'
    }),
    ReviewPointType() {
      return ReviewPointType;
    },
    resources() {
      return this.reviews;
    },
    isLoading() {
      return this.reviews.isNull || this.isFetchingReviews;
    },
    downloadCsvMenuItems() {
      const path = '/api/review/reviews/download_csv';
      const params = { ...this.orgResourceParams, ...this.brandParams };

      return [
        {
          label: this.$t('download_csv_menu.review'),
          type: 'file_link',
          url: `${path}?${qs.stringify(params)}`,
          clickHandler: this.downloadCsvWithConfirm
        },
        {
          label: this.$t('download_csv_menu.comment'),
          type: 'file_link',
          url: `${path}?${qs.stringify({ ...params, csv_type: 'comment' })}`,
          clickHandler: this.downloadCsv
        },
        {
          label: this.$t('download_csv_menu.review_euc_kr'),
          type: 'file_link',
          url: `${path}?${qs.stringify({ ...params, encoding: 'euc-kr' })}`,
          clickHandler: this.downloadCsvWithConfirm
        },
        {
          label: this.$t('download_csv_menu.comment_euc_kr'),
          type: 'file_link',
          url: `${path}?${qs.stringify({
            ...params,
            csv_type: 'comment',
            encoding: 'euc-kr'
          })}`,
          clickHandler: this.downloadCsv
        }
      ];
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    dateRangeWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      const positionStart =
        this.reviews.total_count - (this.reviews.page - 1) * this.reviews.per;
      return this.reviews.items.map((review, index) => {
        const { review_index, photo_review_index } = review;
        const nthReview =
          review_index !== null &&
          review_index < this.reviewSettings.give_mileage_nth_reviews.length
            ? review_index + 1
            : null;
        const nthPhotoReview =
          photo_review_index !== null &&
          photo_review_index <
            this.reviewSettings.give_mileage_nth_photo_reviews.length
            ? photo_review_index + 1
            : null;
        return {
          ...review,
          position: positionStart - index,
          product_code: review.product ? review.product.code : null,
          nthReview,
          nthPhotoReview
        };
      });
    },
    sortButton() {
      const betaBadge = { label: this.$t('app.beta'), badgeStyle: 'red' };
      const options = [
        'created_at_desc',
        'created_at_asc',
        'plus_likes_count_desc',
        'score_asc',
        'delivered_at_desc'
      ];
      if (this.laboratory.ai_recommendation_sort)
        options.push('ai_recommendation');

      return {
        icon: 'icon-sort',
        controlType: 'select',
        options: options.map(value => ({
          label: this.$t(`button.sort.${value}`),
          value,
          badge: value === 'ai_recommendation' ? betaBadge : null
        })),
        value: options.includes(this.$route.query.sort)
          ? this.$route.query.sort
          : 'created_at_desc',
        clickHandler: this.hideSearchbarBubble,
        changeHandler: this.sortButtonChanged
      };
    },
    isCsvDownloadable() {
      return !!(
        this.orgResourceParams.start_date && this.orgResourceParams.end_date
      );
    }
  },
  beforeDestroy() {
    this.resetReviews();
  },
  mounted() {
    this.showSearchbarBubble();
  },
  methods: {
    ...mapMutations('session', ['SET_REVIEW_SETTINGS']),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('review', [
      'fetchReviews',
      'fetchCurrentPageReviews',
      'resetReviews',
      'deleteReviews',
      'giveMileageReviews',
      'cancelMileageReviews',
      'createCommentReviews',
      'updateDisplayReview',
      'updateDisplayReviews',
      'updateTagReviews'
    ]),
    ...mapActions('toast', ['createToast']),
    advancedSearchResource() {
      const { review_tags } = this.resourceParams;
      const reviewTagsLengthFromParams = review_tags
        ? review_tags.split('&').length
        : 0;
      if (reviewTagsLengthFromParams === this.reviewTagsLength) {
        return this.searchResource();
      }

      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('review_tags_alert.title'),
          markdownText: this.$t('review_tags_alert.markdown_text'),
          snoozeId: 'ReviewReviews.review_tags_alert',
          width: DialogSize.SMALL
        }
      ])
        .then(eventBus => {
          eventBus.$on('close', () => this.fetchReviews(this.resourceParams));
        })
        .catch(() => this.createToast(this.$t('review_tags_alert.toast')));
    },
    fetchResource(params) {
      this.fetchReviews(params);
    },
    useMakeshopCrawler() {
      const { shop_builder, use_batch_mileage_api } = this.currentBrand;
      return shop_builder === 'makeshop' && !use_batch_mileage_api;
    },
    batchGiveMileage() {
      const reviews = _.chain(this.selectedReviews)
        .keyBy('id')
        .values()
        .value();

      if (!this.accountConfiguredForMileage) {
        if (confirm(this.$t('reviews.set_brand_manager_confirm'))) {
          this.openDialog([
            'TheSettingsDialog',
            { initialTab: 'settings_dialog_user' }
          ]);
        }
        return;
      }

      this.openDialog(['ReviewReviewsBatchMileageDialog', { reviews }]).then(
        eventBus =>
          eventBus.$on('submit', batchMileage => {
            if (batchMileage.save_mileage_message) {
              const default_batch_mileage_message =
                batchMileage.mileage_message;
              api
                .patch(
                  '/review/settings',
                  { review_settings: { default_batch_mileage_message } },
                  { silent: true }
                )
                .then(() =>
                  this.SET_REVIEW_SETTINGS({
                    ...this.reviewSettings,
                    default_batch_mileage_message
                  })
                );
            }

            this.giveMileageReviews({
              title: this.$t('batch.give_mileage_title'),
              reviews,
              batchMileage,
              intervalRequired: this.useMakeshopCrawler()
            });
          })
      );
    },
    batchCancelMileage() {
      const reviews = _.chain(this.selectedReviews)
        .keyBy('id')
        .values()
        .value();

      if (
        !confirm(
          this.$t('batch.deduct_mileage_confirm', { count: reviews.length })
        )
      )
        return;

      this.cancelMileageReviews({
        title: this.$t('batch.deduct_mileage_title'),
        reviews
      });
    },
    batchComment() {
      const reviews = this.selectedReviews;

      this.openDialog(['ReviewReviewsBatchCommentDialog', { reviews }]).then(
        eventBus =>
          eventBus.$on('apply', batchComment => {
            this.createCommentReviews({
              title: this.$t('batch.comment_title'),
              reviews,
              batchComment
            });
          })
      );
    },
    batchShow() {
      const reviews = this.selectedReviews;

      if (!confirm(this.$t('batch.show_confirm', { count: reviews.length }))) {
        return;
      }

      this.updateDisplayReviews({
        title: this.$t('batch.show_title'),
        reviews,
        display: true
      });
    },
    batchHide() {
      const reviews = this.selectedReviews;

      if (!confirm(this.$t('batch.hide_confirm', { count: reviews.length }))) {
        return;
      }

      this.updateDisplayReviews({
        title: this.$t('batch.hide_title'),
        reviews,
        display: false
      });
    },
    batchTag() {
      const reviews = this.selectedReviews;

      this.openDialog(['ReviewReviewsBatchTagsDialog', { reviews }]).then(
        eventBus =>
          eventBus.$on('apply', reviewIdTagIdsMap => {
            this.updateTagReviews({
              title: this.$t('batch.tag_title'),
              reviews,
              reviewIdTagIdsMap
            });
          })
      );
    },
    batchDelete() {
      const reviews = this.selectedReviews;

      if (
        !confirm(this.$t('batch.delete_confirm', { count: reviews.length }))
      ) {
        return;
      }

      this.deleteReviews({
        title: this.$t('batch.delete_title'),
        reviews,
        resourceParams: this.resourceParams
      });
    },
    editTags(review) {
      this.openDialog(['ReviewReviewsTagsDialog', { review }]);
    },
    goToBookmark() {
      if (this.focusBookmark()) return;

      return this.fetchBookmarkPage().then(page => {
        this.refreshResourceWithParams({ ...this.resourceParams, page });
        const unwatch = this.$watch('reviews', () => {
          this.$nextTick(this.focusBookmark);
          unwatch();
        });
      });
    },
    focusBookmark() {
      const bookmarkIndex = this.rows.findIndex(this.isBookmarkedReview);
      if (bookmarkIndex !== -1) {
        this.tableEventBus.$emit('focus-row', bookmarkIndex);
        return true;
      } else {
        return false;
      }
    },
    setBookmarkedReviewId(bookmarkedReviewId) {
      if (bookmarkedReviewId != this.reviewSettings.bookmarked_review_id) {
        this.SET_REVIEW_SETTINGS({
          ...this.reviewSettings,
          bookmarkedReviewId
        });
      }
    },
    fetchBookmarkPage() {
      return api
        .get('/review/bookmark', {
          params: this.resourceParams
        })
        .then(({ data }) => {
          const { bookmarked_review_id, page } = data;
          this.setBookmarkedReviewId(bookmarked_review_id);
          if (!page && confirm(this.$t('bookmark.confirm_reset_filter'))) {
            this.resourceParams = {
              ...this.defaultResourceParams,
              per: this.resourceParams.per,
              sort: this.resourceParams.sort
            };
            return this.fetchBookmarkPage();
          }
          return page;
        })
        .catch(({ response }) => {
          if (response.status === HttpStatus.NOT_FOUND) {
            this.createToast(this.$t('bookmark.bookmark_removed'));
            this.setBookmarkedReviewId(null);
          }
        });
    },
    reviewsPaginate(pagination) {
      localStorage.reviewReviewsPer = pagination.per;
      this.paginate(pagination);
    },
    downloadCsv({ close, click }) {
      close();
      click();
    },
    downloadCsvWithConfirm({ close, click }) {
      return this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('download_csv_menu.confirm.review_title'),
          markdownText: this.$t(
            'download_csv_menu.confirm.review_markdown_text'
          ),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => this.downloadCsv({ close, click }));
      });
    },
    sortButtonChanged(newValue) {
      this.resourceParams = {
        ...this.resourceParams,
        sort: newValue
      };
      this.searchResource();
    },
    showSearchbarBubble() {
      const counter = JSON.parse(
        localStorage.getItem(this.bubbleStorageKey)
      ) || { closeCount: 0 };
      const { closeCount, closedAt } = counter;
      const yesterday = new Date().valueOf() - 24 * 60 * 60 * 1000;
      if (closeCount >= 3) return;
      if (closedAt && closedAt >= yesterday) return;

      localStorage.setItem(this.bubbleStorageKey, JSON.stringify(counter));
      this.searchbarBubbleVisibility = true;
    },
    hideSearchbarBubble() {
      const counter = JSON.parse(
        localStorage.getItem(this.bubbleStorageKey)
      ) || { closeCount: 0 };
      counter.closeCount += 1;
      counter.closedAt = new Date().valueOf();

      localStorage.setItem(this.bubbleStorageKey, JSON.stringify(counter));
      this.searchbarBubbleVisibility = false;
    },
    openAiRecommendationSortHelpDialog() {
      this.openDialog('TheAiRecommendationSortHelpDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewReviews__searchbar-bubble {
  width: 304px;
  text-align: left;
  text-wrap: wrap;
  line-height: 20px;
}

.ReviewReviews__searchbar-bubble-title {
  font-weight: bold;
}

.ReviewReviews__searchbar-bubble-message {
  font-weight: normal;
}

.ReviewReviews__searchbar-bubble-button {
  color: white;
  float: right;
  font-size: 13px;

  &:hover {
    color: white;
    font-size: 13px;
    text-decoration: none;
  }
}

.ReviewReviews__new-tag-link {
  color: $color-blue;
  cursor: pointer;
}

::v-deep {
  .reviews-table__id {
    flex: 1 0 80px;
  }

  .reviews-table__position {
    flex: 1 0 80px;
  }

  .reviews-table__date {
    flex: 13 0 132px;
  }

  .reviews-table__customer {
    flex: 13 0 136px;
  }

  .reviews-table__product {
    flex: 16 0 180px;
  }

  .reviews-table__product-code {
    flex: 0 0 92px;
  }

  .reviews-table__sub-brand-name {
    flex: 10 0 120px;
  }

  .reviews-table__review {
    flex: 36 0 236px;
  }

  .reviews-table__status {
    flex: 10 0 100px;

    &--body {
      min-height: 0;
    }
  }

  .reviews-table__tags {
    flex: 12 0 120px;

    &--body {
      min-height: 0;
    }
  }

  .reviews-table__ai-recommendation-score {
    flex: 1 0 102px;
  }

  .reviews-table__actions {
    text-align: right;
    flex: 1 0 272px;
  }

  .reviews-table--coupon .reviews-table__actions {
    flex: 1 0 359px;
  }
}
</style>

<i18n locale="ko">
{
  "download_csv": "CSV 파일 다운로드",
  "download_csv_tooltip": "오른쪽에 위치한 기간 설정 버튼을 눌러 다운받을 리뷰, 댓글의 기간을 선택하세요.",
  "download_csv_menu": {
    "review": "리뷰 CSV 파일 다운로드",
    "comment": "댓글 CSV 파일 다운로드",
    "review_euc_kr": "리뷰 CSV 파일 다운로드 (euc-kr 인코딩)",
    "comment_euc_kr": "댓글 CSV 파일 다운로드 (euc-kr 인코딩)",
    "confirm": {
      "review_title": "리뷰 CSV를 다운로드 합니다.",
      "review_markdown_text": "CSV에 포함된 포토/동영상 링크는 유효기간이 7일입니다. 다운로드 시점으로부터 7일이 지나면 해당 링크를 통한 포토/동영상 확인이 불가합니다."
    }
  },
  "review_search_type": {
    "user_id": "고객 ID",
    "user_name": "고객 이름",
    "phone_number": "고객 전화번호",
    "product_name": "상품명",
    "review_id": "리뷰 ID",
    "order_code": "주문번호",
    "review_message": "리뷰내용",
    "product_code": "상품 번호",
    "product_id": "상품 ID"
  },
  "review_date_type": {
    "review_created_at": "리뷰 작성일",
    "sub_order_delivered_at": "배송 완료일",
    "gave_mileage_at": "적립금 지급일",
    "admin_commented_at": "댓글 작성일",
    "order_purchased_at": "결제일",
    "order_created_at": "주문일"
  },
  "searchbar_bubble": {
    "title": "AI 추천순 리뷰 보기 추가!",
    "message": "AI가 분석한 우수 리뷰를 확인하고 추천 리뷰 지정을 편리하게 해보세요."
  },
  "button": {
    "sort": {
      "created_at_desc": "최신순",
      "created_at_asc": "작성일순",
      "plus_likes_count_desc": "도움이 많이된 순",
      "score_asc": "별점 낮은순",
      "delivered_at_desc": "최근 배송완료된 순",
      "ai_recommendation": "AI 추천순"
    }
  },
  "table_header": {
    "id": "ID",
    "position": "번호",
    "date": "작성일",
    "customer": "고객정보",
    "review": "리뷰 상세 내용",
    "tags": "태그",
    "actions": "{point_type} 지급/차감 및 리뷰관리",
    "sub_brand_name": "서브 브랜드",
    "ai_recommendation_score": "AI 추천 점수",
    "ai_recommendation_score_tooltip": "크리마 AI가 분석한 리뷰의 추천 점수입니다.\n100점에 가까울수록 우수한 리뷰입니다."
  },
  "batch": {
    "give_mileage": "{point_type} 지급",
    "deduct_mileage": "{point_type} 차감",
    "comment": "댓글",
    "comment_title": "선택 리뷰 댓글 작성",
    "show": "진열",
    "show_confirm": "선택한 {count}개의 리뷰를 숨김 해제합니다. 진행하시겠습니까?",
    "show_tooltip": "선택한 리뷰 중 숨김 상태의 리뷰가 있으면 숨김 해제합니다.",
    "show_title": "선택 리뷰 진열",
    "hide": "숨김",
    "hide_confirm": "선택한 {count}개의 리뷰를 숨김 처리합니다. 진행하시겠습니까?",
    "hide_tooltip": "선택한 리뷰중 진열 상태의 리뷰를 숨김 처리 합니다.",
    "hide_title": "선택 리뷰 숨김",
    "tag": "태그 수정",
    "tag_title": "선택 리뷰 태그 수정",
    "give_mileage_tooltip": "선택한 리뷰에 {point_type}((을)) 지급합니다.",
    "give_mileage_title": "선택 리뷰 {point_type} 지급",
    "deduct_mileage_confirm": "선택한 {count}개의 리뷰에 지급한 {point_type}((을)) 차감합니다. 진행하시겠습니까?",
    "deduct_mileage_tooltip": "선택한 리뷰 중 {point_type}((을)) 지급한 리뷰의 모든 {point_type}((을)) 차감합니다.",
    "deduct_mileage_title": "선택 리뷰 {point_type} 차감",
    "comment_tooltip": "선택한 리뷰에 댓글을 작성합니다.",
    "tag_tooltip": "선택한 리뷰에 태그를 추가하거나 삭제합니다.",
    "delete_confirm": "선택한 {count}개의 리뷰를 삭제합니다. 진행하시겠습니까?",
    "delete_tooltip": "선택한 리뷰를 삭제합니다.",
    "delete_title": "선택 리뷰 삭제"
  },
  "new_tag": "+태그추가",
  "bookmark": {
    "disabled_tooltip": "설정된 책갈피가 없습니다",
    "go_to_bookmark": "책갈피로 이동",
    "confirm_reset_filter": "책갈피 설정한 리뷰가 현재 검색목록에 없습니다. 검색을 초기화하고 책갈피로 이동하시겠습니까?",
    "bookmark_removed": "책갈피가 삭제되어 이동할 수 없습니다."
  },
  "review_tags_alert": {
    "title": "키워드별 분석 항목 설정이 비어있습니다",
    "markdown_text": "키워드별 분석 항목의 '키워드' 혹은 '분석결과'를 입력하지 않았습니다.<br><br>'키워드' 혹은 '분석결과'를 입력하지 않으면 검색결과에 영향을 주지 않습니다.",
    "toast": "'이 메시지를 다시 표시하지 않습니다.' 설정이 저장된 상태입니다. 'Clear localStorage' 버튼을 클릭하여 저장 내역을 삭제해주세요."
  }
}
</i18n>

<i18n locale="en">
{
  "download_csv": "Download CSV",
  "download_csv_tooltip": "Click the period setting button located on the right to select the period of reviews and comments to be downloaded.",
  "download_csv_menu": {
    "review": "Download Review CSV",
    "comment": "Download Comment CSV",
    "confirm": {
      "review_title": "Download the review CSV.",
      "review_markdown_text": "For photos/videos, the link is available for 7 days from the date of delivery."
    }
  },
  "review_search_type": {
    "user_id": "User ID",
    "user_name": "User Name",
    "phone_number": "User Phone Number",
    "product_name": "Product Name",
    "review_id": "Review ID",
    "order_code": "Order ID",
    "review_message": "Review Message",
    "product_code": "Product Code",
    "product_id": "Product ID"
  },
  "review_date_type": {
    "review_created_at": "Review Created At",
    "sub_order_delivered_at": "Delivered At",
    "admin_commented_at": "Commented At",
    "order_purchased_at": "Purchased At",
    "order_created_at": "Order Created At"
  },
  "button": {
    "sort": {
      "created_at_desc": "Created At Desc",
      "created_at_asc": "Created At Asc",
      "plus_likes_count_desc": "Likes Count Desc",
      "score_asc": "Score Asc",
      "delivered_at_desc": "Delivered At Desc",
      "ai_recommendation": "AI Recommendation"
    }
  },
  "table_header": {
    "id": "ID",
    "position": "Position",
    "date": "Date",
    "customer": "Customer",
    "review": "Message",
    "tags": "Tags",
    "actions": "Review Actions",
    "sub_brand_name": "Sub Brand",
    "ai_recommendation_score": "AI Recommendation Score",
    "ai_recommendation_score_tooltip": "Score analyzed by CREMA AI\nOn a scale of 0 to 100"
  },
  "batch": {
    "give_mileage": "Give {point_type}",
    "deduct_mileage": "Cancel to given {point_type}",
    "comment": "Comment",
    "comment_title": "Write comment on selected reviews",
    "show": "Show",
    "show_confirm": "Unhide {count} selected reviews. Would you like to proceed?",
    "show_tooltip": "If any of the selected reviews are hidden, unhide them.",
    "show_title": "Show selected reviews",
    "hide": "Hide",
    "hide_confirm": "Hide selected {count} reviews. Would you like to proceed?",
    "hide_tooltip": "If any of the selected reviews are shown, hide them.",
    "hide_title": "Hide selected reviews",
    "tag": "Add tags",
    "tag_title": "Add tags to selected reviews",
    "give_mileage_tooltip": "{point_type} will be awarded for selected reviews.",
    "give_mileage_title": "Give {point_type} to selected reviews",
    "deduct_mileage_confirm": "Cancel {point_type} awarded for {count} selected reviews. Would you like to proceed?",
    "deduct_mileage_tooltip": "Cancel to give {point_type} to selected reviews",
    "deduct_mileage_title": "Candel {point_type} to selected reviews",
    "comment_tooltip": "Write a comment on the selected review.",
    "tag_tooltip": "Add or remove tags to selected reviews.",
    "delete_confirm": "Delete {count} selected reviews. Would you like to proceed?",
    "delete_tooltip": "Delete the selected reviews.",
    "delete_title": "Delete selected review"
  },
  "new_tag": "+Add Tag",
  "bookmark": {
    "disabled_tooltip": "No bookmark set",
    "go_to_bookmark": "Go To Bookmark",
    "confirm_reset_filter": "No results. Do you want to reset your search and go to your bookmarks?",
    "bookmark_removed": "Bookmark has been deleted and cannot be moved."
  }
}
</i18n>
