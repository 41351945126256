var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ReviewMessagesMessageSettings"},[_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppForm',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id == 'sending_limit_per_day')?[_c('div',{staticClass:"AppForm__group-field"},[_vm._v(" "+_vm._s(_vm.$t('common_settings.sending_limit_per_day.description'))+" "),_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"name":"message_settings[sending_limit_per_day_enabled]","options":_vm.SENDING_LIMIT_PER_DAY_OPTIONS},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
var checked = ref.checked;
return [(value)?_c('i18n',{attrs:{"path":"common_settings.sending_limit_per_day.options.limited"}},[_c('AppNumberInput',{attrs:{"name":"message_settings[sending_limit_per_day]","digits":3,"disabled":!checked},on:{"blur":function($event){return _vm.validateField('sending_limit_per_day')},"change":function($event){return _vm.validateField('sending_limit_per_day')}},model:{value:(_vm.formObject.sending_limit_per_day),callback:function ($$v) {_vm.$set(_vm.formObject, "sending_limit_per_day", $$v)},expression:"formObject.sending_limit_per_day"}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        message: _vm.$t(
                          'common_settings.sending_limit_per_day.tooltip.message'
                        ),
                        textAlign: 'left',
                        horizontal: true
                      }),expression:"{\n                        message: $t(\n                          'common_settings.sending_limit_per_day.tooltip.message'\n                        ),\n                        textAlign: 'left',\n                        horizontal: true\n                      }"}],staticClass:"ReviewMessagesMessageSettings__sending-limit-per-day-tooltip"},[_vm._v(_vm._s(_vm.$t( 'common_settings.sending_limit_per_day.tooltip.label' )))])],1):_vm._e()]}}],null,true),model:{value:(_vm.formObject.sending_limit_per_day_enabled),callback:function ($$v) {_vm.$set(_vm.formObject, "sending_limit_per_day_enabled", $$v)},expression:"formObject.sending_limit_per_day_enabled"}})],1)])]:(id === 'sending_delay_and_time')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":"common_settings.sending_delay_and_time.description"}},[_c('AppNumberInput',{attrs:{"digits":2,"name":"message_settings[sending_delay_after_delivery_in_days]"},model:{value:(_vm.formObject.sending_delay_after_delivery_in_days),callback:function ($$v) {_vm.$set(_vm.formObject, "sending_delay_after_delivery_in_days", $$v)},expression:"formObject.sending_delay_after_delivery_in_days"}}),_c('AppSelectHour',{attrs:{"name":"message_settings[sending_start_hour]","interval":"half","selectable-hours":_vm.SENDING_START_HOUR_OPTIONS},model:{value:(_vm.formObject.sending_start_hour),callback:function ($$v) {_vm.$set(_vm.formObject, "sending_start_hour", $$v)},expression:"formObject.sending_start_hour"}})],1)],1),_c('AppFormHint',{attrs:{"message":_vm.$t('common_settings.sending_delay_and_time.hint', [
                _vm.formattedSendingStartHour
              ])}})]:(id === 'email_preview')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppButton',{attrs:{"label":_vm.$t(
                  'dispatch_method_settings.email_preview.open_email_preview_button_label'
                )},on:{"click":_vm.openEmailPreviewDialog}}),_c('AppButton',{attrs:{"label":_vm.$t(
                  'dispatch_method_settings.email_preview.send_sample_email_button_label'
                )},on:{"click":_vm.sendSampleEmail}})],1)]:_vm._e()]}},{key:"group-foot",fn:function(ref){
                var id = ref.id;
return [(id === 'message_sending_enabled')?[_c('div',{staticClass:"ReviewMessagesMessageSettings__dispatch-method"},[_c('AppFormHint',{attrs:{"message":_vm.messageSendingHint}}),(
                _vm.isSuperAdminAccessible ||
                  _vm.isDispatchMethodBizMessageOnlyEnabled
              )?_c('div',{class:{
                'super-admin__item': !_vm.isDispatchMethodBizMessageOnlyEnabled
              }},[_c('label',{class:[
                  'AppForm__group-title',
                  _vm.formObject.message_sending_enabled
                    ? ''
                    : 'AppForm__group-title--disabled'
                ]},[_vm._v(_vm._s(_vm.$t('dispatch_method_settings.dispatch_method')))]),_c('AppSelectRadio',{staticClass:"AppForm__group-field",attrs:{"name":"message_settings[dispatch_method]","options":_vm.DISPATCH_METHOD_OPTIONS,"disabled":!_vm.formObject.message_sending_enabled},model:{value:(_vm.formObject.dispatch_method),callback:function ($$v) {_vm.$set(_vm.formObject, "dispatch_method", $$v)},expression:"formObject.dispatch_method"}})],1):_vm._e()],1)]:_vm._e()]}}])},'AppForm',Object.assign({}, _vm.formProps,
        {objectId: 'message_settings',
        submitButton: true,
        formStyle: 'hor'}),false),_vm.formEvents))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }