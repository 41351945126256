var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"ReviewSettingsWidgetsNormal",attrs:{"is-loading":_vm.isLoading}},[(_vm.shouldDisplayAppSearchBar)?_c('AppSearchbar',[_c('li',[_c('AppButton',{class:_vm.isWidgetTypeEtc ? 'super-admin__item' : '',attrs:{"label":_vm.$t('head.new_widget', { widget_type: _vm.WidgetType.t(_vm.widgetType) }),"button-style":"red","disabled":_vm.isWidgetTypeProductScore,"tooltip":_vm.isWidgetTypeProductScore
            ? _vm.$t('tooltip.disabled_product_score_widget')
            : null},on:{"click":_vm.newWidgetClicked}})],1)]):_vm._e(),_c('AppResourceTable',{attrs:{"table-id":"widgets-table","columns":_vm.columns,"rows":_vm.rows,"event-bus":_vm.tableEventBus},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
            var column = ref.column;
            var value = ref.value;
            var rowIndex = ref.rowIndex;
return [(column === 'widget_display_status')?_c('AppBadge',{attrs:{"badge-style":value ? 'mint-green' : 'default',"label":value
            ? _vm.$t('widget_display_status.displayed')
            : _vm.$t('widget_display_status.hidden')}}):(column === 'widget_view_status')?[(
            !_vm.isWidgetTypeManagingReviews(rowIndex) &&
              _vm.selectedWidget(rowIndex).inserted_url
          )?_c('AppExternalLinkWithIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('review_widgets.found_inserted_url')),expression:"$t('review_widgets.found_inserted_url')"}],attrs:{"to":_vm.widgetInsertedUrl(rowIndex),"label":value,"tabindex":"-1"}}):_c('div',[_vm._v(_vm._s(value))])]:(column === 'actions')?[(
            (_vm.isSuperAdminAccessible || _vm.isCurrentUserInstallationAgency) &&
              _vm.isSmartInstallationSupported
          )?_c('AppButton',{staticClass:"super-admin__item",attrs:{"label":_vm.smartInstallLabel(rowIndex)},on:{"click":function($event){return _vm.widgetSmartInstallClicked(rowIndex)}}}):_vm._e(),_c('AppButton',{attrs:{"label":_vm.$t('app.settings')},on:{"click":function($event){return _vm.widgetSettingsClicked(rowIndex)}}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"disabled":_vm.isWidgetTypeManagingReviews(rowIndex),"tooltip":_vm.manageButtonTooltip(rowIndex),"menu-items":[
            _vm.selectedWidget(rowIndex).widget_type !== _vm.WidgetType.ETC
              ? [
                  {
                    label: _vm.$t('table_body.copy_script'),
                    clickHandler: function () { return _vm.widgetCopyScriptClicked(rowIndex); }
                  },
                  {
                    label: _vm.$t('review_widgets.move_to_widget_inserted_url'),
                    type: 'external_link',
                    to: _vm.widgetInsertedUrl(rowIndex),
                    disabled: !_vm.selectedWidget(rowIndex).inserted_url,
                    tooltip: _vm.selectedWidget(rowIndex).inserted_url
                      ? null
                      : _vm.$t('review_widgets.not_found_inserted_url')
                  } ].concat( (_vm.isSuperAdminAccessible
                    ? [
                        {
                          label: _vm.$t('review_widgets.move_to_widget_page'),
                          type: 'external_link',
                          to: _vm.selectedWidget(rowIndex).widget_shortcut_url,
                          superAdmin: true
                        },
                        {
                          label: _vm.$t('review_widgets.override_widget_setting'),
                          superAdmin: true,
                          clickHandler: function () { return _vm.overrideWidgetSettingClicked(rowIndex); }
                        }
                      ]
                    : _vm.isCurrentUserInstallationAgency
                    ? [
                        {
                          label: _vm.$t('review_widgets.move_to_widget_page'),
                          type: 'external_link',
                          to: _vm.selectedWidget(rowIndex).widget_shortcut_url
                        },
                        {
                          label: _vm.$t('review_widgets.override_widget_setting'),
                          clickHandler: function () { return _vm.overrideWidgetSettingClicked(rowIndex); }
                        }
                      ]
                    : [])
                )
              : [],
            [
              {
                label: _vm.$t('review_widgets.delete_widget'),
                clickHandler: function () { return _vm.deleteWidget(_vm.reviewWidgets[rowIndex].id, rowIndex); },
                style: 'danger',
                disabled:
                  _vm.reviewWidgets[rowIndex].default ||
                  (!_vm.isSuperAdminAccessible &&
                    !_vm.isCurrentUserInstallationAgency),
                tooltip: _vm.reviewWidgets[rowIndex].default
                  ? _vm.$t('review_widgets.tooltip_cannot_delete_default')
                  : !_vm.isSuperAdminAccessible &&
                    !_vm.isCurrentUserInstallationAgency
                  ? _vm.$t('review_widgets.tooltip_must_be_admin')
                  : null
              }
            ]
          ]}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }