<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="isGroupTemplate ? $t('subtitle_group') : $t('subtitle_normal')"
    :form-props="{
      ...formProps,
      objectId: 'template',
      submitLabel: inspectDirectly ? $t('submit_and_inspect') : $t('app.save')
    }"
    width="560px"
    v-bind="{ isLoading }"
    v-on="formEvents"
    @change-group="changeGroup"
  >
    <template #left>
      <BizMessageTemplatePreview :template="formObject" />
    </template>
    <template #group="{ id, maxlength, placeholder, error, disabled }">
      <template v-if="['header', 'subtitle'].includes(id)">
        <div class="AppForm__group-field">
          <AppTextInputWithLength
            v-model="formObject[id]"
            :name="`template[${id}]`"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :invalid="!!error"
            @change="validateField(id)"
            @blur="validateField(id)"
          />
        </div>
      </template>
      <template v-else-if="id === 'item_list'">
        <div class="AppForm__group-field">
          <BizMessageTemplateItemList
            v-model="formObject.item_list"
            :invalid="!!error"
            @change="validateField(id)"
          />
        </div>
      </template>
      <template v-else-if="id === 'buttons'">
        <div class="AppForm__group-field">
          <BizMessageTemplateButtons
            :buttons="formObject.buttons"
            :disabled="disabled"
            @add="clickAddButton"
            @sort="sortButtons"
            @edit="clickEditButton"
            @delete="clickDeleteButton"
          />
          <div v-for="(button, i) in buttonFields" :key="i">
            <input
              v-for="key in [
                'id',
                'name',
                'button_type',
                'mobile_link',
                'pc_link',
                'android_link',
                'ios_link',
                'position',
                '_destroy'
              ]"
              :key="key"
              type="hidden"
              :name="'template[buttons_attributes][][' + key + ']'"
              :value="button[key]"
            />
          </div>
        </div>
      </template>
    </template>
    <template #description="{ id }">
      <template v-if="id === 'image'">
        <div class="AppForm__description">
          <AppExternalLink
            class="AppForm__description-link"
            to="https://kakaobusiness.gitbook.io/main/ad/bizmessage/notice-friend/content-guide/image"
          >
            {{ $t('template.image_description') }}
            <AppExternalLinkIcon size="small" />
          </AppExternalLink>
        </div>
      </template>
    </template>
    <template #foot>
      <div class="BizMessageTemplateFormDialog__foot">
        <AppSvgIcon
          class="BizMessageTemplateFormDialog__foot-icon"
          name="icon-info-tooltip"
        />
        {{ $t('foot') }}
      </div>
    </template>
    <input
      v-if="!shouldDisplayServiceType"
      type="hidden"
      name="template[service_type]"
      :value="serviceTypes[0]"
    />
    <input
      v-if="isServiceTypeReview"
      type="hidden"
      name="template[category_code]"
      :value="formObject.category_code"
    />
  </AppModalFormLivePreview>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import { arrayMoveIndex } from '@/lib/array';
import DialogSize from '@/enums/DialogSize';
import BizMessageTemplateMessageType from '@/enums/BizMessageTemplateMessageType';
import BizMessageTemplateEmphasizeType from '@/enums/BizMessageTemplateEmphasizeType';
import BizMessageTemplateButtonType from '@/enums/BizMessageTemplateButtonType';
import BizMessageTemplatePreview from './BizMessageTemplatePreview';
import BizMessageTemplateItemList from './BizMessageTemplateItemList';
import BizMessageTemplateButtons from './BizMessageTemplateButtons';
import CremaServiceType from '@/enums/CremaServiceType';
import { mapGetters, mapActions } from 'vuex';

const REVIEW_DEFAULT_CATEGORY_CODE = '006003';

export default {
  name: 'BizMessageTemplateFormDialog',
  components: {
    BizMessageTemplatePreview,
    BizMessageTemplateItemList,
    BizMessageTemplateButtons
  },
  mixins: [DialogFormView],
  props: {
    serviceType: { type: Number, default: null },
    templateId: { type: Number, default: null },
    isApproved: { type: Boolean, default: false },
    inspectDirectly: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: true,
      categories: [],
      templateNames: [],
      templatePublicNames: [],
      deletedButtons: []
    };
  },
  computed: {
    ...mapGetters('session', [
      'isReviewUsingBizMessage',
      'isTargetOperatingOrExpired'
    ]),
    ...mapGetters('bizMessageTemplate', ['isGroupTemplate']),
    serviceTypes() {
      if (this.serviceType !== null) return [this.serviceType];

      return [
        this.isReviewUsingBizMessage ? CremaServiceType.REVIEW : null,
        this.isTargetOperatingOrExpired ? CremaServiceType.TARGET : null
      ].filter(v => v);
    },
    shouldDisplayServiceType() {
      return this.serviceTypes.length > 1;
    },
    groupOptions() {
      return {
        rejected_message: {
          label: null,
          type: 'alert_box',
          title: this.$t('template.rejected_message')
        },
        hide: { type: 'checkbox' },
        service_type: {
          type: 'select',
          options: CremaServiceType.options(this.serviceTypes)
        },
        name: {
          type: 'textarea',
          rows: 1,
          placeholder: this.$t('template.name_placeholder'),
          maxlength: 30,
          disabled: this.isApproved,
          labelDisabled: this.isApproved,
          validate: [
            'required',
            {
              rule: v => !this.templateNames.includes(v),
              errorMessage: this.$t('template.name_must_be_unique')
            }
          ]
        },
        public_name: {
          type: 'textarea',
          rows: 1,
          placeholder: this.$t('template.name_placeholder'),
          groupDescription: this.$t('template.public_name_description'),
          maxlength: 30,
          validate: [
            {
              rule: v => !this.templatePublicNames.includes(v),
              errorMessage: this.$t('template.name_must_be_unique')
            }
          ]
        },
        category_code: {
          type: 'select_filterable',
          options: this.categories.map(e => ({
            label: e.name,
            value: e.code
          })),
          groupDescription: this.$t('template.category_code_group_description'),
          placeholder: this.$t('template.category_code_placeholder'),
          disabled: this.isApproved,
          labelDisabled: this.isApproved
        },
        message_type: {
          type: 'select',
          options: BizMessageTemplateMessageType.options(),
          disabled: true, // TODO: 알림톡 2차 개선 작업 시 활성화
          labelDisabled: true, // TODO: 알림톡 2차 개선 작업 시 활성화
          superAdmin: true
        },
        emphasize_type: {
          type: 'select_radio',
          options: BizMessageTemplateEmphasizeType.options(),
          disabled: true, // TODO: 알림톡 2차 개선 작업 시 활성화
          labelDisabled: true, // TODO: 알림톡 2차 개선 작업 시 활성화
          superAdmin: true
        },
        header: {
          type: 'custom',
          placeholder: this.$t('template.header_placeholder'),
          maxlength: 16,
          disabled: this.isApproved,
          labelDisabled: this.isApproved
        },
        title: {
          type: 'textarea',
          rows: 1,
          validate: ['required'],
          disabled: this.isApproved,
          labelDisabled: this.isApproved,
          ...this.titleOptions
        },
        subtitle: {
          type: 'custom',
          validate: ['required'],
          disabled: this.isApproved,
          labelDisabled: this.isApproved,
          ...this.subtitleOptions
        },
        image: {
          type: 'image',
          placeholder: this.$t('template.image_placeholder'),
          validate: ['required'],
          disabled: this.isApproved,
          labelDisabled: this.isApproved
        },
        highlight_image: {
          type: 'image',
          placeholder: this.$t('template.highlight_image_placeholder'),
          disabled: this.isApproved,
          labelDisabled: this.isApproved
        },
        item_list: {
          type: 'custom',
          groupDescription: this.$t('template.item_list_group_description'),
          disabled: this.isApproved,
          labelDisabled: this.isApproved,
          validate: [
            {
              rule: v =>
                v.length === 0 ||
                v.filter(e => e.title && e.description).length >= 2,
              errorMessage: this.$t('template.item_list_validation')
            }
          ]
        },
        content: {
          type: 'keyword_textarea',
          keywords: this.keywords,
          placeholder: this.$t('template.content_placeholder'),
          groupDescription: this.$t('template.content_description'),
          autosize: true,
          maxlength: 1000,
          disabled: this.isApproved,
          labelDisabled: this.isApproved,
          validate: [
            'required',
            { rule: 'max_length', limit: 1000 },
            { rule: 'keywords', keywords: this.keywords }
          ]
        },
        extra: {
          type: 'textarea',
          placeholder: this.$t('template.extra_placeholder'),
          autosize: true,
          maxlength: 500,
          disabled: this.isApproved,
          labelDisabled: this.isApproved,
          validate: ['required']
        },
        buttons: {
          type: 'custom',
          groupDescription: this.$t('template.buttons_group_description'),
          disabled: this.isApproved,
          labelDisabled: this.isApproved
        }
      };
    },
    titleOptions() {
      switch (this.formObject.emphasize_type) {
        case BizMessageTemplateEmphasizeType.TEXT:
          return {
            placeholder: this.$t('template.title_placeholder_text'),
            maxlength: 23
          };
        case BizMessageTemplateEmphasizeType.ITEM_LIST:
          return {
            placeholder: this.$t('template.title_placeholder_item_list'),
            maxlength:
              this.formObject.highlight_image &&
              !this.formObject.remove_highlight_image
                ? 20
                : 30
          };
        default:
          return {};
      }
    },
    subtitleOptions() {
      switch (this.formObject.emphasize_type) {
        case BizMessageTemplateEmphasizeType.TEXT:
          return {
            placeholder: this.$t('template.subtitle_placeholder_text'),
            maxlength: 18
          };
        case BizMessageTemplateEmphasizeType.ITEM_LIST:
          return {
            placeholder: this.$t('template.subtitle_placeholder_item_list'),
            maxlength:
              this.formObject.highlight_image &&
              !this.formObject.remove_highlight_image
                ? 13
                : 19
          };
        default:
          return {};
      }
    },
    groupIds() {
      return [
        this.formObject.rejected_message ? 'rejected_message' : null,
        this.isGroupTemplate ? 'hide' : null,
        this.shouldDisplayServiceType ? 'service_type' : null,
        'name',
        this.isGroupTemplate ? 'public_name' : null,
        this.isServiceTypeReview ? null : 'category_code',
        'message_type',
        'emphasize_type',
        ...this.emphasizeTypeGroupIds,
        'content',
        ...this.messageTypeGroupIds
      ].filter(v => v);
    },
    emphasizeTypeGroupIds() {
      switch (this.formObject.emphasize_type) {
        case BizMessageTemplateEmphasizeType.TEXT:
          return ['title', 'subtitle'];
        case BizMessageTemplateEmphasizeType.IMAGE:
          return ['image'];
        case BizMessageTemplateEmphasizeType.ITEM_LIST:
          return [
            'header',
            'title',
            'subtitle',
            'highlight_image',
            'item_list'
          ];
        default:
          return [];
      }
    },
    messageTypeGroupIds() {
      const useExtra = [
        BizMessageTemplateMessageType.EXTRA_INFO,
        BizMessageTemplateMessageType.MIXED
      ].includes(this.formObject.message_type);
      return [...(useExtra ? ['extra'] : []), 'buttons'];
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => ({
            id,
            label: this.$t(`template.${id}`),
            ...this.groupOptions[id]
          }))
        }
      ];
    },
    buttonFields() {
      const buttonFields = [];
      this.formObject.buttons.forEach((button, i) =>
        buttonFields.push({ ...button, position: i + 1 })
      );
      this.deletedButtons.forEach(button => {
        if (button.id) buttonFields.push({ id: button.id, _destroy: true });
      });

      return buttonFields;
    },
    isServiceTypeReview() {
      return this.formObject.service_type === CremaServiceType.REVIEW;
    },
    keywords() {
      return this.isServiceTypeReview
        ? this.reviewKeywords
        : this.targetKeywords;
    },
    reviewKeywords() {
      const keys = [
        'user',
        // 'user_id', TODO: 알림톡 2차 개선 작업 시 활성화
        'brand',
        // 'url',
        'brand_phone',
        'link_id'
        /*
        'order_name',
        'product',
        'review_date'
        */
      ];
      return keys.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`)
      }));
    },
    targetKeywords() {
      return [
        'user',
        'user_id',
        'brand',
        'url',
        'unpaid_orders_count',
        'days_before_inactivation',
        'coupon_name',
        'coupon_expire',
        'days_before_coupon_expiration',
        'days_before_expiration',
        'brand_phone',
        'link_id'
      ].map(key => ({ key, desc: this.$t(`keywords.${key}`) }));
    },
    defaultReviewTemplateOptions() {
      return {
        category_code: REVIEW_DEFAULT_CATEGORY_CODE,
        buttons: [
          {
            position: 1,
            button_type: BizMessageTemplateButtonType.WEB_LINK,
            name: this.$t('template.review_default_button'),
            pc_link: 'http://cre.ma/%{link_id}',
            mobile_link: 'http://cre.ma/%{link_id}'
          }
        ]
      };
    },
    defaultTargetTemplateOptions() {
      return { category_code: null, buttons: [] };
    }
  },
  mounted() {
    const promise = this.templateId
      ? this.editTemplate(this.templateId)
      : this.newTemplate();
    promise
      .then(({ data }) => {
        this.categories = data.categories;
        this.templateNames = data.template_names;
        this.templatePublicNames = data.template_public_names || [];

        const { template } = data;
        if (!this.templateId)
          template.service_type = this.serviceType || this.serviceTypes[0];

        this.orgFormObject = template;
        this.$nextTick(() => {
          if (!this.templateId) this.initTemplate();
        });
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('bizMessageTemplate', [
      'newTemplate',
      'editTemplate',
      'createTemplate',
      'updateTemplate',
      'requestTemplateInspection'
    ]),
    ...mapActions('dialog', ['openDialog', 'confirm']),
    submit(formData) {
      return this.templateId
        ? this.requestUpdateTemplate(formData)
        : this.requestCreateTemplate(formData);
    },
    requestDirectInspection(template) {
      const formData = new FormData();
      formData.append('template_inspection[comment]', '');
      this.requestTemplateInspection({ id: template.id, formData });
    },
    requestUpdateTemplate(formData) {
      this.isSubmitting = true;
      return this.updateTemplate({
        id: this.templateId,
        formData,
        successMessage: this.$t('app.saved')
      })
        .then(template => {
          if (this.inspectDirectly) this.requestDirectInspection(template);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    requestCreateTemplate(formData) {
      this.isSubmitting = true;
      return this.createTemplate({
        formData,
        successMessage: this.$t('created')
      })
        .then(template => {
          this.close(true);
          if (this.inspectDirectly) {
            this.requestDirectInspection(template);
            return;
          }

          this.confirm({
            title: this.$t('inspect_template_confirm.title'),
            message: this.$t('inspect_template_confirm.message'),
            width: DialogSize.AUTO,
            closeButtonLabel: this.$t('inspect_template_confirm.close_button')
          }).then(result => {
            if (!result) return;

            this.openDialog([
              'BizMessageTemplateInspectionRequestDialog',
              { templateId: template.id }
            ]);
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
    clickAddButton() {
      this.openDialog('BizMessageTemplateButtonFormDialog').then(eventBus =>
        eventBus.$on('save', button => this.addButton(button))
      );
    },
    addButton(button) {
      this.formObject.buttons = [...this.formObject.buttons, button];
    },
    sortButtons({ srcIndex, dstIndex }) {
      this.formObject.buttons = arrayMoveIndex(
        this.formObject.buttons,
        srcIndex,
        dstIndex
      );
    },
    clickEditButton({ close, rowIndex }) {
      this.openDialog([
        'BizMessageTemplateButtonFormDialog',
        { button: this.formObject.buttons[rowIndex] }
      ]).then(eventBus =>
        eventBus.$on('save', button => this.updateButton(button, rowIndex))
      );
      close();
    },
    updateButton(button, rowIndex) {
      this.formObject.buttons = this.formObject.buttons.map((b, i) =>
        i === rowIndex ? button : b
      );
    },
    clickDeleteButton({ close, rowIndex }) {
      close();
      // Wait a tick for AppDropdownMenu to get destroyed before removing the button which is its parent
      this.$nextTick(() => {
        const deletedButton = this.formObject.buttons[rowIndex];
        this.formObject.buttons = this.formObject.buttons.filter(
          (b, i) => i !== rowIndex
        );
        this.deletedButtons.push(deletedButton);
      });
    },
    changeGroup(group) {
      if (group.id === 'message_type') this.changeMessageType(group.value);
      else if (group.id === 'service_type') this.initTemplate();
    },
    changeMessageType(value) {
      if (
        ![
          BizMessageTemplateMessageType.CHANNEL_ADD,
          BizMessageTemplateMessageType.MIXED
        ].includes(value)
      )
        return;

      if (
        this.formObject.buttons[0]?.button_type ===
        BizMessageTemplateButtonType.ADD_CHANNEL
      )
        return;

      this.formObject.buttons = [
        {
          name: BizMessageTemplateButtonType.t(
            BizMessageTemplateButtonType.ADD_CHANNEL
          ),
          button_type: BizMessageTemplateButtonType.ADD_CHANNEL
        },
        ...this.formObject.buttons
      ];
    },
    initTemplate() {
      this.formObject = {
        ...this.formObject,
        ...(this.isServiceTypeReview
          ? this.defaultReviewTemplateOptions
          : this.defaultTargetTemplateOptions)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.BizMessageTemplateFormDialog__foot {
  margin-top: 8px;
  color: $color-grey-60;
}
.BizMessageTemplateFormDialog__foot-icon {
  position: relative;
  top: 2px;
}
</style>

<i18n locale="ko">
{
  "title": "템플릿 메시지 설정",
  "subtitle_normal": "알림톡 템플릿 관리",
  "subtitle_group": "알림톡 그룹 템플릿 관리",
  "message": "안내 메시지 본문입니다.",
  "template": {
    "rejected_message": "템플릿 반려 사유",
    "hide": "이 템플릿을 일반 관리자에게 숨기기",
    "service_type": "제품",
    "name": "템플릿 명",
    "name_placeholder": "예시) 크리마_리뷰작성유도001",
    "public_name": "일반 관리자에게 표시할 템플릿 명",
    "public_name_description": "템플릿 명 변경이 필요한 경우에 사용해주세요.",
    "name_must_be_unique": "이 프로필에서 이미 사용중인 템플릿 명입니다. 다른 템플릿 명을 입력해주세요.",
    "category_code": "템플릿 카테고리",
    "category_code_group_description": "카테고리를 입력한 템플릿을 우선 심사하므로 입력을 권장드립니다.",
    "category_code_placeholder": "카테고리를 선택해주세요.",
    "message_type": "메시지 유형",
    "emphasize_type": "강조 유형",
    "header": "헤더",
    "header_placeholder": "최대 16자까지 입력 가능합니다. (변수 사용 가능)",
    "title": "타이틀",
    "title_placeholder_text": "최대 2줄 23자까지 입력 가능합니다.",
    "title_placeholder_item_list": "최대 30자까지 입력 가능합니다. (썸네일 사용시엔 최대 20자)",
    "subtitle": "서브 타이틀",
    "subtitle_placeholder_text": "최대 18자까지 입력 가능합니다.",
    "subtitle_placeholder_item_list": "최대 19자까지 입력 가능합니다. (썸네일 사용시엔 최대 13자)",
    "image": "이미지 첨부",
    "image_placeholder": "이미지를 등록해주세요. (800×400px / JPG, PNG / 최대 500KB)",
    "image_description": "카카오 이미지 가이드",
    "highlight_image": "썸네일 이미지 (1:1 비율)",
    "highlight_image_placeholder": "이미지를 등록해주세요. (108x108px이상 / JPG, PNG / 최대 500KB)",
    "item_list": "아이템 리스트",
    "item_list_group_description": "아이템 리스트는 최소 2개, 최대 10개까지 등록 가능합니다.",
    "item_list_validation": "아이템 리스트 사용을 원하실 경우 2개 이상의 아이템을 입력해주세요.",
    "content": "템플릿 내용",
    "content_placeholder": "한/영 구분없이 1,000자까지 입력 가능합니다.",
    "content_description": "개인화된 텍스트 영역은 변수로 작성할 수 있습니다.",
    "extra": "부가정보",
    "extra_placeholder": "한/영 구분없이 500자까지 입력 가능합니다. (변수 사용 불가, URL 포함 가능)",
    "buttons": "버튼 설정",
    "review_default_button": "배송완료상품 확인",
    "buttons_group_description": "버튼은 최대 5개까지 추가할 수 있습니다."
  },
  "keywords": {
    "user": "고객명",
    "user_id": "고객 아이디",
    "brand": "쇼핑몰명",
    "url": "랜딩페이지 URL",
    "brand_phone": "쇼핑몰 대표 전화번호",
    "link_id": "짧은 URL을 위한 Key값",
    "order_name": "주문명",
    "product": "상품명",
    "review_date": "날짜 (리뷰 작성 마감일)",
    "unpaid_orders_count": "무통장 미입금 주문 수",
    "days_before_inactivation": "휴면회원 전환예정 일수",
    "days_before_expiration": "적립금 지급 기한 만료 잔여일 수",
    "days_before_coupon_expiration": "쿠폰 만료 잔여일",
    "coupon_name": "쿠폰명",
    "coupon_expire": "쿠폰 만료일"
  },
  "foot": "검수를 원하실 경우 저장 후 템플릿 페이지에서 검수요청 버튼을 눌러주세요.",
  "created": "새로운 알림톡 템플릿을 추가했습니다.",
  "inspect_template_confirm": {
    "title": "입력된 템플릿으로 검수요청을 하시겠습니까?",
    "message": "템플릿을 사용하려면 카카오 검수 후 승인을 받아야 합니다. (약 2~3일 소요)<br />카카오 정책에 벗어난 문구나 내용이 있을 경우 반려 처리됩니다.",
    "close_button": "검수요청 하러 가기"
  },
  "submit_and_inspect": "저장 및 검수요청"
}
</i18n>
