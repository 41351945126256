import { constructEnum } from '@/lib/enum';

export default constructEnum('ReviewTransactionType', {
  PAYMENT: 10,
  FEE_PAYMENT: 11,
  SMS_PAYMENT: 15,
  SMS_SEND: 20,
  SMS_RESET: 30,
  SMS_BONUS: 40,
  SMS_AMOUNT_BONUS: 42,
  PERIOD_BONUS: 45,
  FREE_TRIAL: 46,
  EXPIRED: 50,
  SCHEDULED_TERMINATION: 51,
  FORCE_TERMINATION: 52,
  RETURN: 60,
  FEE_RETURN: 61,
  SMS_RETURN: 70,
  ETC: 100
});
