<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppDataItem
      :label="$t('profile')"
      :badges="[profileTypeBadge, sendStatusBadge]"
    >
      <div>{{ $t('registered', [channelId]) }}</div>
      <div
        v-if="!enoughSmsBalance"
        class="ReviewMessagesChatbotMessagesSettings__send-status-reason"
      >
        {{ $t('no_sms_balance') }}
      </div>
      <AppButton
        class="ReviewMessagesChatbotMessagesSettings__button"
        :label="$t('show_profile')"
        @click="clickShowProfile"
      />
    </AppDataItem>
    <AppDataItem
      class="ReviewMessagesChatbotMessagesSettings__chatbot-test"
      :label="$t('chatbot_test')"
    >
      <AppButton :label="$t('send_test_message')" @click="sendTestMessage" />
    </AppDataItem>
    <ChatbotMessageTemplates
      :class="[
        'ReviewMessagesChatbotMessagesSettings__chatbot-message-templates',
        { 'super-admin__item': !isChatbotSettingsPartTwoEnabled }
      ]"
    />
  </AppAjaxContent>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChatbotMessageTemplates from '../../chatbot_messages/components/ChatbotMessageTemplates';
import ChatbotMessageSendStatus from '@/enums/ChatbotMessageSendStatus';
import BizMessageStatus from '@/enums/BizMessageStatus';
import api from '@/lib/api';

export default {
  name: 'ReviewMessagesChatbotMessagesSettings',
  components: { ChatbotMessageTemplates },
  data() {
    return {
      isLoading: true,
      usePremiumChatbot: false,
      sendStatus: ChatbotMessageSendStatus.OPERATING,
      channelId: '',
      chatbotMessageStatus: BizMessageStatus.NONE,
      enoughSmsBalance: true
    };
  },
  computed: {
    ...mapGetters('session', ['isChatbotSettingsPartTwoEnabled']),
    profileTypeBadge() {
      const label = this.usePremiumChatbot
        ? this.$t('profile_type.premium')
        : this.$t('profile_type.standard');
      return { label };
    },
    sendStatusBadge() {
      switch (this.sendStatus) {
        case ChatbotMessageSendStatus.STOP:
          return { label: this.$t('send_status.stop'), badgeStyle: 'red' };
        case ChatbotMessageSendStatus.PENDING:
          return {
            label: this.$t('send_status.pending'),
            badgeStyle: 'default'
          };
        default:
          return {
            label: this.$t('send_status.operating'),
            badgeStyle: 'mint-green'
          };
      }
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/review/chatbot_messages/settings')
      .then(({ data }) => {
        const profile = data.profile;
        this.usePremiumChatbot = profile.premium;
        this.sendStatus = profile.send_status;
        this.channelId = profile.channel_id;
        this.chatbotMessageStatus = profile.chatbot_message_status;
        this.enoughSmsBalance = profile.enough_sms_balance;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickShowProfile() {
      this.openDialog([
        'ChatbotMessageProfileDialog',
        {
          profileTypeBadge: this.profileTypeBadge,
          channelId: this.channelId,
          chatbotMessageStatus: this.chatbotMessageStatus,
          usePremiumChatbot: this.usePremiumChatbot
        }
      ]);
    },
    sendTestMessage() {
      this.openDialog('ChatbotMessageTestMessageDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesChatbotMessagesSettings__send-status-reason {
  @include text-caption-red;
  margin-top: 8px;
}

.ReviewMessagesChatbotMessagesSettings__button {
  margin-top: 8px;
}

.ReviewMessagesChatbotMessagesSettings__chatbot-test {
  margin-top: 32px;
}

.ReviewMessagesChatbotMessagesSettings__chatbot-message-templates {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "profile": "발신 프로필",
  "profile_type": {
    "standard": "일반 챗봇",
    "premium": "프리미엄 챗봇"
  },
  "send_status": {
    "stop": "발송중단",
    "pending": "발송대기",
    "operating": "발송중"
  },
  "registered": "카카오 채널 아이디: {0}",
  "no_sms_balance": "잔여 메시지가 부족하여 챗봇 발송이 중단되었습니다. 발송이 끊기지 않도록 유료 메시지를 결제하여 챗봇을 발송해주세요.",
  "show_profile": "프로필 상세보기",
  "chatbot_test": "메시지 미리보기",
  "send_test_message": "테스트 메시지 발송"
}
</i18n>
