<template>
  <AppModalDefault
    class="TheAdvancedSettingsDialog"
    :title="$t('title', [currentBrand.name])"
    is-maximized-by-default
    is-max-height
    @close="close"
  >
    <template #body>
      <AppTabs
        :tabs="childTabItems('advanced_settings_dialog')"
        :active-tab="activeTab"
        @change="setActiveTab"
      />
      <Component :is="activeComponent" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';
import TheAdvancedSettingsDialogBasic from '@/layouts/components/TheAdvancedSettingsDialog/TheAdvancedSettingsDialogBasic';
import TheAdvancedSettingsDialogWidgetInstallation from '@/layouts/components/TheAdvancedSettingsDialog/TheAdvancedSettingsDialogWidgetInstallation';
import TheAdvancedSettingsDialogDangerZone from '@/layouts/components/TheAdvancedSettingsDialog/TheAdvancedSettingsDialogDangerZone';

export default {
  name: 'TheAdvancedSettingsDialog',
  components: {
    TheAdvancedSettingsDialogBasic,
    TheAdvancedSettingsDialogWidgetInstallation,
    TheAdvancedSettingsDialogDangerZone
  },
  mixins: [DialogView],
  data() {
    return {
      activeTab: 'advanced_settings_danger'
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('menu', ['childTabItems']),
    activeComponent() {
      switch (this.activeTab) {
        case 'advanced_settings_basic':
          return 'TheAdvancedSettingsDialogBasic';
        case 'advanced_settings_widget_installation':
          return 'TheAdvancedSettingsDialogWidgetInstallation';
        case 'advanced_settings_danger':
          return 'TheAdvancedSettingsDialogDangerZone';
        default:
          return 'TheAdvancedSettingsDialogDangerZone';
      }
    }
  },
  methods: {
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheAdvancedSettingsDialog__container {
  text-align: center;
}

::v-deep {
  .AppModalDefault__body {
    min-height: calc(100vh - 64px);
    background-color: $color-grey-05;
  }
}
</style>

<i18n locale="ko">
{
  "title": "{0} 고급설정"
}
</i18n>
