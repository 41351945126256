<template>
  <AppModal
    :width="currentWidth"
    :left-width="leftWidth"
    :maximized="maximized"
    :default-left-min-width="defaultLeftMinWidth"
    :default-main-min-width="defaultMainMinWidth"
    :show-bubble="showBubbleMid"
    @close="close"
  >
    <template slot="left">
      <slot name="left" />
    </template>
    <div slot="head" class="AppModalDefault__head">
      <div class="AppModalDefault__head-wrapper">
        <div v-if="!useCustomTitle" class="AppModalDefault__head-title-wrapper">
          <span class="AppModalDefault__head-title">{{ title }}</span>
          <span v-if="subTitle" class="AppModalDefault__head-sub-title">{{
            subTitle
          }}</span>
        </div>
        <div class="AppModalDefault__head-button-wrapper">
          <slot name="head-button" />
          <AppModalHeadMaximizeButton
            v-if="canMaximize"
            class="AppModalDefault__head-maximize-button"
            :maximized="maximized"
            @maximize="toggleMaximized"
          />
          <AppModalHeadCloseButton
            v-if="!hideHeadCloseButton"
            class="AppModalDefault__head-close-button"
            :disabled="closeButtonDisabled"
            @close="close"
          />
          <AppBubble
            v-if="!isBubbleHidden"
            type="left"
            class="AppModalDefault__head-maximize-bubble"
            @click="hideBubble"
          >
            {{ this.$t('bubble_message') }}
          </AppBubble>
        </div>
      </div>
      <slot name="sub-head" />
    </div>
    <div
      slot="body"
      :class="[
        'AppModalDefault__body',
        bodyStyle ? `AppModalDefault__body--style-${bodyStyle}` : null,
        isMaxHeight && maximized
          ? 'AppModalDefault__body--max-height-maximized'
          : isMaxHeight
          ? 'AppModalDefault__Body--max-height'
          : ''
      ]"
    >
      <slot name="body" />
    </div>
    <div
      v-if="$slots.foot || $scopedSlots.foot"
      slot="foot"
      class="AppModalDefault__foot"
    >
      <slot name="foot" />
    </div>
  </AppModal>
</template>

<script>
import AppModalHeadCloseButton from './AppModal/AppModalHeadCloseButton';
import AppModalHeadMaximizeButton from './AppModal/AppModalHeadMaximizeButton';
import ModalSizeHelper from '@/mixins/ModalSizeHelper';

export default {
  name: 'AppModalDefault',
  components: { AppModalHeadCloseButton, AppModalHeadMaximizeButton },
  mixins: [ModalSizeHelper],
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: null
    },
    isMaxHeight: {
      type: Boolean,
      default: false
    },
    canMaximize: {
      type: Boolean,
      default: false
    },
    isMaximizedByDefault: {
      type: Boolean,
      default: false
    },
    useCustomTitle: {
      type: Boolean,
      default: false
    },
    closeButtonDisabled: { type: Boolean, default: false },
    bodyStyle: {
      type: String,
      default: '',
      validator: v => ['tabs-scrollable', ''].includes(v)
    },
    defaultLeftMinWidth: {
      type: Number,
      default: 600
    },
    defaultMainMinWidth: {
      type: Number,
      default: 418
    },
    showBubbleTop: {
      type: Boolean,
      default: false
    },
    showBubbleMid: {
      type: Boolean,
      default: false
    },
    hideHeadCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maximized: this.isMaximizedByDefault,
      bubbleHidden: false
    };
  },
  computed: {
    leftWidth() {
      if (this.currentWidth?.match(/^\d+%$/))
        return `${100 - parseInt(this.currentWidth)}%`;
      else return null;
    },
    isBubbleHidden() {
      if (!this.showBubbleTop) return true;
      if (this.maximized) return true;

      return this.bubbleHidden;
    }
  },
  watch: {
    maximized: {
      handler(val) {
        if (val) this.hideBubble();
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toggleMaximized() {
      this.maximized = !this.maximized;
    },
    hideBubble() {
      this.bubbleHidden = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppModalDefault__head {
  padding: 11px 10px 13px 23px;
}

.AppModalDefault__head-wrapper {
  position: relative;
  display: flex;
}

.AppModalDefault__head-title-wrapper {
  padding-top: 7px;
  padding-bottom: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
}

.AppModalDefault__head-title {
  @include text-modal-title;
}

.AppModalDefault__head-button-wrapper {
  margin-left: auto;
}

.AppModalDefault__head-sub-title {
  margin-left: 8px;
  @include text-modal-title-light;
}

.AppModalDefault__head-close-button {
  margin-right: 10px;
}

.AppModalDefault__head-maximize-bubble {
  right: 80px;
}

.AppModalDefault__body {
  padding: 12px 23px 16px 23px;

  &--max-height {
    min-height: calc(100vh - 167px);
  }

  &--max-height-maximized {
    min-height: calc(100vh - 128px);
  }

  &--style-tabs-scrollable {
    padding-top: 0;
  }
}

.AppModalDefault__foot {
  position: relative;
  padding: 16px 23px 15px 23px;
}
</style>

<i18n locale="ko">
{
  "bubble_message": "전체화면으로 더 넓은 영역에서 미리보기를 확인할 수 있습니다."
}
</i18n>

<i18n locale="en">
{
  "bubble_message": "Preview is also available in full screen mode."
}
</i18n>
