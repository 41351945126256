<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppContainer class="ReviewBulkReviewCsvs">
      <AppForm
        v-bind="formProps"
        object-id="bulk_review_csv"
        :is-submitting="isSubmitting"
        :submit-button="{
          submitLabel: $t('app.upload'),
          submittingLabel: $t('app.uploading')
        }"
        v-on="formEvents"
        @change-group="formGroupChanged"
      />
    </AppContainer>
    <AppResourceTable
      table-id="bulk-review-csvs-table"
      :table-name="$t('csv_table.title')"
      :resources="bulk_review_csvs"
      :columns="COLUMNS"
      :rows="rows"
      @paginate="paginate"
    >
      <template #additional-table-searchbar>
        <AppSelect
          v-model="resourceParams.brand_csv_status"
          :options="CSV_STATUS_SEARCH_OPTIONS"
          @change="searchResource"
        />
      </template>
      <template #cell="{ column, value, row }">
        <template v-if="column === 'brandCsvStatus'">
          <AppBadge
            :badge-style="dataStatusBadgeInfo(value.status).style"
            :label="dataStatusBadgeInfo(value.status).label"
            :tooltip="value.tooltipMessage"
          />
        </template>
        <template v-if="column === 'action'">
          <AppButton>
            <a :href="value.csvDownloadUrl">
              {{ $t('download_csv_file') }}
            </a>
          </AppButton>
          <AppButton
            v-if="value.showErrorCsvDownloadButton"
            :disabled="!value.errorCsvDownloadUrl"
            :tooltip="value.errorCsvDownloadTooltip"
          >
            <a :href="value.errorCsvDownloadUrl">
              {{ $t('download_error_csv_file') }}
            </a>
          </AppButton>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BrandCsvType from '@/enums/BrandCsvType';
import BrandCsvStatus from '@/enums/BrandCsvStatus';
import FormView from '@/mixins/FormView';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'ReviewBulkReviewCsvs',
  mixins: [FormView, ResourceView],
  data() {
    return {
      showDownloadCsvForm: false,
      COLUMNS: [
        {
          id: 'bulkReviewCsvId',
          label: this.$t('csv_table.index'),
          width: '100px'
        },
        {
          id: 'createdAt',
          label: this.$t('csv_table.created_at')
        },
        {
          id: 'importedAt',
          label: this.$t('csv_table.imported_at')
        },
        {
          id: 'brandCsvStatus',
          label: this.$t('csv_table.csv_status')
        },
        {
          id: 'resultSummary',
          label: this.$t('csv_table.result_summary'),
          type: 'pre',
          headerAlign: 'center',
          align: 'left'
        },
        {
          id: 'action',
          label: this.$t('csv_table.action'),
          headerAlign: 'center',
          align: 'left',
          width: '300px'
        }
      ],
      SUMMARY_SHOWABLE_STATUSES: [
        BrandCsvStatus.IMPORTED,
        BrandCsvStatus.IMPORTED_PARTIAL
      ],
      CSV_STATUS_SEARCH_OPTIONS: [
        { label: this.$t('csv_status.all') },
        {
          label: this.$t('csv_status.import_pending'),
          value: BrandCsvStatus.IMPORT_PENDING
        },
        {
          label: this.$t('csv_status.importing'),
          value: BrandCsvStatus.IMPORTING
        },
        {
          label: this.$t('csv_status.imported'),
          value: BrandCsvStatus.IMPORTED
        },
        {
          label: this.$t('csv_status.import_failed'),
          value: BrandCsvStatus.IMPORT_FAILED
        },
        {
          label: this.$t('csv_status.imported_partial'),
          value: BrandCsvStatus.IMPORTED_PARTIAL
        }
      ]
    };
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand']),
    ...mapState('reviewBulkReviewCsv', ['bulk_review_csvs']),
    ...mapGetters('reviewBulkReviewCsv', [
      'isFetchingBulkReviewCsvs',
      'csvDownloadUrl',
      'errorCsvDownloadUrl'
    ]),
    isLoading() {
      return this.bulk_review_csvs.isNull || this.isFetchingBulkReviewCsvs;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_csv_type',
              type: 'select',
              label: this.$t('csv_type_input_title'),
              placeholder: this.$t('csv_type_placeholder'),
              options: this.isShopifyBrand
                ? BrandCsvType.options([
                    BrandCsvType.BULK_SHOPIFY_REVIEW,
                    BrandCsvType.BULK_JUDGE_ME_REVIEW,
                    BrandCsvType.BULK_REVIEW
                  ])
                : BrandCsvType.options([
                    BrandCsvType.BULK_V_REVIEW,
                    BrandCsvType.BULK_ALPHA_REVIEW,
                    BrandCsvType.BULK_SNAP_REVIEW,
                    BrandCsvType.BULK_REVIEW
                  ]),
              validate: ['required']
            },
            this.showDownloadCsvForm
              ? {
                  id: 'download_csv_template',
                  type: 'button_download',
                  label: this.$t('csv_form'),
                  groupDescription: this.$t('csv_download_button_description'),
                  value: this.$t('csv_download_button_label'),
                  url: '/csv/review_csv_template.csv',
                  filename: 'review_csv_template.csv'
                }
              : null,
            {
              id: 'file',
              type: 'file',
              label: this.$t('csv_upload_file_input_title'),
              placeholder: this.$t('csv_upload_file_input_placeholder'),
              accept: '.csv',
              validate: ['required']
            }
          ].filter(v => v)
        }
      ];
    },
    rows() {
      const { formatDateTime } = this.$options.filters;
      const rows = this.bulk_review_csvs.items.map((bulk_review_csv, index) => {
        const { brand_csv_status } = bulk_review_csv;
        return {
          bulkReviewCsvId: this.bulk_review_csvs.items.length - index,
          createdAt: formatDateTime(bulk_review_csv.created_at),
          importedAt: [
            BrandCsvStatus.IMPORT_FAILED,
            BrandCsvStatus.IMPORTED_PARTIAL
          ].includes(brand_csv_status)
            ? formatDateTime(bulk_review_csv.updated_at)
            : formatDateTime(bulk_review_csv.imported_at),
          brandCsvStatus: {
            status: brand_csv_status,
            tooltipMessage: this.importFailedTooltipMessage(bulk_review_csv)
          },
          resultSummary: this.resultSummary(bulk_review_csv),
          action: {
            csvDownloadUrl: this.csvDownloadUrl(bulk_review_csv.id),
            errorCsvDownloadUrl:
              brand_csv_status === BrandCsvStatus.IMPORTED_PARTIAL
                ? this.errorCsvDownloadUrl(bulk_review_csv.id)
                : null,
            errorCsvDownloadTooltip:
              brand_csv_status === BrandCsvStatus.IMPORT_FAILED
                ? this.$t('download_error_csv_tooltip')
                : null,
            showErrorCsvDownloadButton: [
              BrandCsvStatus.IMPORT_FAILED,
              BrandCsvStatus.IMPORTED_PARTIAL
            ].includes(brand_csv_status)
          }
        };
      });
      return rows;
    }
  },
  methods: {
    ...mapActions('reviewBulkReviewCsv', [
      'fetchBulkReviewCsvs',
      'createBulkReviewCsv'
    ]),
    fetchResource(params) {
      this.fetchBulkReviewCsvs(params);
    },
    submit(formData) {
      this.isSubmitting = true;
      this.createBulkReviewCsv({ formData }).finally(
        () => (this.isSubmitting = false)
      );
    },
    formGroupChanged(group) {
      if (group.id === 'brand_csv_type') {
        this.showDownloadCsvForm = group.value === BrandCsvType.BULK_REVIEW;
      }
    },
    resultSummary(bulkReviewCsv) {
      const { brand_csv_status } = bulkReviewCsv;
      if (brand_csv_status === BrandCsvStatus.IMPORT_FAILED)
        return bulkReviewCsv.error_message
          ?.replace(/\((detail).*\)$/, '')
          ?.trim();

      if (this.SUMMARY_SHOWABLE_STATUSES.includes(brand_csv_status)) {
        const { rows_count, failure_count } = bulkReviewCsv;
        const success_count = rows_count - failure_count;

        return this.$t('csv_result_summary', { success_count, failure_count });
      }

      return '-';
    },
    importFailedTooltipMessage(bulkReviewCsv) {
      if (bulkReviewCsv.brand_csv_status !== BrandCsvStatus.IMPORT_FAILED)
        return;

      return bulkReviewCsv.error_message?.match(/\((detail).*\)$/g)
        ? this.$t('import_failed_with_system_error')
        : this.$t('import_failed_with_header_missing');
    },
    dataStatusBadgeInfo(dataStatus) {
      switch (dataStatus) {
        case BrandCsvStatus.IMPORT_PENDING:
          return {
            style: 'default',
            label: this.$t('csv_status.import_pending')
          };
        case BrandCsvStatus.IMPORTING:
          return {
            style: 'mint-green-outline',
            label: this.$t('csv_status.importing')
          };
        case BrandCsvStatus.IMPORTED:
          return { style: 'mint-green', label: this.$t('csv_status.imported') };
        case BrandCsvStatus.IMPORTED_PARTIAL:
          return {
            style: 'red',
            label: this.$t('csv_status.imported_partial')
          };
        case BrandCsvStatus.IMPORT_FAILED:
          return { style: 'red', label: this.$t('csv_status.import_failed') };
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "csv_type_input_title": "CSV 파일 종류",
  "csv_type_placeholder": "리뷰 앱 선택",
  "csv_form": "CSV 파일 양식",
  "csv_download_button_description": "하단 버튼을 클릭해 파일을 다운로드하고, 양식에 맞게 파일을 수정하여 업로드해주세요. 양식이 다를 경우 문제가 발생할 수 있습니다.",
  "csv_download_button_label": "예시 CSV 파일 다운로드",
  "csv_upload_file_input_title": "CSV 파일 업로드",
  "csv_upload_file_input_placeholder": "업로드할 CSV 파일을 등록해주세요.",
  "csv_table": {
    "title": "리뷰 일괄 등록 내역",
    "index": "ID",
    "created_at": "등록일",
    "imported_at": "완료일",
    "csv_status": "상태",
    "result_summary": "결과",
    "action": "기능"
  },
  "csv_result_summary": "등록: {success_count}\n실패: {failure_count}",
  "csv_status": {
    "all": "모든 상태",
    "import_pending": "대기 중",
    "importing": "등록 중",
    "imported": "완료",
    "imported_partial": "일부 오류",
    "import_failed": "전체 오류"
  },
  "download_csv_file": "파일 다운로드",
  "download_error_csv_file": "오류 내역 다운로드",
  "download_error_csv_tooltip": "모든 항목 등록이 실패했습니다. '파일 다운로드' 버튼을 이용해주세요.",
  "import_failed_with_system_error": "시스템 오류가 발생했습니다. 재시도 후 오류가 반복되면 크리마로 문의해주세요.",
  "import_failed_with_header_missing": "CSV 파일에서 필수 항목이 누락되지 않았는지 확인해주세요."
}
</i18n>
