<template>
  <div class="ChatbotMessageTemplates">
    <div class="ChatbotMessageTemplates__title">
      {{ $t('title') }}
    </div>
    <AppGrid>
      <div
        v-for="(section, i) in sections"
        :key="i"
        class="AppGrid__col AppGrid__col--4"
      >
        <AppDataSection :title="section.title">
          <AppButton
            :label="$t('app.settings')"
            class="ChatbotMessageTemplates__settings-button"
            @click="section.onClick"
          />
        </AppDataSection>
      </div>
    </AppGrid>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ChatbotMessageTemplates',
  computed: {
    sections() {
      return [
        {
          title: this.$t('biz_message'),
          onClick: this.onClickBizMessageSettings
        },
        {
          title: this.$t('first_message'),
          onClick: this.onClickFirstMessageSettings
        },
        {
          title: this.$t('last_message'),
          onClick: this.onClickLastMessageSettings
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    onClickBizMessageSettings() {
      this.openDialog('ChatbotMessageTemplatesFormDialog');
    },
    onClickFirstMessageSettings() {
      alert('onClickFirstMessageSettings');
    },
    onClickLastMessageSettings() {
      alert('onClickLastMessageSettings');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ChatbotMessageTemplates__title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: $color-grey-50;
}

.ChatbotMessageTemplates__settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

<i18n locale="ko">
{
  "title": "챗봇 메시지 설정",
  "biz_message": "첫 메시지 (알림톡)",
  "first_message": "작성 시작 메시지",
  "last_message": "마지막 메시지"
}
</i18n>
