<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppStatCard
      v-tooltip="
        stats.campaign_start_date
          ? `${stats.campaign_start_date} ~ ${stats.end_date}`
          : null
      "
      class="ReviewMessagesCampaign__stat-card"
      :title="$t('stats.review_with_message_rate')"
      v-bind="reviewWithMessageStat"
    />
    <AppStatCard
      v-if="isSuperAdminAccessible"
      class="ReviewMessagesCampaign__stat-card super-admin__item"
      :title="$t('stats.review_without_message_rate')"
      v-bind="reviewWithoutMessageStat"
    />
    <div class="ReviewMessagesCampaign__stat-hint">
      {{
        $t('date_range', [
          formatDate(stats.start_date),
          formatDate(stats.end_date)
        ])
      }}
    </div>
    <AppResourceTable
      :columns="columns"
      :rows="rows"
      :resources="campaigns"
      table-id="campaigns"
      class="ReviewMessagesCampaign__table"
      @paginate="paginate"
    >
      <template #cell="{ row, column, value }">
        <template v-if="column === 'effect'">
          <ReviewMessagesCampaignsEffectCell :effect="row.effect" />
        </template>
        <template v-else-if="column === 'updated_at'">
          <ReviewMessagesCampaignsUpdatedAtCell
            :created-at="row.created_at"
            :updated-at="row.updated_at"
          />
        </template>
        <template v-else-if="column === 'manage'">
          <ReviewMessagesCampaignsManageButton
            :campaign="row"
            @change-status="refreshResource"
          />
          <AppButton
            :label="$t('app.settings')"
            button-style="mango-outline"
            type="link"
            :to="`/review/messages/campaigns/${row.id}`"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import ReviewCampaignStatus from '@/enums/ReviewCampaignStatus';

export default {
  name: 'ReviewMessagesCampaigns',
  mixins: [ResourceView],
  data: () => ({
    isLoading: true,
    stats: {},
    usingReviewCampaign: false,
    campaigns: nullResources
  }),
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ReviewCampaignStatus: () => ReviewCampaignStatus,
    reviewWithMessageStat() {
      if (!this.usingReviewCampaign)
        return { detail: this.$t('stats.not_using_review_campaign') };
      const {
        campaign_start_date,
        reviews_with_message_count,
        orders_with_message_count
      } = this.stats;
      if (!campaign_start_date) return { detail: this.$t('stats.empty') };

      return {
        stat: this.stat(reviews_with_message_count, orders_with_message_count),
        detail: this.detail(
          reviews_with_message_count,
          orders_with_message_count
        )
      };
    },
    reviewWithoutMessageStat() {
      const {
        reviews_without_message_count,
        orders_without_message_count
      } = this.stats;
      return {
        stat: this.stat(
          reviews_without_message_count,
          orders_without_message_count
        ),
        detail: this.detail(
          reviews_without_message_count,
          orders_without_message_count
        )
      };
    },
    columns() {
      return [
        {
          id: 'name',
          label: this.$t('column.name.label'),
          align: 'left'
        },
        {
          id: 'effect',
          label: this.$t('column.effect.label'),
          width: '320px',
          superAdmin: true
        },
        {
          id: 'updated_at',
          label: this.$t('column.updated_at.label'),
          width: '160px'
        },
        {
          id: 'status',
          type: 'badge',
          label: this.$t('column.status.label'),
          width: '120px'
        },
        {
          id: 'manage',
          label: this.$t('column.manage.label'),
          width: '150px',
          align: 'right'
        }
      ];
    },
    rows() {
      return this.campaigns.items.map(c => ({
        id: c.id,
        name: ReviewCampaignType.t(c.campaign_type),
        effect: c.effect,
        status: {
          badgeStyle: ReviewCampaignStatus.badgeStyle(c.status),
          label: ReviewCampaignStatus.t(c.status)
        },
        created_at: this.formatDateTime(c.created_at),
        updated_at: this.formatDateTime(c.updated_at)
      }));
    }
  },
  mounted() {
    api
      .get('/review/campaign/stats')
      .then(({ data }) => {
        this.usingReviewCampaign = data.using_review_campaign;
        this.stats = data.stats;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/review/campaigns', { params })
        .then(({ data }) => {
          this.campaigns = data.campaigns;
        })
        .finally(() => (this.isLoading = false));
    },
    stat(reviewsCount, ordersCount) {
      return ordersCount ? reviewsCount / ordersCount : 0;
    },
    detail(reviewsCount, ordersCount) {
      if (!reviewsCount || !ordersCount) return '';
      return `${reviewsCount.toLocaleString()}/${ordersCount.toLocaleString()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesCampaign__stat-card {
  width: 260px;
  display: inline-block;

  & + & {
    margin-left: 8px;
    margin-top: 0;
  }
}

.ReviewMessagesCampaign__stat-hint {
  @include text-caption;
  margin-top: 4px !important;
}

.ReviewMessagesCampaign__table {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "stats": {
    "review_with_message_rate": "리뷰 작성률 (리뷰 작성유도 대상 주문)",
    "review_without_message_rate": "리뷰 작성률 (리뷰 작성유도 대상 외 주문)",
    "not_using_review_campaign": "리뷰 작성유도 캠페인 미사용 중",
    "empty": "효과 수집 중입니다."
  },
  "date_range": "*. 효과 수집을 완료한 {0} ~ {1} 사이의 값입니다.",
  "column": {
    "name": {
      "label": "캠페인 이름"
    },
    "effect": {
      "label": "효과"
    },
    "updated_at": {
      "label": "최근 수정일"
    },
    "status": {
      "label": "상태"
    },
    "manage": {
      "label": "관리"
    }
  }
}
</i18n>
