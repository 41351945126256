import _ from 'lodash';
import ReviewSort from '@/enums/ReviewSort';
import WidgetStyle from '@/enums/WidgetStyle';
import store from '@/store';

export default {
  formSections({
    widgetStyle,
    isDeviceMobile,
    isDevicePc,
    isRenewed,
    isGalleryRenewed,
    useVuejs
  }) {
    const display_widgets = [useVuejs ? 'show_widget_on_all_categories' : null];
    const display_reviews = ['tag_id', 'exclude_tag_id', 'filter_photo_only'];
    const isDevicePcOnly = isDevicePc && !isDeviceMobile;
    switch (widgetStyle) {
      case WidgetStyle.LIST:
        return {
          common: ['no_item_action'],
          display_reviews,
          sort: ['display_order'],
          feature: [
            'show_header_section',
            isDevicePc && !isRenewed ? 'thumbnail_summary' : null,
            'show_my_review_first',
            'review_show_photo_first'
          ],
          design: [
            'posts_per_page',
            'message_initial_rows',
            isRenewed ? null : 'show_image_when_collapsed',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null,
            isDeviceMobile ? 'keep_board_style_for_mobile' : null,
            isDevicePc && !isRenewed ? 'camera_icon' : null
          ]
        };
      case WidgetStyle.LIST_V3:
        return {
          common: ['no_item_action'],
          display_reviews,
          sort: ['display_order'],
          feature: [
            'show_header_section',
            'show_my_review_first',
            'review_show_photo_first',
            'use_ai_summary'
          ],
          design: [
            'posts_per_page',
            'message_initial_rows',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null,
            isDeviceMobile ? 'keep_board_style_for_mobile' : null
          ]
        };
      case WidgetStyle.GALLERY:
        return {
          common: ['no_item_action'],
          display_widgets,
          display_reviews: [
            useVuejs ? null : 'exclude_category_ids', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            'tag_id',
            'exclude_tag_id',
            useVuejs && isDeviceMobile ? 'show_video_only' : null
          ],
          sort: ['display_orders_in_use'],
          feature: ['show_header_section', 'show_my_review_first'],
          design: [
            isDevicePcOnly ? null : 'posts_per_page',
            isDevicePcOnly && !isGalleryRenewed ? 'use_custom_layout' : null,
            isDevicePcOnly && isGalleryRenewed ? 'reviews_custom_layout' : null,
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            'show_thumbnail_as_square',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isGalleryRenewed ? 'message_initial_rows' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.BOARD:
      case WidgetStyle.DETACHABLE_BOARD:
        return {
          common: ['no_item_action'],
          display_reviews,
          sort: ['display_order'],
          feature: [
            'show_header_section',
            'show_my_review_first',
            'review_show_photo_first'
          ],
          design: [
            'posts_per_page',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.GRID:
        return {
          common: ['posts_per_page', 'no_item_action'],
          display_reviews,
          sort: ['display_order'],
          feature: [
            'external_widget',
            'show_product_score',
            'review_show_photo_first'
          ],
          design: [
            'title',
            'posts_per_line',
            'photo_review_thumbnails_per_widget',
            isDevicePc ? 'use_reviews_button' : 'reviews_button_title',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.PHOTO_THUMBNAIL:
        return {
          common: ['posts_per_page', 'no_item_action'],
          display_widgets,
          display_reviews: [
            useVuejs ? null : 'exclude_category_ids', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            'tag_id',
            'exclude_tag_id'
          ],
          sort: ['display_order'],
          feature: [
            useVuejs ? 'show_product_score' : null,
            'photo_thumbnail_color'
          ],
          design: [
            'title',
            useVuejs ? null : 'posts_per_line',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null
          ]
        };
      case WidgetStyle.OPEN_MARKET:
        return {
          common: ['posts_per_page', 'no_item_action'],
          display_reviews: ['tag_id', 'exclude_tag_id', 'display_review_type'],
          sort: ['display_order'],
          feature: [
            'external_widget',
            'show_product_score',
            'review_show_photo_first'
          ],
          design: ['title']
        };
      case WidgetStyle.EXTERNAL:
        return {
          common: ['no_item_action'],
          display_reviews,
          sort: ['display_order'],
          feature: [
            'display_admin_comments_first',
            useVuejs ? null : 'display_review_source_message_summary_on_list', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            'display_share_message',
            'show_social_media_title'
          ],
          design: [
            'posts_per_page',
            isDevicePc ? 'posts_per_line' : null,
            'comments_count_on_list',
            'message_initial_rows',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.RESPONSIVE:
        return {
          common: ['no_item_action'],
          display_reviews,
          sort: ['display_order'],
          feature: [
            'thumbnail_summary',
            'show_product_score',
            'show_my_review_first',
            'review_show_photo_first'
          ],
          design: [
            'title',
            'posts_per_page',
            'message_initial_rows',
            'show_image_when_collapsed',
            'reviews_button_title',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.HORIZONTAL_SCROLL:
        return {
          common: ['posts_per_page', 'no_item_action'],
          display_widgets,
          display_reviews: [
            useVuejs ? null : 'exclude_category_ids', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            'tag_id',
            'exclude_tag_id'
          ],
          sort: ['display_order'],
          feature: [
            useVuejs ? 'show_header_section' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거 (수정)
            'horizontal_scroll_enable_autoscroll'
          ],
          design: [
            useVuejs ? null : 'title', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            'show_thumbnail_as_square',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            useVuejs ? 'message_initial_rows' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거 (수정)
            'horizontal_scroll_contents_to_show',
            isDevicePc
              ? 'horizontal_scroll_initial_posts_on_page_for_pc'
              : null,
            isDeviceMobile ? 'horizontal_scroll_initial_posts_on_page' : null
          ]
        };
      case WidgetStyle.PRODUCT_OPTIONS:
        return {
          common: ['posts_per_page', 'no_item_action'],
          display_reviews: ['filter_photo_only'],
          sort: ['display_order'],
          feature: ['review_show_photo_first'],
          design: [
            'title',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null
          ]
        };
      case WidgetStyle.KEYWORD:
        return {
          common: ['posts_per_page', 'no_item_action'],
          display_widgets,
          display_reviews: [useVuejs ? null : 'exclude_category_ids'],
          sort: ['display_order'],
          design: [
            'title',
            'description_text',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null
          ]
        };
      case WidgetStyle.STATISTICS:
        return {
          common: ['no_item_action'],
          display_widgets,
          design: ['title', 'highlight_statistical_results', 'main_color']
        };
      default:
        return {};
    }
  },
  widgetStyles({
    currentWidgetStyle,
    isSuperAdminAccessible,
    isDevicePc,
    isListV3InstallationEnabled
  }) {
    const widgetStyles = isSuperAdminAccessible
      ? isDevicePc
        ? WidgetStyle.PC_PRODUCT_REVIEWS_STYLES_FOR_ADMIN
        : WidgetStyle.MOBILE_PRODUCT_REVIEWS_STYLES_FOR_ADMIN
      : _.union(
          [currentWidgetStyle],
          WidgetStyle.PRODUCT_REVIEWS_COMMON_STYLES
        );

    const finalWidgetStyles = [
      ...widgetStyles,
      isListV3InstallationEnabled ? WidgetStyle.LIST_V3 : null
    ];

    return finalWidgetStyles.filter(v => v !== null && v !== undefined);
  },
  displayOrderOptions() {
    const reviewSortOptions = store.getters['reviewWidgets/reviewSortOptions'];
    return reviewSortOptions([
      ReviewSort.RECENTLY_CREATED,
      ReviewSort.MOST_SCORES,
      ReviewSort.MOST_LIKES
    ]);
  },
  showHeaderSectionSubSettings({
    widgetStyle,
    isDevicePc,
    isDeviceMobile,
    isRenewed,
    isGalleryRenewed,
    isUsingNlp,
    useVuejs
  }) {
    switch (widgetStyle) {
      case WidgetStyle.LIST:
        return [
          'show_title',
          isRenewed ? 'description_text' : null,
          'reviews_button_title',
          useVuejs ? 'show_notices' : null,
          'show_product_score',
          isRenewed ? 'show_media_summary' : null,
          isRenewed ? 'show_score_filter' : null,
          isUsingNlp && isRenewed ? 'use_review_keyword_filter' : null
        ].filter(v => v);
      case WidgetStyle.HORIZONTAL_SCROLL:
        if (!useVuejs) return []; // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
        return [
          'show_title',
          'description_text',
          isDevicePc ? 'reviews_button_title' : null,
          'show_notices',
          'show_score_filter'
        ].filter(v => v);
      case WidgetStyle.GALLERY:
        return [
          isGalleryRenewed ? 'show_title' : 'review_options_title_html',
          isGalleryRenewed ? 'description_text' : null,
          isGalleryRenewed ? 'reviews_button_title' : null,
          isGalleryRenewed ? 'show_notices' : null,
          isGalleryRenewed ? 'show_score_filter' : null,
          isGalleryRenewed ? 'show_search_section' : null
        ].filter(v => v);
      case WidgetStyle.BOARD:
        return [
          useVuejs ? 'show_title' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          useVuejs ? 'description_text' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          isDevicePc ? 'reviews_button_title' : null,
          useVuejs ? 'show_notices' : null,
          useVuejs ? 'show_product_score' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          useVuejs ? 'show_media_summary' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          useVuejs && isDevicePc ? 'photo_thumbnail_color' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          useVuejs ? 'show_score_filter' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          !useVuejs && isDevicePc ? 'title' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          !useVuejs && isDevicePc ? 'show_product_score' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          !useVuejs && isDevicePc ? 'thumbnail_summary' : null // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
        ].filter(v => v);
      case WidgetStyle.DETACHABLE_BOARD:
        return [
          isDevicePc ? 'title' : null,
          isDevicePc ? 'reviews_button_title' : null,
          useVuejs && isDeviceMobile ? 'show_notices' : null,
          isDevicePc ? 'show_product_score' : null,
          isDevicePc ? 'thumbnail_summary' : null
        ].filter(v => v);
      default:
        return [];
    }
  },
  useBackgroundColor({ widgetStyle }) {
    const useBackgroundColorWidgetStyles = [
      WidgetStyle.LIST,
      WidgetStyle.LIST_V3,
      WidgetStyle.GALLERY,
      WidgetStyle.PHOTO_THUMBNAIL,
      WidgetStyle.BOARD,
      WidgetStyle.DETACHABLE_BOARD,
      WidgetStyle.HORIZONTAL_SCROLL,
      WidgetStyle.EXTERNAL,
      WidgetStyle.KEYWORD,
      WidgetStyle.PRODUCT_OPTIONS
    ];
    return useBackgroundColorWidgetStyles.includes(widgetStyle);
  }
};
